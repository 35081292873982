define('twapi/models/ira-detail', ['exports', 'ember-restless', '@tastyworks/tastyworks-api', 'twapi/utils/deser', 'twapi/utils/validation', 'ember-decorators/object', 'twapi/models/external-transaction'], function (exports, _emberRestless, _tastyworksApi, _deser, _validation, _object, _externalTransaction) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _dec2, _dec3, _dec4, _dec5, _desc, _value, _obj;

  var Model = _emberRestless.default.Model;
  var _Ember$computed = Ember.computed,
      equal = _Ember$computed.equal,
      or = _Ember$computed.or,
      isBlank = Ember.isBlank,
      isNone = Ember.isNone,
      get = Ember.get,
      set = Ember.set,
      getProperties = Ember.getProperties,
      setProperties = Ember.setProperties;


  var RECURRING_DEPOSIT_ELIGIBLE_REASONS = [_externalTransaction.IRA_CONTRIBUTION_REASONS.REGULAR, _externalTransaction.IRA_CONTRIBUTION_REASONS.EMPLOYEE, _externalTransaction.IRA_CONTRIBUTION_REASONS.EMPLOYER, _externalTransaction.IRA_CONTRIBUTION_REASONS.TRANSFER];
  var NEEDS_NIA_DISTRIBUTION_REASONS = [_externalTransaction.IRA_DISTRIBUTION_REASONS.EXCESS_CONTRIBUTION_REMOVAL_BEFORE_TAX_DEADLINE, _externalTransaction.IRA_DISTRIBUTION_REASONS.EXCESS_CONTRIBUTION_REMOVAL_AFTER_TAX_DEADLINE, _externalTransaction.IRA_DISTRIBUTION_REASONS.RECHARACTERIZATION_PRIOR_YEAR, _externalTransaction.IRA_DISTRIBUTION_REASONS.RECHARACTERIZATION_CURRENT_YEAR];
  var MIN_WITHHOLDING_AMOUNT = 0.01;

  function dollarAmount(withholdingAmount, withholdingType, isPercentType, totalAmount) {
    if (isBlank(totalAmount) || isBlank(withholdingAmount) || isBlank(withholdingType)) {
      return 0;
    }

    return isPercentType ? totalAmount * (withholdingAmount / 100) : withholdingAmount;
  }

  exports.default = Model.extend((_dec = (0, _object.computed)('distributionReason'), _dec2 = (0, _object.computed)('contributionReason', 'distributionReason'), _dec3 = (0, _object.computed)('contributionReason', 'isPreviousYear'), _dec4 = (0, _object.computed)('stateWithholding', 'stateWithholdingType', 'isStatePercentType', 'totalAmount'), _dec5 = (0, _object.computed)('federalWithholding', 'federalWithholdingType', 'isFederalPercentType', 'totalAmount'), (_obj = {
    isStateDollarType: equal('stateWithholdingType', _tastyworksApi.WITHHOLDING_TYPES.FIXED),

    isStatePercentType: equal('stateWithholdingType', _tastyworksApi.WITHHOLDING_TYPES.PERCENT),

    isFederalDollarType: equal('federalWithholdingType', _tastyworksApi.WITHHOLDING_TYPES.FIXED),

    isFederalPercentType: equal('federalWithholdingType', _tastyworksApi.WITHHOLDING_TYPES.PERCENT),

    isTransferDistribution: equal('distributionReason', _externalTransaction.IRA_DISTRIBUTION_REASONS.TRANSFER),

    hasFederalWithholding: false,

    hasStateWithholding: false,

    isPreviousYear: equal('contributionYear', _tastyworksApi.IraDetail.previousYear()),

    isPremature: equal('distributionReason', _externalTransaction.IRA_DISTRIBUTION_REASONS.PREMATURE),

    isRegularContribution: equal('contributionReason', _externalTransaction.IRA_CONTRIBUTION_REASONS.REGULAR),

    isConversionDistribution: equal('distributionReason', _externalTransaction.IRA_DISTRIBUTION_REASONS.CONVERSION),

    contributionNeedsNiaCalculation: equal('contributionReason', _externalTransaction.IRA_CONTRIBUTION_REASONS.RECHARACTERIZATION),

    needsNiaCalculation: or('distributionNeedsNiaCalculation', 'contributionNeedsNiaCalculation'),

    distributionNeedsNiaCalculation: function distributionNeedsNiaCalculation(distributionReason) {
      if (isNone(distributionReason)) {
        return false;
      }
      return NEEDS_NIA_DISTRIBUTION_REASONS.includes(distributionReason);
    },
    hasRecharacterization: function hasRecharacterization(contributionReason, distributionReason) {
      if (contributionReason === _externalTransaction.IRA_CONTRIBUTION_REASONS.RECHARACTERIZATION) {
        return true;
      }

      return distributionReason === _externalTransaction.IRA_DISTRIBUTION_REASONS.RECHARACTERIZATION_PRIOR_YEAR || distributionReason === _externalTransaction.IRA_DISTRIBUTION_REASONS.RECHARACTERIZATION_CURRENT_YEAR;
    },
    isRecurringDepositEligible: function isRecurringDepositEligible(contributionReason, isPreviousYear) {
      if (isPreviousYear) {
        return false;
      }
      if (isNone(contributionReason)) {
        return false;
      }

      return RECURRING_DEPOSIT_ELIGIBLE_REASONS.includes(contributionReason);
    },


    minStateWithholdingAmount: MIN_WITHHOLDING_AMOUNT,

    minFederalWithholdingAmount: MIN_WITHHOLDING_AMOUNT,

    totalAmount: null,

    stateDollarAmount: function stateDollarAmount(withholdingAmount, withholdingType, isPercentType, totalAmount) {
      return dollarAmount(withholdingAmount, withholdingType, isPercentType, totalAmount);
    },
    federalDollarAmount: function federalDollarAmount(withholdingAmount, withholdingType, isPercentType, totalAmount) {
      return dollarAmount(withholdingAmount, withholdingType, isPercentType, totalAmount);
    }
  }, (_applyDecoratedDescriptor(_obj, 'distributionNeedsNiaCalculation', [_dec], Object.getOwnPropertyDescriptor(_obj, 'distributionNeedsNiaCalculation'), _obj), _applyDecoratedDescriptor(_obj, 'hasRecharacterization', [_dec2], Object.getOwnPropertyDescriptor(_obj, 'hasRecharacterization'), _obj), _applyDecoratedDescriptor(_obj, 'isRecurringDepositEligible', [_dec3], Object.getOwnPropertyDescriptor(_obj, 'isRecurringDepositEligible'), _obj), _applyDecoratedDescriptor(_obj, 'stateDollarAmount', [_dec4], Object.getOwnPropertyDescriptor(_obj, 'stateDollarAmount'), _obj), _applyDecoratedDescriptor(_obj, 'federalDollarAmount', [_dec5], Object.getOwnPropertyDescriptor(_obj, 'federalDollarAmount'), _obj)), _obj)), _deser.buildModel(_tastyworksApi.IRA_DETAIL_DESER)).reopen(_validation.build({
    distributionReason: {
      presence: true,
      inclusion: { in: Object.values(_externalTransaction.IRA_DISTRIBUTION_REASONS) }
    },
    contributionReason: {
      presence: true,
      inclusion: { in: Object.values(_externalTransaction.IRA_CONTRIBUTION_REASONS) }
    },
    contributionYear: {
      presence: true,
      inclusion: { in: Object.values([_tastyworksApi.IraDetail.previousYear(), _tastyworksApi.IraDetail.currentYear()]) }
    },
    stateWithholdingType: {
      presence: {
        presence: true
      },
      inclusion: {
        in: Object.values(_tastyworksApi.WITHHOLDING_TYPES)
      }
    },
    federalWithholdingType: {
      presence: {
        presence: true
      },
      inclusion: {
        in: Object.values(_tastyworksApi.WITHHOLDING_TYPES)
      }
    }
  })).reopen({
    sanitize: function sanitize() {
      if (isNone(get(this, 'distributionReason'))) {
        setProperties(this, {
          federalWithholding: null,
          federalWithholdingType: null,
          stateWithholding: null,
          stateWithholdingType: null
        });
        return this;
      }

      var _getProperties = getProperties(this, ['hasFederalWithholding', 'hasStateWithholding']),
          hasFederalWithholding = _getProperties.hasFederalWithholding,
          hasStateWithholding = _getProperties.hasStateWithholding;

      if (!hasFederalWithholding) {
        set(this, 'federalWithholding', null);
        set(this, 'federalWithholdingType', null);
      }
      if (!hasStateWithholding) {
        set(this, 'stateWithholding', null);
        set(this, 'stateWithholdingType', null);
      }
      return this;
    }
  }).reopenClass({
    resourceName: 'ira-detail'
  });
});