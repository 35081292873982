define('twapi/components/beneficiary/twapi-beneficiary-percentages', ['exports', 'twapi/models/account-beneficiary', 'ember-decorators/object'], function (exports, _accountBeneficiary, _object) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _dec2, _dec3, _desc, _value, _obj;

  exports.default = Ember.Component.extend((_dec = (0, _object.computed)('twapiBeneficiaries.beneficiaries.@each.beneficiaryType', 'type'), _dec2 = (0, _object.computed)('beneficiaries.@each.beneficiaryPercent', 'percentage'), _dec3 = (0, _object.computed)('beneficiaries.@each.beneficiaryPercent', 'type'), (_obj = {
    twapiBeneficiaries: Ember.inject.service(),

    showPercentages: Ember.computed.or('isPrimary', 'hasBeneficiaries'),

    hasBeneficiaries: Ember.computed.bool('beneficiaries.length'),

    isPrimary: Ember.computed.equal('type', _accountBeneficiary.BeneficiaryTypes.PRIMARY),

    beneficiaries: function beneficiaries(_beneficiaries, type) {
      return _beneficiaries.filter(function (beneficiary) {
        return type === beneficiary.get('beneficiaryType');
      });
    },
    percentIsValid: function percentIsValid(beneficiaries, percentage) {
      var hasZeroes = beneficiaries.some(function (beneficiary) {
        return Number(beneficiary.get('beneficiaryPercent')) === 0;
      });

      return percentage === 100 && !hasZeroes;
    },
    percentage: function percentage(beneficiaries, type) {
      return this.get('twapiBeneficiaries').getPercentages(type);
    }
  }, (_applyDecoratedDescriptor(_obj, 'beneficiaries', [_dec], Object.getOwnPropertyDescriptor(_obj, 'beneficiaries'), _obj), _applyDecoratedDescriptor(_obj, 'percentIsValid', [_dec2], Object.getOwnPropertyDescriptor(_obj, 'percentIsValid'), _obj), _applyDecoratedDescriptor(_obj, 'percentage', [_dec3], Object.getOwnPropertyDescriptor(_obj, 'percentage'), _obj)), _obj)));
});