define('twapi/utils/ember-run', ['exports', 'twapi/utils/decorator'], function (exports, _decorator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.throttle = exports.debounce = exports.scheduleOnce = exports.schedule = exports.once = undefined;
  var once = exports.once = (0, _decorator.asDecorator)(function (fn) {
    return function () {
      var _Ember$run;

      (_Ember$run = Ember.run).once.apply(_Ember$run, [this, fn].concat(Array.prototype.slice.call(arguments)));
    };
  });

  var schedule = exports.schedule = (0, _decorator.asDecorator)(function (queue, fn) {
    return function () {
      var _Ember$run2;

      (_Ember$run2 = Ember.run).schedule.apply(_Ember$run2, [queue, this, fn].concat(Array.prototype.slice.call(arguments)));
    };
  });

  var scheduleOnce = exports.scheduleOnce = (0, _decorator.asDecorator)(function (queue, fn) {
    return function () {
      var _Ember$run3;

      (_Ember$run3 = Ember.run).scheduleOnce.apply(_Ember$run3, [queue, this, fn].concat(Array.prototype.slice.call(arguments)));
    };
  });

  var debounce = exports.debounce = (0, _decorator.asDecorator)(function (wait, immediate, fn) {
    if (fn == null) {
      fn = immediate;
      immediate = false;
    }

    return function () {
      var _Ember$run4;

      (_Ember$run4 = Ember.run).debounce.apply(_Ember$run4, [this, fn].concat(Array.prototype.slice.call(arguments), [wait, immediate]));
    };
  });

  var throttle = exports.throttle = (0, _decorator.asDecorator)(function (wait, immediate, fn) {
    if (fn == null) {
      fn = immediate;
      immediate = true;
    }

    return function () {
      var _Ember$run5;

      (_Ember$run5 = Ember.run).throttle.apply(_Ember$run5, [this, fn].concat(Array.prototype.slice.call(arguments), [wait, immediate]));
    };
  });
});