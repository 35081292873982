define('twapi/utils/sanitize', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.symbol = symbol;
  exports.currency = currency;
  exports.stripSpaces = stripSpaces;
  exports.taxNumber = taxNumber;
  exports.oneTimePassword = oneTimePassword;
  exports.accountTypeName = accountTypeName;
  function symbol(value) {
    return _lodash.default.trim(value).toUpperCase();
  }

  function currency(value) {
    return _lodash.default.trim(value).replace(/[$,]/g, '');
  }

  function stripSpaces(value) {
    return value.replace(/[\s]/g, '');
  }

  function taxNumber(value) {
    return _lodash.default.trim(value).replace(/\D/g, '');
  }

  function oneTimePassword(value) {
    return _lodash.default.trim(value).replace(/\D/g, '');
  }

  function accountTypeName(value) {
    return _lodash.default.trim(value).replace(/[^A-Za-z0-9 ]/g, '');
  }
});