define('twapi/components/twapi-model-state-pending', ['exports', 'twapi/templates/components/twapi-model-state-pending', 'ember-decorators/object'], function (exports, _twapiModelStatePending, _object) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _desc, _value, _obj;

  var Component = Ember.Component,
      get = Ember.get;
  exports.default = Component.extend((_dec = (0, _object.computed)('model'), (_obj = {
    layout: _twapiModelStatePending.default,
    classNames: ['twapi-model-state-pending', 'twapi-model-state-overlay'],
    classNameBindings: ['isActive::twapi-model-state-hide'],

    model: null,
    isActive: false,

    description: function description() {
      return get(this, 'model.constructor.resourceName');
    }
  }, (_applyDecoratedDescriptor(_obj, 'description', [_dec], Object.getOwnPropertyDescriptor(_obj, 'description'), _obj)), _obj)));
});