define("ember-cli-foundation-6-sass/components/zf-accordion", ["exports", "ember-cli-foundation-6-sass/mixins/zf-widget"], function (_exports, _zfWidget) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_zfWidget.default, {
    /** @member tag type */
    tagName: 'ul',

    /** @member Class names */
    classNames: ['accordion'],

    /** @member Attribute bindings */
    attributeBindings: ['data-accordion'],

    /** @member Makes the data attribute binding appear */
    'data-accordion': ' ',

    /** @member Foundation type */
    'zfType': 'Accordion',

    /** @member Foundation specific options */
    'zfOptions': ['slideSpeed', 'multiExpand', 'allowAllClosed'],
    update: Ember.on('didUpdate', function () {
      this._setup();
    })
  });

  _exports.default = _default;
});