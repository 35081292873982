define('twapi/models/applicant', ['exports', 'ember-restless', 'twapi/models/applicant-data'], function (exports, _emberRestless, _applicantData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.findApplicant = findApplicant;
  exports.otherApplicant = otherApplicant;
  var Model = _emberRestless.default.Model,
      belongsTo = _emberRestless.default.belongsTo;
  var _Ember$computed = Ember.computed,
      alias = _Ember$computed.alias,
      readOnly = _Ember$computed.readOnly,
      get = Ember.get,
      _isEqual = Ember.isEqual,
      isNone = Ember.isNone;
  function findApplicant(applicants, email) {
    return applicants.find(function (applicant) {
      return get(applicant, 'applicantData.email') === email;
    });
  }

  function otherApplicant(applicants, givenApplicant) {
    if (isNone(applicants) || isNone(givenApplicant)) {
      return;
    }
    var otherApplicants = applicants.filter(function (applicant) {
      return !_isEqual(applicant, givenApplicant);
    });

    return get(otherApplicants, 'firstObject');
  }

  exports.default = Model.extend({
    applicantData: belongsTo('applicant-data', { defaultValue: function defaultValue() {
        return _applicantData.default.create();
      } }),

    customer: belongsTo('customer'),

    estatePercent: alias('applicantData.estatePercent'),

    isComplete: readOnly('applicantData.acceptedAgreements'),

    isEqual: function isEqual(other) {
      return _isEqual(get(this, 'applicantData.email'), get(other, 'applicantData.email'));
    }
  }).reopenClass({
    resourceName: 'applicant'
  });
});