define('twapi/models/account', ['exports', 'ember-restless', 'ember-decorators/object', 'twapi/models/account-type', 'twapi/utils/validation'], function (exports, _emberRestless, _object, _accountType, _validation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.RISK_TOLERANCES = exports.INVESTMENT_TIME_HORIZONS = exports.ACCOUNT_PURPOSES = exports.INVESTMENT_OBJECTIVES = exports.OptionPlans = undefined;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _dec2, _dec3, _dec4, _desc, _value, _obj;

  var Model = _emberRestless.default.Model,
      attr = _emberRestless.default.attr;
  var _Ember$computed = Ember.computed,
      bool = _Ember$computed.bool,
      equal = _Ember$computed.equal,
      match = _Ember$computed.match,
      get = Ember.get,
      isNone = Ember.isNone;
  var OptionPlans = exports.OptionPlans = {
    LIMITED: 'Limited',
    BASIC: 'Basic',
    THE_WORKS: 'The Works'
  };

  var INVESTMENT_OBJECTIVES = exports.INVESTMENT_OBJECTIVES = {
    SPECULATION: 'SPECULATION',
    GROWTH: 'GROWTH',
    INCOME: 'INCOME',
    CAPITAL_PRESERVATION: 'CAPITAL_PRESERVATION'
  };

  var ACCOUNT_PURPOSES = exports.ACCOUNT_PURPOSES = {
    SPECULATING: 'SPECULATING'
  };

  var INVESTMENT_TIME_HORIZONS = exports.INVESTMENT_TIME_HORIZONS = ['SHORT', 'AVERAGE', 'LONGEST'];

  var RISK_TOLERANCES = exports.RISK_TOLERANCES = ['LOW', 'MEDIUM', 'HIGH'];

  var TEST_DRIVE_ACCOUNT_NUMBER_REGEX = /^1D[A-Z]\d{5}$/;

  var AUSTRALIAN_ACCOUNT_NUMBER_REGEX = /^6A[A-Z]\d{5}$/;

  exports.default = Model.extend(_accountType.HasAccountTypeName, (_dec = (0, _object.computed)('accountType.isAnyIra', 'marginOrCash'), _dec2 = (0, _object.computed)('nickname', 'isClosed'), _dec3 = (0, _object.computed)('nameStatus', 'accountNumber'), _dec4 = (0, _object.computed)('accountType.displayName', 'marginTypeDisplay', 'isTestDrive'), (_obj = {

    accountNumber: attr('string'),

    externalId: attr('string'),

    openedAt: attr('momentDateTime'),

    closedAt: attr('momentDateTime'),

    nickname: attr('string'),

    accountTypeName: attr('string'),

    dayTraderStatus: attr('string'),

    customerId: attr('number'),

    fundingDate: attr('momentDateTime'),

    futuresAccountPurpose: attr('string'),

    isForeign: attr('boolean'),

    marginOrCash: attr('string'),

    investmentObjective: attr('string'),

    investmentTimeHorizon: attr('string'),

    riskTolerance: attr('string'),

    isCash: equal('marginOrCash', _accountType.MARGIN_TYPES.CASH),

    isClosed: bool('closedAt'),

    isFunded: bool('fundingDate'),

    isTestDrive: match('accountNumber', TEST_DRIVE_ACCOUNT_NUMBER_REGEX),

    hasAustralianBranchCode: match('accountNumber', AUSTRALIAN_ACCOUNT_NUMBER_REGEX),

    marginTypeDisplay: function marginTypeDisplay(isIra, marginOrCash) {
      if (isIra) {
        return ''; // Don't display margin/cash for IRA accounts
      }
      return (marginOrCash || '').capitalize();
    },
    nameStatus: function nameStatus(nickname, isClosed) {
      var name = nickname.trim();
      if (isClosed) {
        name = name + ' (closed)';
      }
      return name;
    },
    displayName: function displayName(nameStatus, accountNumber) {
      return accountNumber + ': ' + nameStatus;
    },
    typeDisplay: function typeDisplay(accountTypeName, marginTypeDisplay, isTestDrive) {
      if (isTestDrive) {
        return 'Challenge';
      }

      return accountTypeName + ' ' + marginTypeDisplay;
    },
    isEqual: function isEqual(other) {
      if (isNone(this)) {
        return false;
      }
      if (isNone(other)) {
        return false;
      }

      return get(this, 'accountNumber') === get(other, 'accountNumber');
    }
  }, (_applyDecoratedDescriptor(_obj, 'marginTypeDisplay', [_dec], Object.getOwnPropertyDescriptor(_obj, 'marginTypeDisplay'), _obj), _applyDecoratedDescriptor(_obj, 'nameStatus', [_dec2], Object.getOwnPropertyDescriptor(_obj, 'nameStatus'), _obj), _applyDecoratedDescriptor(_obj, 'displayName', [_dec3], Object.getOwnPropertyDescriptor(_obj, 'displayName'), _obj), _applyDecoratedDescriptor(_obj, 'typeDisplay', [_dec4], Object.getOwnPropertyDescriptor(_obj, 'typeDisplay'), _obj)), _obj))).reopen(_validation.build({
    investmentObjective: {
      inclusion: { in: Object.values(INVESTMENT_OBJECTIVES) }
    },
    futuresAccountPurpose: {
      inclusion: { in: Object.values(ACCOUNT_PURPOSES) }
    },
    investmentTimeHorizon: {
      presence: true,
      inclusion: { in: INVESTMENT_TIME_HORIZONS }
    },
    riskTolerance: {
      presence: true,
      inclusion: { in: RISK_TOLERANCES }
    }
  })).reopenClass({
    resourceName: 'account',
    primaryKey: 'accountNumber'
  });
});