define('twapi/proxies/internal-cash-transfer', ['exports', 'twapi/mixins/object-state-poller', 'twapi/models/internal-cash-transfer', '@tastyworks/tastyworks-api', 'twapi/utils/deser'], function (exports, _objectStatePoller, _internalCashTransfer, _tastyworksApi, _deser) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var ObjectProxy = Ember.ObjectProxy,
      bool = Ember.computed.bool,
      get = Ember.get,
      set = Ember.set;
  exports.default = ObjectProxy.extend(_objectStatePoller.default, {
    twapiRest: null,

    shouldPoll: bool('isPending'),

    toJsObject: function toJsObject() {
      return get(this, 'content').toJsObject();
    },
    fetchSelf: function fetchSelf() {
      var _this = this;

      var twapiRest = get(this, 'twapiRest');
      get(twapiRest, 'client').internalCashTransferService.show(get(this, 'accountNumber'), get(this, 'id')).then(function (itemResponse) {
        set(_this, 'content', _deser.translateSingleResponse(_tastyworksApi.INTERNAL_CASH_TRANSFER_DESER, _internalCashTransfer.default, itemResponse));
      });
    }
  });
});