define('twapi/services/twapi-rest', ['exports', '@tastyworks/tastyworks-api', 'twapi/utils/computed', 'ember-decorators/object'], function (exports, _tastyworksApi, _computed, _object) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _dec2, _desc, _value, _obj;

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  function _possibleConstructorReturn(self, call) {
    if (!self) {
      throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    }

    return call && (typeof call === "object" || typeof call === "function") ? call : self;
  }

  function _inherits(subClass, superClass) {
    if (typeof superClass !== "function" && superClass !== null) {
      throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
    }

    subClass.prototype = Object.create(superClass && superClass.prototype, {
      constructor: {
        value: subClass,
        enumerable: false,
        writable: true,
        configurable: true
      }
    });
    if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
  }

  var Client = _tastyworksApi.TastyworksRest.Client;
  var Service = Ember.Service,
      alias = Ember.computed.alias,
      service = Ember.inject.service;


  var logger = {
    warn: function warn(message) {
      for (var _len = arguments.length, optionalParams = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        optionalParams[_key - 1] = arguments[_key];
      }

      console.warn(message, optionalParams);
    },
    info: function info(message) {
      for (var _len2 = arguments.length, optionalParams = Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
        optionalParams[_key2 - 1] = arguments[_key2];
      }

      console.info(message, optionalParams);
    },
    error: function error(message) {
      for (var _len3 = arguments.length, optionalParams = Array(_len3 > 1 ? _len3 - 1 : 0), _key3 = 1; _key3 < _len3; _key3++) {
        optionalParams[_key3 - 1] = arguments[_key3];
      }

      console.error(message, optionalParams);
    },
    log: function log(message) {
      for (var _len4 = arguments.length, optionalParams = Array(_len4 > 1 ? _len4 - 1 : 0), _key4 = 1; _key4 < _len4; _key4++) {
        optionalParams[_key4 - 1] = arguments[_key4];
      }

      console.log(message, optionalParams);
    }
  };

  var sessionErrorHandler = {
    onInvalidSession: function onInvalidSession() {
      console.info('Invalid session');
    },
    onOTPRequired: function onOTPRequired(_response) {
      console.info('One time password required');
    },
    onHttpNetworkError: function onHttpNetworkError(_url, _request, _error) {
      console.error('Network issue');
    }
  };

  var Session = function (_TwSession) {
    _inherits(Session, _TwSession);

    function Session(sessionToken) {
      _classCallCheck(this, Session);

      var _this = _possibleConstructorReturn(this, (Session.__proto__ || Object.getPrototypeOf(Session)).call(this, 'twapi-ember'));

      _this._sessionToken = sessionToken;
      return _this;
    }

    _createClass(Session, [{
      key: 'sessionToken',
      get: function get() {
        return this._sessionToken;
      },
      set: function set(_token) {
        throw new Error('unsupported operation');
      }
    }]);

    return Session;
  }(_tastyworksApi.TwSession);

  exports.default = Service.extend((_dec = (0, _object.computed)('sessionToken'), _dec2 = (0, _object.computed)('config.twapi.baseURL', 'twSession'), (_obj = {
    session: service(),

    config: _computed.default.registry.resolve('config:environment'),

    sessionToken: alias('session.response.sessionToken'),

    twSession: function twSession(sessionToken) {
      return new Session(sessionToken);
    },
    client: function client(baseUrl, twSession) {
      return new Client(baseUrl, logger, fetch.bind(window), sessionErrorHandler, twSession);
    }
  }, (_applyDecoratedDescriptor(_obj, 'twSession', [_dec], Object.getOwnPropertyDescriptor(_obj, 'twSession'), _obj), _applyDecoratedDescriptor(_obj, 'client', [_dec2], Object.getOwnPropertyDescriptor(_obj, 'client'), _obj)), _obj)));
});