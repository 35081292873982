define('ember-text-mask-addons/createAutoCorrectedDatePipe', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = createAutoCorrectedDatePipe;
  var maxValueMonth = [31, 31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  var formatOrder = ['yyyy', 'yy', 'mm', 'dd', 'HH', 'MM', 'SS'];
  function createAutoCorrectedDatePipe() {
    var dateFormat = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'mm dd yyyy';

    var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
        _ref$minYear = _ref.minYear,
        minYear = _ref$minYear === undefined ? 1 : _ref$minYear,
        _ref$maxYear = _ref.maxYear,
        maxYear = _ref$maxYear === undefined ? 9999 : _ref$maxYear;

    var dateFormatArray = dateFormat.split(/[^dmyHMS]+/).sort(function (a, b) {
      return formatOrder.indexOf(a) - formatOrder.indexOf(b);
    });
    return function (conformedValue) {
      var indexesOfPipedChars = [];
      var maxValue = { 'dd': 31, 'mm': 12, 'yy': 99, 'yyyy': maxYear, 'HH': 23, 'MM': 59, 'SS': 59 };
      var minValue = { 'dd': 1, 'mm': 1, 'yy': 0, 'yyyy': minYear, 'HH': 0, 'MM': 0, 'SS': 0 };
      var conformedValueArr = conformedValue.split('');

      // Check first digit
      dateFormatArray.forEach(function (format) {
        var position = dateFormat.indexOf(format);
        var maxFirstDigit = parseInt(maxValue[format].toString().substr(0, 1), 10);

        if (parseInt(conformedValueArr[position], 10) > maxFirstDigit) {
          conformedValueArr[position + 1] = conformedValueArr[position];
          conformedValueArr[position] = 0;
          indexesOfPipedChars.push(position);
        }
      });

      // Check for invalid date
      var month = 0;
      var isInvalid = dateFormatArray.some(function (format) {
        var position = dateFormat.indexOf(format);
        var length = format.length;
        var textValue = conformedValue.substr(position, length).replace(/\D/g, '');
        var value = parseInt(textValue, 10);
        if (format === 'mm') {
          month = value || 0;
        }
        var maxValueForFormat = format === 'dd' ? maxValueMonth[month] : maxValue[format];
        if (format === 'yyyy' && (minYear !== 1 || maxYear !== 9999)) {
          var scopedMaxValue = parseInt(maxValue[format].toString().substring(0, textValue.length), 10);
          var scopedMinValue = parseInt(minValue[format].toString().substring(0, textValue.length), 10);
          return value < scopedMinValue || value > scopedMaxValue;
        }
        return value > maxValueForFormat || textValue.length === length && value < minValue[format];
      });

      if (isInvalid) {
        return false;
      }

      return {
        value: conformedValueArr.join(''),
        indexesOfPipedChars: indexesOfPipedChars
      };
    };
  }
});