define('ember-array-helper/helpers/array', ['exports', 'ember'], function (exports, _ember) {
  var helper = _ember['default'].Helper.helper;
  var newArray = _ember['default'].A;
  exports['default'] = helper(function (params) {
    // this has a bug in ember 2.10
    // https://github.com/emberjs/ember.js/pull/14649
    // let array = newArray(params);

    var array = newArray();
    array.pushObjects(params);

    return array;
  });
});