define('twapi/models/permitted-account-type', ['exports', 'ember-restless'], function (exports, _emberRestless) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var ReadOnlyModel = _emberRestless.default.ReadOnlyModel,
      attr = _emberRestless.default.attr,
      hasMany = _emberRestless.default.hasMany;
  exports.default = ReadOnlyModel.extend({
    isPermitted: attr('boolean'),

    name: attr('string'),

    subTypes: hasMany('permitted-account-type'),

    reason: attr('string')
  }).reopenClass({
    resourceName: 'permitted-account-type'
  });
});