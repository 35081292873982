define('twapi/components/fully-paid-securities-lending/twapi-acknowledgement', ['exports', 'twapi/utils/validation'], function (exports, _validation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      alias = Ember.computed.alias,
      service = Ember.inject.service;
  exports.default = Component.extend({
    twapiDocuments: service(),

    clearingFullyPaidSecuritiesLendingProgramAgreement: alias('twapiDocuments.clearingFullyPaidSecuritiesLendingProgramAgreement'),

    fullyPaidSecuritiesDisclosure: alias('twapiDocuments.fullyPaidSecuritiesDisclosure')
  }).reopen(_validation.build({
    hasViewedClearingFullyPaidSecuritiesLendingProgramAgreement: {
      inclusion: { in: [true] }
    },
    hasViewedFullyPaidSecuritiesDisclosure: {
      inclusion: { in: [true] }
    }
  }));
});