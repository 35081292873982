define('ember-ambitious-forms/components/amb-form-field', ['exports', 'ember-computed-indirect/utils/indirect', 'ember-ambitious-forms/mixins/converted-options', 'ember-ambitious-forms/utils/error-state'], function (exports, _indirect, _convertedOptions, _errorState) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var INTERACTION_STATE = {
    ACTIVE: 'active',
    DONE: 'done'
  };

  exports.default = Ember.Component.extend(_convertedOptions.default, {
    layoutName: 'ember-ambitious-forms@components/amb-form-field',
    service: Ember.inject.service('ember-ambitious-forms'),

    tagName: 'div',
    classNames: ['amb-form-field'],
    classNameBindings: ['readOnly:amb-form-field-read-only', '_errorStateClass'],

    readOnly: false,
    // By default, do not show errors until user has interacted with the field
    alwaysShowErrors: false,
    _interactionState: null,

    _onInsert: Ember.on('didInsertElement', function () {
      this.sendAction('onInsert', this);
    }),

    _onRemove: Ember.on('willDestroyElement', function () {
      this.sendAction('onRemove', this);
    }),

    _onToggleReadOnly: Ember.observer('readOnly', function () {
      this.sendAction('onToggleReadOnly', this);
    }),

    _errorStateClass: Ember.computed('errorState.value', function () {
      var errorStateValue = this.get('errorState.value');
      return 'amb-form-field-error-' + errorStateValue;
    }),

    _fieldTypeConfig: function _fieldTypeConfig(configName) {
      var fieldType = this.get('fieldType');
      if (fieldType) {
        return this.get('service.config.fieldTypeMappings.' + fieldType + '.' + configName);
      }
    },


    scopeName: Ember.computed('scope.scopeName', 'scope', function () {
      var scopeName = this.get('scope.scopeName');
      if (scopeName) {
        return scopeName;
      }
      var scope = this.get('scope');
      if (Ember.isNone(scope)) {
        return;
      }

      // See if scope has injection Ember object toString()
      var matcher = /^.*:([-/a-z0-9]+):.*$/;
      var injectedClassName = scope.toString();

      if (matcher.test(injectedClassName)) {
        return injectedClassName.replace(matcher, '$1');
      }
    }),

    type: Ember.computed('options.length', 'fieldType', 'fieldKey', function () {
      var fieldTypeComponent = this._fieldTypeConfig('type');
      if (fieldTypeComponent) {
        return fieldTypeComponent;
      }

      if (this.get('options.length') > 0) {
        return 'select';
      }

      switch (this.getWithDefault('fieldType', '').toLowerCase()) {
        case 'number':
          return 'number';
        case 'boolean':
          return 'checkbox';
      }

      var lcaseFieldKey = (this.get('fieldKey') || '').toLowerCase();

      if (lcaseFieldKey.includes('password')) {
        return 'password';
      } else if (lcaseFieldKey.includes('email')) {
        return 'email';
      } else if (lcaseFieldKey.includes('phone')) {
        return 'tel';
      } else if (lcaseFieldKey.includes('url')) {
        return 'url';
      }

      return 'text';
    }),

    _isComponent: function _isComponent(name) {
      // Magic sauce: https://github.com/emberjs/ember.js/blob/a841b2e498557cff62c5cb56b01342a0af7abecd/packages/ember-htmlbars/lib/utils/is-component.js#L13
      var owner = Ember.getOwner(this);
      return owner.hasRegistration('component:' + name) || owner.hasRegistration('template:components/' + name);
    },


    // The 'component' helper only takes names and component-helper output, not component classes
    _asComponent: function _asComponent(name) {
      return this._isComponent(name) ? name : null;
    },


    inputComponent: Ember.computed('readOnly', 'type', function () {
      if (this.get('readOnly')) {
        return 'amb-form-read-only';
      }

      var type = this.get('type');

      return this._asComponent(type) || this._asComponent('amb-form-' + type) || 'amb-form-input';
    }),

    value: (0, _indirect.default)('_valueKey'),
    _valueKey: Ember.computed('scope', 'fieldKey', function () {
      if (this.get('scope')) {
        return 'scope.' + this.get('fieldKey');
      } else {
        // Scope does not exist. Stick it on current component instance instead
        return '_value';
      }
    }),

    formattedValue: Ember.computed('value', function () {
      var formatter = this.get('formatter');
      return formatter.call(this, this.get('value'));
    }),

    formatter: function formatter(value) {
      if (this.get('type') === 'password') {
        return;
      }

      var selectedOption = Ember.A(this.get('convertedOptions') || []).findBy('value', value);
      if (selectedOption) {
        return selectedOption.get('text');
      }

      return value;
    },


    errors: null,
    hasErrors: Ember.computed('readOnly', 'errors.length', function () {
      return !this.get('readOnly') && Boolean(this.get('errors.length'));
    }),
    showErrors: Ember.computed('alwaysShowErrors', '_interactionState', function () {
      return this.get('alwaysShowErrors') || this.get('_interactionState') === INTERACTION_STATE.DONE;
    }),

    errorState: Ember.computed(function () {
      return _errorState.default.create({ content: Ember.A([this]) });
    }),

    focusIn: function focusIn() {
      if (this._doFocusOutTimer) {
        Ember.run.cancel(this._doFocusOutTimer);
        this._doFocusOutTimer = null;
      }

      this.set('_interactionState', INTERACTION_STATE.ACTIVE);
    },


    // If the field has 2 inputs, it might trigger focusOut => focusIn immediately
    // Delay this fire to prevent double triggering from executing.
    focusOut: function focusOut() {
      this._doFocusOutTimer = Ember.run.debounce(this, this._doFocusOut, 50);
    },
    _doFocusOut: function _doFocusOut() {
      if (!this.isDestroyed) {
        this.set('_interactionState', INTERACTION_STATE.DONE);
        this.sendAction('onInteractionComplete', this);
      }
    },


    required: false,
    label: null,

    labelClass: Ember.computed('required', function () {
      var required = this.get('required');
      if (required === true) {
        return 'amb-form-required';
      } else if (required) {
        return 'amb-form-required-' + required;
      }
    }),

    hint: null,
    hintClass: null,

    description: null,
    descriptionClass: null,

    prompt: Ember.computed.oneWay('service.config.prompt'),
    options: Ember.computed.oneWay('optionValues'),

    optionValues: Ember.computed('fieldType', function () {
      return this._fieldTypeConfig('options');
    }),

    resetErrorState: function resetErrorState() {
      if (!this.get('readOnly') && this.get('type') !== 'hidden') {
        this.set('value', null);
        this.set('_interactionState', null);
      }
    },


    actions: {
      valueChanged: function valueChanged(newValue) {
        var interactionState = this.get('_interactionState');
        if (Ember.isPresent(interactionState) && interactionState !== 'active') {
          this.set('_interactionState', INTERACTION_STATE.DONE);
        }
        this.set('value', newValue);
        this.sendAction('onChange', this);
      },
      setInteractionComplete: function setInteractionComplete() {
        this.set('_interactionState', INTERACTION_STATE.DONE);
      }
    }
  }).reopenClass({
    positionalParams: ['fieldKey']
  });
});