define('twapi/models/fully-paid-securities-lending-request', ['exports', 'ember-restless', '@tastyworks/tastyworks-api', 'twapi/utils/deser'], function (exports, _emberRestless, _tastyworksApi, _deser) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberRestless.default.Model;
  var _Ember$computed = Ember.computed,
      and = _Ember$computed.and,
      equal = _Ember$computed.equal;
  exports.default = Model.extend(_deser.buildModel(_tastyworksApi.FULLY_PAID_SECURITIES_LENDING_REQUEST_DESER)).reopen({
    isComplete: equal('status', _tastyworksApi.FullyPaidSecuritiesLendingRequestStatus.COMPLETE),

    isOptedIn: and('isOptIn', 'isComplete'),

    isOptedOut: and('isOptOut', 'isComplete'),

    isOptIn: equal('direction', _tastyworksApi.FullyPaidSecuritiesLendingRequestDirection.OPT_IN),

    isOptInPending: and('isOptIn', 'isPending'),

    isOptOut: equal('direction', _tastyworksApi.FullyPaidSecuritiesLendingRequestDirection.OPT_OUT),

    isOptOutPending: and('isOptOut', 'isPending'),

    isPending: equal('status', _tastyworksApi.FullyPaidSecuritiesLendingRequestStatus.PENDING)
  }).reopenClass({
    resourceName: 'fully-paid-securities-lending-request'
  });
});