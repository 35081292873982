define('twapi/models/access-plan', ['exports', 'ember-decorators/object', 'lodash', 'twapi/utils/mixin', 'twapi/models/options-level'], function (exports, _object, _lodash, _mixin, _optionsLevel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.LimitedPlan = exports.BasicPlan = exports.IraTheWorksPlan = exports.TheWorksPlan = exports.ALL_PERMISSIONS = exports.DERIVATIVE_PERMISSIONS = exports.EQUITY_PERMISSIONS = exports.PERMISSION_NAMES = undefined;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _desc, _value, _obj, _desc2, _value2, _obj2, _dec, _desc3, _value3, _obj3, _desc4, _value4, _obj4;

  var get = Ember.get,
      isPresent = Ember.isPresent;
  var PERMISSION_NAMES = exports.PERMISSION_NAMES = {
    buy_stock: 'Buy Stock',
    sell_stock: 'Short Sell Stock',
    buy_options: 'Buy Options',
    sell_covered_calls: 'Sell Covered Calls',
    sell_cash_secured_puts: 'Sell Cash-Secured Puts',
    defined_risk_spreads: 'Defined-Risk Spreads',
    sell_naked_calls: 'Sell Naked Calls',
    sell_naked_puts: 'Sell Naked Puts',
    futures: 'Futures'
  };
  var EQUITY_PERMISSIONS = exports.EQUITY_PERMISSIONS = [PERMISSION_NAMES.buy_stock, PERMISSION_NAMES.sell_stock];
  var DERIVATIVE_PERMISSIONS = exports.DERIVATIVE_PERMISSIONS = [PERMISSION_NAMES.buy_options, PERMISSION_NAMES.sell_covered_calls, PERMISSION_NAMES.sell_cash_secured_puts, PERMISSION_NAMES.defined_risk_spreads, PERMISSION_NAMES.sell_naked_calls, PERMISSION_NAMES.sell_naked_puts, PERMISSION_NAMES.futures];
  var ALL_PERMISSIONS = exports.ALL_PERMISSIONS = [].concat(EQUITY_PERMISSIONS, DERIVATIVE_PERMISSIONS);

  var AccessPlan = Ember.Object.extend(_mixin.createFlagsMixin({ permissions: ALL_PERMISSIONS }));

  var TheWorksPlan = exports.TheWorksPlan = AccessPlan.extend((_obj = {
    name: 'The Works',

    optionsLevel: _optionsLevel.OPTIONS_LEVELS.NO_RESTRICTIONS,

    permissions: function permissions() {
      return _lodash.default.without(ALL_PERMISSIONS, PERMISSION_NAMES.sell_cash_secured_puts);
    }
  }, (_applyDecoratedDescriptor(_obj, 'permissions', [_object.computed], Object.getOwnPropertyDescriptor(_obj, 'permissions'), _obj)), _obj));

  var IraTheWorksPlan = exports.IraTheWorksPlan = AccessPlan.extend((_obj2 = {
    name: 'IRA The Works',

    optionsLevel: _optionsLevel.OPTIONS_LEVELS.DEFINED_RISK_PLUS_NAKED,

    permissions: function permissions() {
      return _lodash.default.without(ALL_PERMISSIONS, PERMISSION_NAMES.sell_stock, PERMISSION_NAMES.sell_naked_puts);
    }
  }, (_applyDecoratedDescriptor(_obj2, 'permissions', [_object.computed], Object.getOwnPropertyDescriptor(_obj2, 'permissions'), _obj2)), _obj2));

  var BasicPlan = exports.BasicPlan = AccessPlan.extend((_dec = (0, _object.computed)('accountType'), (_obj3 = {
    name: 'Basic',

    optionsLevel: _optionsLevel.OPTIONS_LEVELS.DEFINED_RISK,

    permissions: function permissions(accountType) {
      var permissions = _lodash.default.without(ALL_PERMISSIONS, PERMISSION_NAMES.sell_stock, PERMISSION_NAMES.sell_naked_calls, PERMISSION_NAMES.futures);

      if (isPresent(accountType) && get(accountType, 'isAnyIra')) {
        return _lodash.default.without(permissions, PERMISSION_NAMES.sell_naked_puts);
      } else {
        return _lodash.default.without(permissions, PERMISSION_NAMES.sell_cash_secured_puts);
      }
    }
  }, (_applyDecoratedDescriptor(_obj3, 'permissions', [_dec], Object.getOwnPropertyDescriptor(_obj3, 'permissions'), _obj3)), _obj3)));

  var LimitedPlan = exports.LimitedPlan = AccessPlan.extend((_obj4 = {
    name: 'Limited',

    optionsLevel: _optionsLevel.OPTIONS_LEVELS.COVERED_AND_CASH_SECURED,

    permissions: function permissions() {
      return _lodash.default.without(ALL_PERMISSIONS, PERMISSION_NAMES.sell_stock, PERMISSION_NAMES.defined_risk_spreads, PERMISSION_NAMES.sell_naked_calls, PERMISSION_NAMES.sell_naked_puts, PERMISSION_NAMES.futures);
    }
  }, (_applyDecoratedDescriptor(_obj4, 'permissions', [_object.computed], Object.getOwnPropertyDescriptor(_obj4, 'permissions'), _obj4)), _obj4));

  AccessPlan.reopenClass({
    all: function all(accountType) {
      var plans = [];

      if (get(accountType, 'isAnyIra')) {
        plans.push(IraTheWorksPlan.create({ accountType: accountType }));
      } else {
        plans.push(TheWorksPlan.create({ accountType: accountType }));
      }

      plans.push(BasicPlan.create({ accountType: accountType }), LimitedPlan.create({ accountType: accountType }));

      return plans;
    },
    fromOptionsLevel: function fromOptionsLevel(optionsLevel, accountType) {
      return this.all(accountType).find(function (plan) {
        return get(plan, 'optionsLevel') === optionsLevel;
      });
    }
  });

  exports.default = AccessPlan;
});