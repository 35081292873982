define('twapi/utils/future-option-symbol', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.parse = parse;
  exports.buildFromRegex = buildFromRegex;


  var TWO_DIGIT_YEAR_REGEX = /^\.\/([A-Z0-9]{2,4})([A-Z])([0-9]{2})[ ]([A-Z0-9]{2,4})([A-Z])([0-9]{2})[ ](\d{2})(\d{2})(\d{2})([CP])(\S+)$/;

  var ONE_DIGIT_YEAR_REGEX = /^\.\/([A-Z0-9]{2,3})([A-Z])([0-9])[ ]{0,1}([A-Z0-9]{2,3})([A-Z])([0-9])[ ]{0,2}(\d{2})(\d{2})(\d{2})([CP])(\S+)$/;

  function parse(symbol) {
    var matches = TWO_DIGIT_YEAR_REGEX.exec(symbol);

    if (matches) {
      return buildFromRegex(symbol, matches);
    }

    matches = ONE_DIGIT_YEAR_REGEX.exec(symbol);

    if (matches) {
      return buildFromRegex(symbol, matches);
    }

    // TODO: Error?
    return null;
  }

  function buildFromRegex(symbol, matches) {
    var underlyingSymbol = '/' + matches[1] + matches[2] + matches[3];
    var optionRootSymbol = matches[4] + matches[5] + matches[6];

    var date = (0, _moment.default)([parseInt(matches[7]) + 2000, parseInt(matches[8] - 1), parseInt(matches[9])]);

    return {
      symbol: symbol,
      rootSymbol: underlyingSymbol,
      underlyingSymbol: underlyingSymbol,
      optionRootSymbol: optionRootSymbol,
      expirationDate: date,
      optionType: matches[10],
      strikePrice: matches[11]
    };
  }
});