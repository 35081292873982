define('twapi/components/documents/twapi-entity-agreement-link', ['exports', 'twapi/templates/components/documents/twapi-entity-agreement-link'], function (exports, _twapiEntityAgreementLink) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      readOnly = Ember.computed.readOnly,
      service = Ember.inject.service;
  exports.default = Component.extend({
    twapiDocuments: service(),

    layout: _twapiEntityAgreementLink.default,

    isMargin: false,

    clearingCorporateMarginAccountAgreementUrl: readOnly('twapiDocuments.clearingCorporateMarginAccountAgreement.url'),

    clearingCorporateCashAccountAgreementUrl: readOnly('twapiDocuments.clearingCorporateCashAccountAgreement.url'),

    clearingLlcAgreementUrl: readOnly('twapiDocuments.clearingLlcAgreement.url'),

    clearingPartnershipAgreementUrl: readOnly('twapiDocuments.clearingPartnershipAgreement.url'),

    clearingTrustAgreementUrl: readOnly('twapiDocuments.clearingTrustAgreement.url')

  });
});