define('twapi/models/withdrawal-fee', ['exports', 'ember-restless'], function (exports, _emberRestless) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberRestless.default.Model,
      attr = _emberRestless.default.attr,
      belongsTo = _emberRestless.default.belongsTo;
  exports.default = Model.extend({
    type: attr('string'),

    value: attr('number'),

    withdrawalDryRun: belongsTo('withdrawal-dry-run')
  }).reopenClass({
    resourceName: 'withdrawal-fee'
  });
});