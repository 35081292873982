define('twapi/utils/error', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.parseErrorCode = parseErrorCode;
  exports.parseFieldValidations = parseFieldValidations;
  exports.parse = parse;
  var get = Ember.get,
      isPresent = Ember.isPresent,
      capitalize = Ember.String.capitalize;
  function parseErrorCode(errors) {
    if (!errors) {
      return;
    }
    return get(errors, 'error.code');
  }

  function parseFieldValidations(errors) {
    if (!errors) {
      return [];
    }

    if (!get(errors, 'error.errors')) {
      return [];
    }

    if (get(errors, 'error.errors').every(function (errorObject) {
      return isPresent(errorObject.message);
    })) {
      return parseActiveRecordValidationError(errors);
    } else {
      return parseGrapeValidationError(errors);
    }
  }

  function parseActiveRecordValidationError(errors) {
    return _lodash.default.uniq(get(errors, 'error.errors').map(function (errorObject) {
      return errorObject.message;
    }));
  }

  function parseGrapeValidationError(errors) {
    return Object.keys(get(errors, 'error.fields')).map(function (key) {
      var reason = get(errors, 'error.fields.' + key);
      return capitalize(key + ' ' + reason);
    });
  }

  function parse(errors) {
    var message = null;

    if (isPresent(get(errors, 'error'))) {
      if (isPresent(get(errors, 'error.fields'))) {
        message = parseFieldValidations(errors).join('. ');
      } else {
        message = get(errors, 'error.message');
      }
    } else if (isPresent(get(errors, 'errorThrown'))) {
      message = [errors['status'], errors['errorThrown']].join(' ');
    } else if (isPresent(get(errors, 'message'))) {
      message = errors['message'];
    }

    if (isPresent(message)) {
      // Clear out consecutive periods
      return _lodash.default.trim(message.replace(/\.\.\s/g, '. '));
    }

    return 'Oops! Something went wrong. Please try again later.';
  }
});