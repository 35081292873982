define('twapi/models/user', ['exports', 'ember-restless', 'twapi/utils/validation', 'twapi/utils/regex'], function (exports, _emberRestless, _validation, _regex) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.PASSWORD_VALIDATIONS = undefined;
  var Model = _emberRestless.default.Model,
      attr = _emberRestless.default.attr;
  var PASSWORD_VALIDATIONS = exports.PASSWORD_VALIDATIONS = {
    presence: true,
    length: { min: 12 }
  };

  exports.default = Model.extend({
    id: attr('string'),

    name: attr('string'),
    nickname: attr('string'),
    email: attr('string'),
    username: attr('string'),
    password: attr('string'),
    externalId: attr('string'),
    recaptchaToken: attr('string')

  }).reopen(_validation.build({
    email: { format: { type: 'email' } },
    username: {
      length: {
        min: 3,
        max: 24
      },
      format: {
        regex: _regex.exact.USERNAME,
        message: 'Username must be lowercased and alphanumeric'
      }
    },
    password: PASSWORD_VALIDATIONS,
    passwordConfirmation: { confirmation: { on: 'password' } },
    emailConfirmation: { confirmation: { on: 'email' } }
  })).reopenClass({
    resourceName: 'user'
  });
});