define('twapi/models/expiration-monitoring-entry', ['exports', 'ember-restless'], function (exports, _emberRestless) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberRestless.default.Model,
      attr = _emberRestless.default.attr;
  exports.default = Model.extend({
    accountNumber: attr('string'),
    monitoringDate: attr('momentDateTime', { readOnly: true }),
    active: attr('boolean', { readOnly: true })
  }).reopenClass({
    resourceName: 'expiration-monitoring-entry'
  });
});