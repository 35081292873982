define('twapi/models/autotrade-operator', ['exports', 'ember-restless'], function (exports, _emberRestless) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberRestless.default.Model,
      attr = _emberRestless.default.attr,
      hasMany = _emberRestless.default.hasMany;
  exports.default = Model.extend({
    name: attr('string'),

    description: attr('string'),

    website: attr('string'),

    modelPortfolios: hasMany('model-portfolio')
  }).reopenClass({
    resourceName: 'autotrade-operator'
  });
});