define('twapi/components/beneficiary/twapi-beneficiary-form', ['exports', 'ember-decorators/object', 'twapi/utils/mask'], function (exports, _object, _mask) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _dec2, _desc, _value, _obj;

  exports.default = Ember.Component.extend((_dec = (0, _object.computed)('beneficiaries.[]', 'currentBeneficiary'), _dec2 = (0, _object.computed)('isEditing', 'beneficiaries.length'), (_obj = {
    classNames: ['twapi-beneficiary-form'],
    twapiBeneficiaries: Ember.inject.service(),
    beneficiaries: Ember.computed.alias('twapiBeneficiaries.beneficiaries'),

    currentBeneficiary: Ember.computed.alias('twapiBeneficiaries.currentBeneficiary'),

    isExisting: Ember.computed.not('currentBeneficiary.isNew'),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.sendAction('didInsertForm', this);
    },
    isEditing: function isEditing(beneficiaries, currentBeneficiary) {
      return beneficiaries.includes(currentBeneficiary);
    },
    buttonText: function buttonText(isEditing, numBeneficiaries) {
      if (isEditing) {
        return 'Save Beneficiary';
      } else {
        return 'Add beneficiary #' + (numBeneficiaries + 1);
      }
    },


    hasNoBeneficiaries: Ember.computed.equal('beneficiaries.length', 0),

    taxNumberMask: _mask.default.SSN,

    actions: {
      cancelBeneficiaryInput: function cancelBeneficiaryInput() {
        this.get('twapiBeneficiaries').cancelBeneficiaryEdit(this.get('currentBeneficiary'));
        this.get('cancelBeneficiaryInput')();
      },
      saveBeneficiary: function saveBeneficiary() {
        this.get('twapiBeneficiaries').saveBeneficiary(this.get('currentBeneficiary'));
        this.get('saveBeneficiary')();
      }
    }
  }, (_applyDecoratedDescriptor(_obj, 'isEditing', [_dec], Object.getOwnPropertyDescriptor(_obj, 'isEditing'), _obj), _applyDecoratedDescriptor(_obj, 'buttonText', [_dec2], Object.getOwnPropertyDescriptor(_obj, 'buttonText'), _obj)), _obj)));
});