define('twapi/models/referral-order-product', ['exports', 'ember-restless'], function (exports, _emberRestless) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberRestless.default.Model.extend({
    id: _emberRestless.default.attr('string'),
    referralProductId: _emberRestless.default.attr('number'),
    referralOrder: _emberRestless.default.belongsTo('referral-order'),
    referralProduct: _emberRestless.default.belongsTo('referral-product'),
    isCash: _emberRestless.default.attr('boolean'),
    quantity: _emberRestless.default.attr('number'),

    isEqual: function isEqual(other) {
      if (Ember.isPresent(this.get('id'))) {
        return this.get('id') === other.get('id');
      }
      return this.get('isCash') === other.get('isCash') && Ember.isEqual(this.get('referralProduct'), other.get('referralProduct'));
    }
  }).reopenClass({
    resourceName: 'referral-order-product'
  });
});