define('twapi/proxies/ach-relationship', ['exports', 'twapi/mixins/object-state-poller'], function (exports, _objectStatePoller) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  exports.default = Ember.ObjectProxy.extend(_objectStatePoller.default, {
    endpoints: null,

    shouldPoll: Ember.computed.bool('isPresend'),

    fetchSelf: function fetchSelf() {
      get(this, 'endpoints').getAchRelationship(this, {
        accountNumber: get(this, 'accountNumber'),
        id: get(this, 'id')
      });
    }
  });
});