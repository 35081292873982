define('twapi/utils/occ-symbol', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.parse = parse;


  var REGEX = /^([A-Z0-9]{1,5})([ ]{0,5})([0-9]{0,5})([0-9]{2})([0-9]{2})([CP])([0-9]{8})$/;

  function parse(symbol) {
    var matches = REGEX.exec(symbol);

    if (!matches) {
      // TODO: Error?
      return null;
    }

    var date = (0, _moment.default)([parseInt(matches[3]) + 2000, parseInt(matches[4]) - 1, parseInt(matches[5])]);

    return {
      symbol: symbol,
      rootSymbol: matches[1],
      underlyingSymbol: matches[1],
      optionChainType: 'Standard',
      expirationDate: date,
      optionType: matches[6],
      strikePrice: matches[7] / 1000,
      sharesPerContract: 100
    };
  }
});