define('twapi/utils/proxy', ['exports', 'ember-decorators/object', 'lodash', 'twapi/utils/ember-run'], function (exports, _object, _lodash, _emberRun) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.createPartial = createPartial;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  function createPartial(source, properties) {
    var _dec, _dec2, _desc, _value2, _obj;

    var aliases = (0, _lodash.default)(properties).zipObject().mapValues(function (_value, key) {
      return Ember.computed.alias('source.' + key);
    }).value();

    var Proxy = Ember.Object.extend(aliases, Ember.Copyable, (_dec = _object.observes.apply(undefined, _toConsumableArray(properties)), _dec2 = _emberRun.default.once, (_obj = {
      copy: function copy() {
        return Ember.Object.create(this.getProperties(properties));
      },
      _proxyPropertyChanged: function _proxyPropertyChanged() {
        this.notifyPropertyChange('_proxyPropertyChanged');
      }
    }, (_applyDecoratedDescriptor(_obj, '_proxyPropertyChanged', [_dec, _dec2], Object.getOwnPropertyDescriptor(_obj, '_proxyPropertyChanged'), _obj)), _obj)));

    return Proxy.create({ source: source });
  }
});