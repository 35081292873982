define('twapi/models/external-institution', ['exports', 'ember-restless'], function (exports, _emberRestless) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberRestless.default.ReadOnlyModel.extend({
    accountNumber: _emberRestless.default.attr('string'),
    accessToken: _emberRestless.default.attr('string'),
    processorToken: _emberRestless.default.attr('string'),
    externalAccountId: _emberRestless.default.attr('string')
  }).reopenClass({
    resourceName: 'external-institutions'
  });
});