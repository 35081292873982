define('twapi/proxies/entity', ['exports', 'ember-decorators/object', 'lodash'], function (exports, _object, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _desc, _value, _obj;

  var ObjectProxy = Ember.ObjectProxy,
      alias = Ember.computed.alias,
      get = Ember.get;
  exports.default = ObjectProxy.extend((_dec = (0, _object.computed)('id'), (_obj = {
    endpoints: null,
    entity: alias('content'),

    suitability: function suitability(entityId) {
      if (!entityId) {
        return;
      }

      return get(this, 'endpoints').getEntitySuitability({ entityId: entityId });
    },
    patchSuitability: function patchSuitability(params) {
      return get(this, 'endpoints').patchEntitySuitability(get(this, 'suitability'), _lodash.default.merge(params, { entityId: get(this, 'id') }));
    },
    saveSuitability: function saveSuitability() {
      var suitability = get(this, 'entitySuitability');
      var entityId = get(this, 'id');

      if (get(suitability, 'isNew')) {
        return get(this, 'endpoints').createEntitySuitability(suitability, { entityId: entityId });
      } else {
        return get(this, 'endpoints').saveEntitySuitability(suitability, { entityId: entityId });
      }
    }
  }, (_applyDecoratedDescriptor(_obj, 'suitability', [_dec], Object.getOwnPropertyDescriptor(_obj, 'suitability'), _obj)), _obj)));
});