define('twapi/models/supporting-document-verification-json', ['exports', 'ember-restless'], function (exports, _emberRestless) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberRestless.default.Model,
      belongsTo = _emberRestless.default.belongsTo;
  exports.default = Model.extend({
    document: belongsTo('supporting-document-verification-document')
  }).reopenClass({
    resourceName: 'supporting-document-verification-json'
  });
});