define('twapi/utils/date', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.calcBusinessDaysEndDate = calcBusinessDaysEndDate;
  exports.isWeekDay = isWeekDay;


  // Calculate a date that is # business days from fromDate
  function calcBusinessDaysEndDate(fromDate, numBusinessDays) {
    var helper = (0, _moment.default)(fromDate);
    var daysCount = 0;
    // Only include business days
    while (daysCount < numBusinessDays) {
      helper.add(1, 'days');
      if (isWeekDay(helper.toDate())) {
        daysCount++;
      }
    }
    return helper.toDate();
  }

  function isWeekDay(date) {
    return date.getUTCDay() !== 6 && date.getUTCDay() !== 0;
  }
});