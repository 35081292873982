define('twapi/utils/query-params', ['exports', 'lodash', 'twapi/utils/proxy'], function (exports, _lodash, _proxy) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.names = names;
  exports.computedWrap = computedWrap;

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function names(queryParams) {
    if (Ember.isArray(queryParams)) {
      var _$partition = _lodash.default.partition(queryParams, _lodash.default.isString),
          _$partition2 = _slicedToArray(_$partition, 2),
          strings = _$partition2[0],
          objects = _$partition2[1];

      return strings.concat.apply(strings, _toConsumableArray(objects.map(Object.keys)));
    } else {
      return Object.keys(queryParams);
    }
  }

  function computedWrap() {
    var queryParamsProperty = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'queryParams';

    return Ember.computed(queryParamsProperty, function (key) {
      var _this = this;

      var queryParams = this.get(queryParamsProperty);
      var properties = names(queryParams);

      var proxy = _proxy.default.createPartial(this, properties);
      proxy.addObserver('_proxyPropertyChanged', this, function () {
        return _this.notifyPropertyChange(key);
      });
      return proxy;
    });
  }
});