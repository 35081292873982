define('twapi/components/twapi-production-guarded', ['exports', 'twapi/utils/computed'], function (exports, _computed) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      _Ember$computed = Ember.computed,
      equal = _Ember$computed.equal,
      not = _Ember$computed.not;
  exports.default = Component.extend({
    config: _computed.default.registry.resolve('config:environment'),

    isProduction: equal('config.environment', 'production'),

    isNotProduction: not('isProduction')
  });
});