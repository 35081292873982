define('twapi/mixins/adult-birth-date', ['exports', 'moment', 'ember-restless', 'twapi/utils/date-format', 'twapi/utils/validation'], function (exports, _moment, _emberRestless, _dateFormat, _validation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.ADULT_BIRTH_DATE_VALIDATIONS = undefined;
  var attr = _emberRestless.default.attr;
  var Mixin = Ember.Mixin;
  exports.default = Mixin.create({
    birthDate: attr('string')
  });
  var ADULT_BIRTH_DATE_VALIDATIONS = exports.ADULT_BIRTH_DATE_VALIDATIONS = {
    birthDate: {
      presence: {
        presence: true,
        message: _validation.BIRTH_DATE_VALIDATION_MESSAGE
      },
      date: {
        onOrBefore: (0, _moment.default)().subtract(18, 'years').toDate(),
        precision: 'day',
        format: _dateFormat.DATE_FORMAT,
        message: 'You must be 18 years or older to apply'
      }
    }
  };
});