define('ember-ambitious-forms/mixins/converted-options', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.convert = convert;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var ConvertedOption = exports.ConvertedOption = Ember.Object.extend({
    source: null,

    value: null,
    classNames: Ember.computed('value', function () {
      var dashValue = Ember.String.dasherize('' + this.get('value'));
      return 'amb-form-option-' + dashValue;
    }),
    text: Ember.computed('value', function () {
      var value = this.get('value');
      return value == null ? '' : value.toString();
    }),
    isSelected: Ember.computed('value', 'source.value', function () {
      return this.get('value') === this.get('source.value');
    }),
    testId: Ember.computed('value', function () {
      var dashValue = Ember.String.dasherize('' + this.get('value'));
      return 'amb-form-option-' + dashValue;
    })
  });

  function convert(source, rawOption) {
    var option = ConvertedOption.create({ source: source });
    if (Ember.isArray(rawOption)) {
      var _rawOption = _slicedToArray(rawOption, 3),
          value = _rawOption[0],
          text = _rawOption[1],
          description = _rawOption[2];

      option.setProperties({ value: value, text: text, description: description });
    } else if (rawOption.hasOwnProperty('value')) {
      option.setProperties(rawOption);
    } else {
      option.set('value', rawOption);
    }
    return option;
  }

  exports.default = Ember.Mixin.create({
    convertedOptions: Ember.computed('options.[]', function () {
      var _this = this;

      var options = this.get('options');
      if (options) {
        return options.map(function (option) {
          return convert(_this, option);
        });
      }
    }),

    selectConvertedOption: function selectConvertedOption(value) {
      if (ConvertedOption.detectInstance(value)) {
        value = value.get('value');
      }
      this.set('value', value);

      if (this.sendAction) {
        this.sendAction('action', value);
        this.sendAction('didInteractionComplete');
      }
    },


    actions: {
      selectConvertedOption: function selectConvertedOption(value) {
        this.selectConvertedOption(value);
      }
    }
  });
});