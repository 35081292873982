define('twapi/locales/en/amb-form', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  /* eslint-disable */
  var common = {
    'current-password': 'Current Password',
    'has-signature': 'Signature',
    'has-signature.hint': '(Please sign below)',
    'two-factor': {
      'mobile-number': 'New Mobile Number',
      'one-time-password': 'Enter your code:'
    },
    'options.boolean': {
      'true': 'Yes',
      'false': 'No'
    },
    'options.investment-objective': {
      'speculation': 'Speculation',
      'speculation.description': 'Taking larger risks, usually by frequent trading, with hope of higher than-average gain.',
      'growth': 'Growth',
      'growth.description': 'Investing in stocks, options, or futures with strong earnings and/or revenue growth or potential.',
      'income': 'Income',
      'income.description': 'A strategy focused on current income rather than capital appreciation.',
      'capital-preservation': 'Capital Preservation',
      'capital-preservation.description': 'A conservative investment strategy characterized by a desire to avoid risk of loss.'
    },
    'options.account-purpose': {
      'speculating': 'Speculation'
    },
    'options.investment-time-horizon': {
      'short': 'Short',
      'short.description': 'Less than 3 years',
      'average': 'Average',
      'average.description': '4 to 7 years',
      'longest': 'Longest',
      'longest.description': '8 years or more'
    },
    'options.risk-tolerance': {
      'low': 'Low',
      'low.description': 'I want to preserve my initial principal in this account with minimal risk.',
      'medium': 'Medium',
      'medium.description': 'I am willing to accept some risk to my initial principal and tolerate some volatility to seek higher returns. I understand I could lose a portion of my money.',
      'high': 'High',
      'high.description': 'I am willing to take on maximum risk and I am financially able to tolerate significant losses, such as unlimited loss, to aggressively seek maximum returns.'
    },
    'options.net-worth': {
      '0': '$0 - 50K',
      '50001': '$50K - 100K',
      '100001': '$100K - 200K',
      '200001': '$200K - 500K',
      '500001': '$500K - 1M',
      '1000001': '$1M - 5M',
      '5000001': '$5M +'
    },
    'options.net-income': {
      '0': '$0 - 25K',
      '25001': '$25K - 50K',
      '50001': '$50K - 100K',
      '100001': '$100K - 200K',
      '200001': '$200K - 300K',
      '300001': '$300K - 500K',
      '500001': '$500K - 1.2M',
      '1200001': '$1.2M +'
    },
    'options.experience': {
      'limited': 'Limited',
      'good': 'Good',
      'extensive': 'Extensive'
    },
    'options.available-countries': {
      'afg': 'Afghanistan',
      'ala': 'Åland Islands',
      'alb': 'Albania',
      'dza': 'Algeria',
      'asm': 'American Samoa',
      'and': 'Andorra',
      'ago': 'Angola',
      'aia': 'Anguilla',
      'ata': 'Antarctica',
      'atg': 'Antigua and Barbuda',
      'arg': 'Argentina',
      'arm': 'Armenia',
      'abw': 'Aruba',
      'aus': 'Australia',
      'aut': 'Austria',
      'aze': 'Azerbaijan',
      'bhs': 'Bahamas',
      'bhr': 'Bahrain',
      'bgd': 'Bangladesh',
      'brb': 'Barbados',
      'blr': 'Belarus',
      'bel': 'Belgium',
      'blz': 'Belize',
      'ben': 'Benin',
      'bmu': 'Bermuda',
      'btn': 'Bhutan',
      'bol': 'Bolivia (Plurinational State of)',
      'bes': 'Bonaire:Sint Eustatius and Saba',
      'bih': 'Bosnia and Herzegovina',
      'bwa': 'Botswana',
      'bvt': 'Bouvet Island',
      'bra': 'Brazil',
      'iot': 'British Indian Ocean Territory',
      'brn': 'Brunei Darussalam',
      'bgr': 'Bulgaria',
      'bfa': 'Burkina Faso',
      'bdi': 'Burundi',
      'cpv': 'Cabo Verde',
      'khm': 'Cambodia',
      'cmr': 'Cameroon',
      'can': 'Canada',
      'cym': 'Cayman Islands',
      'caf': 'Central African Republic',
      'tcd': 'Chad',
      'chl': 'Chile',
      'chn': 'China',
      'cxr': 'Christmas Island',
      'cck': 'Cocos (Keeling) Islands',
      'col': 'Colombia',
      'com': 'Comoros',
      'cod': 'Congo (Democratic Republic of the)',
      'cog': 'Congo',
      'cok': 'Cook Islands',
      'cri': 'Costa Rica',
      'hrv': 'Croatia',
      'cub': 'Cuba',
      'cuw': 'Curaçao',
      'cyp': 'Cyprus',
      'cze': 'Czech Republic',
      'civ': "Côte d'Ivoire",
      'dnk': 'Denmark',
      'dji': 'Djibouti',
      'dma': 'Dominica',
      'dom': 'Dominican Republic',
      'ecu': 'Ecuador',
      'egy': 'Egypt',
      'slv': 'El Salvador',
      'gnq': 'Equatorial Guinea',
      'eri': 'Eritrea',
      'est': 'Estonia',
      'eth': 'Ethiopia',
      'flk': 'Falkland Islands (Malvinas)',
      'fro': 'Faroe Islands',
      'fji': 'Fiji',
      'fin': 'Finland',
      'fra': 'France',
      'guf': 'French Guiana',
      'pyf': 'French Polynesia',
      'atf': 'French Southern Territories',
      'gab': 'Gabon',
      'gmb': 'Gambia',
      'geo': 'Georgia',
      'deu': 'Germany',
      'gha': 'Ghana',
      'gib': 'Gibraltar',
      'grc': 'Greece',
      'grl': 'Greenland',
      'grd': 'Grenada',
      'glp': 'Guadeloupe',
      'gum': 'Guam',
      'gtm': 'Guatemala',
      'ggy': 'Guernsey',
      'gin': 'Guinea',
      'gnb': 'Guinea-Bissau',
      'guy': 'Guyana',
      'hti': 'Haiti',
      'hmd': 'Heard Island and McDonald Islands',
      'vat': 'Holy See',
      'hnd': 'Honduras',
      'hkg': 'Hong Kong',
      'hun': 'Hungary',
      'isl': 'Iceland',
      'ind': 'India',
      'idn': 'Indonesia',
      'irn': 'Iran (Islamic Republic of)',
      'irq': 'Iraq',
      'irl': 'Ireland',
      'imn': 'Isle of Man',
      'isr': 'Israel',
      'ita': 'Italy',
      'jam': 'Jamaica',
      'jpn': 'Japan',
      'jey': 'Jersey',
      'jor': 'Jordan',
      'kaz': 'Kazakhstan',
      'ken': 'Kenya',
      'kir': 'Kiribati',
      'prk': "Korea (Democratic People's Republic of)",
      'kor': 'Korea (Republic of)',
      'kwt': 'Kuwait',
      'kgz': 'Kyrgyzstan',
      'lao': "Lao People's Democratic Republic",
      'lva': 'Latvia',
      'lbn': 'Lebanon',
      'lso': 'Lesotho',
      'lbr': 'Liberia',
      'lby': 'Libya',
      'lie': 'Liechtenstein',
      'ltu': 'Lithuania',
      'lux': 'Luxembourg',
      'mac': 'Macao',
      'mkd': 'Macedonia (the former Yugoslav Republic of)',
      'mdg': 'Madagascar',
      'mwi': 'Malawi',
      'mys': 'Malaysia',
      'mdv': 'Maldives',
      'mli': 'Mali',
      'mlt': 'Malta',
      'mhl': 'Marshall Islands',
      'mtq': 'Martinique',
      'mrt': 'Mauritania',
      'mus': 'Mauritius',
      'myt': 'Mayotte',
      'mex': 'Mexico',
      'fsm': 'Micronesia (Federated States of)',
      'mda': 'Moldova (Republic of)',
      'mco': 'Monaco',
      'mng': 'Mongolia',
      'mne': 'Montenegro',
      'msr': 'Montserrat',
      'mar': 'Morocco',
      'moz': 'Mozambique',
      'mmr': 'Myanmar',
      'nam': 'Namibia',
      'nru': 'Nauru',
      'npl': 'Nepal',
      'nld': 'Netherlands',
      'ncl': 'New Caledonia',
      'nzl': 'New Zealand',
      'nic': 'Nicaragua',
      'ner': 'Niger',
      'nga': 'Nigeria',
      'niu': 'Niue',
      'nfk': 'Norfolk Island',
      'mnp': 'Northern Mariana Islands',
      'nor': 'Norway',
      'omn': 'Oman',
      'pak': 'Pakistan',
      'plw': 'Palau',
      'pse': 'Palestine:State of',
      'pan': 'Panama',
      'png': 'Papua New Guinea',
      'pry': 'Paraguay',
      'per': 'Peru',
      'phl': 'Philippines',
      'pcn': 'Pitcairn',
      'pol': 'Poland',
      'prt': 'Portugal',
      'pri': 'Puerto Rico',
      'qat': 'Qatar',
      'reu': 'Réunion',
      'rou': 'Romania',
      'rus': 'Russian Federation',
      'rwa': 'Rwanda',
      'blm': 'Saint Barthélemy',
      'shn': 'Saint Helena:Ascension and Tristan da Cunha',
      'kna': 'Saint Kitts and Nevis',
      'lca': 'Saint Lucia',
      'maf': 'Saint Martin (French part)',
      'spm': 'Saint Pierre and Miquelon',
      'vct': 'Saint Vincent and the Grenadines',
      'wsm': 'Samoa',
      'smr': 'San Marino',
      'stp': 'Sao Tome and Principe',
      'sau': 'Saudi Arabia',
      'sen': 'Senegal',
      'srb': 'Serbia',
      'syc': 'Seychelles',
      'sle': 'Sierra Leone',
      'sgp': 'Singapore',
      'sxm': 'Sint Maarten (Dutch part)',
      'svk': 'Slovakia',
      'svn': 'Slovenia',
      'slb': 'Solomon Islands',
      'som': 'Somalia',
      'zaf': 'South Africa',
      'sgs': 'South Georgia and the South Sandwich Islands',
      'ssd': 'South Sudan',
      'esp': 'Spain',
      'lka': 'Sri Lanka',
      'sdn': 'Sudan',
      'sur': 'Suriname',
      'sjm': 'Svalbard and Jan Mayen',
      'swz': 'Swaziland',
      'swe': 'Sweden',
      'che': 'Switzerland',
      'syr': 'Syrian Arab Republic',
      'twn': 'Taiwan',
      'tjk': 'Tajikistan',
      'tza': 'Tanzania:United Republic of',
      'tha': 'Thailand',
      'tls': 'Timor-Leste',
      'tgo': 'Togo',
      'tkl': 'Tokelau',
      'ton': 'Tonga',
      'tto': 'Trinidad and Tobago',
      'tun': 'Tunisia',
      'tur': 'Turkey',
      'tkm': 'Turkmenistan',
      'tca': 'Turks and Caicos Islands',
      'tuv': 'Tuvalu',
      'uga': 'Uganda',
      'ukr': 'Ukraine',
      'are': 'United Arab Emirates',
      'gbr': 'United Kingdom of Great Britain and Northern Ireland',
      'usa': 'United States of America',
      'umi': 'United States Minor Outlying Islands',
      'ury': 'Uruguay',
      'uzb': 'Uzbekistan',
      'vut': 'Vanuatu',
      'ven': 'Venezuela (Bolivarian Republic of)',
      'vnm': 'Viet Nam',
      'vgb': 'Virgin Islands (British)',
      'vir': 'Virgin Islands (U.S.)',
      'wlf': 'Wallis and Futuna',
      'esh': 'Western Sahara',
      'yem': 'Yemen',
      'zmb': 'Zambia',
      'zwe': 'Zimbabwe'
    }
  };

  exports.default = {
    common: common,

    'user.have-other-accounts': 'Do you have other accounts with us?',

    'user.email': 'Email Address',
    'user.username': 'Username',
    'user.password': 'Password',
    'user.password.hint': '(12+ characters)',
    'user.password-confirmation': 'Confirm Password',
    'user.email-confirmation': 'Confirm Email',

    'user-security-question.question': 'Security Question',
    'user-security-question.answer': 'Question {{number}} Answer',
    'acat-request': {
      'delivering-account-title': 'Title of Your Account',
      'delivering-account-title.hint': 'Name(s) of the account holder(s) and the account type',
      'delivering-account-number': 'Account Number',
      'delivering-account-number-confirmation': 'Confirm Account Number',
      'transfer-type.options': {
        'full-transfer': 'Transfer all assets in kind',
        'partial-transfer-receiver': 'Partial Transfer'
      }
    },

    'account': {
      'futures-account-purpose.options': common['options.account-purpose'],
      'investment-objective.options': common['options.investment-objective'],
      'investment-time-horizon.options': common['options.investment-time-horizon'],
      'risk-tolerance.options': common['options.risk-tolerance']
    },

    'account-beneficiaries.beneficiary-type': 'Type',
    'account-beneficiaries.first-name': 'First Name',
    'account-beneficiaries.middle-name': 'Middle Name',
    'account-beneficiaries.last-name': 'Last Name',
    'account-beneficiaries.beneficiary-relationship': 'Relationship',
    'account-beneficiaries.tax-number': 'Social Security Number',
    'account-beneficiaries.tax-number.hint': '(XXX-XX-XXXX)',
    'account-beneficiaries.birth-date': 'Date of Birth',

    'applicant-data': {
      'email': 'Email Address',
      'email-confirmation': 'Confirm Email',
      'first-name': 'First Name',
      'last-name': 'Last Name'
    },

    'beneficial-owner': {
      'birth-date': 'Date of Birth',
      'email': 'Email Address',
      'first-name': 'First Name',
      'middle-name': 'Middle Name',
      'last-name': 'Last Name',
      'ownership-percent': 'Ownership %',
      'prefix-name': 'Prefix',
      'suffix-name': 'Suffix',
      'tax-number': 'Social Security Number',
      'tax-number.hint': '(XXX-XX-XXXX)'
    },

    'customer': {
      'prefix-name': 'Prefix',
      'first-name': 'First Name',
      'middle-name': 'Middle Name',
      'last-name': 'Last Name',
      'suffix-name': 'Suffix',
      'mobile-phone-number': 'Mobile Phone',
      'work-phone-number': 'Work Phone',
      'home-phone-number': 'Home Phone',
      'tax-number': 'Social Security Number',
      'tax-number.hint': '(XXX-XX-XXXX)',
      'birth-date': 'Date of Birth',
      'email': 'Email Address',
      'gender': 'Gender',
      'gender.question': 'Which of the following most accurately describe(s) you? Choose as many as you like',
      'gender.options': {
        'custom': 'Let me type...',
        'intersex': 'Intersex',
        'female': 'Female',
        'male': 'Male',
        'non-binary': 'Non-binary',
        'prefer-not-to-say': 'I prefer not to say',
        'transgender': 'Transgender'
      },
      'is-address-same': 'My mailing address and physical address are the same',

      'citizenship-country': 'Country of Citizenship',
      'citizenship-country.options': common['options.available-countries'],
      'foreign-tax-number': 'Tax Number',
      'birth-country': 'Country of Birth',
      'birth-country.options': common['options.available-countries'],
      'visa-type': 'Visa Type',
      'visa-type.options': {
        'e1': 'E1',
        'e2': 'E2',
        'e3': 'E3',
        'f1': 'F1',
        'h1b': 'H1B',
        'l1': 'L1',
        'o1': 'O1',
        'tn': 'TN1'
      },
      'visa-expiration-date': 'Visa Expiration Date',

      'has-industry-affiliation': 'Are you employed, registered or affiliated with a securities or futures member firm, exchange or exchange member?',
      'listed-affiliation-symbol': 'Company Name',
      'has-political-affiliation': 'Is the account maintained for a current or former Politically Exposed Person or Public Official (includes U.S. and Foreign Individuals)?',
      'political-organization': 'Political Organization',
      'family-member-names': 'Family Member(s)',
      'has-listed-affiliation': 'Are you a director, 10% shareholder, or policy-making officer of a publicly traded company?',
      'industry-affiliation-firm': 'Firm or Exchange Name',
      'subject-to-tax-withholding': 'Are you subject to backup withholding?',
      'is-investment-adviser': 'Are you an investment adviser registered either with the SEC under Section 203 of the Investment Advisers Act or with a state securities commission (or any agency or office performing like functions)?',
      'has-institutional-assets': 'Is this account maintained for any other person (whether a natural person, corporation, partnership, trust or otherwise) with total assets of at least $50 million?'
    },

    'customer-suitability.marital-status': 'Marital Status',
    'customer-suitability.number-of-dependents': '# Dependents',

    'customer-suitability': {
      'stock-trading-experience': 'Stocks',
      'covered-options-trading-experience': 'Covered Options and Spreads',
      'uncovered-options-trading-experience': 'Uncovered Options',
      'futures-trading-experience': 'Futures',
      'stock-trading-experience.options': common['options.experience'],
      'covered-options-trading-experience.options': common['options.experience'],
      'uncovered-options-trading-experience.options': common['options.experience'],
      'futures-trading-experience.options': common['options.experience'],

      'annual-net-income': 'Annual Income',
      'net-worth': 'Net Worth',
      'net-worth.hint': '(excluding residence)',
      'liquid-net-worth': 'Liquid Net Worth',
      'net-worth.options': common['options.net-worth'],
      'liquid-net-worth.options': common['options.net-worth'],
      'annual-net-income.options': common['options.net-income']
    },

    'customer-suitability.employment-status': 'Employment Status',
    'customer-suitability.employer-name': 'Employer Name',
    'customer-suitability.business-name': 'Business Name',
    'customer-suitability.job-title': 'Job Title',
    'customer-suitability.occupation': 'Occupation',
    'customer-suitability.marital-status.options': {
      'single': 'Single',
      'married': 'Married',
      'divorced': 'Divorced',
      'widowed': 'Widowed'
    },
    'customer-suitability.employment-status.options': {
      'employed': 'Employed',
      'unemployed': 'Unemployed',
      'retired': 'Retired',
      'student': 'Student'
    },

    'entity': {
      'business-nature': 'Business Nature',
      'grantor-birth-date': 'Grantor\'s Date of Birth',
      'grantor-email': 'Grantor\'s Email',
      'grantor-first-name': 'Grantor\'s First Name',
      'grantor-middle-name': 'Grantor\'s Middle Name',
      'grantor-last-name': 'Grantor\'s Last Name',
      'grantor-tax-number': 'Grantor\'s Social Security Number',
      'grantor-tax-number.hint': '(XXX-XX-XXXX)',
      'legal-name': 'Legal Name',
      'legal-exemption-reason': 'Is this account maintained for a Legal Entity defined as:',
      'legal-exemption-reason.options': {
        'cftc-registered-entity': 'a registered entity, commodity pool operator, commodity trading advisor, retail foreign exchange dealer, swap dealer or major swap participant, each as defined in section 1a of the Commodity Exchange Act, that is registered with the Commodity Futures Trading Commission',
        'issuer-of-securities': 'an issuer of a class of securities registered under section 12 of the Securities Exchange Act of 1934 or that is required to file reports under section 15(d) of that Act',
        'listed-entity': 'any entity (other than a bank) whose common stock or analogous equity interests are listed on the New York, American or NASDAQ stock exchange (each, a Listed Entity)',
        'none': 'none of the above apply to this legal entity',
        'public-accounting-firm': 'a public accounting firm registered under section 102 of the Sarbanes-Oxley Act',
        'registered-exchange-or-clearing-agency': 'an exchange or clearing agency, as defined in section 3 of the Securities Exchange Act of 1934, that is registered under section 6 or 17A of that Act',
        'registered-investment-adviser': 'an investment adviser, as defined in section 202(a) (11) of the Investment Advisers Act of 1940, that is registered with the SEC under that Act',
        'registered-investment-company': 'an investment company, as defined in section 3 of the Investment Company Act of 1940, that is registered with the Securities and Exchange Commission (SEC) under that Act',
        'regulated-insurance-company': 'an insurance company that is regulated by a State',
        'sec-registered-entity': 'any other entity registered with the SEC under the Securities Exchange Act of 1934',
        'usa-department-or-agency': 'a department or agency of the United States, of any State, or of any political subdivision of any State'
      },
      'phone-number': 'Phone Number',
      'subject-to-tax-withholding': 'Are you, any authorized agent, or the entity itself subject to backup tax withholding?',
      'tax-number': 'Entity Tax ID Number'
    },

    'entity-suitability': {
      'stock-trading-experience': 'Stocks',
      'covered-options-trading-experience': 'Covered Options and Spreads',
      'uncovered-options-trading-experience': 'Uncovered Options',
      'futures-trading-experience': 'Futures',
      'stock-trading-experience.options': common['options.experience'],
      'covered-options-trading-experience.options': common['options.experience'],
      'uncovered-options-trading-experience.options': common['options.experience'],
      'futures-trading-experience.options': common['options.experience'],

      'annual-net-income': 'Annual Income',
      'net-worth': 'Net Worth',
      'net-worth.hint': '(excluding residence)',
      'liquid-net-worth': 'Liquid Net Worth',
      'net-worth.options': common['options.net-worth'],
      'liquid-net-worth.options': common['options.net-worth'],
      'annual-net-income.options': common['options.net-income']
    },

    'futures-agreement-record': {
      'futures-industry-firm': 'Futures Commission Merchant or Introducing Broker',
      'has-futures-industry-affiliation': 'Are you an affiliated person, general partner, employee, or otherwise associated with a Futures Commission Merchant or Futures Introducing Broker?',
      'is-nfa-pool': 'Are you registered as a pool or pool operator with the NFA?',
      'is-registered': 'Are you registered with the CFTC, NFA, or any futures exchange member?',
      'is-registration-exempt': 'Are you operating pursuant to a registration exemption under the Commodities Exchange Act?',
      'is-soliciting': 'Are you soliciting or pooling funds for the purpose of investing in a capacity that requires NFA registration or a qualified exemption?',
      'listed-affiliation-symbol': 'What is the symbol for the equity are you affiliated with?',
      'registration-exemption': 'Provide Exemption',
      'registration-member': 'Regulatory body or exchange member'
    },

    'address.street-one': 'Address 1',
    'address.street-two': 'Address 2',
    'address.street-two.hint': '(Apt / Suite / Other)',
    'address.city': 'City',
    'address.state-region': 'State',
    'address.postal-code': 'Zip Code',
    'address.country': 'Country',
    'address.country.options': common['options.available-countries'],
    'address.full-double-line': 'Address',
    'mailing-address.full-double-line': 'Mailing Address',

    'account-opening-application.investment-objective': 'Investment Objective',
    'account-opening-application.is-trustee-compensated': 'Are you compensated for the services you provide on behalf of the trust?',

    'account-opening-application.options-level.options': {
      'not-suitable': 'None',
      'covered-and-cash-secured': 'Style 1',
      'defined-risk-spreads': 'Style 2',
      'no-restrictions': 'Style 3',
      'covered-and-cash-secured.description': 'If you wish to buy and sell stock, sell covered calls, and sell cash-secured puts.',
      'defined-risk-spreads.description': 'If you wish to trade Style 1 strategies, plus buy call and put options as well as buy and sell covered option spreads.',
      'no-restrictions.description': 'If you wish to trade Style 1 and Style 2 strategies, plus sell naked options.'
    },

    'account-opening-application.investment-objective.options': common['options.investment-objective'],

    'account-opening-application.margin-or-cash.options': {
      'margin': 'Margin Account',
      'cash': 'Cash Account',
      'margin.description': "In a margin account we may lend you a portion of the purchase price. This is referred to as buying securities 'on margin'. You are liable for repaying the borrowed funds and the interest incurred.",
      'cash.description': 'In a cash account you pay for the securities in full by the settlement date of the purchase.'
    },

    'account-opening-application.risk-tolerance.options': common['options.risk-tolerance'],

    'account-opening-application.investment-time-horizon.options': common['options.investment-time-horizon'],

    'agreement.i-agree': 'I Agree',
    'agreement.signature': 'Signature',

    'money/transactions-filter': {
      'type': 'Type',
      'status': 'Status',
      'time-period': 'Time Period'
    },

    'money/deposits/one-time-deposit-frequency': {
      'frequency': 'Transaction Frequency',
      'frequency.options': {
        'one-time': 'One Time'
      }
    },

    'cash-transaction': {
      'bank-country': 'Bank Country',
      'bank-country.options': common['options.available-countries'],
      'delivery-method': 'Delivery Method',
      'delivery-method.options': {
        'standard': 'Standard Delivery {{fee}} (5-7 business days)',
        'overnight': 'Overnight Delivery {{fee}} (Requests must be submitted by 9am CST)'
      }
    },
    'referred-prospect': {
      'employer': 'Employer',
      'first-name': 'First Name',
      'last-name': 'Last Name',
      'mailing-street-one': 'Home Address 1',
      'mailing-street-two': 'Home Address 2',
      'mailing-city': 'City',
      'mailing-country': 'Country',
      'mailing-country.options': common['options.available-countries'],
      'mailing-postal-code': 'Postal Code',
      'mobile-phone': 'Mobile Phone',
      'occupation': 'Occupation',
      'postal-code': 'Zip Code',
      'state-region': 'State'
    },

    'trust': {
      'legal-name': 'Title of Trust',
      'phone-number': 'Grantor\'s Phone Number',
      'subject-to-tax-withholding': 'Are you, any authorized agent, or the Trust itself subject to backup tax withholding?',
      'tax-number': 'Social Security or Tax ID of Trust'
    },

    'trusted-contact': {
      'birth-date': 'Date of Birth',
      'email': 'Email Address',
      'first-name': 'First Name',
      'last-name': 'Last Name',
      'middle-name': 'Middle Name',
      'phone-number': 'Phone Number',
      'prefix-name': 'Prefix',
      'suffix-name': 'Suffix'
    },

    'twapi-address-document-verification': {
      'address-document-type': 'Choose an option for address document upload',
      'address-document-type.options': {
        'bank-statement': 'Bank Statement',
        'credit-card-statement': 'Credit Card Statement',
        'utility-bill': 'Utility Bill'
      }
    },

    'twapi-domestic-address-question': {
      'is-us-resident.options': common['options.boolean']
    }
  };
});