define('twapi/models/account-portfolio-subscription', ['exports', 'ember-restless', 'lodash', 'twapi/utils/validation'], function (exports, _emberRestless, _lodash, _validation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.STATUSES = undefined;
  var Model = _emberRestless.default.Model,
      attr = _emberRestless.default.attr,
      belongsTo = _emberRestless.default.belongsTo;
  var _Ember$computed = Ember.computed,
      equal = _Ember$computed.equal,
      or = _Ember$computed.or;
  var STATUSES = exports.STATUSES = {
    ACTIVE: 'Active',
    CATCHUP_ELIGIBLE: 'Catchup Eligible',
    FUNDING_REQUIRED: 'Funding Required',
    INACTIVE: 'Inactive',
    NEEDS_REVIEW: 'Needs Review',
    PENDING_ACTIVATION: 'Pending Activation'
  };

  exports.default = Model.extend({
    modelPortfolio: belongsTo('model-portfolio'),

    accountNumber: attr('string'),

    status: attr('string'),

    optInDate: attr('momentDateTime'),

    optOutDate: attr('momentDateTime'),

    unitCount: attr('number', { defaultValue: 1 }),

    isActive: equal('status', STATUSES.ACTIVE),

    isFundingRequired: equal('status', STATUSES.FUNDING_REQUIRED),

    isInactive: equal('status', STATUSES.INACTIVE),

    isPending: equal('status', STATUSES.PENDING_ACTIVATION)
  }).reopen(_validation.build(_lodash.default.merge({
    modelPortfolio: {
      presence: true
    },
    unitCount: {
      presence: true,
      number: {
        allowString: true,
        integer: true,
        gte: 1
      }
    }
  }))).reopenClass({
    resourceName: 'account-portfolio-subscription'
  });
});