define('twapi/models/document-verification-message', ['exports', 'ember-restless'], function (exports, _emberRestless) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var _Ember$computed = Ember.computed,
      equal = _Ember$computed.equal,
      readOnly = _Ember$computed.readOnly;
  var Model = _emberRestless.default.Model,
      attr = _emberRestless.default.attr,
      belongsTo = _emberRestless.default.belongsTo;


  var STATUSES = {
    ERROR: 'error',
    LOADED: 'loaded',
    SUCCESS: 'success'
  };

  exports.default = Model.extend({
    customerInternalReference: attr('string'),

    eventType: attr('number'),

    dateTime: attr('momentDateTime'),

    payload: belongsTo('document-verification-payload'),

    transactionReference: attr('string'),

    verificationStatus: readOnly('payload.value'),

    hasLoadedStatus: equal('verificationStatus', STATUSES.LOADED),

    hasSuccessStatus: equal('verificationStatus', STATUSES.SUCCESS),

    hasErrorStatus: equal('verificationStatus', STATUSES.ERROR)
  }).reopenClass({
    resourceName: 'document-verification-message'
  });
});