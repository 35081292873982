define('twapi/models/equity-option-root-translation', ['exports', 'ember-restless'], function (exports, _emberRestless) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberRestless.default.ReadOnlyModel.extend({
    rootSymbol: _emberRestless.default.attr('string'),
    underlyingSymbol: _emberRestless.default.attr('string'),
    optionChainType: _emberRestless.default.attr('string')
  });
});