define('twapi/transforms/big-number', ['exports', 'ember-restless', 'bignumber.js'], function (exports, _emberRestless, _bignumber) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Transform = _emberRestless.default.Transform;
  exports.default = Transform.extend({
    deserialize: _bignumber.default,
    serialize: function serialize(obj) {
      if (_bignumber.default.isBigNumber(obj)) {
        return obj.toString();
      } else {
        return (0, _bignumber.default)(obj).toString();
      }
    }
  });
});