define('twapi/components/fully-paid-securities-lending/twapi-advantages-disadvantages', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    helpUrl: 'https://support.tastytrade.com/support/s/solutions/articles/43000685319'
  });
});