define('twapi/validators/international-phone-number', ['exports', 'ember-cp-validations/validators/base', 'libphonenumber-js'], function (exports, _base, _libphonenumberJs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var isEmpty = Ember.isEmpty;


  var InternationalPhoneNumber = _base.default.extend({
    validate: function validate(value, options, model, attribute) {
      if (isEmpty(value)) {
        if (options.allowBlank) {
          return true;
        } else {
          return this.createErrorMessage(undefined, undefined, options);
        }
      }
      var sanitized = '+' + value.replace(/\D/g, '');

      if (!(0, _libphonenumberJs.isValidNumber)(sanitized)) {
        return this.createErrorMessage(undefined, undefined, options);
      }
      return true;
    }
  });

  exports.default = InternationalPhoneNumber;
});