define('twapi/models/supporting-document', ['exports', 'ember-restless', 'ember-decorators/object', 'twapi/utils/slug'], function (exports, _emberRestless, _object, _slug) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var _Ember = Ember,
      _Ember$computed = _Ember.computed,
      equal = _Ember$computed.equal,
      or = _Ember$computed.or;
  var Model = _emberRestless.default.Model,
      attr = _emberRestless.default.attr;


  var STATUSES = {
    REJECTED: 'Rejected',
    REQUESTED: 'Requested',
    REQUEST_CANCELLED: 'Request Cancelled',
    SUBMITTED: 'Submitted',
    UPLOADED_TO_SNAP: 'Uploaded to SNAP'
  };

  exports.default = Model.extend({
    documentType: attr('string'),
    slug: attr('string'),
    isAvailable: attr('boolean'),
    status: attr('string'),

    isSubmitted: equal('status', STATUSES.SUBMITTED),
    isUploadedToSnap: equal('status', STATUSES.UPLOADED_TO_SNAP),
    isAccepted: or('isSubmitted', 'isUploadedToSnap'),

    isIdDocument: equal('slug', _slug.SLUGS.ID_DOCUMENT),
    isAddressDocument: equal('slug', _slug.SLUGS.ADDRESS_DOCUMENT)
  }).reopenClass({
    resourceName: 'supporting-document'
  });
});