define('twapi/models/plaid-link-state', ['exports', 'ember-restless'], function (exports, _emberRestless) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.LINK_LOCATIONS = undefined;
  var Model = _emberRestless.default.Model,
      belongsTo = _emberRestless.default.belongsTo,
      attr = _emberRestless.default.attr;
  var _Ember$computed = Ember.computed,
      equal = _Ember$computed.equal,
      or = _Ember$computed.or;
  var LINK_LOCATIONS = exports.LINK_LOCATIONS = {
    ACAT: 'ACAT',
    ACH_DEPOSIT: 'ACH_DEPOSIT',
    ACH_WITHDRAWAL: 'ACH_WITHDRAWAL'
  };

  exports.default = Model.extend({
    accountNumber: attr('string'),

    linkLocation: attr('string'),

    formData: attr(), // NOTE: [KT] free-form javascript object depending on link location

    plaidLinkToken: belongsTo('plaid-link-token'),

    isAcat: equal('linkLocation', LINK_LOCATIONS.ACAT),

    isAch: or('isAchWithdrawal', 'isAchDeposit'),

    isAchDeposit: equal('linkLocation', LINK_LOCATIONS.ACH_DEPOSIT),

    isAchWithdrawal: equal('linkLocation', LINK_LOCATIONS.ACH_WITHDRAWAL)
  }).reopenClass({
    resourceName: 'plaid-link-state'
  });
});