define('twapi/models/account-type', ['exports', 'ember-restless', 'ember-decorators/object', 'twapi/models/-server-static'], function (exports, _emberRestless, _object, _serverStatic) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.HasAccountTypeName = exports.MARGIN_TYPES = exports.ENTITY_TYPES = exports.JOINT_TYPES = exports.IRA_TYPES = exports.ACCOUNT_TYPE_NAMES = exports.ACCOUNT_TYPES = exports.TRUST = exports.BUSINESS = exports.BENEFICIARY_ROTH_IRA = exports.BENEFICIARY_TRADITIONAL_IRA = exports.SEP_IRA = exports.ROTH_IRA = exports.TRADITIONAL_IRA = exports.JOINT_TENANTS_IN_COMMON = exports.JOINT_WROS = exports.INDIVIDUAL = undefined;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _desc, _value, _obj, _dec7, _desc2, _value2, _obj2, _init;

  var INDIVIDUAL = exports.INDIVIDUAL = 'Individual';
  var JOINT_WROS = exports.JOINT_WROS = 'Joint Tenants with Rights of Survivorship';
  var JOINT_TENANTS_IN_COMMON = exports.JOINT_TENANTS_IN_COMMON = 'Joint Tenants in Common';
  var TRADITIONAL_IRA = exports.TRADITIONAL_IRA = 'Traditional IRA';
  var ROTH_IRA = exports.ROTH_IRA = 'Roth IRA';
  var SEP_IRA = exports.SEP_IRA = 'SEP';
  var BENEFICIARY_TRADITIONAL_IRA = exports.BENEFICIARY_TRADITIONAL_IRA = 'Beneficiary Traditional IRA';
  var BENEFICIARY_ROTH_IRA = exports.BENEFICIARY_ROTH_IRA = 'Beneficiary Roth IRA';
  var BUSINESS = exports.BUSINESS = 'Business';
  var TRUST = exports.TRUST = 'Trust';

  var ACCOUNT_TYPES = exports.ACCOUNT_TYPES = {
    INDIVIDUAL: INDIVIDUAL,
    JOINT_WROS: JOINT_WROS,
    JOINT_TENANTS_IN_COMMON: JOINT_TENANTS_IN_COMMON,
    TRADITIONAL_IRA: TRADITIONAL_IRA,
    ROTH_IRA: ROTH_IRA,
    SEP_IRA: SEP_IRA,
    BENEFICIARY_TRADITIONAL_IRA: BENEFICIARY_TRADITIONAL_IRA,
    BENEFICIARY_ROTH_IRA: BENEFICIARY_ROTH_IRA,
    BUSINESS: BUSINESS,
    TRUST: TRUST
  };

  var ACCOUNT_TYPE_NAMES = exports.ACCOUNT_TYPE_NAMES = Object.values(ACCOUNT_TYPES);

  var IRA_TYPES = exports.IRA_TYPES = {
    TRADITIONAL_IRA: TRADITIONAL_IRA,
    ROTH_IRA: ROTH_IRA,
    SEP_IRA: SEP_IRA,
    BENEFICIARY_TRADITIONAL_IRA: BENEFICIARY_TRADITIONAL_IRA,
    BENEFICIARY_ROTH_IRA: BENEFICIARY_ROTH_IRA
  };

  var BENEFICIARY_TYPES = {
    BENEFICIARY_TRADITIONAL_IRA: BENEFICIARY_TRADITIONAL_IRA,
    BENEFICIARY_ROTH_IRA: BENEFICIARY_ROTH_IRA
  };

  var JOINT_TYPES = exports.JOINT_TYPES = {
    WROS: JOINT_WROS,
    TENANTS_IN_COMMON: JOINT_TENANTS_IN_COMMON
  };

  var ENTITY_TYPES = exports.ENTITY_TYPES = {
    BUSINESS: BUSINESS,
    TRUST: TRUST
  };

  var MARGIN_TYPES = exports.MARGIN_TYPES = {
    MARGIN: 'Margin',
    CASH: 'Cash'
  };

  var _set = Ember.set,
      _Ember$computed = Ember.computed,
      equal = _Ember$computed.equal,
      or = _Ember$computed.or;
  var attr = _emberRestless.default.attr,
      hasMany = _emberRestless.default.hasMany;


  var AccountType = _serverStatic.default.extend((_dec = (0, _object.computed)('name'), _dec2 = (0, _object.computed)('marginTypes.[]'), _dec3 = (0, _object.computed)('name'), _dec4 = (0, _object.computed)('name'), _dec5 = (0, _object.computed)('name'), _dec6 = (0, _object.computed)('name'), (_obj = {
    name: attr('string'),
    description: attr('string'),
    isTaxAdvantaged: attr('boolean'),
    hasMultipleOwners: attr('boolean'),
    isPubliclyAvailable: attr('boolean'),

    marginTypes: hasMany('margin-type'),

    isAnyRothIra: or('isRothIra', 'isBeneficiaryRothIra'),

    isAnyTraditionalIra: or('isTraditionalIra', 'isBeneficiaryTraditionalIra'),

    isBeneficiaryRothIra: equal('name', BENEFICIARY_ROTH_IRA),

    isBeneficiaryTraditionalIra: equal('name', BENEFICIARY_TRADITIONAL_IRA),

    isBusiness: equal('name', BUSINESS),

    isEmployerSponsored: equal('name', SEP_IRA),

    isEntity: or('isTrust', 'isBusiness'),

    isFullyPaidSecuritiesLendingEligible: or('isAnyIra', 'isIndividual'),

    isIndividual: equal('name', INDIVIDUAL),

    isJointTenantsInCommon: equal('name', JOINT_TENANTS_IN_COMMON),

    isJointWros: equal('name', JOINT_WROS),

    isRothIra: equal('name', ROTH_IRA),

    isSepIra: equal('name', SEP_IRA),

    isTraditionalIra: equal('name', TRADITIONAL_IRA),

    isTrust: equal('name', TRUST),

    displayName: function displayName(name) {
      switch (name) {
        case SEP_IRA:
          return 'SEP IRA';
        case JOINT_WROS:
          return 'Joint WROS';
        default:
          return name;
      }
    },
    supportsMargin: function supportsMargin(marginTypes) {
      return marginTypes.isAny('isMargin');
    },
    isAnyIra: function isAnyIra(accountTypeName) {
      return Object.values(IRA_TYPES).includes(accountTypeName);
    },
    isAnyBeneficiaryIra: function isAnyBeneficiaryIra(accountTypeName) {
      return Object.values(BENEFICIARY_TYPES).includes(accountTypeName);
    },
    isAnyJoint: function isAnyJoint(accountTypeName) {
      return Object.values(JOINT_TYPES).includes(accountTypeName);
    },
    isAnyEntity: function isAnyEntity(accountTypeName) {
      return Object.values(ENTITY_TYPES).includes(accountTypeName);
    },
    supportsCash: function supportsCash() {
      // As of 2016-04-06, all accounts support cash so we have nothing identifying this.
      return true;
    }
  }, (_applyDecoratedDescriptor(_obj, 'displayName', [_dec], Object.getOwnPropertyDescriptor(_obj, 'displayName'), _obj), _applyDecoratedDescriptor(_obj, 'supportsMargin', [_dec2], Object.getOwnPropertyDescriptor(_obj, 'supportsMargin'), _obj), _applyDecoratedDescriptor(_obj, 'isAnyIra', [_dec3], Object.getOwnPropertyDescriptor(_obj, 'isAnyIra'), _obj), _applyDecoratedDescriptor(_obj, 'isAnyBeneficiaryIra', [_dec4], Object.getOwnPropertyDescriptor(_obj, 'isAnyBeneficiaryIra'), _obj), _applyDecoratedDescriptor(_obj, 'isAnyJoint', [_dec5], Object.getOwnPropertyDescriptor(_obj, 'isAnyJoint'), _obj), _applyDecoratedDescriptor(_obj, 'isAnyEntity', [_dec6], Object.getOwnPropertyDescriptor(_obj, 'isAnyEntity'), _obj), _applyDecoratedDescriptor(_obj, 'supportsCash', [_object.computed], Object.getOwnPropertyDescriptor(_obj, 'supportsCash'), _obj)), _obj))).reopenClass({
    resourceName: 'account-type',
    primaryKey: 'name',
    afterFindAll: function afterFindAll(all) {
      ACCOUNT_TYPE_NAMES.pushObjects(all.mapBy('name'));
    }
  });

  exports.default = AccountType;
  var HasAccountTypeName = exports.HasAccountTypeName = Ember.Mixin.create((_dec7 = (0, _object.computed)('accountTypeName'), (_obj2 = {
    accountType: {
      get: function get(name) {
        return AccountType.find(name);
      },
      set: function set(type) {
        _set(this, 'accountTypeName', type.get('name'));
        return type;
      }
    }
  }, (_applyDecoratedDescriptor(_obj2, 'accountType', [_dec7], (_init = Object.getOwnPropertyDescriptor(_obj2, 'accountType'), _init = _init ? _init.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function initializer() {
      return _init;
    }
  }), _obj2)), _obj2)));
});