define('twapi/models/customer-suitability', ['exports', 'ember-restless', 'ember-decorators/object', 'twapi/mixins/suitability', 'twapi/utils/validation'], function (exports, _emberRestless, _object, _suitability, _validation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.INVESTMENT_EXPERIENCES = exports.OPTIONS = exports.EMPLOYMENT_STATUSES = undefined;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _dec2, _dec3, _desc, _value, _obj;

  var alias = Ember.computed.alias;
  var Model = _emberRestless.default.Model,
      attr = _emberRestless.default.attr;
  var EMPLOYMENT_STATUSES = exports.EMPLOYMENT_STATUSES = {
    EMPLOYED: 'EMPLOYED',
    UNEMPLOYED: 'UNEMPLOYED',
    RETIRED: 'RETIRED',
    STUDENT: 'STUDENT'
  };

  var OPTIONS = exports.OPTIONS = {
    OCCUPATION: ['Accounting', 'Administration & Office Management', 'Agriculture', 'Architecture & Engineering', 'Art & Entertainment', 'Education', 'Financial Services', 'Food Services', 'Healthcare', 'Legal', 'Sales', 'Technology', 'Other'],
    EMPLOYMENT_STATUS: [EMPLOYMENT_STATUSES.EMPLOYED, EMPLOYMENT_STATUSES.UNEMPLOYED, EMPLOYMENT_STATUSES.RETIRED, EMPLOYMENT_STATUSES.STUDENT],
    IS_EMPLOYED: [EMPLOYMENT_STATUSES.EMPLOYED],
    MARITAL_STATUS: ['SINGLE', 'MARRIED', 'DIVORCED', 'WIDOWED']
  };

  var INVESTMENT_EXPERIENCES = exports.INVESTMENT_EXPERIENCES = {
    LIMITED: 'LIMITED',
    GOOD: 'GOOD',
    EXTENSIVE: 'EXTENSIVE'
  };

  exports.default = Model.extend(_suitability.default, (_dec = (0, _object.computed)('employmentStatus'), _dec2 = (0, _object.computed)('employmentStatus'), _dec3 = (0, _object.computed)('employmentStatus'), (_obj = {

    maritalStatus: attr('string'),

    numberOfDependents: attr('number'),

    employmentStatus: attr('string'),

    occupation: attr('string'),

    employerName: attr('string'),

    jobTitle: attr('string'),

    businessName: alias('employerName'),

    isEmployed: function isEmployed(employmentStatus) {
      return OPTIONS.IS_EMPLOYED.some(function (option) {
        return option === employmentStatus;
      });
    },
    isSelfEmployed: function isSelfEmployed(employmentStatus) {
      return employmentStatus === EMPLOYMENT_STATUSES.SELF_EMPLOYED;
    },
    isEmployedByOtherCompany: function isEmployedByOtherCompany(employmentStatus) {
      return employmentStatus === EMPLOYMENT_STATUSES.EMPLOYED;
    }
  }, (_applyDecoratedDescriptor(_obj, 'isEmployed', [_dec], Object.getOwnPropertyDescriptor(_obj, 'isEmployed'), _obj), _applyDecoratedDescriptor(_obj, 'isSelfEmployed', [_dec2], Object.getOwnPropertyDescriptor(_obj, 'isSelfEmployed'), _obj), _applyDecoratedDescriptor(_obj, 'isEmployedByOtherCompany', [_dec3], Object.getOwnPropertyDescriptor(_obj, 'isEmployedByOtherCompany'), _obj)), _obj))).reopen(_validation.build({
    maritalStatus: {
      inclusion: { in: OPTIONS.MARITAL_STATUS }
    },
    employmentStatus: {
      inclusion: { in: OPTIONS.EMPLOYMENT_STATUS }
    },
    occupation: {
      inclusion: { in: OPTIONS.OCCUPATION },
      presence: { 'if': 'isEmployed' }
    },
    numberOfDependents: {
      presence: true,
      number: {
        allowString: true,
        integer: true,
        gte: 0,
        lte: 40
      }
    },
    employerName: { presence: { 'if': 'isEmployed' } },
    jobTitle: {
      presence: { 'if': 'isEmployedByOtherCompany' }
    }
  })).reopenClass({
    resourceName: 'customerSuitability'
  });
});