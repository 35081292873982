define('twapi/models/address', ['exports', 'ember-restless', 'ember-decorators/object', 'twapi/utils/regex', 'twapi/utils/validation', 'twapi/utils/country'], function (exports, _emberRestless, _object, _regex, _validation, _country) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.STATE_OPTIONS = exports.FOREIGN_COUNTRIES_OPTIONS = exports.ACCEPTED_COUNTRIES_OPTIONS = exports.FOREIGN_ENTITY_OWNER_COUNTRIES_OPTIONS = exports.ENTITY_OWNER_COUNTRIES_OPTIONS = exports.WITHDRAWAL_COUNTRIES_OPTIONS = undefined;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _dec2, _dec3, _dec4, _desc, _value, _obj;

  var _Ember$computed = Ember.computed,
      bool = _Ember$computed.bool,
      equal = _Ember$computed.equal,
      not = _Ember$computed.not,
      or = _Ember$computed.or,
      get = Ember.get,
      _isEqual = Ember.isEqual,
      isPresent = Ember.isPresent;
  var Model = _emberRestless.default.Model,
      attr = _emberRestless.default.attr;


  var WITHDRAWAL_ONLY_COUNTRIES = {
    BULGARIA: 'BGR',
    KENYA: 'KEN',
    NAMIBIA: 'NAM',
    SOUTH_AFRICA: 'ZAF'

    // NOTE: ordered by full country name except USA
  };var ORDERED_COUNTRIES_OPTIONS = [_country.USA_COUNTRY_CODE, 'AND', 'ARG', 'AUS', 'AUT', 'BHR', 'BEL', 'BOL', WITHDRAWAL_ONLY_COUNTRIES.BULGARIA, 'BRA', _country.CANADA_COUNTRY_CODE, 'CHL', 'COL', 'CZE', 'DNK', 'DOM', 'ECU', 'EGY', 'EST', 'FIN', 'FRA', 'PYF', 'DEU', 'GRC', 'HUN', 'ISL', 'IND', 'IDN', 'IRL', 'IMN', 'ISR', 'ITA', WITHDRAWAL_ONLY_COUNTRIES.KENYA, 'KWT', 'LTU', 'MYS', 'MEX', 'MCO', WITHDRAWAL_ONLY_COUNTRIES.NAMIBIA, 'NLD', 'NZL', 'NOR', 'PER', 'POL', 'PRT', 'ROU', 'SMR', 'SAU', 'SGP', 'SVK', WITHDRAWAL_ONLY_COUNTRIES.SOUTH_AFRICA, 'ESP', 'SWE', 'CHE', 'TWN', 'THA', 'ARE', 'GBR', 'URY'];

  var WITHDRAWAL_COUNTRIES_OPTIONS = exports.WITHDRAWAL_COUNTRIES_OPTIONS = ORDERED_COUNTRIES_OPTIONS.filter(function (country) {
    return country !== _country.USA_COUNTRY_CODE && country !== _country.CANADA_COUNTRY_CODE;
  });

  var ENTITY_OWNER_COUNTRIES_OPTIONS = exports.ENTITY_OWNER_COUNTRIES_OPTIONS = ORDERED_COUNTRIES_OPTIONS.filter(function (country) {
    return !Object.values(WITHDRAWAL_ONLY_COUNTRIES).includes(country);
  });

  var FOREIGN_ENTITY_OWNER_COUNTRIES_OPTIONS = exports.FOREIGN_ENTITY_OWNER_COUNTRIES_OPTIONS = ENTITY_OWNER_COUNTRIES_OPTIONS.filter(function (country) {
    return country !== _country.USA_COUNTRY_CODE;
  });

  var ACCEPTED_COUNTRIES_OPTIONS = exports.ACCEPTED_COUNTRIES_OPTIONS = ENTITY_OWNER_COUNTRIES_OPTIONS.filter(function (country) {
    return country !== _country.CANADA_COUNTRY_CODE;
  });

  var FOREIGN_COUNTRIES_OPTIONS = exports.FOREIGN_COUNTRIES_OPTIONS = ACCEPTED_COUNTRIES_OPTIONS.filter(function (country) {
    return country !== _country.USA_COUNTRY_CODE;
  });

  var STATE_OPTIONS = exports.STATE_OPTIONS = ['AA', 'AE', 'AK', 'AL', 'AP', 'AR', 'AZ', 'CA', 'CO', 'CT', 'DC', 'DE', 'FL', 'GA', 'GU', 'HI', 'IA', 'ID', 'IL', 'IN', 'KS', 'KY', 'LA', 'MA', 'MD', 'ME', 'MI', 'MN', 'MO', 'MS', 'MT', 'NC', 'ND', 'NE', 'NH', 'NJ', 'NM', 'NV', 'NY', 'OH', 'OK', 'OR', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VA', 'VI', 'VT', 'WA', 'WI', 'WV', 'WY'];

  exports.default = Model.extend((_dec = (0, _object.computed)('streetOne', 'streetTwo'), _dec2 = (0, _object.computed)('city', 'stateRegion', 'postalCode', 'country'), _dec3 = (0, _object.computed)('lineOne', 'lineTwo'), _dec4 = (0, _object.computed)('lineOne', 'lineTwo'), (_obj = {
    streetOne: attr('string'),
    streetTwo: attr('string'),
    city: attr('string'),
    stateRegion: attr('string'),
    postalCode: attr('string'),
    country: attr('string', { defaultValue: _country.USA_COUNTRY_CODE }),

    isDomestic: equal('country', _country.USA_COUNTRY_CODE),

    isForeign: not('isDomestic'),

    isEntityOwnerAddress: false,

    isTrustedContactAddress: false,

    lineOne: function lineOne(streetOne, streetTwo) {
      return ((streetOne || '') + ' ' + (streetTwo || '')).trim();
    },
    lineTwo: function lineTwo(city, stateRegion, postalCode, country) {
      var lineTwo = city;

      if (isPresent(stateRegion)) {
        lineTwo += ', ' + stateRegion;
      }

      if (isPresent(postalCode)) {
        lineTwo += ' ' + postalCode;
      }

      lineTwo += ', ' + country;

      return lineTwo;
    },
    fullSingleLine: function fullSingleLine(lineOne, lineTwo) {
      return (lineOne || '') + ', ' + (lineTwo || '');
    },
    fullDoubleLine: function fullDoubleLine(lineOne, lineTwo) {
      return (lineOne || '') + '\n' + (lineTwo || '');
    },
    isEqual: function isEqual(other) {
      return _isEqual(get(this, 'streetOne'), get(other, 'streetOne')) && _isEqual(get(this, 'streetTwo'), get(other, 'streetTwo')) && _isEqual(get(this, 'city'), get(other, 'city')) && _isEqual(get(this, 'stateRegion'), get(other, 'stateRegion')) && _isEqual(get(this, 'postalCode'), get(other, 'postalCode')) && _isEqual(get(this, 'country'), get(other, 'country'));
    }
  }, (_applyDecoratedDescriptor(_obj, 'lineOne', [_dec], Object.getOwnPropertyDescriptor(_obj, 'lineOne'), _obj), _applyDecoratedDescriptor(_obj, 'lineTwo', [_dec2], Object.getOwnPropertyDescriptor(_obj, 'lineTwo'), _obj), _applyDecoratedDescriptor(_obj, 'fullSingleLine', [_dec3], Object.getOwnPropertyDescriptor(_obj, 'fullSingleLine'), _obj), _applyDecoratedDescriptor(_obj, 'fullDoubleLine', [_dec4], Object.getOwnPropertyDescriptor(_obj, 'fullDoubleLine'), _obj)), _obj))).reopen(_validation.build({
    streetOne: {
      presence: true,
      length: { max: 30 }
    },
    streetTwo: {
      length: { max: 30, allowBlank: true }
    },
    city: { presence: true },
    stateRegion: {
      inclusion: {
        disabled: bool('model.isForeign'),
        in: STATE_OPTIONS
      }
    },
    postalCode: {
      presence: {
        presence: true,
        disabled: bool('model.isForeign')
      },
      format: {
        disabled: bool('model.isForeign'),
        regex: _regex.exact.ZIP_CODE,
        allowBlank: false,
        message: 'must be a valid zip code'
      }
    },
    country: {
      presence: true,
      inclusion: [{ in: ACCEPTED_COUNTRIES_OPTIONS, disabled: or('model.isEntityOwnerAddress', 'model.isTrustedContactAddress') }, { in: ENTITY_OWNER_COUNTRIES_OPTIONS, disabled: not('model.isEntityOwnerAddress') }, { in: _country.AVAILABLE_ADDRESS_COUNTRIES_OPTIONS, disabled: not('model.isTrustedContactAddress') }]
    }
  })).reopenClass({
    resourceName: 'address'
  });
});