define('twapi/components/twapi-qr-code', ['exports', 'qrcode'], function (exports, _qrcode) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      getProperties = Ember.getProperties,
      isPresent = Ember.isPresent;
  exports.default = Component.extend({
    tagName: 'canvas',

    content: null,

    onError: function onError() {},

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this._renderQrCode();
    },
    didRender: function didRender() {
      this._super.apply(this, arguments);

      this._renderQrCode();
    },
    _renderQrCode: function _renderQrCode() {
      var _getProperties = getProperties(this, 'element', 'content'),
          element = _getProperties.element,
          content = _getProperties.content;

      if (isPresent(element) && isPresent(content)) {
        _qrcode.default.toCanvas(element, content, this.onError);
      }
    }
  });
});