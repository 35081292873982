define('ember-ambitious-forms/mixins/lookup', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function computedLookupKey(subname, defawlt) {
    return Ember.computed('lookupKey', function () {
      if (defawlt && defawlt.call(this)) {
        return defawlt.call(this);
      }

      var lookupKey = this.get('lookupKey');
      if (lookupKey) {
        return lookupKey + '.' + subname;
      }
    });
  }

  function computedLookupOptional(keyName) {
    return Ember.computed('_lookupCache', 'lookupArguments', keyName, function () {
      var key = this.get(keyName);
      if (key && this._lookupExists(key)) {
        return this._lookup(key, this.get('lookupArguments'));
      }
    });
  }

  exports.default = Ember.Mixin.create({
    lookupKeyConvert: Ember.String.dasherize,

    lookupArgs: Ember.A(),
    lookupArguments: Ember.computed.alias('lookupArgs'),

    lookupKey: Ember.computed('scopeName', 'fieldKey', function () {
      var scopeName = this.get('scopeName');
      var fieldKey = this.get('fieldKey');
      if (scopeName && fieldKey) {
        var convertedScopeName = this.lookupKeyConvert(this.get('scopeName'));
        var convertedFieldKey = this.lookupKeyConvert(this.get('fieldKey'));
        return 'amb-form.' + convertedScopeName + '.' + convertedFieldKey;
      }
    }),

    lookupHintKey: computedLookupKey('hint'),
    lookupDescriptionKey: computedLookupKey('description'),
    lookupPlaceholderKey: computedLookupKey('placeholder'),
    lookupOptionsKey: computedLookupKey('options', function () {
      return this._fieldTypeConfig('lookupOptionsKey');
    }),

    label: Ember.computed('_lookupCache', 'lookupArguments', 'lookupKey', function () {
      var key = this.get('lookupKey');
      if (key) {
        return this._lookup(key, this.get('lookupArguments'));
      }
    }),

    hint: computedLookupOptional('lookupHintKey'),
    description: computedLookupOptional('lookupDescriptionKey'),
    placeholder: computedLookupOptional('lookupPlaceholderKey'),

    options: Ember.computed('_lookupCache', 'optionValues.[]', 'lookupOptionsKey', function () {
      var _this = this;

      var optionValues = this.get('optionValues');
      if (!optionValues) {
        return;
      }

      var lookupOptionsKey = this.get('lookupOptionsKey');

      if (!lookupOptionsKey) {
        return optionValues;
      }

      return optionValues.map(function (value) {
        var valueKey = _this.lookupKeyConvert(value.toString());

        var option = { value: value };

        if (_this._lookupExists(lookupOptionsKey + '.' + valueKey)) {
          option.text = _this._lookup(lookupOptionsKey + '.' + valueKey);
        }

        if (_this._lookupExists(lookupOptionsKey + '.' + valueKey + '.description')) {
          option.description = _this._lookup(lookupOptionsKey + '.' + valueKey + '.description');
        }

        return option;
      });
    })
  });
});