define('twapi/models/ach-relationship', ['exports', 'ember-restless', 'ember-decorators/object'], function (exports, _emberRestless, _object) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.RELATIONSHIP_METHODS = exports.RELATIONSHIP_STATUSES = undefined;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _desc, _value, _obj;

  var attr = _emberRestless.default.attr,
      belongsTo = _emberRestless.default.belongsTo,
      Model = _emberRestless.default.Model;
  var equal = Ember.computed.equal,
      isNone = Ember.isNone;


  var MAX_NICKNAME_LENGTH = 17;

  var RELATIONSHIP_STATUSES = exports.RELATIONSHIP_STATUSES = {
    PRE_SEND: 'PRE_SEND',
    REJECTED: 'REJECTED',
    PENDING: 'PENDING',
    APPROVED: 'APPROVED',
    CANCELED: 'CANCELED',
    IDENTITY_FAIL: 'IDENTITY_FAIL',
    PREVIOUS_ACH_RETURNED_R10: 'PREVIOUS_ACH_RETURNED_R10'
  };

  var RELATIONSHIP_METHODS = exports.RELATIONSHIP_METHODS = {
    MICRO_DEPOSIT: 'MICRO_DEPOSIT',
    PLAID: 'PLAID'
  };

  exports.default = Model.extend((_dec = (0, _object.computed)('nickname', 'bankAccountNumberMask', 'isApproved'), (_obj = {
    id: attr('number'),
    account: belongsTo('account'), // TODO: How does this get hooked up?
    accountNumber: attr('string'),
    bankAccountNumberMask: attr('string'),
    externalAccountNumber: attr('string'),
    externalAccountType: attr('string'),
    nickname: attr('string'),
    achRelationshipStatus: attr('string'),
    achRelationshipMethod: attr('string'),
    extCreatedAt: attr('momentDateTime'),
    approvalAttemptsCount: attr('number'),
    maxApprovalAttempts: attr('boolean'),
    depositsIssuedAt: attr('momentDateTime', { readOnly: true }),
    cancellationComment: attr('string'),

    isPresend: equal('achRelationshipStatus', RELATIONSHIP_STATUSES.PRE_SEND),
    isRejected: equal('achRelationshipStatus', RELATIONSHIP_STATUSES.REJECTED),
    isPending: equal('achRelationshipStatus', RELATIONSHIP_STATUSES.PENDING),
    isApproved: equal('achRelationshipStatus', RELATIONSHIP_STATUSES.APPROVED),
    isCanceled: equal('achRelationshipStatus', RELATIONSHIP_STATUSES.CANCELED),
    isIdentityFail: equal('achRelationshipStatus', RELATIONSHIP_STATUSES.IDENTITY_FAIL),
    isMicroDeposit: equal('achRelationshipMethod', RELATIONSHIP_METHODS.MICRO_DEPOSIT),
    isPlaid: equal('achRelationshipMethod', RELATIONSHIP_METHODS.PLAID),
    isPreviousAchReturned: equal('achRelationshipStatus', RELATIONSHIP_STATUSES.PREVIOUS_ACH_RETURNED_R10),

    displayName: function displayName(nickname, bankAccountNumberMask) {
      if (isNone(nickname)) {
        return '...'.concat(bankAccountNumberMask);
      }

      var prefix = nickname.slice(0, MAX_NICKNAME_LENGTH);
      return prefix.concat('...', bankAccountNumberMask);
    }
  }, (_applyDecoratedDescriptor(_obj, 'displayName', [_dec], Object.getOwnPropertyDescriptor(_obj, 'displayName'), _obj)), _obj))).reopenClass({
    resourceName: 'ach-relationships'
  });
});