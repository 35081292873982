define("ember-restless/index", ["exports"], function (exports) {
  "use strict";

  exports.__esModule = true;
  var Client = exports.Client = RESTless.Client;
  var Adapter = exports.Adapter = RESTless.Adapter;
  var RESTAdapter = exports.RESTAdapter = RESTless.RESTAdapter;
  var attr = exports.attr = RESTless.attr;
  var belongsTo = exports.belongsTo = RESTless.belongsTo;
  var hasMany = exports.hasMany = RESTless.hasMany;
  var Model = exports.Model = RESTless.Model;
  var ReadOnlyModel = exports.ReadOnlyModel = RESTless.ReadOnlyModel;
  var RecordArray = exports.RecordArray = RESTless.RecordArray;
  var Serializer = exports.Serializer = RESTless.Serializer;
  var JSONSerializer = exports.JSONSerializer = RESTless.JSONSerializer;
  var Transform = exports.Transform = RESTless.Transform;
  var BooleanTransform = exports.BooleanTransform = RESTless.BooleanTransform;
  var NumberTransform = exports.NumberTransform = RESTless.NumberTransform;
  var StringTransform = exports.StringTransform = RESTless.StringTransform;
  var DateTransform = exports.DateTransform = RESTless.DateTransform;
  var JSONTransforms = exports.JSONTransforms = RESTless.JSONTransforms;
});