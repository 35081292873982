define('twapi/models/cash-transaction', ['exports', 'ember-restless'], function (exports, _emberRestless) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberRestless.default.Model.extend({
    customerId: _emberRestless.default.attr('number'),
    sourceId: _emberRestless.default.attr('number'),
    sourceType: _emberRestless.default.attr('number'),
    sourceNumber: _emberRestless.default.attr('string'),
    destinationId: _emberRestless.default.attr('string'),
    destinationType: _emberRestless.default.attr('number'),
    destinationNumber: _emberRestless.default.attr('string'),
    amount: _emberRestless.default.attr('number'),
    status: _emberRestless.default.attr('string'),
    createdAt: _emberRestless.default.attr('momentDateTime')
  });
});