define('twapi/models/applicant-data', ['exports', 'lodash', 'ember-restless', 'twapi/mixins/name', 'twapi/utils/validation'], function (exports, _lodash, _emberRestless, _name, _validation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.CUSTOMER_APPLICANT_PROPERTIES = undefined;
  exports.fromCustomer = fromCustomer;
  var Model = _emberRestless.default.Model,
      attr = _emberRestless.default.attr;
  var get = Ember.get,
      getProperties = Ember.getProperties,
      set = Ember.set,
      isPresent = Ember.isPresent;
  var CUSTOMER_APPLICANT_PROPERTIES = exports.CUSTOMER_APPLICANT_PROPERTIES = ['firstName', 'lastName', 'email'];

  function fromCustomer(applicant, customer) {
    var applicantData = get(applicant, 'applicantData');
    var customerProperties = getProperties(customer, CUSTOMER_APPLICANT_PROPERTIES);

    CUSTOMER_APPLICANT_PROPERTIES.forEach(function (property) {
      var value = get(customerProperties, property);

      if (isPresent(value)) {
        set(applicantData, property, value);
      }
    });

    set(applicant, 'customer', customer);

    return applicant;
  }

  exports.default = Model.extend(_name.default, {
    acceptedAgreements: attr('boolean'),

    email: attr('string'),

    estatePercent: attr('percent')

  }).reopen(_validation.build(_lodash.default.merge({
    email: {
      presence: true,
      format: { type: 'email' }
    },
    emailConfirmation: {
      confirmation: {
        on: 'email'
      }
    },
    estatePercent: {
      presence: true,
      number: {
        allowString: true,
        integer: true,
        gt: 0,
        lt: 100
      }
    }
  }, _name.NAME_VALIDATIONS))).reopenClass({
    resourceName: 'applicant-data'
  });
});