define('twapi/models/referred-prospect', ['exports', 'ember-restless', 'twapi/utils/country', 'twapi/models/address', 'twapi/models/customer', 'twapi/utils/validation', 'twapi/models/customer-suitability'], function (exports, _emberRestless, _country, _address, _customer, _validation, _customerSuitability) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.fromPreregistrationProspect = fromPreregistrationProspect;
  var Model = _emberRestless.default.Model,
      attr = _emberRestless.default.attr;
  var _Ember$computed = Ember.computed,
      alias = _Ember$computed.alias,
      bool = _Ember$computed.bool,
      equal = _Ember$computed.equal,
      not = _Ember$computed.not,
      getProperties = Ember.getProperties;
  function fromPreregistrationProspect(preregistrationProspect) {
    var _getProperties = getProperties(preregistrationProspect, 'email', 'firstName', 'lastName', 'mailingCity', 'mailingCountry', 'mailingPostalCode', 'mailingStateRegion', 'mailingStreetOne', 'mobilePhone'),
        email = _getProperties.email,
        firstName = _getProperties.firstName,
        lastName = _getProperties.lastName,
        mailingCity = _getProperties.mailingCity,
        mailingCountry = _getProperties.mailingCountry,
        mailingPostalCode = _getProperties.mailingPostalCode,
        mailingStateRegion = _getProperties.mailingStateRegion,
        mailingStreetOne = _getProperties.mailingStreetOne,
        mobilePhone = _getProperties.mobilePhone;

    return ReferredProspect.create({
      email: email,
      firstName: firstName,
      lastName: lastName,
      mailingCity: mailingCity,
      mailingCountry: mailingCountry,
      mailingPostalCode: mailingPostalCode,
      mailingStateRegion: mailingStateRegion,
      mailingStreetOne: mailingStreetOne,
      mobilePhone: mobilePhone
    });
  }

  var ReferredProspect = Model.extend({
    email: attr('string'),

    employer: attr('string'),

    firstName: attr('string'),

    isMarketDataProfessional: attr('boolean'),

    lastName: attr('string'),

    mailingCity: attr('string'),

    mailingCountry: attr('string'),

    mailingPostalCode: attr('string'),

    mailingStateRegion: attr('string'),

    mailingStreetOne: attr('string'),

    mailingStreetTwo: attr('string'),

    mobilePhone: attr('string'),

    occupation: attr('string'),

    referralCode: attr('string'),

    isDomestic: equal('mailingCountry', _country.USA_COUNTRY_CODE),

    isForeign: not('isDomestic'),

    stateRegion: alias('mailingStateRegion'),

    postalCode: alias('mailingPostalCode')
  }).reopen(_validation.build({
    mailingCountry: {
      presence: true,
      inclusion: [{ in: _address.ACCEPTED_COUNTRIES_OPTIONS }]
    },
    mobilePhone: {
      'domestic-phone-number': {
        disabled: not('model.isDomestic'),
        message: _customer.DOMESTIC_PHONE_NUMBER_MESSAGE
      },
      'international-phone-number': {
        disabled: not('model.isForeign'),
        message: _customer.INTERNATIONAL_PHONE_NUMBER_MESSAGE
      }
    },
    occupation: {
      inclusion: {
        in: _customerSuitability.OPTIONS.OCCUPATION
      }
    },
    stateRegion: {
      inclusion: {
        disabled: bool('model.isForeign'),
        in: _address.STATE_OPTIONS
      }
    }
  })).reopenClass({
    resourceName: 'referred-prospect'
  });

  exports.default = ReferredProspect;
});