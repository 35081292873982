define('twapi/mixins/instrument-type', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Mixin = Ember.Mixin,
      equal = Ember.computed.equal;


  var INSTRUMENT_TYPES = {
    BOND: 'Bond',
    CRYPTOCURRENCY: 'Cryptocurrency',
    EQUITY: 'Equity',
    EQUITY_OPTION: 'Equity Option',
    FUTURE: 'Future',
    FUTURE_OPTION: 'Future Option',
    WARRANT: 'Warrant'
  };

  exports.default = Mixin.create({
    isBond: equal('instrumentType', INSTRUMENT_TYPES.BOND),
    isCryptocurrency: equal('instrumentType', INSTRUMENT_TYPES.CRYPTOCURRENCY),
    isEquity: equal('instrumentType', INSTRUMENT_TYPES.EQUITY),
    isEquityOption: equal('instrumentType', INSTRUMENT_TYPES.EQUITY_OPTION),
    isFuture: equal('instrumentType', INSTRUMENT_TYPES.FUTURE),
    isFutureOption: equal('instrumentType', INSTRUMENT_TYPES.FUTURE_OPTION),
    isWarrant: equal('instrumentType', INSTRUMENT_TYPES.WARRANT)
  });
});