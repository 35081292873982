define('twapi/utils/params', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.withOneTimePassword = withOneTimePassword;

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  var assign = Ember.assign;
  var OTP_HEADER = exports.OTP_HEADER = 'X-Tastyworks-OTP';

  function withOneTimePassword(oneTimePassword) {
    var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    return assign(params, {
      httpOptions: {
        headers: _defineProperty({}, OTP_HEADER, oneTimePassword)
      }
    });
  }
});