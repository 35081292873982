define('twapi/components/twapi-plan-access-selection', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      _Ember$computed = Ember.computed,
      alias = _Ember$computed.alias,
      readOnly = _Ember$computed.readOnly,
      service = Ember.inject.service;
  exports.default = Component.extend({

    twapiPlanAccess: service(),

    classNames: 'twapi-plan-access-selection',

    accountType: alias('twapiPlanAccess.accountType'),

    currentOptionsLevel: null,

    readOnly: true,

    accessPlanOptions: readOnly('twapiPlanAccess.accessPlanOptions'),

    permissionGroups: readOnly('twapiPlanAccess.permissionGroups')

  });
});