define('twapi/mixins/name', ['exports', 'lodash', 'ember-restless', 'ember-decorators/object', 'twapi/utils/regex'], function (exports, _lodash, _emberRestless, _object, _regex) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.NAME_VALIDATIONS = undefined;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _desc, _value, _obj;

  var SPECIAL_CHARACTER_MESSAGE = 'Please use the English substitute for any special character (ex: ä, é) your name may contain';

  var FORMAT_VALIDATIONS = {
    format: {
      regex: _regex.exact.FULL_NAME,
      message: SPECIAL_CHARACTER_MESSAGE
    }
  };

  exports.default = Ember.Mixin.create((_dec = (0, _object.computed)('prefixName', 'firstName', 'middleName', 'lastName', 'suffixName'), (_obj = {
    prefixName: _emberRestless.default.attr('string'),
    firstName: _emberRestless.default.attr('string'),
    middleName: _emberRestless.default.attr('string'),
    lastName: _emberRestless.default.attr('string'),
    suffixName: _emberRestless.default.attr('string'),

    fullName: function fullName() {
      for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      return args.filter(function (part) {
        return part;
      }).join(' ');
    }
  }, (_applyDecoratedDescriptor(_obj, 'fullName', [_dec], Object.getOwnPropertyDescriptor(_obj, 'fullName'), _obj)), _obj)));
  var NAME_VALIDATIONS = exports.NAME_VALIDATIONS = {
    prefixName: { inclusion: { allowBlank: true, in: ['Mr.', 'Mrs.', 'Miss', 'Ms.', 'Dr.'] } },
    firstName: _lodash.default.merge({ presence: true }, FORMAT_VALIDATIONS),
    middleName: _lodash.default.merge({ format: { allowBlank: true } }, FORMAT_VALIDATIONS),
    lastName: _lodash.default.merge({ presence: true }, FORMAT_VALIDATIONS),
    suffixName: {
      inclusion: {
        allowBlank: true,
        in: ['JR', 'SR', 'I', 'II', 'III', 'IV', 'V']
      }
    }
  };
});