define('twapi/models/tax-document', ['exports', 'twapi/models/account-document', 'ember-decorators/object', 'moment', 'ember-restless', 'twapi/utils/slug'], function (exports, _accountDocument, _object, _moment, _emberRestless, _slug) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _dec2, _dec3, _dec4, _dec5, _desc, _value, _obj;

  var attr = _emberRestless.default.attr;


  var FILENAME = new RegExp('' + '([0-9]{4})' + '-.{8}' + '-(.*)' + '_tax.pdf');

  var equal = Ember.computed.equal,
      isPresent = Ember.isPresent;


  function dateFor(dateString) {
    return (0, _moment.default)(dateString, 'YYYY-MM-DD');
  }

  exports.default = _accountDocument.default.extend((_dec = (0, _object.computed)('effectiveSlug'), _dec2 = (0, _object.computed)('documentTypeName', 'slug'), _dec3 = (0, _object.computed)('slug', 'documentType'), _dec4 = (0, _object.computed)('effectiveSlug', 'taxYear', 'createdAt'), _dec5 = (0, _object.computed)('documentTypeName', 'documentDate'), (_obj = {

    createdAt: attr('momentDateTime'),

    documentDate: attr('string'),

    historyVersion: attr('string'),

    displayName: function displayName(slug) {
      switch (slug) {
        case _slug.SLUGS.IRS_1042_S_TAX:
          return 'Form 1042-S';
        case _slug.SLUGS.IRS_1099_TAX:
          return 'Consolidated Form 1099';
        case _slug.SLUGS.IRS_1099B_CRYPTOCURRENCIES_TAX:
          return 'Form 1099-B Cryptocurrencies';
        case _slug.SLUGS.IRS_1099B_TAX:
          return 'Form 1099-B Futures';
        case _slug.SLUGS.IRS_1099R_TAX:
          return 'Form 1099-R (Distributions)';
        case _slug.SLUGS.IRS_5498_TAX:
          return 'Form 5498 (Contributions)';
      }
    },
    documentType: function documentType(documentTypeName, slug) {
      if (isPresent(slug)) {
        return slug.replace(/_tax$/, '');
      } else if (FILENAME.test(documentTypeName)) {
        var _FILENAME$exec = FILENAME.exec(documentTypeName),
            _FILENAME$exec2 = _slicedToArray(_FILENAME$exec, 3),
            documentType = _FILENAME$exec2[2];

        return documentType;
      } else {
        return null;
      }
    },
    effectiveSlug: function effectiveSlug(slug, documentType) {
      // TODO: [KT] remove this when documents are migrated off of alfresco
      return slug || documentType + '_tax';
    },


    isOriginal: equal('historyVersion', '1.0.0'),

    releaseDate: function releaseDate(slug, taxYear, createdAt) {
      if (taxYear.year() === 2017) {
        switch (slug) {
          case _slug.SLUGS.IRS_1042_S_TAX:
            return dateFor('2018-03-15');
          case _slug.SLUGS.IRS_1099_TAX:
            return dateFor('2018-02-21');
          case _slug.SLUGS.IRS_1099B_TAX:
            return dateFor('2018-01-29');
          case _slug.SLUGS.IRS_1099R_TAX:
            return dateFor('2018-01-31');
          case _slug.SLUGS.IRS_5498_TAX:
            return dateFor('2018-05-31');
        }
      } else {
        return createdAt;
      }
    },
    taxYear: function taxYear(documentTypeName, documentDate) {
      if (isPresent(documentDate)) {
        return (0, _moment.default)(documentDate, 'YYYY');
      } else if (FILENAME.test(documentTypeName)) {
        var _FILENAME$exec3 = FILENAME.exec(documentTypeName),
            _FILENAME$exec4 = _slicedToArray(_FILENAME$exec3, 2),
            taxYear = _FILENAME$exec4[1];

        return (0, _moment.default)(taxYear, 'YYYY');
      } else {
        return (0, _moment.default)();
      }
    }
  }, (_applyDecoratedDescriptor(_obj, 'displayName', [_dec], Object.getOwnPropertyDescriptor(_obj, 'displayName'), _obj), _applyDecoratedDescriptor(_obj, 'documentType', [_dec2], Object.getOwnPropertyDescriptor(_obj, 'documentType'), _obj), _applyDecoratedDescriptor(_obj, 'effectiveSlug', [_dec3], Object.getOwnPropertyDescriptor(_obj, 'effectiveSlug'), _obj), _applyDecoratedDescriptor(_obj, 'releaseDate', [_dec4], Object.getOwnPropertyDescriptor(_obj, 'releaseDate'), _obj), _applyDecoratedDescriptor(_obj, 'taxYear', [_dec5], Object.getOwnPropertyDescriptor(_obj, 'taxYear'), _obj)), _obj))).reopenClass({
    resourceName: 'tax-document'
  });
});