define('twapi/utils/cost', ['exports', 'lodash', 'bignumber.js'], function (exports, _lodash, _bignumber) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.EFFECTS = undefined;
  var EFFECTS = exports.EFFECTS = { CREDIT: 'Credit', DEBIT: 'Debit', NONE: 'None' };

  var Cost = Ember.Object.extend({
    init: function init() {
      this._super.apply(this, arguments);

      if (Ember.isEmpty(this.get('value'))) {
        throw new Error('value must be initialized');
      }
      if (Ember.isEmpty(this.get('effect'))) {
        throw new Error('effect must be initialized');
      }
      if (this.get('value').isZero()) {
        this.set('effect', EFFECTS.NONE);
      }
    },


    isCredit: Ember.computed.equal('effect', EFFECTS.CREDIT),
    isDebit: Ember.computed.equal('effect', EFFECTS.DEBIT),
    isZero: Ember.computed.equal('effect', EFFECTS.NONE),

    toString: function toString() {
      var value = this.get('value');
      var effect = this.get('effect');
      return '<Cost ' + value.toString() + ' ' + effect + '>';
    },


    effect: Ember.computed({
      get: function get() {
        return this._effect;
      },
      set: function set(key, value) {
        if (!Object.values(EFFECTS).includes(value)) {
          throw new Error('effect must be ' + JSON.stringify(EFFECTS));
        }
        this._effect = value;
        return this._effect;
      }
    }),

    value: Ember.computed({
      get: function get() {
        return this._value;
      },
      set: function set(key, value) {
        if (value.isLessThan(0)) {
          throw new Error('value must be positive');
        }
        this._value = (0, _bignumber.default)(value);
        return this._value;
      }
    }),

    isEqual: function isEqual(other) {
      if (!Cost.detectInstance(other)) {
        return false;
      }
      if (!(this.get('effect') === other.get('effect'))) {
        return false;
      }
      return this.get('value').isEqualTo(other.get('value'));
    },
    adjust: function adjust(other) {
      if (Ember.isNone(other) || other.get('isZero')) {
        return this;
      } else if (this.get('isZero')) {
        return other;
      }

      var props = this.getProperties('effect', 'value');
      var oProps = other.getProperties('effect', 'value');

      if (props.effect === oProps.effect) {
        return Cost.create({
          effect: props.effect,
          value: props.value.plus(oProps.value)
        });
      } else if (props.value.isEqualTo(oProps.value)) {
        return Cost.zero();
      } else if (props.value.isGreaterThan(oProps.value)) {
        return Cost.create({
          effect: props.effect,
          value: props.value.minus(oProps.value)
        });
      } else {
        return Cost.create({
          effect: oProps.effect,
          value: oProps.value.minus(props.value)
        });
      }
    },
    multiply: function multiply(quantity) {
      if (isNaN(quantity)) {
        throw new TypeError('quantity must be a number: ' + quantity);
      }

      return Cost.create({
        effect: this.get('effect'),
        value: this.get('value').multipliedBy(quantity)
      });
    },
    divide: function divide(quantity) {
      if (isNaN(quantity)) {
        throw new TypeError('quantity must be a number: ' + quantity);
      }

      return Cost.create({
        effect: this.get('effect'),
        value: this.get('value').dividedBy(quantity)
      });
    },
    opposite: function opposite() {
      if (this.get('isZero')) {
        return Cost.zero();
      }
      var value = this.get('value');

      return Cost.create({
        effect: Cost.oppositeEffect(this.get('effect')),
        value: value
      });
    }
  }).reopenClass({
    credit: function credit(value) {
      return Cost.create({ effect: EFFECTS.CREDIT, value: (0, _bignumber.default)(value) });
    },
    debit: function debit(value) {
      return Cost.create({ effect: EFFECTS.DEBIT, value: (0, _bignumber.default)(value) });
    },


    zero: _lodash.default.once(function () {
      return Cost.create({ effect: EFFECTS.NONE, value: (0, _bignumber.default)(0) });
    }),

    computed: function computed(valueProperty, effectProperty) {
      return Ember.computed(valueProperty, effectProperty, function () {
        var value = this.get(valueProperty);
        var effect = this.get(effectProperty);
        if (value != null && effect != null) {
          value = (0, _bignumber.default)(value);
          return Cost.create({ value: value, effect: effect });
        }
      });
    },
    oppositeEffect: function oppositeEffect(effect) {
      if (effect === EFFECTS.CREDIT) {
        return EFFECTS.DEBIT;
      } else if (effect === EFFECTS.DEBIT) {
        return EFFECTS.CREDIT;
      } else if (effect === EFFECTS.NONE) {
        return EFFECTS.NONE;
      } else {
        throw new Error('Unknown effect');
      }
    }
  });

  exports.default = Cost;
});