define('ember-ambitious-forms/components/amb-form-textarea', ['exports', 'ember-ambitious-forms/components/amb-form-input'], function (exports, _ambFormInput) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_ambFormInput.InputChangeMixin, {
    classNames: 'amb-form-textarea',
    tagName: 'textarea',

    attributeBindings: ['value', 'minlength', 'maxlength', 'placeholder', 'disabled', 'readonly', 'required', 'cols', 'rows']
  });
});