define('twapi/components/twapi-model-state', ['exports', 'ember-decorators/object', 'ember-decorators/object/evented', 'twapi/templates/components/twapi-model-state'], function (exports, _object, _evented, _twapiModelState) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _dec2, _dec3, _desc, _value, _obj;

  exports.default = Ember.Component.extend((_dec = (0, _object.computed)('model.isError', 'model.isLoaded', 'model.isNew'), _dec2 = (0, _object.computed)('state'), _dec3 = (0, _evented.on)('init'), (_obj = {
    layout: _twapiModelState.default,
    classNames: ['twapi-model-state'],
    classNameBindings: ['stateClass'],

    model: null,

    state: function state(isError, isLoaded, isNew) {
      if (isError) {
        return 'error';
      } else if (isLoaded || isNew) {
        return 'ready';
      } else {
        return 'pending';
      }
    },
    stateClass: function stateClass(state) {
      return 'twapi-model-state-is-' + state;
    },


    isError: Ember.computed.equal('state', 'error'),
    isReady: Ember.computed.equal('state', 'ready'),
    isPending: Ember.computed.equal('state', 'pending'),

    overrideComponents: Ember.computed(function () {
      return Ember.Object.create();
    }),
    // Delay setting these to prevent overlay 'flashing'
    showDefaultPending: null,
    showDefaultError: null,

    _onInit: function _onInit() {
      var _this = this;

      Ember.run.schedule('afterRender', function () {
        if (_this.get('showDefaultPending') == null) {
          _this.set('showDefaultPending', true);
        }

        if (_this.get('showDefaultError') == null) {
          _this.set('showDefaultError', true);
        }
      });
    },


    actions: {
      overridePending: function overridePending() {
        var _this2 = this;

        Ember.run.schedule('afterRender', function () {
          _this2.set('showDefaultPending', false);
        });
      },
      overrideError: function overrideError() {
        var _this3 = this;

        Ember.run.schedule('afterRender', function () {
          _this3.set('showDefaultError', false);
        });
      }
    }
  }, (_applyDecoratedDescriptor(_obj, 'state', [_dec], Object.getOwnPropertyDescriptor(_obj, 'state'), _obj), _applyDecoratedDescriptor(_obj, 'stateClass', [_dec2], Object.getOwnPropertyDescriptor(_obj, 'stateClass'), _obj), _applyDecoratedDescriptor(_obj, '_onInit', [_dec3], Object.getOwnPropertyDescriptor(_obj, '_onInit'), _obj)), _obj))).reopenClass({
    positionalParams: ['model']
  });
});