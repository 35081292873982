define('twapi/components/twapi-signature-pad', ['exports', 'signature_pad', 'twapi/templates/components/twapi-signature-pad'], function (exports, _signature_pad, _twapiSignaturePad) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      get = Ember.get,
      service = Ember.inject.service,
      set = Ember.set;
  exports.default = Component.extend({
    layout: _twapiSignaturePad.default,

    twapiSignature: service(),

    classNames: ['twapi-signature-pad'],

    signaturePad: null,

    canvas: null,

    width: 450,

    height: 150,

    url: 'customers/me/documents/async',

    didRender: function didRender() {
      this._super.apply(this, arguments);

      var options = { onEnd: this._onEnd.bind(this) };
      var canvasElement = this.$('.canvas-pad').get(0);

      set(this, 'canvas', canvasElement);
      set(this, 'signaturePad', new _signature_pad.default(canvasElement, options));
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      get(this, 'signaturePad').off();
    },


    actions: {
      clear: function clear() {
        get(this, 'signaturePad').clear();
        get(this, 'clear')();
      }
    },

    _onEnd: function _onEnd() {
      var _this = this;

      if (!get(this, 'signaturePad').isEmpty()) {
        get(this, 'canvas').toBlob(function (signature) {
          get(_this, 'twapiSignature').onStrokeEnd(signature);
          get(_this, 'onStrokeEnd')(signature);
        });
      }
    }
  });
});