define('twapi/models/equity', ['exports', 'ember-restless', 'twapi/utils/computed', 'twapi/mixins/instrument-type'], function (exports, _emberRestless, _computed, _instrumentType) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberRestless.default.ReadOnlyModel.extend(_instrumentType.default, {
    symbol: _emberRestless.default.attr('string'),
    instrumentType: _computed.default.readOnlyValue('Equity'),
    cusip: _emberRestless.default.attr('string'),
    shortDescription: _emberRestless.default.attr('string'),
    isIndex: _emberRestless.default.attr('boolean'),
    marginEligible: _emberRestless.default.attr('boolean'),
    fullMarginRqd: _emberRestless.default.attr('boolean'),
    listedMarket: _emberRestless.default.attr('string'),
    description: _emberRestless.default.attr('string'),
    regTRate: _emberRestless.default.attr('number'),
    maintenanceRate: _emberRestless.default.attr('number'),
    fullMaintRqdPrice: _emberRestless.default.attr('number'),
    fedCallRqdPrice: _emberRestless.default.attr('number'),
    lendability: _emberRestless.default.attr('string'),
    borrowRate: _emberRestless.default.attr('number'),
    haltedAt: _emberRestless.default.attr('momentDateTime'),
    stopsTradingAt: _emberRestless.default.attr('momentDateTime'),
    marketTimeInstrumentCollection: _emberRestless.default.attr('string')
  });
});