define('ember-ambitious-forms/components/amb-form', ['exports', 'ember-ambitious-forms/components/amb-form-group'], function (exports, _ambFormGroup) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ambFormGroup.default.extend({
    tagName: 'form',
    classNames: 'amb-form',

    readOnly: false,
    alwaysShowErrors: false,

    scrollToErrorField: function scrollToErrorField() {
      var index = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;

      var field = this.get('fieldsWithErrors').objectAt(index);
      if (field) {
        this.scrollTo(field, { paddingTop: 20 });
      }
    },
    scrollTo: function scrollTo(component) {
      var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
          _ref$paddingTop = _ref.paddingTop,
          paddingTop = _ref$paddingTop === undefined ? 0 : _ref$paddingTop,
          _ref$timeout = _ref.timeout,
          timeout = _ref$timeout === undefined ? 200 : _ref$timeout;

      var offset = component.$().offset();
      Ember.$('html, body').animate({ scrollTop: offset.top - paddingTop }, timeout);
    },
    submit: function submit(e) {
      this._doTriggerErrorStateChanged();

      if (e) {
        e.preventDefault();
      }

      if (this.get('hasErrors')) {
        this.scrollToErrorField();
        this.set('alwaysShowErrors', true);
        this.sendAction('onError', this);
      } else {
        this.sendAction('onSubmit', this);
        this.sendAction('action', this);
      }
    }
  });
});