define("twapi/utils/search-params", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.getSearchParams = getSearchParams;
  function getSearchParams(window) {
    return new URL(window.location).searchParams;
  }
});