define('twapi/models/referral-product', ['exports', 'ember-restless'], function (exports, _emberRestless) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberRestless.default.Model.extend({
    id: _emberRestless.default.attr('string'),
    name: _emberRestless.default.attr('string'),
    requiredCredits: _emberRestless.default.attr('number'),
    cashValue: _emberRestless.default.attr('number'),
    thumbnail: _emberRestless.default.attr('string'),

    isEqual: function isEqual(other) {
      if (Ember.isPresent(this.get('id'))) {
        return this.get('id') === other.get('id');
      }
      return this.get('name') === other.get('name') && this.get('requiredCredits') === other.get('requiredCredits') && this.get('cashValue') === other.get('cashValue') && this.get('thumbnail') === other.get('thumbnail');
    }
  }).reopenClass({
    resourceName: 'referral-product'
  });
});