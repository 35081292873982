define('twapi/mixins/suitability', ['exports', 'ember-restless', 'twapi/utils/validation'], function (exports, _emberRestless, _validation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Mixin = Ember.Mixin,
      readOnly = Ember.computed.readOnly;
  var attr = _emberRestless.default.attr;


  var EXPERIENCE = ['LIMITED', 'GOOD', 'EXTENSIVE'];

  var INCOME = [0, 25001, 50001, 100001, 200001, 300001, 500001, 1200001];

  var NET_WORTH = [0, 50001, 100001, 200001, 500001, 1000001, 5000001];

  exports.default = Mixin.create({

    annualNetIncome: attr('number'),

    netWorth: attr('number'),

    liquidNetWorth: attr('number'),

    stockTradingExperience: attr('string'),

    coveredOptionsTradingExperience: attr('string'),

    uncoveredOptionsTradingExperience: attr('string'),

    futuresTradingExperience: attr('string')

  }).reopen(_validation.build({
    annualNetIncome: {
      inclusion: { in: INCOME }
    },
    netWorth: {
      inclusion: { in: NET_WORTH }
    },
    liquidNetWorth: {
      inclusion: { in: NET_WORTH },
      number: {
        lte: readOnly('model.netWorth'),
        message: 'Liquid net worth must be less than or equal to net worth'
      }
    },
    stockTradingExperience: {
      inclusion: { in: EXPERIENCE }
    },
    coveredOptionsTradingExperience: {
      inclusion: { in: EXPERIENCE }
    },
    uncoveredOptionsTradingExperience: {
      inclusion: { in: EXPERIENCE }
    },
    futuresTradingExperience: {
      inclusion: { in: EXPERIENCE }
    }
  }));
});