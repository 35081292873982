define('twapi/utils/array', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.replaceObjectAt = replaceObjectAt;
  exports.replaceAllObjects = replaceAllObjects;
  exports.asNative = asNative;

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  function replaceObjectAt(array, index, item) {
    if (array.replace) {
      var length = Ember.get(array, 'length');
      var objects = [item];
      if (index > length) {
        objects.unshift.apply(objects, _toConsumableArray(Array(index - length)));
      }
      array.replace(index, 1, objects);
    } else {
      array[index] = item;
    }
    return item;
  }

  function replaceAllObjects(array, newObjects) {
    if (array.beginPropertyChanges) {
      array.replace(0, array.get('length'), newObjects);
    } else {
      while (array.length > 0) {
        array.pop();
      }
      array.push.apply(array, _toConsumableArray(newObjects));
    }
  }

  function asNative(array) {
    return Array.isArray(array) ? array : array.toArray();
  }
});