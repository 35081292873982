define('twapi/mixins/ira-detail-sanitize', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Mixin = Ember.Mixin,
      isNone = Ember.isNone,
      get = Ember.get,
      set = Ember.set;
  exports.default = Mixin.create({
    sanitize: function sanitize() {
      var iraDetail = get(this, 'iraDetail');
      if (isNone(iraDetail)) {
        return this;
      }

      set(this, 'iraDetail', iraDetail.sanitize());
      return this;
    }
  });
});