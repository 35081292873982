define('twapi/models/equifax-result', ['exports', 'ember-restless'], function (exports, _emberRestless) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var _Ember$computed = Ember.computed,
      bool = _Ember$computed.bool,
      equal = _Ember$computed.equal,
      not = _Ember$computed.not;
  var Model = _emberRestless.default.Model,
      attr = _emberRestless.default.attr,
      belongsTo = _emberRestless.default.belongsTo;
  exports.default = Model.extend({
    decision: attr('string'),
    idValidationServiceType: attr('string'),

    response: belongsTo('equifax-response'),

    hasDecision: bool('decision'),
    isAccepted: equal('decision', 'Y'),
    isRejected: not('isAccepted')
  }).reopenClass({
    resourceName: 'equifax-result'
  });
});