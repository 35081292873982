define('twapi/models/advanced-trading-request-eligibility', ['exports', 'ember-restless'], function (exports, _emberRestless) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.STATUSES = undefined;
  var _Ember$computed = Ember.computed,
      equal = _Ember$computed.equal,
      not = _Ember$computed.not;
  var Model = _emberRestless.default.Model,
      attr = _emberRestless.default.attr;
  var STATUSES = exports.STATUSES = {
    ELIGIBLE: 'Eligible',
    NEEDS_OPTIONS_LEVEL_UPGRADE: 'Needs Options Level Upgrade',
    NEEDS_SUITABILITY_UPGRADE: 'Needs Suitability Upgrade',
    UNAVAILABLE: 'Unavailable'
  };

  exports.default = Model.extend({
    reason: attr('string'),

    status: attr('string'),

    isAvailable: not('isUnavailable'),

    isEligible: equal('status', STATUSES.ELIGIBLE),

    isUnavailable: equal('status', STATUSES.UNAVAILABLE),

    needsSuitabilityUpgrade: equal('status', STATUSES.NEEDS_SUITABILITY_UPGRADE)
  }).reopenClass({
    resourceName: 'advanced-trading-request-eligibility'
  });
});