define('twapi/models/customer-authority', ['exports', 'ember-restless'], function (exports, _emberRestless) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberRestless.default.Model.extend({
    authorityLevel: _emberRestless.default.attr('string'),
    customer: _emberRestless.default.belongsTo('customer')
  }).reopenClass({
    resourceName: 'customer-authority'
  });
});