define('twapi/models/supporting-document-request', ['exports', 'ember-restless'], function (exports, _emberRestless) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberRestless.default.Model,
      attr = _emberRestless.default.attr,
      hasMany = _emberRestless.default.hasMany;
  exports.default = Model.extend({
    message: attr('string'),
    supportingDocuments: hasMany('supporting-document')
  }).reopenClass({
    resourceName: 'supporting-document-request'
  });
});