define('twapi/utils/computed/math', ['exports', 'lodash', 'twapi/utils/number'], function (exports, _lodash, _number) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.add = add;
  exports.subtract = subtract;
  exports.multiply = multiply;
  exports.divide = divide;
  exports.pow = pow;

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  function build(values, func) {
    var props = _lodash.default.reject(values, _number.default.isNumber);

    return Ember.computed.apply(Ember, _toConsumableArray(props).concat([function () {
      var _this = this;

      var numbers = values.map(function (value) {
        return _number.default.isNumber(value) ? value : _this.get(value);
      });
      return func(numbers);
    }]));
  }

  function add() {
    for (var _len = arguments.length, values = Array(_len), _key = 0; _key < _len; _key++) {
      values[_key] = arguments[_key];
    }

    return build(values, _lodash.default.sum);
  }

  function subtract() {
    for (var _len2 = arguments.length, values = Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
      values[_key2] = arguments[_key2];
    }

    return build(values, function (numbers) {
      return _lodash.default.reduce(numbers, function (a, b) {
        return a - b;
      });
    });
  }

  function multiply() {
    for (var _len3 = arguments.length, values = Array(_len3), _key3 = 0; _key3 < _len3; _key3++) {
      values[_key3] = arguments[_key3];
    }

    return build(values, function (numbers) {
      return _lodash.default.reduce(numbers, function (a, b) {
        return a * b;
      });
    });
  }

  function divide() {
    for (var _len4 = arguments.length, values = Array(_len4), _key4 = 0; _key4 < _len4; _key4++) {
      values[_key4] = arguments[_key4];
    }

    return build(values, function (numbers) {
      return _lodash.default.reduce(numbers, function (a, b) {
        return a / b;
      });
    });
  }

  function pow() {
    for (var _len5 = arguments.length, values = Array(_len5), _key5 = 0; _key5 < _len5; _key5++) {
      values[_key5] = arguments[_key5];
    }

    return build(values, function (numbers) {
      return _lodash.default.reduce(numbers, function (a, b) {
        return Math.pow(a, b);
      });
    });
  }
});