define('twapi/models/statement', ['exports', 'twapi/models/account-document', 'ember-decorators/object', 'moment'], function (exports, _accountDocument, _object, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _dec2, _desc, _value, _obj;

  var isPresent = Ember.isPresent;


  var STATEMENTS_FILENAME = new RegExp('' + '([0-9]{4}-[0-9]{2}-[0-9]{2})' + '-.{8}-statement.pdf');

  exports.default = _accountDocument.default.extend((_dec = (0, _object.computed)('documentTypeName', 'documentDate'), _dec2 = (0, _object.computed)('filename'), (_obj = {
    processDate: function processDate(documentTypeName, documentDate) {
      if (isPresent(documentDate)) {
        return documentDate;
      } else if (STATEMENTS_FILENAME.test(documentTypeName)) {
        var _STATEMENTS_FILENAME$ = STATEMENTS_FILENAME.exec(documentTypeName),
            _STATEMENTS_FILENAME$2 = _slicedToArray(_STATEMENTS_FILENAME$, 2),
            dateString = _STATEMENTS_FILENAME$2[1];

        return (0, _moment.default)(dateString);
      } else {
        return (0, _moment.default)();
      }
    },
    filenameWithExtension: function filenameWithExtension(filename) {
      return filename + '.pdf';
    }
  }, (_applyDecoratedDescriptor(_obj, 'processDate', [_dec], Object.getOwnPropertyDescriptor(_obj, 'processDate'), _obj), _applyDecoratedDescriptor(_obj, 'filenameWithExtension', [_dec2], Object.getOwnPropertyDescriptor(_obj, 'filenameWithExtension'), _obj)), _obj))).reopenClass({
    resourceName: 'statement'
  });
});