define('twapi/components/twapi-id-document-verification', ['exports', 'twapi/models/supporting-document-verification-client', 'twapi/utils/slug', 'twapi/components/twapi-document-verification'], function (exports, _supportingDocumentVerificationClient, _slug, _twapiDocumentVerification) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var _Ember$computed = Ember.computed,
      and = _Ember$computed.and,
      equal = _Ember$computed.equal,
      readOnly = _Ember$computed.readOnly,
      get = Ember.get,
      set = Ember.set;


  var VALID_MESSAGE_ORIGINS = ['https://tastyworks.web.amer-1.jumio.ai', 'https://tastyworks.web.emea-1.jumio.ai', 'https://tastyworks.web.apac-1.jumio.ai'];
  var JUMIO_TIMEOUT_ERROR_CODE = 9210;

  exports.default = _twapiDocumentVerification.default.extend({
    iframeClass: 'document-verification-id-iframe',

    documentVerificationMessage: null,

    validMessageOrigins: VALID_MESSAGE_ORIGINS,

    isLoaded: and('isMessagePresent', 'documentVerificationMessage.hasLoadedStatus'),

    errorCode: readOnly('documentVerificationMessage.payload.metainfo.code'),

    hasTimeoutError: equal('errorCode', JUMIO_TIMEOUT_ERROR_CODE),

    fetchDocumentVerification: function fetchDocumentVerification() {
      var supportingDocumentVerificationClient = _supportingDocumentVerificationClient.default.create({
        slug: _slug.SLUGS.ID_DOCUMENT
      });

      get(this, '_fetchDocumentVerification').perform(supportingDocumentVerificationClient);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this._addIframeErrorHandler();

      this.fetchDocumentVerification();
    },


    actions: {
      resetDocumentVerification: function resetDocumentVerification() {
        set(this, 'documentVerificationMessage', null);

        this.fetchDocumentVerification();
      }
    }
  });
});