define('twapi/components/beneficiary/twapi-beneficiary-share', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['twapi-beneficiary-share'],
    twapiBeneficiaries: Ember.inject.service(),

    updateBeneficiaries: false,

    allBeneficiaries: Ember.computed.alias('twapiBeneficiaries.beneficiaries'),

    actions: {
      newBeneficiary: function newBeneficiary() {
        var beneficiary = this.get('twapiBeneficiaries').newBeneficiary();
        this.sendAction('newBeneficiary', beneficiary);
      }
    }
  });
});