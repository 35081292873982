define('twapi/models/supporting-document-verification-client', ['exports', 'ember-restless'], function (exports, _emberRestless) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.ADDRESS_DOCUMENT_TYPES = undefined;
  var Model = _emberRestless.default.Model,
      attr = _emberRestless.default.attr;
  var ADDRESS_DOCUMENT_TYPES = exports.ADDRESS_DOCUMENT_TYPES = {
    BANK_STATEMENT: 'Bank Statement',
    CREDIT_CARD_STATEMENT: 'Credit Card Statement',
    UTILITY_BILL: 'Utility Bill'
  };

  exports.default = Model.extend({
    iframeUrl: attr('string'),

    slug: attr('string'),

    documentCountry: attr('string'),

    documentType: attr('string'),

    successUrl: attr('string'),

    transactionReference: attr('string')
  }).reopenClass({
    resourceName: 'supporting-document-verification-client'
  });
});