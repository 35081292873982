define('twapi/models/equifax-quiz-question', ['exports', 'ember-restless', 'ember-decorators/object', 'twapi/utils/validation'], function (exports, _emberRestless, _object, _validation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _desc, _value, _obj;

  var bool = Ember.computed.bool,
      get = Ember.get;
  var Model = _emberRestless.default.Model,
      attr = _emberRestless.default.attr,
      hasMany = _emberRestless.default.hasMany;
  exports.default = Model.extend((_dec = (0, _object.computed)('answers.[]'), (_obj = {
    text: attr('string', { readOnly: true }),
    answers: hasMany('equifax-quiz-answer', { readOnly: true }),
    selectedAnswerId: attr('number'),

    isAnswered: bool('selectedAnswerId'),

    options: function options(answers) {
      return answers.map(function (answer) {
        return {
          value: get(answer, 'id'),
          text: get(answer, 'text')
        };
      });
    }
  }, (_applyDecoratedDescriptor(_obj, 'options', [_dec], Object.getOwnPropertyDescriptor(_obj, 'options'), _obj)), _obj))).reopen(_validation.build({
    selectedAnswerId: { presence: true }
  }));
});