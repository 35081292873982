define('twapi/components/documents/twapi-entity-agreement', ['exports', 'twapi/templates/components/documents/twapi-entity-agreement'], function (exports, _twapiEntityAgreement) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      K = Ember.K,
      get = Ember.get;
  exports.default = Component.extend({
    isMargin: false,
    layout: _twapiEntityAgreement.default,
    uploading: K,
    uploadSuccess: K,

    actions: {
      uploading: function uploading() {
        get(this, 'uploading')();
      },
      uploadSuccess: function uploadSuccess() {
        get(this, 'uploadSuccess')();
      }
    }
  });
});