define('twapi/services/twapi-client', ['exports', 'lodash', 'twapi/utils/computed', 'twapi/utils/object', 'twapi/utils/url'], function (exports, _lodash, _computed, _object, _url) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var Service = Ember.Service,
      assign = Ember.assign,
      alias = Ember.computed.alias,
      get = Ember.get,
      set = Ember.set,
      service = Ember.inject.service,
      _Ember$run = Ember.run,
      debounce = _Ember$run.debounce,
      next = _Ember$run.next;


  var MINUTE_MULTIPLIER = 60 * 1000;

  var SESSION_TIMEOUT = 60 * MINUTE_MULTIPLIER;
  var SESSION_TIMEOUT_WARNING = SESSION_TIMEOUT - 5 * MINUTE_MULTIPLIER;

  exports.default = Service.extend({
    session: service(),

    isSessionTimingOut: false,

    adapter: _computed.registry.lookup('adapter:twapi'),

    serializer: _computed.registry.lookup('serializer:twapi'),

    rootPath: alias('adapter.rootPath'),
    headers: alias('adapter.headers'),

    request: function request(path) {
      var _this = this;

      var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      var options = params.httpOptions || {};
      delete params.httpOptions;

      var _UrlUtil$resolve = _url.resolve(path, params),
          _UrlUtil$resolve2 = _slicedToArray(_UrlUtil$resolve, 2),
          url = _UrlUtil$resolve2[0],
          queryParams = _UrlUtil$resolve2[1];

      var headers = get(options, 'headers');
      options = _lodash.default.defaultsDeep(options, {
        url: _url.join(get(this, 'rootPath'), url),
        type: 'GET',
        headers: assign({}, get(this, 'headers'), headers),
        data: queryParams
      });

      if (options.data && Ember.typeOf(options.data) !== 'string') {
        options.data = this.recursiveDasherizeKeys(options.data);
      }

      if (options.type !== 'GET' && options.data && Ember.typeOf(options.data) !== 'string') {
        options.contentType = 'application/json';
        options.data = JSON.stringify(options.data);
      }

      return new Ember.RSVP.Promise(function (resolve, reject) {
        Ember.$.ajax(options).done(function (_data, _status, xhr) {
          var response = xhr.responseJSON;
          var obj = Ember.typeOf(response) === 'object' ? response : {};

          debounce(_this, _this._invalidateSession, SESSION_TIMEOUT, false);
          set(_this, 'isSessionTimingOut', false);
          debounce(_this, _this._setSessionTimeoutWarning, SESSION_TIMEOUT_WARNING, false);

          next(function () {
            resolve(_object.withNonserializedProperties(obj, { xhr: xhr }));
          });
        }).fail(function (xhr, textStatus, error) {
          var adaptedErrors = get(_this, 'adapter').parseAjaxErrors(xhr, textStatus, error);
          next(function () {
            reject(_object.withNonserializedProperties(adaptedErrors, { xhr: xhr }));
          });
        });
      });
    },
    _invalidateSession: function _invalidateSession() {
      if (get(this, 'session.isAuthenticated')) {
        get(this, 'session').invalidate();
      }
    },
    _setSessionTimeoutWarning: function _setSessionTimeoutWarning() {
      set(this, 'isSessionTimingOut', true);
    },
    recursiveDasherizeKeys: function recursiveDasherizeKeys(params) {
      var _this2 = this;

      var dasherized = _lodash.default.mapKeys(params, function (value, key) {
        return Ember.String.dasherize(key);
      });

      dasherized = _lodash.default.mapValues(dasherized, function (value) {
        if (Ember.typeOf(value) === 'object') {
          return _this2.recursiveDasherizeKeys(value);
        }

        return value;
      });

      return dasherized;
    },
    post: function post(path) {
      var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      return this.request(path, _lodash.default.defaultsDeep(params, { httpOptions: { type: 'POST' } }));
    },
    put: function put(path) {
      var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      return this.request(path, _lodash.default.defaultsDeep(params, { httpOptions: { type: 'PUT' } }));
    },
    patch: function patch(path) {
      var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      return this.request(path, _lodash.default.defaultsDeep(params, { httpOptions: { type: 'PATCH' } }));
    },
    del: function del(path) {
      var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      return this.request(path, _lodash.default.defaultsDeep(params, { httpOptions: { type: 'DELETE' } }));
    }
  });
});