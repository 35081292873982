define('twapi/components/twapi-address-suggestion', ['exports', 'ember-decorators/object', 'twapi/utils/string-match'], function (exports, _object, _stringMatch) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _dec2, _desc, _value, _obj;

  var Component = Ember.Component,
      readOnly = Ember.computed.readOnly,
      get = Ember.get;
  exports.default = Component.extend((_dec = (0, _object.computed)('suggestedAddress', 'streetOne'), _dec2 = (0, _object.computed)('suggestedAddress', 'streetOne'), (_obj = {
    classNames: 'twapi-address-autocomplete-suggestion',

    tagName: 'li',

    addressSuggestion: null,

    streetOne: null,

    suggestedAddress: readOnly('addressSuggestion.suggestedAddress'),

    click: function click(e) {
      var addressSuggestion = get(this, 'addressSuggestion');
      this.selectSuggestion(addressSuggestion);
    },
    addressMatchingInput: function addressMatchingInput(suggestedAddress, streetOne) {
      return _stringMatch.default.findPartialMatch(suggestedAddress, streetOne);
    },
    remainingAddress: function remainingAddress(suggestedAddress, streetOne) {
      return _stringMatch.default.findNotMatching(suggestedAddress, streetOne);
    }
  }, (_applyDecoratedDescriptor(_obj, 'addressMatchingInput', [_dec], Object.getOwnPropertyDescriptor(_obj, 'addressMatchingInput'), _obj), _applyDecoratedDescriptor(_obj, 'remainingAddress', [_dec2], Object.getOwnPropertyDescriptor(_obj, 'remainingAddress'), _obj)), _obj)));
});