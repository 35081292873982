define('ember-ambitious-forms/components/amb-form-radio-group', ['exports', 'ember-ambitious-forms/mixins/converted-options'], function (exports, _convertedOptions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_convertedOptions.default, {
    layoutName: 'ember-ambitious-forms@components/amb-form-radio-group',
    tagName: 'span',
    classNames: ['amb-form-radio-group'],

    name: Ember.computed.oneWay('elementId')
  });
});