define('ember-ambitious-forms/components/amb-form-input', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var InputChangeMixin = exports.InputChangeMixin = Ember.Mixin.create({
    _lastValue: null,

    _sendUpdate: function _sendUpdate(value) {
      if (this.get('_lastValue') !== value) {
        this.sendAction('action', value);
        this.set('_lastValue', value);
      }
    },
    change: function change(e) {
      this._sendUpdate(e.target.value);
    },
    input: function input(e) {
      this._sendUpdate(e.target.value);
    }
  });

  exports.default = Ember.Component.extend(InputChangeMixin, {
    classNames: 'amb-form-input',
    tagName: 'input',

    attributeBindings: ['value', 'name', 'type', 'disabled', 'placeholder', 'disabled', 'size', 'maxlength', 'autocomplete', 'autofocus', 'min', 'max', 'step']
  });
});