define('twapi/models/customer-document', ['exports', 'ember-restless', 'twapi/utils/slug'], function (exports, _emberRestless, _slug) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.FOREIGN_SUPPORTING_DOCUMENT_SLUGS = undefined;
  var Model = _emberRestless.default.Model,
      attr = _emberRestless.default.attr;
  var FOREIGN_SUPPORTING_DOCUMENT_SLUGS = exports.FOREIGN_SUPPORTING_DOCUMENT_SLUGS = [_slug.SLUGS.ID_DOCUMENT, _slug.SLUGS.ADDRESS_DOCUMENT];

  exports.default = Model.extend({
    documentTypeName: attr('string'), // DEPRECATED

    sortKey: attr('string'),

    slug: attr('string')
  }).reopenClass({
    resourceName: 'customer-document'
  });
});