define('twapi/services/twapi-beneficiaries', ['exports', 'twapi/models/account-beneficiary', 'ember-decorators/object', 'twapi/utils/restless', 'lodash'], function (exports, _accountBeneficiary, _object, _restless, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.FULL_BENEFICIARY_ALLOTMENT = undefined;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _desc, _value, _obj;

  var FULL_BENEFICIARY_ALLOTMENT = exports.FULL_BENEFICIARY_ALLOTMENT = 100;

  exports.default = Ember.Service.extend((_dec = (0, _object.computed)('beneficiaries'), (_obj = {
    init: function init() {
      this._super.apply(this, arguments);
      this.set('currentBeneficiary', this.createDefaultBeneficiary());
      this.set('beneficiaries', Ember.A());
    },
    hasSpecificPercentagesByTypes: function hasSpecificPercentagesByTypes() {
      return Ember.Object.create(_lodash.default.values(_accountBeneficiary.BeneficiaryTypes).reduce(function (result, type) {
        result[type] = false;
        return result;
      }, {}));
    },
    setPercentChange: function setPercentChange(beneficiaryType) {
      var beneficiaries = this.getBeneficiaries(beneficiaryType);
      beneficiaries.forEach(function (b) {
        return _restless.default.saveFields(b, ['beneficiaryPercent']);
      });

      this.get('hasSpecificPercentagesByTypes').set(beneficiaryType, true);
    },
    createDefaultBeneficiary: function createDefaultBeneficiary() {
      return _accountBeneficiary.default.create({
        beneficiaryType: _accountBeneficiary.BeneficiaryTypes.PRIMARY,
        beneficiaryPercent: 0
      });
    },
    setBeneficiaries: function setBeneficiaries(beneficiaries) {
      this.set('beneficiaries', beneficiaries);
    },
    newBeneficiary: function newBeneficiary() {
      var beneficiary = this.createDefaultBeneficiary();
      return this.set('currentBeneficiary', beneficiary);
    },
    editBeneficiary: function editBeneficiary(beneficiary) {
      this.set('currentBeneficiary', beneficiary);
    },
    cancelBeneficiaryEdit: function cancelBeneficiaryEdit() {
      if (Ember.isPresent(this.get('currentBeneficiary'))) {
        _restless.default.restoreFields(this.get('currentBeneficiary'));
      }
      this.set('currentBeneficiary', null);
    },
    saveBeneficiary: function saveBeneficiary(beneficiary) {
      if (!this.get('beneficiaries').includes(beneficiary)) {
        this.addBeneficiary(beneficiary);
      }

      var beneficiaryType = beneficiary.get('beneficiaryType');
      if (!this.get('hasSpecificPercentagesByTypes').get(beneficiaryType)) {
        this.equalizeBeneficiaryPercentages(beneficiaryType);
      }
      this.adjustBeneficiaryPercentages();
      _restless.default.saveFields(beneficiary);
      this.set('currentBeneficiary', null);
    },
    addBeneficiary: function addBeneficiary(beneficiary) {
      this.get('beneficiaries').pushObject(beneficiary);
    },
    equalizeBeneficiaryPercentages: function equalizeBeneficiaryPercentages(beneficiaryType) {
      var beneficiaries = this.getBeneficiaries(beneficiaryType);
      var numBeneficiaries = beneficiaries.get('length');

      if (numBeneficiaries === 0) {
        return;
      }

      var share = Math.floor(FULL_BENEFICIARY_ALLOTMENT / numBeneficiaries);
      beneficiaries.forEach(function (b) {
        return b.set('beneficiaryPercent', share);
      });

      var sum = beneficiaries.reduce(function (total, beneficiary) {
        return total + beneficiary.get('beneficiaryPercent');
      }, 0);
      var remainder = FULL_BENEFICIARY_ALLOTMENT - sum;
      var lastBeneficiary = beneficiaries.get('lastObject');

      lastBeneficiary.set('beneficiaryPercent', lastBeneficiary.get('beneficiaryPercent') + remainder);

      beneficiaries.forEach(function (b) {
        return _restless.default.saveFields(b, ['beneficiaryPercent']);
      });
    },
    getPercentages: function getPercentages(beneficiaryType) {
      return this.getBeneficiaries(beneficiaryType).reduce(function (sum, beneficiary) {
        return sum + Number(beneficiary.get('beneficiaryPercent'));
      }, 0);
    },
    isPercentageValid: function isPercentageValid(beneficiaryType) {
      var hasZeroes = this.getBeneficiaries(beneficiaryType).some(function (beneficiary) {
        return Number(beneficiary.get('beneficiaryPercent')) === 0;
      });

      return this.getPercentages(beneficiaryType) === 100 && !hasZeroes;
    },
    allPercentagesValid: function allPercentagesValid() {
      var isValid = this.isPercentageValid(_accountBeneficiary.BeneficiaryTypes.PRIMARY);

      if (Ember.isPresent(this.getBeneficiaries(_accountBeneficiary.BeneficiaryTypes.CONTINGENT))) {
        isValid = isValid && this.isPercentageValid(_accountBeneficiary.BeneficiaryTypes.CONTINGENT);
      }

      return isValid;
    },
    getBeneficiaries: function getBeneficiaries(beneficiaryType) {
      return Ember.A(this.get('beneficiaries').filter(function (beneficiary) {
        return beneficiary.get('beneficiaryType') === beneficiaryType;
      }));
    },
    adjustBeneficiaryPercentages: function adjustBeneficiaryPercentages() {
      var _this = this;

      return _lodash.default.values(_accountBeneficiary.BeneficiaryTypes).forEach(function (type) {
        var beneficiaries = _this.getBeneficiaries(type);
        if (beneficiaries.get('length') === 1) {
          beneficiaries.set('firstObject.beneficiaryPercent', FULL_BENEFICIARY_ALLOTMENT);
          _restless.default.saveFields(beneficiaries.get('firstObject'), ['beneficiaryPercent']);
        }
      });
    },
    removeBeneficiary: function removeBeneficiary(beneficiary) {
      if (Ember.isEqual(this.get('currentBeneficiary'), beneficiary)) {
        this.set('currentBeneficiary', null);
      }
      this.get('beneficiaries').removeObject(beneficiary);
      this.adjustBeneficiaryPercentages();

      if (Ember.isEmpty(this.get('beneficiaries'))) {
        this.set('currentBeneficiary', this.createDefaultBeneficiary());
      }
    }
  }, (_applyDecoratedDescriptor(_obj, 'hasSpecificPercentagesByTypes', [_dec], Object.getOwnPropertyDescriptor(_obj, 'hasSpecificPercentagesByTypes'), _obj)), _obj)));
});