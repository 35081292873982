define('twapi/utils/computed', ['exports', 'lodash', 'twapi/utils/computed/registry', 'twapi/utils/computed/math'], function (exports, _lodash, _registry, _math) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.math = exports.registry = undefined;
  exports.copy = copy;
  exports.deepCopy = deepCopy;
  exports.readOnlyValue = readOnlyValue;
  exports.objectOf = objectOf;
  exports.arrayOf = arrayOf;

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.registry = _registry;
  exports.math = _math;
  function copy(property) {
    return Ember.computed(property, function () {
      return Ember.copy(this.get(property));
    });
  }

  function deepCopy(property) {
    return Ember.computed(property, function () {
      return Ember.copy(this.get(property), true);
    });
  }

  function readOnlyValue(value) {
    return Ember.computed(function () {
      return value;
    }).readOnly();
  }

  function objectOf() {
    for (var _len = arguments.length, names = Array(_len), _key = 0; _key < _len; _key++) {
      names[_key] = arguments[_key];
    }

    names = _lodash.default.flatten(names);
    return Ember.computed.apply(Ember, _toConsumableArray(names).concat([function () {
      return this.getProperties(names);
    }]));
  }

  function arrayOf() {
    for (var _len2 = arguments.length, names = Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
      names[_key2] = arguments[_key2];
    }

    names = _lodash.default.flatten(names);
    return Ember.computed.apply(Ember, _toConsumableArray(names).concat([function () {
      var _this = this;

      return names.map(function (name) {
        return _this.get(name);
      });
    }]));
  }
});