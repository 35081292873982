define('twapi/utils/promise', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.unwrapHash = unwrapHash;
  function unwrapHash(object) {
    var ret = Ember.Object.create({});

    var _loop = function _loop(key) {
      if (object.hasOwnProperty(key)) {
        Ember.RSVP.resolve(object[key]).then(function (value) {
          ret.set(key, value);
        });
      }
    };

    for (var key in object) {
      _loop(key);
    }
    return ret;
  }
});