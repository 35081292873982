define("twapi/utils/string-match", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.findMatchEndIndex = findMatchEndIndex;
  exports.findPartialMatch = findPartialMatch;
  exports.findNotMatching = findNotMatching;
  function findMatchEndIndex(string, searchValue) {
    var matchEndIndex = 0;
    while (matchEndIndex < searchValue.length) {
      if (searchValue.charAt(matchEndIndex).toLowerCase() !== string.charAt(matchEndIndex).toLowerCase()) {
        return matchEndIndex;
      }
      matchEndIndex++;
    }
    return matchEndIndex;
  }

  function findPartialMatch(string, searchValue) {
    var endMatchIndex = findMatchEndIndex(string, searchValue);
    return string.substr(0, endMatchIndex);
  }

  function findNotMatching(string, searchValue) {
    var endMatchIndex = findMatchEndIndex(string, searchValue);
    return string.substr(endMatchIndex);
  }
});