define("ember-cli-foundation-6-sass/components/zf-dropdown", ["exports", "ember-cli-foundation-6-sass/mixins/zf-widget"], function (_exports, _zfWidget) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_zfWidget.default, {
    /** @member Class names */
    classNames: ['dropdown-pane'],

    /* @member Position class name */
    classNameBindings: ['_position'],

    /** @member Attribute bindings */
    attributeBindings: ['data-dropdown', 'data-auto-focus', 'data-hover', 'data-hover-pane'],

    /** @member Makes the data attribute binding appear */
    'data-dropdown': ' ',
    'data-auto-focus': true,

    /** @member enables hover options **/
    'zfHover': false,
    'data-hover': Ember.computed('zfHover', function () {
      return this.get('zfHover');
    }),
    'data-hover-pane': Ember.computed('zfHover', function () {
      return this.get('zfHover');
    }),

    /** @member Foundation type */
    'zfType': 'Dropdown',

    /** @private  Hanlde attribute bindings for position **/
    _position: Ember.computed('positionClass', function () {
      var allowedPositions = ['top', 'right', 'left', 'bottom'];
      var position = this.get('positionClass');
      (false && !(allowedPositions.includes(position)) && Ember.assert('Must provide a valid foundation position for dropdown', allowedPositions.includes(position)));
      return position;
    }),

    /** @member Foundation specific options */
    'zfOptions': ['hoverDelay', 'hover', 'hoverPane', 'vOffset', 'hOffset', 'positionClass', 'trapFocus', 'autoFocus', 'closeOnClick']
  });

  _exports.default = _default;
});