define("ember-cli-foundation-6-sass/components/zf-dropdown-menu", ["exports", "ember-cli-foundation-6-sass/mixins/zf-widget"], function (_exports, _zfWidget) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_zfWidget.default, {
    /** @member tag type */
    tagName: 'ul',

    /** @member Class names */
    classNames: ['dropdown', 'menu'],

    /** @member Attribute bindings */
    attributeBindings: ['data-dropdown-menu'],

    /** @member Makes the data attribute binding appear */
    'data-dropdown-menu': ' ',

    /** @member Foundation type */
    'zfType': 'DropdownMenu',

    /** @member Foundation specific options */
    'zfOptions': ['disableHover', 'autoclose', 'hoverDelay', 'clickOpen', 'closingTime', 'alignment', 'closeOnClick', 'verticalClass', 'rightClass', 'forceFollow']
  });

  _exports.default = _default;
});