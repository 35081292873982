define('twapi/components/twapi-menu-content', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['twapi-menu-content'],
    classNameBindings: ['isOpen'],
    showContent: false,
    isOpen: Ember.computed.bool('showContent'),

    actions: {
      toggleContent: function toggleContent() {
        this.toggleProperty('showContent');
      }
    }
  });
});