define('twapi/components/twapi-select-content', ['exports', 'ember-decorators/object'], function (exports, _object) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _desc, _value, _obj, _dec2, _dec3, _desc2, _value2, _obj2;

  var Component = Ember.Component,
      get = Ember.get,
      set = Ember.set,
      isNone = Ember.isNone;


  var ContentTab = Ember.Object.extend((_dec = (0, _object.computed)('selectedValue', 'name'), (_obj = {
    name: null,

    selectedValue: null,

    isActive: function isActive(selectedValue, name) {
      return selectedValue === name;
    }
  }, (_applyDecoratedDescriptor(_obj, 'isActive', [_dec], Object.getOwnPropertyDescriptor(_obj, 'isActive'), _obj)), _obj)));

  exports.default = Component.extend((_dec2 = (0, _object.computed)('contents.[]'), _dec3 = (0, _object.computed)('contents.[]', 'selectedValue'), (_obj2 = {
    classNames: 'twapi-select-content',

    contents: null,

    selectedValue: null,

    options: function options(contents) {
      if (isNone(contents)) {
        return [];
      }
      return contents.map(function (content) {
        return [get(content, 'name'), get(content, 'title')];
      });
    },
    contentTabs: function contentTabs(contents, selectedValue) {
      if (isNone(contents)) {
        return [];
      }
      return contents.map(function (content) {
        return ContentTab.create({
          name: get(content, 'name'),
          selectedValue: selectedValue,
          title: get(content, 'title')
        });
      });
    },


    actions: {
      selectTab: function selectTab(contentTab) {
        set(this, 'selectedValue', get(contentTab, 'name'));
      },
      showContent: function showContent(value) {
        set(this, 'selectedValue', value);
      }
    }
  }, (_applyDecoratedDescriptor(_obj2, 'options', [_dec2], Object.getOwnPropertyDescriptor(_obj2, 'options'), _obj2), _applyDecoratedDescriptor(_obj2, 'contentTabs', [_dec3], Object.getOwnPropertyDescriptor(_obj2, 'contentTabs'), _obj2)), _obj2)));
});