define('twapi/utils/regex/combinators', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.or = or;
  function or() {
    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    if (args.every(function (arg) {
      return arg.length === 1;
    })) {
      return '[' + args.join() + ']';
    } else {
      return '(?:' + args.map(function (arg) {
        return '(?:' + arg + ')';
      }).join('|') + ')';
    }
  }
});