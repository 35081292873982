define('twapi/components/twapi-address-document-verification', ['exports', 'ember-decorators/object', 'twapi/models/supporting-document-verification-client', 'twapi/utils/slug', 'twapi/utils/validation', 'twapi/components/twapi-document-verification', 'twapi/utils/computed'], function (exports, _object, _supportingDocumentVerificationClient, _slug, _validation, _twapiDocumentVerification, _computed) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _desc, _value, _obj;

  var _Ember$computed = Ember.computed,
      notEmpty = _Ember$computed.notEmpty,
      readOnly = _Ember$computed.readOnly,
      get = Ember.get,
      isNone = Ember.isNone,
      set = Ember.set;


  function loadHandler() {
    set(this, 'isLoaded', true);
  }

  exports.default = _twapiDocumentVerification.default.extend((_dec = (0, _object.computed)('validMessageOrigin'), (_obj = {
    config: _computed.default.registry.resolve('config:environment'),

    iframeClass: 'document-verification-address-iframe',

    citizenshipCountry: null,

    addressDocumentType: null,

    isLoaded: false,

    validMessageOrigin: readOnly('config.documentVerificationBaseUrl'),

    hasSelectedAddressDocumentType: notEmpty('addressDocumentType'),

    _loadHandler: null,

    validMessageOrigins: function validMessageOrigins(validMessageOrigin) {
      return [validMessageOrigin];
    },


    actions: {
      fetchDocumentVerification: function fetchDocumentVerification() {
        if (!get(this, 'hasSelectedAddressDocumentType')) {
          return;
        }

        this._addIframeErrorHandler();

        var iframe = get(this, 'iframe');
        iframe.addEventListener('load', get(this, '_loadHandler'));

        var supportingDocumentVerificationClient = _supportingDocumentVerificationClient.default.create({
          slug: _slug.SLUGS.ADDRESS_DOCUMENT,
          documentCountry: get(this, 'citizenshipCountry'),
          documentType: get(this, 'addressDocumentType')
        });

        get(this, '_fetchDocumentVerification').perform(supportingDocumentVerificationClient);
      }
    },

    init: function init() {
      this._super.apply(this, arguments);

      this.set('_loadHandler', loadHandler.bind(this));
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      var iframe = get(this, 'iframe');
      if (isNone(iframe)) {
        return;
      }

      iframe.removeEventListener('load', get(this, '_loadHandler'));
    }
  }, (_applyDecoratedDescriptor(_obj, 'validMessageOrigins', [_dec], Object.getOwnPropertyDescriptor(_obj, 'validMessageOrigins'), _obj)), _obj))).reopen(_validation.build({
    addressDocumentType: {
      inclusion: {
        in: Object.values(_supportingDocumentVerificationClient.ADDRESS_DOCUMENT_TYPES)
      }
    }
  }));
});