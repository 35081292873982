define("twapi/utils/input", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.focusInputStart = focusInputStart;
  exports.focusInputIndex = focusInputIndex;
  function focusInputStart(querySelector) {
    focusInputIndex(querySelector, 0);
  }

  function focusInputIndex(querySelector, index) {
    querySelector.focus();
    querySelector.setSelectionRange(index, index);
  }
});