define('twapi/models/holding', ['exports', 'ember-restless', 'ember-decorators/object', 'ember-decorators/object/evented', 'lodash', 'twapi/utils/validation'], function (exports, _emberRestless, _object, _evented, _lodash, _validation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.UNACCEPTED_TYPES = exports.HOLDING_TYPE = undefined;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _dec2, _dec3, _dec4, _dec5, _desc, _value, _obj;

  var _Ember$computed = Ember.computed,
      and = _Ember$computed.and,
      equal = _Ember$computed.equal,
      lt = _Ember$computed.lt,
      not = _Ember$computed.not,
      notEmpty = _Ember$computed.notEmpty,
      or = _Ember$computed.or,
      readOnly = _Ember$computed.readOnly,
      get = Ember.get,
      isNone = Ember.isNone,
      set = Ember.set;
  var attr = _emberRestless.default.attr,
      Model = _emberRestless.default.Model;
  var HOLDING_TYPE = exports.HOLDING_TYPE = {
    CASH: 'cash',
    DERIVATIVE: 'derivative',
    EQUITY: 'equity',
    ETF: 'etf',
    FIXED_INCOME: 'fixed income',
    LOAN: 'loan',
    MUTUAL_FUND: 'mutual fund',
    OTHER: 'other'
  };

  var UNACCEPTED_TYPES = exports.UNACCEPTED_TYPES = [HOLDING_TYPE.FIXED_INCOME, HOLDING_TYPE.LOAN, HOLDING_TYPE.MUTUAL_FUND];

  var CURRENCY_REGEX = /CUR:\D+/;

  exports.default = Model.extend((_dec = (0, _object.computed)('isShort', 'isCash'), _dec2 = (0, _object.computed)('isShort', 'isCash'), _dec3 = (0, _object.computed)('externalType'), _dec4 = (0, _object.computed)('symbol', 'isCash'), _dec5 = (0, _evented.on)('didLoad'), (_obj = {
    amount: attr('number'),
    description: attr('string'),
    eligibility: attr('string'),
    externalType: attr('string'),
    isoCurrencyCode: attr('string'),
    symbol: attr('string'),
    transferAmount: attr('number'),

    isSelected: false,

    isMutualFund: equal('externalType', HOLDING_TYPE.MUTUAL_FUND),

    isCash: equal('externalType', HOLDING_TYPE.CASH),

    isShort: lt('amount', 0),

    hasHoldingNotes: notEmpty('holdingNotes'),

    notSelected: not('isSelected'),

    showNotes: and('isSelected', 'hasHoldingNotes'),

    isShortPosition: function isShortPosition(isShort, isCash) {
      return isShort && !isCash;
    },
    isLongPosition: function isLongPosition(isShort, isCash) {
      return !isShort && !isCash;
    },
    holdingNotes: function holdingNotes(externalType) {
      if (UNACCEPTED_TYPES.includes(externalType)) {
        return 'This appears to be a ' + externalType.toLowerCase() + ' and will likely not be transferable. If you believe\n      this is transferable you may still select this holding but it will be subject to review and may result in longer processing times.';
      }

      return '';
    },
    formattedIdentifier: function formattedIdentifier(symbol, isCash) {
      if (!isCash) {
        return symbol;
      }
      if (CURRENCY_REGEX.test(symbol)) {
        return 'Cash (' + symbol.slice(4) + ')';
      }

      return 'Cash';
    },
    _didLoad: function _didLoad() {
      if (isNone(get(this, 'transferAmount'))) {
        if (get(this, 'isCash')) {
          set(this, 'transferAmount', _lodash.default.floor(get(this, 'amount'), 2));
        } else {
          set(this, 'transferAmount', _lodash.default.floor(get(this, 'amount')));
        }
      }
    }
  }, (_applyDecoratedDescriptor(_obj, 'isShortPosition', [_dec], Object.getOwnPropertyDescriptor(_obj, 'isShortPosition'), _obj), _applyDecoratedDescriptor(_obj, 'isLongPosition', [_dec2], Object.getOwnPropertyDescriptor(_obj, 'isLongPosition'), _obj), _applyDecoratedDescriptor(_obj, 'holdingNotes', [_dec3], Object.getOwnPropertyDescriptor(_obj, 'holdingNotes'), _obj), _applyDecoratedDescriptor(_obj, 'formattedIdentifier', [_dec4], Object.getOwnPropertyDescriptor(_obj, 'formattedIdentifier'), _obj), _applyDecoratedDescriptor(_obj, '_didLoad', [_dec5], Object.getOwnPropertyDescriptor(_obj, '_didLoad'), _obj)), _obj))).reopenClass({
    resourceName: 'holding'
  }).reopen(_validation.build({
    transferAmount: {
      number: [{
        allowString: true,
        integer: true,
        disabled: readOnly('model.isCash'),
        message: 'must be a whole number (no partial shares)'
      }, {
        allowString: true,
        gt: 0,
        lte: readOnly('model.amount'),
        disabled: or('model.isShortPosition'),
        message: 'must not exceed available amount'
      }, {
        allowString: true,
        lt: 0,
        gte: readOnly('model.amount'),
        disabled: or('model.isLongPosition', 'model.isCash'),
        message: 'must not exceed available amount'
      }]
    }
  }));
});