define('twapi/models/equifax-quiz', ['exports', 'ember-restless'], function (exports, _emberRestless) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberRestless.default.Model,
      attr = _emberRestless.default.attr,
      belongsTo = _emberRestless.default.belongsTo,
      hasMany = _emberRestless.default.hasMany;
  exports.default = Model.extend({
    transactionKey: attr('string'),
    quizId: attr('number'),

    completion: belongsTo('equifax-response'),
    questions: hasMany('equifax-quiz-question')
  }).reopenClass({
    resourceName: 'equifax-quiz',
    primaryKey: 'quizId'
  });
});