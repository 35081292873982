define('twapi/models/entity-suitability', ['exports', 'ember-restless', 'twapi/mixins/suitability', 'twapi/utils/validation'], function (exports, _emberRestless, _suitability, _validation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberRestless.default.Model,
      attr = _emberRestless.default.attr;
  exports.default = Model.extend(_suitability.default, {

    taxBracket: attr('percent')

  }).reopen(_validation.build({
    taxBracket: {
      number: {
        allowString: true,
        gte: 0,
        lt: 100
      }
    }
  })).reopenClass({
    resourceName: 'entity-suitability'
  });
});