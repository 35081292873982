define('twapi/services/twapi-instruments', ['exports', 'ember-restless', 'ember-decorators/object', 'lodash', 'twapi/models/equity', 'twapi/models/equity-option', 'twapi/utils/array', 'twapi/utils/occ-symbol', 'twapi/utils/restless'], function (exports, _emberRestless, _object, _lodash, _equity, _equityOption, _array, _occSymbol, _restless) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _desc, _value, _obj;

  var DEFINITIONS = {
    'Equity': {
      serverLookup: function serverLookup(symbols) {
        return this.get('twapiEndpoints').getEquities({ symbol: symbols });
      },
      create: function create(symbol) {
        return _equity.default.create({ symbol: symbol });
      }
    },

    'Equity Option': {
      serverLookup: function serverLookup(symbols) {
        return this.get('twapiEndpoints').getEquityOptions({ symbol: symbols });
      },
      create: function create(symbol) {
        var data = _occSymbol.default.parse(symbol);
        var equityOption = _equityOption.default.create(data);
        this._translateRootSymbol(equityOption);
        return equityOption;
      }
    }
  };

  exports.default = Ember.Service.extend((_obj = {
    twapiEndpoints: Ember.inject.service(),

    lookup: function lookup(obj) {
      if (Ember.isArray(obj)) {
        return this.lookupMany(obj);
      } else {
        return this.lookupOne(obj);
      }
    },
    lookupOne: function lookupOne(obj) {
      return this.lookupMany([obj]).objectAt(0);
    },
    lookupMany: function lookupMany(objs) {
      var _this = this;

      var missing = [];
      var content = objs.map(function (obj) {
        var symbol = Ember.get(obj, 'symbol');
        var type = Ember.get(obj, 'instrumentType');
        return _this._getCachedInstrument(symbol, type, function (equity) {
          return missing.push(equity);
        });
      });
      var instruments = _emberRestless.default.RecordArray.create({ content: Ember.A(content) });

      if (missing.length > 0) {
        this.reload(missing).then(function () {
          return instruments.onLoaded();
        });
      } else {
        instruments.set('isLoaded', true);
      }

      return instruments;
    },
    reload: function reload(instrument) {
      if (Ember.isArray(instrument)) {
        return this.reloadMany(instrument);
      } else {
        return this.reloadOne(instrument);
      }
    },
    reloadOne: function reloadOne(instrument) {
      return this.reloadMany([instrument]).then(function (instruments) {
        return instruments.objectAt(0);
      });
    },
    reloadMany: function reloadMany(instruments) {
      var _this2 = this;

      var instrumentsByType = _lodash.default.groupBy(_array.default.asNative(instruments), function (i) {
        return i.get('instrumentType');
      });
      var promises = _lodash.default.map(instrumentsByType, function (instruments, type) {
        var definition = DEFINITIONS[type];
        var symbols = instruments.map(function (instrument) {
          return instrument.get('symbol');
        });
        var serverInstruments = definition.serverLookup.call(_this2, symbols);
        return _restless.default.asPromise(serverInstruments).then(function () {
          _restless.default.copyManyFields(serverInstruments, instruments, 'symbol');
        });
      });

      return Ember.RSVP.all(promises).then(function () {
        instruments.onLoaded && instruments.onLoaded();
        return instruments;
      });
    },


    _cachedInstruments: Ember.computed(function () {
      return {};
    }),
    _getCachedInstrument: function _getCachedInstrument(symbol, type) {
      var ifMissing = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : Ember.K;

      var key = '_cachedInstruments.' + symbol + ':' + type;
      var instrument = this.get(key);
      if (!instrument) {
        instrument = DEFINITIONS[type].create.call(this, symbol);
        this.set(key, instrument);
        ifMissing(instrument);
      }

      return instrument;
    },
    _translateRootSymbol: function _translateRootSymbol(equityOption) {
      var rootSymbol = equityOption.get('rootSymbol');
      var rootTranslation = this.get('_rootTranslations.' + rootSymbol);
      if (rootTranslation) {
        _restless.default.copyFields(rootTranslation, equityOption);
      }
    },
    _rootTranslations: function _rootTranslations() {
      var translations = {};
      var rawTranslations = this.get('twapiEndpoints').getEquityOptionRootTranslations();
      rawTranslations.on('didLoad', function () {
        rawTranslations.forEach(function (translation) {
          var rootSymbol = translation.get('rootSymbol');
          translations[rootSymbol] = translation;
        });
      });
      return translations;
    }
  }, (_applyDecoratedDescriptor(_obj, '_rootTranslations', [_object.computed], Object.getOwnPropertyDescriptor(_obj, '_rootTranslations'), _obj)), _obj));
});