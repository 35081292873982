define('twapi/models/equity-option', ['exports', 'ember-restless', 'twapi/utils/computed', 'twapi/mixins/instrument-type'], function (exports, _emberRestless, _computed, _instrumentType) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberRestless.default.ReadOnlyModel.extend(_instrumentType.default, {
    symbol: _emberRestless.default.attr('string'),
    instrumentType: _computed.default.readOnlyValue('Equity Option'),
    listedMarket: _emberRestless.default.attr('string'),
    strikePrice: _emberRestless.default.attr('number'),
    rootSymbol: _emberRestless.default.attr('string'),
    underlyingSymbol: _emberRestless.default.attr('string'),
    expirationDate: _emberRestless.default.attr('string'),
    exerciseStyle: _emberRestless.default.attr('string'),
    sharesPerContract: _emberRestless.default.attr('number'),
    optionType: _emberRestless.default.attr('string'),
    optionChainType: _emberRestless.default.attr('string'),
    expirationType: _emberRestless.default.attr('string'),
    haltedAt: _emberRestless.default.attr('momentDateTime'),
    stopsTradingAt: _emberRestless.default.attr('momentDateTime'),
    marketTimeInstrumentCollection: _emberRestless.default.attr('string')
  });
});