define('twapi/models/referral-owner', ['exports', 'ember-restless'], function (exports, _emberRestless) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberRestless.default.Model.extend({
    id: _emberRestless.default.attr('string'),
    displayName: _emberRestless.default.attr('string')
  }).reopenClass({
    resourceName: 'referral-owner'
  });
});