define('twapi/models/customer-portfolio-entitlement', ['exports', 'ember-restless', 'twapi/models/model-portfolio'], function (exports, _emberRestless, _modelPortfolio) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Model = _emberRestless.default.Model,
	    attr = _emberRestless.default.attr,
	    belongsTo = _emberRestless.default.belongsTo;
	exports.default = Model.extend({
		externalId: attr('string'),

		modelPortfolio: belongsTo('model-portfolio', { defaultValue: function defaultValue() {
				return _modelPortfolio.default.create();
			} }),

		endDate: attr('momentDateTime')
	}).reopenClass({
		resourceName: 'customer-portfolio-entitlement'
	});
});