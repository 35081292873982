define('twapi/transforms/percent', ['exports', 'ember-restless'], function (exports, _emberRestless) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Transform = _emberRestless.default.Transform;
  var isEmpty = Ember.isEmpty;
  exports.default = Transform.extend({
    deserialize: function deserialize(serialized) {
      return isEmpty(serialized) ? null : Number(serialized) * 100;
    },
    serialize: function serialize(obj) {
      return isEmpty(obj) ? null : Number(obj) / 100;
    }
  });
});