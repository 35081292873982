define('twapi/components/amb-form-one-time-password', ['exports', 'twapi/templates/components/amb-form-one-time-password', 'twapi/utils/sanitize', 'twapi/utils/input'], function (exports, _ambFormOneTimePassword, _sanitize, _input) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      get = Ember.get,
      set = Ember.set,
      isBlank = Ember.isBlank;


  var OTP_MASK = Object.freeze([/\d/, ' ', /\d/, ' ', /\d/, ' ', /\d/, ' ', /\d/, ' ', /\d/]);

  exports.default = Component.extend({
    layout: _ambFormOneTimePassword.default,

    autofocus: false,

    mask: OTP_MASK,

    maskedValue: null,

    value: null,

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (get(this, 'autofocus')) {
        this._focusInputStart();
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      set(this, 'maskedValue', get(this, 'value'));
    },
    click: function click() {
      if (isBlank(get(this, 'value'))) {
        this._focusInputStart();
      }
    },


    actions: {
      onUpdate: function onUpdate() {
        var maskedValue = get(this, 'maskedValue');

        set(this, 'value', _sanitize.oneTimePassword(maskedValue));
      }
    },

    _focusInputStart: function _focusInputStart() {
      _input.focusInputStart(get(this, 'element').querySelector('.amb-form-input'));
    }
  });
});