define('twapi/components/forms/twapi-trusted-contact-form', ['exports', 'twapi/utils/country'], function (exports, _country) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      _Ember$computed = Ember.computed,
      alias = _Ember$computed.alias,
      bool = _Ember$computed.bool;
  exports.default = Component.extend({
    address: alias('f.scope.address'),

    isAddressDomestic: bool('address.isDomestic'),

    foreignCountryOptions: _country.AVAILABLE_ADDRESS_COUNTRIES_OPTIONS.filter(function (country) {
      return country !== _country.USA_COUNTRY_CODE;
    })
  });
});