define('twapi/utils/castle-token', ['exports', 'lodash', '@castleio/castle-js'], function (exports, _lodash, _castleJs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.attachTokenAsPromise = attachTokenAsPromise;
  var RSVP = Ember.RSVP;


  function createTokenAsPromise() {
    return new RSVP.Promise(function (resolve, reject) {
      try {
        _castleJs.createRequestToken().then(function (requestToken) {
          resolve(requestToken);
        });
      } catch (error) {
        reject(error);
      }
    });
  }

  function attachTokenAsPromise(params) {
    return createTokenAsPromise().then(function (requestToken) {
      return _lodash.default.merge(params, { httpOptions: {
          headers: { 'X-Castle-Request-Token': requestToken }
        } });
    });
  }
});