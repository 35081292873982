define('ember-ambitious-forms/components/amb-form-checkbox', ['exports', 'ember-ambitious-forms/components/amb-form-input'], function (exports, _ambFormInput) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend(_ambFormInput.InputChangeMixin, {
    layoutName: 'ember-ambitious-forms@components/amb-form-checkbox',
    tagName: 'label',
    classNames: 'amb-form-checkbox',
    classNameBindings: 'value:checked:unchecked'
  });
});