define('twapi/models/pdt-reset-request', ['exports', 'ember-restless', 'twapi/utils/deser', '@tastyworks/tastyworks-api'], function (exports, _emberRestless, _deser, _tastyworksApi) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberRestless.default.Model;
  exports.default = Model.extend(_deser.buildModel(_tastyworksApi.PDT_RESET_RESPONSE_DESER)).reopenClass({
    resourceName: 'pdt-reset-request'
  }).reopen(_deser.buildToJsObjectTranslator(_tastyworksApi.PdtResetResponse, _tastyworksApi.PDT_RESET_RESPONSE_DESER));
});