define('twapi/proxies/user', ['exports', 'ember-decorators/object', 'lodash', 'twapi/utils/restless', 'twapi/models/open-api-access-entitlement', 'ember-restless'], function (exports, _object, _lodash, _restless, _openApiAccessEntitlement, _emberRestless) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _desc, _value, _obj;

  var RecordArray = _emberRestless.default.RecordArray;
  var ObjectProxy = Ember.ObjectProxy,
      _Ember$computed = Ember.computed,
      alias = _Ember$computed.alias,
      empty = _Ember$computed.empty,
      get = Ember.get,
      isBlank = Ember.isBlank,
      isNone = Ember.isNone,
      set = Ember.set;
  exports.default = ObjectProxy.extend((_dec = (0, _object.computed)('id'), _dec2 = (0, _object.computed)('id', '_securityQuestions'), _dec3 = (0, _object.computed)('id'), _dec4 = (0, _object.computed)('referralUser', 'referralUser.isLoaded'), _dec5 = (0, _object.computed)('referralUser', 'referralUser.isLoaded'), _dec6 = (0, _object.computed)('id', '_openApiAccessEntitlements', 'twapiRest'), (_obj = {
    endpoints: null,
    user: alias('content'),
    hasNoSecurityQuestions: empty('securityQuestions'),

    updateUser: function updateUser(params) {
      return get(this, 'endpoints').updateUser(this, params);
    },
    mobileConfirmation: function mobileConfirmation() {
      return get(this, 'endpoints').getUserMobileConfirmation();
    },
    securityQuestions: function securityQuestions(userId) {
      if (!userId) {
        return [];
      }
      if (isNone(get(this, '_securityQuestions'))) {
        this.fetchSecurityQuestions();
      }

      return get(this, '_securityQuestions');
    },
    fetchSecurityQuestions: function fetchSecurityQuestions() {
      var userId = get(this, 'id');
      var securityQuestions = get(this, 'endpoints').getSecurityQuestions({ userId: userId });

      set(this, '_securityQuestions', securityQuestions);

      return _restless.asPromise(securityQuestions);
    },
    saveSecurityQuestions: function saveSecurityQuestions(params) {
      var securityQuestions = get(this, 'securityQuestions');
      return get(this, 'endpoints').saveSecurityQuestions(securityQuestions, _lodash.default.merge({ userId: get(this, 'id') }, params));
    },
    referralUser: function referralUser(id) {
      return get(this, 'endpoints').getReferralUser();
    },
    referral: function referral(referralUser, isLoaded) {
      if (!isLoaded) {
        return;
      }
      return get(referralUser, 'referral');
    },
    referralUsed: function referralUsed(referralUser, isLoaded) {
      if (!isLoaded) {
        return;
      }
      return get(referralUser, 'referralUsed');
    },
    fetchOpenApiAccessEntitlements: function fetchOpenApiAccessEntitlements() {
      var _this = this;

      set(this, '_openApiAccessEntitlements', RecordArray.create({ isLoaded: false }));

      get(this, 'twapiRest.client').openApiAccessEntitlementService.index().then(function (openApiAccessEntitlementResponse) {
        set(_this, '_openApiAccessEntitlements', _openApiAccessEntitlement.default.createListFromTwResponse(openApiAccessEntitlementResponse));
      });
    },
    openApiAccessEntitlements: function openApiAccessEntitlements(id, _openApiAccessEntitlements, twapiRest) {
      if (isBlank(id)) {
        return A();
      }
      if (isNone(_openApiAccessEntitlements)) {
        this.fetchOpenApiAccessEntitlements();
      }

      return get(this, '_openApiAccessEntitlements');
    }
  }, (_applyDecoratedDescriptor(_obj, 'mobileConfirmation', [_dec], Object.getOwnPropertyDescriptor(_obj, 'mobileConfirmation'), _obj), _applyDecoratedDescriptor(_obj, 'securityQuestions', [_dec2], Object.getOwnPropertyDescriptor(_obj, 'securityQuestions'), _obj), _applyDecoratedDescriptor(_obj, 'referralUser', [_dec3], Object.getOwnPropertyDescriptor(_obj, 'referralUser'), _obj), _applyDecoratedDescriptor(_obj, 'referral', [_dec4], Object.getOwnPropertyDescriptor(_obj, 'referral'), _obj), _applyDecoratedDescriptor(_obj, 'referralUsed', [_dec5], Object.getOwnPropertyDescriptor(_obj, 'referralUsed'), _obj), _applyDecoratedDescriptor(_obj, 'openApiAccessEntitlements', [_dec6], Object.getOwnPropertyDescriptor(_obj, 'openApiAccessEntitlements'), _obj)), _obj)));
});