define('twapi/models/customer-entity', ['exports', 'ember-restless'], function (exports, _emberRestless) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.AUTHORITY_LEVELS = undefined;
  var Model = _emberRestless.default.Model,
      attr = _emberRestless.default.attr,
      belongsTo = _emberRestless.default.belongsTo;
  var AUTHORITY_LEVELS = exports.AUTHORITY_LEVELS = {
    ADMINISTRATOR: 'administrator',
    MEMBER: 'member'
  };

  exports.default = Model.extend({

    ownerOfRecord: attr('boolean'),

    authorityLevel: attr('string'),

    entity: belongsTo('entity')

  }).reopenClass({
    resourceName: 'customer-entity'
  });
});