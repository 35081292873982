define('twapi/models/withdrawal-dry-run', ['exports', 'ember-restless'], function (exports, _emberRestless) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberRestless.default.Model,
      attr = _emberRestless.default.attr,
      hasMany = _emberRestless.default.hasMany;
  var _Ember$computed = Ember.computed,
      mapBy = _Ember$computed.mapBy,
      sum = _Ember$computed.sum;
  exports.default = Model.extend({
    amount: attr('number'),

    permittedWithdrawalAmount: attr('number'),

    netDebitAmount: attr('number'),

    fees: hasMany('withdrawal-fee'),

    feeValues: mapBy('fees', 'value'),

    totalFeeAmount: sum('feeValues')
  }).reopenClass({
    resourceName: 'withdrawal-dry-run'
  });
});