define('twapi/models/options-level', ['exports', 'ember-restless', 'ember-decorators/object'], function (exports, _emberRestless, _object) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.OPTIONS_LEVELS = undefined;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _desc, _value, _obj;

  var Model = _emberRestless.default.Model,
      attr = _emberRestless.default.attr;
  var get = Ember.get;
  var OPTIONS_LEVELS = exports.OPTIONS_LEVELS = {
    COVERED_AND_CASH_SECURED: 'Covered And Cash Secured',
    DEFINED_RISK: 'Defined Risk Spreads',
    DEFINED_RISK_PLUS_NAKED: 'Defined Risk Spreads Plus Naked',
    NO_RESTRICTIONS: 'No Restrictions'

    // from most to least restrictive
  };var RANKING = [OPTIONS_LEVELS.COVERED_AND_CASH_SECURED, OPTIONS_LEVELS.DEFINED_RISK, OPTIONS_LEVELS.DEFINED_RISK_PLUS_NAKED, OPTIONS_LEVELS.NO_RESTRICTIONS];

  exports.default = Model.extend((_dec = (0, _object.computed)('optionsLevel'), (_obj = {

    optionsLevel: attr('string', { defaultValue: OPTIONS_LEVELS.NO_RESTRICTIONS }),

    rank: function rank(optionsLevel) {
      return RANKING.indexOf(optionsLevel);
    },
    isDisabled: function isDisabled(availableOptionsLevel) {
      return get(this, 'rank') > get(availableOptionsLevel, 'rank');
    }
  }, (_applyDecoratedDescriptor(_obj, 'rank', [_dec], Object.getOwnPropertyDescriptor(_obj, 'rank'), _obj)), _obj))).reopenClass({
    resourceName: 'options-level'
  });
});