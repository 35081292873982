define('twapi/models/account-transaction', ['exports', 'ember-restless', 'twapi/utils/cost', 'ember-decorators/object'], function (exports, _emberRestless, _cost, _object) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _desc, _value, _obj;

  exports.default = _emberRestless.default.Model.extend((_dec = (0, _object.computed)('regulatoryFeesCost', 'clearingFeesCost', 'proprietaryIndexOptionFeesCost'), (_obj = {
    accountNumber: _emberRestless.default.attr('string'),
    executedAt: _emberRestless.default.attr('momentDateTime'),
    transactionType: _emberRestless.default.attr('string'),
    transactionSubType: _emberRestless.default.attr('string'),
    description: _emberRestless.default.attr('string'),
    value: _emberRestless.default.attr('number'),
    valueEffect: _emberRestless.default.attr('string'),
    regulatoryFees: _emberRestless.default.attr('number'),
    regulatoryFeesEffect: _emberRestless.default.attr('string'),
    clearingFees: _emberRestless.default.attr('number'),
    clearingFeesEffect: _emberRestless.default.attr('string'),
    proprietaryIndexOptionFees: _emberRestless.default.attr('number'),
    proprietaryIndexOptionFeesEffect: _emberRestless.default.attr('string'),
    otherCharge: _emberRestless.default.attr('number'),
    otherChargeEffect: _emberRestless.default.attr('string'),
    otherChargeDescription: _emberRestless.default.attr('string'),

    underlyingSymbol: _emberRestless.default.attr('string'),
    instrumentType: _emberRestless.default.attr('string'),
    symbol: _emberRestless.default.attr('string'),
    action: _emberRestless.default.attr('string'),
    commission: _emberRestless.default.attr('number'),
    commissionEffect: _emberRestless.default.attr('string'),
    quantity: _emberRestless.default.attr('number'),
    price: _emberRestless.default.attr('number'),

    valueCost: _cost.default.computed('value', 'valueEffect'),
    otherChargeCost: _cost.default.computed('otherCharge', 'otherChargeEffect'),
    commissionCost: _cost.default.computed('commission', 'commissionEffect'),

    regulatoryFeesCost: _cost.default.computed('regulatoryFees', 'regulatoryFeesEffect'),
    clearingFeesCost: _cost.default.computed('clearingFees', 'clearingFeesEffect'),
    proprietaryIndexOptionFeesCost: _cost.default.computed('proprietaryIndexOptionFees', 'proprietaryIndexOptionFeesEffect'),

    totalFeesCost: function totalFeesCost(regulatoryFeesCost, clearingFeesCost, proprietaryIndexOptionFeesCost) {
      var transactionFees = _cost.default.zero();

      if (clearingFeesCost) {
        transactionFees = transactionFees.adjust(clearingFeesCost);
      }
      if (regulatoryFeesCost) {
        transactionFees = transactionFees.adjust(regulatoryFeesCost);
      }
      if (proprietaryIndexOptionFeesCost) {
        transactionFees = transactionFees.adjust(proprietaryIndexOptionFeesCost);
      }
      return transactionFees;
    }
  }, (_applyDecoratedDescriptor(_obj, 'totalFeesCost', [_dec], Object.getOwnPropertyDescriptor(_obj, 'totalFeesCost'), _obj)), _obj)));
});