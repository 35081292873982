define('twapi/utils/recaptcha', ['exports', 'raven'], function (exports, _raven) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.ACTIONS = undefined;
  exports.withRecaptchaToken = withRecaptchaToken;


  /* global grecaptcha */

  var ACTIONS = exports.ACTIONS = {
    REGISTER: 'register'
  };

  function withRecaptchaToken(action) {
    return grecaptcha.execute(window._recaptchaClientId, { action: action }).catch(function (error) {
      _raven.default.captureException('Error executing recaptcha', { extra: { error: error } });
    });
  }
});