define('twapi/models/expiration-monitoring-eligibility', ['exports', 'ember-restless'], function (exports, _emberRestless) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.STATUSES = undefined;
  var Model = _emberRestless.default.Model,
      attr = _emberRestless.default.attr;
  var STATUSES = exports.STATUSES = {
    ACTIVE: 'Active',
    AVAILABLE: 'Available',
    INACTIVE: 'Inactive',
    UNAVAILABLE: 'Unavailable'
  };

  exports.default = Model.extend({
    accountNumber: attr('string'),
    status: attr('string')
  }).reopenClass({
    resourceName: 'expiration-monitoring-eligibility'
  });
});