define('twapi/models/user-two-factor-status', ['exports', 'ember-restless'], function (exports, _emberRestless) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberRestless.default.Model,
      attr = _emberRestless.default.attr;
  exports.default = Model.extend({
    isTwoFactorOptOut: attr('boolean')
  }).reopenClass({
    resourceName: 'user-two-factor-status'
  });
});