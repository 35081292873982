define('twapi/models/pdt-reset-request-eligibility', ['exports', 'ember-restless'], function (exports, _emberRestless) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.STATUSES = undefined;
  var Model = _emberRestless.default.Model,
      attr = _emberRestless.default.attr;
  var STATUSES = exports.STATUSES = {
    ELIGIBLE: 'Eligible',
    NOT_CLASSIFIED_PDT: 'Not Classified PDT',
    PENDING: 'Pending',
    PENDING_JOINT_AGREEMENT: 'Pending Joint Agreement',
    UNAVAILABLE: 'Unavailable'
  };

  exports.default = Model.extend({
    status: attr('string'),
    lastResetDate: attr('momentDateTime')
  }).reopenClass({
    resourceName: 'pdt-reset-request-eligibility'
  });
});