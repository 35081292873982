define('twapi/components/twapi-document-upload', ['exports', 'ember-uploader', 'twapi/templates/components/twapi-document-upload', 'twapi/utils/computed', 'ember-decorators/object'], function (exports, _emberUploader, _twapiDocumentUpload, _computed, _object) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _desc, _value, _obj;

  var Component = Ember.Component,
      readOnly = Ember.computed.readOnly,
      get = Ember.get,
      service = Ember.inject.service,
      isEmpty = Ember.isEmpty,
      isPresent = Ember.isPresent,
      set = Ember.set;
  exports.default = Component.extend((_dec = (0, _object.computed)('name', 'elementId'), (_obj = {
    session: service(),

    layout: _twapiDocumentUpload.default,

    classNames: 'twapi-document-upload',

    url: 'customers/me/documents/async',

    slug: null,

    inProgress: false,

    isError: false,

    isDone: false,

    config: _computed.default.registry.resolve('config:environment'),

    sessionToken: readOnly('session.response.sessionToken'),

    accept: null,

    name: null,

    showUploadedImage: false,

    documentUploadId: function documentUploadId(name, elementId) {
      return name + '-' + elementId;
    },


    actions: {
      filesDidChange: function filesDidChange(files) {
        var file = files[0];
        var slug = get(this, 'slug');
        var uploader = _emberUploader.default.Uploader.create({
          ajaxSettings: {
            headers: {
              'Authorization': get(this, 'sessionToken')
            }
          },
          url: '' + this.get('config.twapi.baseURL') + get(this, 'url') + '/' + slug
        });

        set(this, 'showUploadedImage', false);
        set(this, 'uploadedImageSrc', null);

        if (!isEmpty(files)) {
          uploader.upload(files[0]);
          uploader.on('progress', this._progress.bind(this));
          uploader.on('didUpload', this._didUpload.bind(this, file));
          uploader.on('didError', this._didError.bind(this));
        }

        // NOTE: Clear out input for file to allow a re-upload in case of error
        this.$('input[type=file]').val(null);
      }
    },

    _progress: function _progress(e) {
      var onProgress = get(this, 'progress');
      if (isPresent(onProgress)) {
        onProgress();
      }

      if (get(this, 'isDestroyed')) {
        return;
      }

      set(this, 'percent', Math.floor(get(e, 'percent')));
      set(this, 'inProgress', true);
      set(this, 'isError', false);
      set(this, 'isDone', false);
    },
    _didUpload: function _didUpload(file) {
      var _this = this;

      var fileName = file.name;
      var onDidUpload = get(this, 'didUpload');
      if (isPresent(onDidUpload)) {
        onDidUpload();
      }

      if (get(this, 'isDestroyed')) {
        return;
      }

      if (file.type.startsWith('image/')) {
        var reader = new FileReader();
        reader.onloadend = function () {
          set(_this, 'uploadedImageSrc', reader.result);
          set(_this, 'showUploadedImage', true);
        };
        reader.readAsDataURL(file);
      }

      set(this, 'fileName', fileName);
      set(this, 'inProgress', false);
      set(this, 'isDone', true);
      set(this, 'isError', false);
    },
    _didError: function _didError() {
      if (get(this, 'isDestroyed')) {
        return;
      }

      set(this, 'inProgress', false);
      set(this, 'isDone', false);
      set(this, 'isError', true);
    }
  }, (_applyDecoratedDescriptor(_obj, 'documentUploadId', [_dec], Object.getOwnPropertyDescriptor(_obj, 'documentUploadId'), _obj)), _obj)));
});