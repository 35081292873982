define('twapi/components/twapi-domestic-address-question', ['exports', 'twapi/utils/validation', 'twapi/utils/country'], function (exports, _validation, _country) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      oneWay = Ember.computed.oneWay,
      get = Ember.get,
      set = Ember.set;
  exports.default = Component.extend({
    classNames: 'twapi-domestic-address-question',

    address: null,

    hasDefaultSelection: true,

    isUsResident: null,

    didUpdateResidency: function didUpdateResidency() {},

    init: function init() {
      this._super.apply(this, arguments);

      if (get(this, 'hasDefaultSelection')) {
        set(this, 'isUsResident', get(this, 'address.isDomestic'));
      }
    },


    actions: {
      updateCountry: function updateCountry() {
        var isUsResident = get(this, 'isUsResident');
        var address = get(this, 'address');
        var isDomestic = get(address, 'isDomestic');

        if (isUsResident) {
          set(address, 'country', _country.USA_COUNTRY_CODE);

          // NOTE: [HR] checking isDomestic to prevent clearing the selected country
        } else if (isDomestic) {
          set(address, 'country', null);
        }

        this.didUpdateResidency();
      }
    }
  }).reopen(_validation.build({
    isUsResident: { presence: true, inclusion: { in: [false, true] } }
  }));
});