define('twapi/instance-initializers/castle-publishable-key', ['exports', '@castleio/castle-js'], function (exports, _castleJs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(applicationInstance) {
    if (applicationInstance.environment === 'production') {
      _castleJs.configure({ pk: 'pk_qhJsz8XyKrxgyQdFZnDqTiZ9qDMt9E3p' });
    } else {
      _castleJs.configure({ pk: 'pk_ZBt5mqQMNqhT5nEbAXnv2tuZpBHUbHn9' });
    }
  }
});