define('twapi/models/account-authority', ['exports', 'ember-restless'], function (exports, _emberRestless) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberRestless.default.Model.extend({
    authorityLevel: _emberRestless.default.attr('string'),
    account: _emberRestless.default.belongsTo('account'),
    isOwned: Ember.computed.equal('authorityLevel', 'owner')
  }).reopenClass({
    resourceName: 'account-authority'
  });
});