define('twapi/utils/regex/constants', ['exports', 'twapi/utils/regex/combinators'], function (exports, _combinators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.US_CURRENCY = exports.BANK_NUMBER = exports.BANK_NAME = exports.BANK_CITY = exports.SWIFT_CODE = exports.ROUTING_NUMBER = exports.ITIN = exports.EIN = exports.SSN = exports.FULL_NAME = exports.USERNAME = exports.EMAIL = exports.DOMAIN_NAME = exports.IP_ADDRESS = exports.PHONE_NUMBER = exports.ZIP_CODE = undefined;

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  /* eslint operator-linebreak: 0 */

  var ZIP_CODE = exports.ZIP_CODE = new RegExp('' + '[0-9]{5}' // standard five numbers
  );

  /* http://docs.aws.amazon.com/sns/latest/dg/sms_publish-to-phone.html */
  var PHONE_NUMBER = exports.PHONE_NUMBER = new RegExp('' + '\\+?([0-9]{1,2})?[- ]?' // optional country code prefix (1-)
  + '\\(?([2-9][0-9]{2})\\)?' // area code with optional parens
  + '[- ]?' // separator
  + '([0-9]{3})' // exchange code
  + '[- ]?' // separator
  + '([0-9]{4,7})' // subscriber number
  );

  var IP_ADDRESS = exports.IP_ADDRESS = new RegExp('' + '([0-9]{1,3})' + '[.]' + '([0-9]{1,3})' + '[.]' + '([0-9]{1,3})' + '[.]' + '([0-9]{1,3})');

  var DOMAIN_NAME = exports.DOMAIN_NAME = new RegExp('' + '([-a-zA-Z0-9]+[.])+[a-zA-Z]{2,}');

  var EMAIL = exports.EMAIL = new RegExp('' + _combinators.or('[^.<>()[\\],;:\\s@"]+(\\.[^.<>()[\\],;:\\s@"]+)*', '".+"') + '@' + _combinators.or('\\[' + IP_ADDRESS.source + ']', DOMAIN_NAME.source));

  var USERNAME = exports.USERNAME = new RegExp('' + '^[a-z0-9]+$');

  var FULL_NAME = exports.FULL_NAME = new RegExp('' + '^[A-Za-z@\' -]+$' // allow letters, @, apostrophes, spaces, hyphens
  );

  var SSN = exports.SSN = new RegExp('' + _combinators.or( // Cannot be 000, 666, 9xx
  '[1-578][0-9]{2}', '0[1-9]{2}', '00[1-9]', '0[1-9]0', '6[0-57-9]{2}', '66[0-57-9]', '6[0-57-9]6') + '-?' + _combinators.or( // Cannot be 00
  '[1-9][0-9]', '[0-9][1-9]') + '-?' + _combinators.or( // Cannot be 0000
  '[1-9][0-9]{3}', '[0-9][1-9][0-9]{2}', '[0-9]{2}[1-9][0-9]', '[0-9]{3}[1-9]'));

  // https://www.irs.gov/Businesses/Small-Businesses-&-Self-Employed/How-EINs-are-Assigned-and-Valid-EIN-Prefixes
  var EIN_PREFIXES = '\n  10 12\n  60 67\n  50 53\n  01 02 03 04 05 06 11 13 14 16 21 22 23 25 34 51 52 54 55 56 57 58 59 65\n  30 32 35 36 37 38 61\n  15 24\n  40 44\n  94 95\n  80 90\n  33 39 41 42 43 46 48 62 63 64 66 68 71 72 73 74 75 76 77 82 83 84 85 86 87 88 91 92 93 98 99\n  20 26 27 45 46 47 81\n  31\n'.split(/\s+/).filter(function (s) {
    return s;
  });

  var EIN = exports.EIN = new RegExp('' + _combinators.or.apply(_combinators, _toConsumableArray(EIN_PREFIXES)) + '-?' + '[0-9]{7}');

  var ITIN = exports.ITIN = new RegExp('' + '9[0-9]{2}' + '-?' + _combinators.or('7[0-9]', '8[0-8]', '9[0-24-9]') + '-?' + '[0-9]{4}');

  var ROUTING_NUMBER = exports.ROUTING_NUMBER = new RegExp('' + '[0-9]{9}');

  var SWIFT_CODE = exports.SWIFT_CODE = new RegExp('' + '[A-Za-z]{6}[A-Za-z0-9]{2}([A-Za-z0-9]{3,4})?');

  var BANK_CITY = exports.BANK_CITY = new RegExp(/^[0-9a-zA-Z &/#(),.-]*$/); // NOTE: [KT] matches Apex's regex of allowed characters
  var BANK_NAME = exports.BANK_NAME = new RegExp(/^[0-9a-zA-Z &/#(),.-]*$/); // NOTE: [KT] matches Apex's regex of allowed characters
  var BANK_NUMBER = exports.BANK_NUMBER = new RegExp(/[\s\S]*\S[\s\S]*/);

  var US_CURRENCY = exports.US_CURRENCY = new RegExp(/^\$?((\d{1,3}(,\d{3})*|(\d+)))?(\.\d{1,2})?$/);
});