define('twapi/components/beneficiary/twapi-beneficiary-row', ['exports', 'ember-decorators/object'], function (exports, _object) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _dec2, _desc, _value, _obj;

  exports.default = Ember.Component.extend((_dec = (0, _object.computed)('type', 'beneficiaries.@each.beneficiaryType'), _dec2 = (0, _object.computed)('twapiBeneficiaries.currentBeneficiary', 'beneficiary'), (_obj = {
    classNames: ['row', 'twapi-beneficiary-row'],
    twapiBeneficiaries: Ember.inject.service(),
    beneficiaries: Ember.computed.alias('twapiBeneficiaries.beneficiaries'),

    hasOne: function hasOne(type) {
      return this.get('twapiBeneficiaries').getBeneficiaries(type).get('length') === 1;
    },
    isBeingEdited: function isBeingEdited(currentBeneficiary, beneficiary) {
      return Ember.isEqual(currentBeneficiary, beneficiary);
    },


    actions: {
      onPercentChange: function onPercentChange() {
        this.get('twapiBeneficiaries').setPercentChange(this.get('type'));
      },
      removeBeneficiary: function removeBeneficiary(beneficiary) {
        this.get('twapiBeneficiaries').removeBeneficiary(beneficiary);
        this.get('removeBeneficiary')();
      },
      editBeneficiary: function editBeneficiary(beneficiary) {
        this.get('twapiBeneficiaries').editBeneficiary(beneficiary);
        this.get('editBeneficiary')();
      }
    }
  }, (_applyDecoratedDescriptor(_obj, 'hasOne', [_dec], Object.getOwnPropertyDescriptor(_obj, 'hasOne'), _obj), _applyDecoratedDescriptor(_obj, 'isBeingEdited', [_dec2], Object.getOwnPropertyDescriptor(_obj, 'isBeingEdited'), _obj)), _obj)));
});