define('twapi/models/recurring-external-transaction', ['exports', 'ember-restless', 'ember-decorators/object'], function (exports, _emberRestless, _object) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.RECURRING_DEPOSITS_TRANSFER_STATES = exports.ACH_DEPOSIT_TRANSACTION_FREQUENCIES = exports.ACH_RECURRING_TRANSACTION_PERIODS = undefined;
  exports.getFrequency = getFrequency;
  exports.getPeriodFromFrequency = getPeriodFromFrequency;
  exports.getIntervalFromFrequency = getIntervalFromFrequency;

  var _FREQUENCY_DATA;

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  var equal = Ember.computed.equal,
      get = Ember.get;
  var Model = _emberRestless.default.Model,
      attr = _emberRestless.default.attr,
      belongsTo = _emberRestless.default.belongsTo;
  var ACH_RECURRING_TRANSACTION_PERIODS = exports.ACH_RECURRING_TRANSACTION_PERIODS = {
    DAY_OF_MONTH: 'Day of Month',
    DAYS: 'Days',
    ONE_TIME_TRANSACTION: 'One Time Transaction',
    WEEKS: 'Weeks'
  };

  var ACH_DEPOSIT_TRANSACTION_FREQUENCIES = exports.ACH_DEPOSIT_TRANSACTION_FREQUENCIES = {
    ONE_TIME: 'One Time',
    DAILY: 'Daily',
    WEEKLY: 'Weekly',
    BIWEEKLY: 'Biweekly',
    MONTHLY: 'Monthly'
  };

  var RECURRING_DEPOSITS_TRANSFER_STATES = exports.RECURRING_DEPOSITS_TRANSFER_STATES = {
    ACTIVE: 'Active',
    CANCELED: 'Canceled',
    COMPLETE: 'Complete',
    PRE_SEND: 'Pre-Send',
    SEND_FAILED: 'Send Failed'
  };

  var FREQUENCY_DATA = (_FREQUENCY_DATA = {}, _defineProperty(_FREQUENCY_DATA, ACH_DEPOSIT_TRANSACTION_FREQUENCIES.ONE_TIME, {
    period: null,
    interval: 1
  }), _defineProperty(_FREQUENCY_DATA, ACH_DEPOSIT_TRANSACTION_FREQUENCIES.DAILY, {
    period: ACH_RECURRING_TRANSACTION_PERIODS.DAYS,
    interval: 1
  }), _defineProperty(_FREQUENCY_DATA, ACH_DEPOSIT_TRANSACTION_FREQUENCIES.WEEKLY, {
    period: ACH_RECURRING_TRANSACTION_PERIODS.WEEKS,
    interval: 1
  }), _defineProperty(_FREQUENCY_DATA, ACH_DEPOSIT_TRANSACTION_FREQUENCIES.BIWEEKLY, {
    period: ACH_RECURRING_TRANSACTION_PERIODS.WEEKS,
    interval: 2
  }), _defineProperty(_FREQUENCY_DATA, ACH_DEPOSIT_TRANSACTION_FREQUENCIES.MONTHLY, {
    period: ACH_RECURRING_TRANSACTION_PERIODS.DAY_OF_MONTH,
    interval: 1
  }), _FREQUENCY_DATA);

  function getFrequency(period, interval) {
    return Object.keys(FREQUENCY_DATA).find(function (frequency) {
      var frequencyData = get(FREQUENCY_DATA, frequency);
      return get(frequencyData, 'period') === period && get(frequencyData, 'interval') === interval;
    });
  }

  function getPeriodFromFrequency(frequency) {
    var frequencyData = get(FREQUENCY_DATA, frequency);
    return get(frequencyData, 'period');
  }

  function getIntervalFromFrequency(frequency) {
    var frequencyData = get(FREQUENCY_DATA, frequency);
    return get(frequencyData, 'interval');
  }

  exports.default = Model.extend({
    direction: attr('string'),

    accountNumber: attr('string'),

    createdAt: attr('momentDateTime', { readOnly: true }),

    state: attr('string'),

    amount: attr('number'),

    reason: attr('string'),

    isCancelable: attr('boolean'),

    achRelationshipId: attr('number'),

    achRelationship: belongsTo('ach-relationship'),

    iraDetail: belongsTo('ira-detail'),

    startDate: attr('momentDateTime'),

    period: attr('string'),

    interval: attr('number'),

    isActive: equal('state', RECURRING_DEPOSITS_TRANSFER_STATES.ACTIVE),

    isComplete: equal('state', RECURRING_DEPOSITS_TRANSFER_STATES.COMPLETE),

    isCanceled: equal('state', RECURRING_DEPOSITS_TRANSFER_STATES.CANCELED),

    isPresend: equal('state', RECURRING_DEPOSITS_TRANSFER_STATES.PRE_SEND),

    isSendFailed: equal('state', RECURRING_DEPOSITS_TRANSFER_STATES.SEND_FAILED)
  });
});