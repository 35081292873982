define('twapi/services/twapi-gtm-events', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  exports.default = Service.extend({
    createLoginEvent: function createLoginEvent(userId) {
      window.dataLayer.push({
        'event': 'customer_login',
        'tasty-user-id': userId
      });
    },
    createLogoutEvent: function createLogoutEvent(userId) {
      window.dataLayer.push({
        'event': 'customer_logout',
        'tasty-user-id': userId
      });
    }
  });
});