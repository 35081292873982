define('twapi/components/amb-form-international-phone-number', ['exports', 'lodash', 'libphonenumber-js', 'twapi/templates/components/amb-form-international-phone-number'], function (exports, _lodash, _libphonenumberJs, _ambFormInternationalPhoneNumber) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      get = Ember.get,
      set = Ember.set,
      isBlank = Ember.isBlank,
      isNone = Ember.isNone,
      next = Ember.run.next;


  var INTERNATIONAL_FORMAT = 'E.164';

  function sanitize(input) {
    if (input === '+') {
      return input;
    }

    var value = _lodash.default.trim(input).replace(/\D/g, '');
    if (isBlank(value)) {
      return value;
    }

    return '+' + value;
  }

  function internationalize(phoneNumber) {
    return (0, _libphonenumberJs.formatNumber)(get(phoneNumber, 'phone'), get(phoneNumber, 'country'), INTERNATIONAL_FORMAT);
  }

  function parse(rawValue) {
    return (0, _libphonenumberJs.parseNumber)(rawValue);
  }

  exports.default = Component.extend({
    layout: _ambFormInternationalPhoneNumber.default,

    value: null,

    maskedValue: null,

    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this._super.apply(this, arguments);

      var rawValue = sanitize(get(this, 'value'));

      set(this, 'maskedValue', rawValue);

      next(function () {
        _this._updateValue();
      });
    },


    actions: {
      onUpdate: function onUpdate() {
        this._updateValue();
      }
    },

    _updateValue: function _updateValue() {
      var rawValue = sanitize(get(this, 'maskedValue'));
      var phoneNumber = parse(rawValue);

      if (isNone(phoneNumber.phone)) {
        set(this, 'value', rawValue);
      } else {
        set(this, 'value', internationalize(phoneNumber));
      }

      set(this, 'maskedValue', new _libphonenumberJs.AsYouType().input(rawValue));

      this.sendAction && this.sendAction('action', get(this, 'value'));
    }
  });
});