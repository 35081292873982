define('twapi/proxies/recurring-external-transaction', ['exports', 'twapi/mixins/object-state-poller'], function (exports, _objectStatePoller) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var ObjectProxy = Ember.ObjectProxy,
      bool = Ember.computed.bool,
      get = Ember.get;
  exports.default = ObjectProxy.extend(_objectStatePoller.default, {
    endpoints: null,

    shouldPoll: bool('isPresend'),

    fetchSelf: function fetchSelf() {
      get(this, 'endpoints').getRecurringDeposit(this, {
        accountNumber: get(this, 'accountNumber'),
        id: get(this, 'id')
      });
    }
  });
});