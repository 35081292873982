define('twapi/components/twapi-hoverable-popup', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    isHovered: false,

    mouseEnter: function mouseEnter() {
      this.set('isHovered', true);
    },

    mouseLeave: function mouseLeave() {
      this.set('isHovered', false);
    }
  });
});