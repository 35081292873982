define('twapi/models/futures-agreement-record', ['exports', 'ember-restless', 'twapi/utils/validation'], function (exports, _emberRestless, _validation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberRestless.default.Model,
      attr = _emberRestless.default.attr;
  exports.default = Model.extend({
    isRegistrationExempt: attr('boolean'),
    registrationExemption: attr('string'),

    isRegistered: attr('boolean'),
    registrationMember: attr('string'),

    isSoliciting: attr('boolean'),
    isNfaPool: attr('boolean'),

    hasFuturesIndustryAffiliation: attr('boolean'),
    futuresIndustryFirm: attr('string')
  }).reopen(_validation.build({
    isRegistrationExempt: { presence: true },
    registrationExemption: { presence: { if: 'isRegistrationExempt' } },

    isRegistered: { presence: true },
    registrationMember: { presence: { if: 'isRegistered' } },

    isSoliciting: { presence: true },
    isNfaPool: { presence: { if: 'isSoliciting' } },

    hasFuturesIndustryAffiliation: { presence: true },
    futuresIndustryFirm: { presence: { if: 'hasFuturesIndustryAffiliation' } }
  })).reopenClass({
    resourceName: 'futures-agreement-record'
  });
});