define('twapi/models/exchange-affiliation', ['exports', 'ember-restless'], function (exports, _emberRestless) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberRestless.default.Model.extend({
    customerId: _emberRestless.default.attr('number'),
    exchange: _emberRestless.default.attr('string'),
    identityToken: _emberRestless.default.attr('string'),
    exchangeExternalId: _emberRestless.default.attr('string'),
    status: _emberRestless.default.attr('string'),
    startDate: _emberRestless.default.attr('momentDateTime'),
    endDate: _emberRestless.default.attr('momentDateTime')
  }).reopenClass({
    resourceName: 'exchange-affiliation'
  });
});