define('twapi/validators/entity-tax-number', ['exports', 'ember-cp-validations/validators/base', 'twapi/utils/regex'], function (exports, _base, _regex) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.TYPES = undefined;
  var get = Ember.get,
      isEmpty = Ember.isEmpty;
  var TYPES = exports.TYPES = {
    TRUST: 'trust',
    ENTITY: 'entity'
  };

  var EntityTaxNumber = _base.default.extend({
    validate: function validate(value, options, model, attribute) {
      var type = get(model, 'isTrust') ? TYPES.TRUST : TYPES.ENTITY;

      if (isEmpty(value)) {
        if (options.allowBlank) {
          return true;
        } else {
          return this.createErrorMessage(type, undefined, options);
        }
      }

      if (_regex.exact.SSN.test(value)) {
        return true;
      } else if (_regex.exact.EIN.test(value)) {
        return true;
      }

      return this.createErrorMessage(type, undefined, options);
    }
  });

  exports.default = EntityTaxNumber;
});