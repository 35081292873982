define('twapi/models/entity', ['exports', 'lodash', 'twapi/models/address', 'ember-decorators/object', 'twapi/models/entity-suitability', 'ember-restless', 'twapi/utils/regex', 'twapi/utils/validation', 'twapi/utils/sanitize', 'twapi/validators/entity-tax-number'], function (exports, _lodash, _address, _object, _entitySuitability, _emberRestless, _regex, _validation, _sanitize, _entityTaxNumber) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.JOB_TITLES = exports.RELATIONSHIP_TYPES = exports.ENTITY_TYPES = undefined;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _dec2, _desc, _value, _obj;

  var Model = _emberRestless.default.Model,
      attr = _emberRestless.default.attr,
      belongsTo = _emberRestless.default.belongsTo,
      hasMany = _emberRestless.default.hasMany;
  var _Ember$computed = Ember.computed,
      equal = _Ember$computed.equal,
      not = _Ember$computed.not,
      or = _Ember$computed.or,
      get = Ember.get,
      isPresent = Ember.isPresent,
      set = Ember.set;


  var BUSINESS_NATURES = {
    AGRICULTURE: 'Agriculture, Forestry, and Fishing',
    CONSTRUCTION: 'Construction',
    FINANCE: 'Finance, Insurance, or Real Estate',
    GENERAL_SERVICES: 'General Services',
    MANUFACTURING: 'Manufacturing',
    MINING: 'Mining',
    PUBLIC_ADMINISTRATION: 'Public Administration',
    RETAIL_TRADE: 'Retail Trade',
    TRANSPORTATION_OR_UTILITIES: 'Transportation or Utilities',
    WHOLESALE_TRADE: 'Wholesale Trade'
  };

  var LEGAL_EXEMPTION_REASONS = {
    CFTC_REGISTERED_ENTITY: 'CFTC Registered Entity',
    ISSUER_OF_SECURITIES: 'Issuer of Securities',
    LISTED_ENTITY: 'Listed Entity',
    PUBLIC_ACCOUNTING_FIRM: 'Public Accounting Firm',
    REGISTERED_EXCHANGE_OR_CLEARING_AGENCY: 'Registered Exchange or Clearing Agency',
    REGISTERED_INVESTMENT_ADVISER: 'Registered Investment Adviser',
    REGISTERED_INVESTMENT_COMPANY: 'Registered Investment Company',
    REGULATED_INSURANCE_COMPANY: 'Regulated Insurance Company',
    SEC_REGISTERED_ENTITY: 'SEC Registered Entity',
    USA_DEPARTMENT_OR_AGENCY: 'USA Department or Agency',
    NONE: 'None'
  };

  var ENTITY_TYPES = exports.ENTITY_TYPES = {
    S_CORP: 'S. Corp',
    C_CORP: 'C. Corp',
    LLC: 'LLC',
    PARTNERSHIP: 'Partnership',
    TRUST: 'Trust'
  };

  var RELATIONSHIP_TYPES = exports.RELATIONSHIP_TYPES = {
    OWNER: 'Owner',
    MANAGER: 'Managing Director/Officer',
    EMPLOYEE: 'Employee'
  };

  var JOB_TITLES = exports.JOB_TITLES = {
    CEO: 'Chief Executive Officer',
    CFO: 'Chief Financial Officer',
    COO: 'Chief Operating Officer',
    GENERAL_PARTNER: 'General Partner',
    MANAGING_MEMBER: 'Managing Member',
    PRESIDENT: 'President',
    TREASURER: 'Treasurer',
    VICE_PRESIDENT: 'Vice President'
  };

  exports.default = Model.extend((_dec = (0, _object.computed)('entityType'), _dec2 = (0, _object.computed)('taxNumber'), (_obj = {
    businessNature: attr('string'),

    entityType: attr('string'),

    legalExemptionReason: attr('string'),

    foreignInstitution: attr('string'),

    grantorBirthDate: attr('string'),

    grantorEmail: attr('string'),

    grantorFirstName: attr('string'),

    grantorMiddleName: attr('string'),

    grantorLastName: attr('string'),

    grantorTaxNumber: attr('string'),

    hasForeignBankAffiliation: attr('boolean'),

    hasForeignInstitutionAffiliation: attr('boolean'),

    hasBeneficialOwners: attr('boolean'),

    hasLegalExemption: attr('boolean'),

    isDomestic: attr('boolean', { defaultValue: true }),

    legalName: attr('string'),

    phoneNumber: attr('string'),

    taxNumber: attr('string'),

    address: belongsTo('address', { defaultValue: function defaultValue() {
        return _address.default.create();
      } }),

    entityBeneficialOwners: hasMany('beneficial-owner'),

    entitySuitability: belongsTo('entity-suitability', { defaultValue: function defaultValue() {
        return _entitySuitability.default.create();
      } }),

    isCCorporation: equal('entityType', ENTITY_TYPES.C_CORP),

    isSCorporation: equal('entityType', ENTITY_TYPES.S_CORP),

    isCorporateAccount: or('isCCorporation', 'isSCorporation'),

    isLlc: equal('entityType', ENTITY_TYPES.LLC),

    isPartnership: equal('entityType', ENTITY_TYPES.PARTNERSHIP),

    isTrust: equal('entityType', ENTITY_TYPES.TRUST),

    entityTypeKey: function entityTypeKey(entityType) {
      return _lodash.default.findKey(ENTITY_TYPES, function (value) {
        return value === entityType;
      });
    },
    sanitizedTaxNumber: function sanitizedTaxNumber(taxNumber) {
      return _sanitize.taxNumber(taxNumber);
    },
    serialize: function serialize() {
      var serialized = this._super.apply(this, arguments);

      if (isPresent(get(this, 'id'))) {
        serialized = _lodash.default.omit(serialized, 'tax-number');
      }

      var isTrust = get(this, 'isTrust');
      var hasLegalExemption = get(this, 'legalExemptionReason') !== LEGAL_EXEMPTION_REASONS.NONE && !isTrust;

      if (!hasLegalExemption) {
        serialized = _lodash.default.omit(serialized, 'legal-exemption-reason');
      }
      set(serialized, 'has-legal-exemption', hasLegalExemption);

      return serialized;
    }
  }, (_applyDecoratedDescriptor(_obj, 'entityTypeKey', [_dec], Object.getOwnPropertyDescriptor(_obj, 'entityTypeKey'), _obj), _applyDecoratedDescriptor(_obj, 'sanitizedTaxNumber', [_dec2], Object.getOwnPropertyDescriptor(_obj, 'sanitizedTaxNumber'), _obj)), _obj))).reopen(_validation.build({
    businessNature: {
      presence: true,
      inclusion: { in: Object.values(BUSINESS_NATURES) }
    },

    entityType: { inclusion: { in: ENTITY_TYPES } },

    foreignInstitution: {
      presence: {
        presence: true,
        disabled: not('model.hasForeignInstitutionAffiliation')
      }
    },

    grantorBirthDate: {
      presence: {
        presence: true,
        disabled: not('model.isTrust')
      }
    },

    grantorEmail: {
      presence: {
        presence: true,
        disabled: not('model.isTrust')
      },
      format: { type: 'email' }
    },

    grantorFirstName: {
      presence: {
        presence: true,
        disabled: not('model.isTrust')
      }
    },

    grantorLastName: {
      presence: {
        presence: true,
        disabled: not('model.isTrust')
      }
    },

    grantorTaxNumber: {
      presence: {
        presence: true,
        disabled: not('model.isTrust')
      },
      format: { regex: _regex.exact.SSN }
    },

    hasBeneficialOwners: { presence: true },

    hasForeignBankAffiliation: {
      presence: {
        presence: true
      }
    },

    hasForeignInstitutionAffiliation: {
      presence: {
        presence: true
      }
    },

    legalExemptionReason: {
      presence: true,
      inclusion: { in: Object.values(LEGAL_EXEMPTION_REASONS) }
    },

    legalName: { presence: true },

    phoneNumber: {
      format: { type: 'phone', regex: _regex.exact.PHONE_NUMBER }
    },

    taxNumber: {
      presence: true,
      'entity-tax-number': {
        message: function message(type, options, value, context) {
          if (type === _entityTaxNumber.TYPES.TRUST) {
            return 'Please enter a Social Security Number XXX-XX-XXXX or Tax ID XX-XXXXXXX';
          }

          return 'Please enter the Entity\'s 9 digit Tax ID Number';
        }
      }
    }
  })).reopenClass({
    resourceName: 'entity'
  });
});