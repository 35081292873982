define('twapi/models/account-balances', ['exports', 'ember-restless', 'twapi/utils/computed'], function (exports, _emberRestless, _computed) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberRestless.default.Model,
      attr = _emberRestless.default.attr;
  var _Ember$computed = Ember.computed,
      alias = _Ember$computed.alias,
      gte = _Ember$computed.gte;
  exports.default = Model.extend({
    accountNumber: attr('string'),
    snapshotAt: attr('momentDateTime'),

    cashBalance: attr('number'),
    moneyMarketBalance: attr('number'),

    longEquityValue: attr('number'),
    shortEquityValue: attr('number'),

    longDerivativeValue: attr('number'),
    shortDerivativeValue: attr('number'),

    longFuturesValue: attr('number'),
    shortFuturesValue: attr('number'),

    longMargineableValue: attr('number'),
    longMarginableValue: alias('longMargineableValue'), // TODO: correct attribute when backend is fixed
    shortMargineableValue: attr('number'),
    shortMarginableValue: alias('shortMargineableValue'),

    marginEquity: attr('number'),

    equityBuyingPower: attr('number'),
    derivativeBuyingPower: attr('number'),
    futuresBuyingPower: attr('number'),
    dayTradingBuyingPower: attr('number'),

    availableTradingFunds: attr('number'),
    maintenanceRequirement: attr('number'),
    maintenanceCallValue: attr('number'),

    regTCallValue: attr('number'),
    dayTradingCallValue: attr('number'),
    dayEquityCallValue: attr('number'),
    netLiquidatingValue: attr('number'),
    cashAvailableToWithdraw: attr('number'),
    dayTradeExcess: attr('number'),
    pendingCash: attr('number'),
    futuresOvernightMarginRequirement: attr('number'),

    // TODO: serialize this
    netLiquidatingChange: null,

    maintenanceExcess: _computed.math.subtract('marginEquity', 'maintenanceRequirement', 'futuresOvernightMarginRequirement'),
    longPositionsValue: _computed.math.add('longEquityValue', 'longDerivativeValue', 'longFuturesValue'),
    shortPositionsValue: _computed.math.add('shortEquityValue', 'shortDerivativeValue', 'shortFuturesValue'),
    totalPositionsValue: _computed.math.subtract('longPositionsValue', 'shortPositionsValue'),
    totalMarginableSecurities: _computed.math.add('longMarginableValue', 'shortMarginableValue'),

    isFunded: gte('netLiquidatingValue', 500.0)
  }).reopenClass({
    resourceName: 'account-balances'
  });
});