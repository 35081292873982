define('twapi/mixins/sortable', ['exports', 'ember-decorators/object'], function (exports, _object) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.SortableArray = undefined;

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _dec2, _desc, _value, _obj, _init;

  var SortableMixin = Ember.Mixin.create((_dec = (0, _object.computed)('sortProperties.firstObject'), _dec2 = (0, _object.computed)('content.[]', 'sortProperties.[]', 'sortAscending'), (_obj = {
    sortProperties: null,
    sortAscending: true,

    sortProperty: {
      get: function get(firstObject) {
        return firstObject;
      },
      set: function set(value) {
        this.set('sortProperties', Ember.A([value]));
        return value;
      }
    },

    arrangedContent: function arrangedContent(content, sortProperties, sortAscending) {
      if (!content) {
        return;
      }

      var sorted = content.sortBy.apply(content, _toConsumableArray(Ember.makeArray(sortProperties)));

      if (!sortAscending) {
        sorted.reverse();
      }

      return sorted;
    }
  }, (_applyDecoratedDescriptor(_obj, 'sortProperty', [_dec], (_init = Object.getOwnPropertyDescriptor(_obj, 'sortProperty'), _init = _init ? _init.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function initializer() {
      return _init;
    }
  }), _obj), _applyDecoratedDescriptor(_obj, 'arrangedContent', [_dec2], Object.getOwnPropertyDescriptor(_obj, 'arrangedContent'), _obj)), _obj)));

  exports.default = SortableMixin;
  var SortableArray = exports.SortableArray = Ember.ArrayProxy.extend(SortableMixin);
});