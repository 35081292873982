define('twapi/models/trusted-contact', ['exports', 'lodash', 'twapi/models/address', 'twapi/mixins/adult-birth-date', 'twapi/mixins/name', 'ember-restless', 'twapi/utils/validation'], function (exports, _lodash, _address, _adultBirthDate, _name, _emberRestless, _validation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberRestless.default.Model,
      attr = _emberRestless.default.attr,
      belongsTo = _emberRestless.default.belongsTo;
  var not = Ember.computed.not,
      set = Ember.set;


  var INVALID_PHONE_NUMBER_MESSAGE = 'Invalid phone number.';

  exports.default = Model.extend(_adultBirthDate.default, _name.default, {
    email: attr('string'),

    phoneNumber: attr('string'),

    address: belongsTo('address', { defaultValue: function defaultValue() {
        return _address.default.create({ isTrustedContactAddress: true });
      } }),

    didLoad: function didLoad() {
      this._super.apply(this, arguments);

      set(this, 'address.isTrustedContactAddress', true);
    }
  }).reopen(_validation.build(_lodash.default.merge({
    email: {
      presence: true,
      format: { type: 'email' }
    },

    address: { presence: true },

    phoneNumber: {
      'domestic-phone-number': {
        disabled: not('model.address.isDomestic'),
        message: INVALID_PHONE_NUMBER_MESSAGE
      },
      'international-phone-number': {
        disabled: not('model.address.isForeign'),
        message: INVALID_PHONE_NUMBER_MESSAGE
      }
    }
  }, _adultBirthDate.ADULT_BIRTH_DATE_VALIDATIONS, _name.NAME_VALIDATIONS))).reopenClass({
    resourceName: 'trusted-contact'
  });
});