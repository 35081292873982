define('twapi/models/equifax-response', ['exports', 'ember-restless'], function (exports, _emberRestless) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var bool = Ember.computed.bool;
  var Model = _emberRestless.default.Model,
      belongsTo = _emberRestless.default.belongsTo;
  exports.default = Model.extend({
    completion: belongsTo('equifax-result'),
    quiz: belongsTo('equifax-quiz'),

    hasQuiz: bool('quiz')
  }).reopenClass({
    resourceName: 'equifax-response'
  });
});