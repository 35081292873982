define('twapi/models/external-affiliate-commission', ['exports', 'ember-restless', 'twapi/utils/deser', '@tastyworks/tastyworks-api'], function (exports, _emberRestless, _deser, _tastyworksApi) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberRestless.default.Model;
  exports.default = Model.extend(_deser.buildModel(_tastyworksApi.EXTERNAL_AFFILIATE_COMMISSION_DESER)).reopenClass({
    resourceName: 'external-affiliate-commission'
  }).reopen(_deser.buildToJsObjectTranslator(_tastyworksApi.ExternalAffiliateCommission, _tastyworksApi.EXTERNAL_AFFILIATE_COMMISSION_DESER));
});