define('ember-ambitious-forms/components/amb-form-group', ['exports', 'ember-ambitious-forms/utils/error-state'], function (exports, _errorState) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layoutName: 'ember-ambitious-forms@components/amb-form-group',
    classNames: 'amb-form-group',
    disabled: false,
    fields: Ember.computed(function () {
      return Ember.A();
    }),
    fieldsWithErrors: Ember.computed.alias('errorState.contentWithErrors'),

    hasErrors: false,
    errorState: Ember.computed('fields', function () {
      return _errorState.default.create({ content: this.get('fields') });
    }),

    _triggerErrorStateChanged: Ember.on('init', Ember.observer('errorState.value', function () {
      // This needs to fire after _syncFields
      Ember.run.debounce(this, this._doTriggerErrorStateChanged, 30);
    })),

    _doTriggerErrorStateChanged: function _doTriggerErrorStateChanged() {
      if (!this.get('isDestroyed')) {
        this.set('hasErrors', this.get('errorState.hasAny'));
        this.sendAction('onErrorStateChanged', this, this.get('errorState'));
      }
    },


    _toInsert: Ember.computed(function () {
      return Ember.A();
    }),
    _toRemove: Ember.computed(function () {
      return Ember.A();
    }),
    _syncFields: function _syncFields() {
      var fields = this.get('fields');
      var toInsert = this.get('_toInsert').filter(function (field) {
        return !field.get('isDestroyed');
      });
      var toRemove = this.get('_toRemove');

      fields.beginPropertyChanges();
      fields.addObjects(toInsert);
      fields.removeObjects(toRemove);
      toInsert.clear();
      toRemove.clear();
      fields.endPropertyChanges();

      this._triggerErrorStateChanged();
    },
    resetFields: function resetFields() {
      this.get('fields').forEach(function (field) {
        return field.resetErrorState();
      });
    },


    actions: {
      insertField: function insertField(component) {
        this.get('_toInsert').push(component);
        Ember.run.debounce(this, this._syncFields, 10);
        this._triggerErrorStateChanged();
        this.sendAction('onInsertField', component);
      },
      removeField: function removeField(component) {
        this.get('_toRemove').push(component);
        Ember.run.debounce(this, this._syncFields, 10);
        this._triggerErrorStateChanged();
        this.sendAction('onRemoveField', component);
      },
      triggerErrorStateChanged: function triggerErrorStateChanged() {
        Ember.run.debounce(this, this._syncFields, 10);
        this._triggerErrorStateChanged();
      }
    }
  }).reopenClass({
    positionalParams: ['scope']
  });
});