define('twapi/models/referral', ['exports', 'ember-restless', 'ember-decorators/object', 'twapi/models/referral-owner'], function (exports, _emberRestless, _object, _referralOwner) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _dec2, _desc, _value, _obj;

  var Model = _emberRestless.default.Model,
      attr = _emberRestless.default.attr,
      belongsTo = _emberRestless.default.belongsTo;
  exports.default = Model.extend((_dec = (0, _object.computed)('startDate', 'expirationDate'), _dec2 = (0, _object.computed)('totalUsers', 'totalQualifiedUsers'), (_obj = {
    id: attr('string'),
    category: attr('string'),
    referralOwnerType: attr('string'),
    code: attr('string'),
    startDate: attr('momentDateTime'),
    expirationDate: attr('momentDateTime'),
    totalUsers: attr('number'),
    totalQualifiedUsers: attr('number'),

    referralOwner: belongsTo('referral-owner', { defaultValue: function defaultValue() {
        return _referralOwner.default.create();
      } }),

    isActive: function isActive(start, expiry) {
      return !start || Date.now() > start && Date.now() < expiry;
    },
    pendingCredits: function pendingCredits(totalUsers, totalQualifiedUsers) {
      return totalUsers - totalQualifiedUsers;
    }
  }, (_applyDecoratedDescriptor(_obj, 'isActive', [_dec], Object.getOwnPropertyDescriptor(_obj, 'isActive'), _obj), _applyDecoratedDescriptor(_obj, 'pendingCredits', [_dec2], Object.getOwnPropertyDescriptor(_obj, 'pendingCredits'), _obj)), _obj))).reopenClass({
    resourceName: 'referral'
  });
});