define('twapi/models/margin-type', ['exports', 'ember-restless'], function (exports, _emberRestless) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberRestless.default.ReadOnlyModel.extend({
    name: _emberRestless.default.attr('string'),
    isMargin: _emberRestless.default.attr('boolean')
  }).reopenClass({
    resourceName: 'margin-type'
  });
});