define('twapi/models/beneficial-owner', ['exports', 'lodash', 'ember-restless', 'twapi/models/address', 'twapi/utils/validation', 'twapi/mixins/name', 'twapi/mixins/tax-number'], function (exports, _lodash, _emberRestless, _address, _validation, _name, _taxNumber) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberRestless.default.Model,
      attr = _emberRestless.default.attr,
      belongsTo = _emberRestless.default.belongsTo;
  var readOnly = Ember.computed.readOnly,
      get = Ember.get,
      isPresent = Ember.isPresent;
  exports.default = Model.extend(_name.default, _taxNumber.default, {

    birthDate: attr('string'),

    email: attr('string'),

    ownershipPercent: attr('percent'),

    address: belongsTo('address', { defaultValue: function defaultValue() {
        return _address.default.create();
      } }),

    isDomesticTaxNumber: readOnly('address.isDomestic'),

    isEqual: function isEqual(other) {
      if (isPresent(get(this, 'id'))) {
        return get(this, 'id') === get(other, 'id');
      } else {
        return get(this, 'taxNumber') === get(other, 'taxNumber');
      }
    },
    serialize: function serialize() {
      var serialized = this._super.apply(this, arguments);

      if (isPresent(get(this, 'id'))) {
        return _lodash.default.omit(serialized, 'tax-number');
      }

      return serialized;
    }
  }).reopen(_validation.build(_lodash.default.merge({
    address: { presence: true },

    birthDate: {
      presence: {
        presence: true,
        message: _validation.BIRTH_DATE_VALIDATION_MESSAGE
      }
    },

    email: {
      presence: true,
      format: { type: 'email' }
    },

    ownershipPercent: {
      number: {
        allowString: true,
        lte: 100
      },
      presence: true
    }
  }, _name.NAME_VALIDATIONS, _taxNumber.TAX_NUMBER_VALIDATIONS))).reopenClass({
    resourceName: 'beneficial-owner'
  });
});