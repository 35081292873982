define('twapi/validators/domestic-phone-number', ['exports', 'ember-cp-validations/validators/base', 'libphonenumber-js'], function (exports, _base, _libphonenumberJs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var isEmpty = Ember.isEmpty;


  var DomesticPhoneNumber = _base.default.extend({
    validate: function validate(value, options, model, attribute) {
      if (isEmpty(value)) {
        if (options.allowBlank) {
          return true;
        } else {
          return this.createErrorMessage(undefined, undefined, options);
        }
      }
      if (!(0, _libphonenumberJs.isValidNumber)(value, 'US')) {
        return this.createErrorMessage(undefined, undefined, options);
      }
      return true;
    }
  });

  exports.default = DomesticPhoneNumber;
});