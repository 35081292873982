define('twapi/components/twapi-footer', ['exports', 'ember-decorators/object', 'moment', 'twapi/utils/computed'], function (exports, _object, _moment, _computed) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _desc, _value, _obj;

  var readOnly = Ember.computed.readOnly;
  var Component = Ember.Component;
  exports.default = Component.extend((_dec = (0, _object.computed)(), (_obj = {
    config: _computed.default.registry.resolve('config:environment'),

    classNames: 'twapi-footer',

    characteristicsAndRisksOfStandardizedOptionsURL: readOnly('config.characteristicsAndRisksOfStandardizedOptionsURL'),

    futuresExchangeTradedOptionsRiskDisclosureAgreementURL: readOnly('config.futuresExchangeTradedOptionsRiskDisclosureAgreementURL'),

    currentYear: function currentYear() {
      return (0, _moment.default)().format('YYYY');
    }
  }, (_applyDecoratedDescriptor(_obj, 'currentYear', [_dec], Object.getOwnPropertyDescriptor(_obj, 'currentYear'), _obj)), _obj)));
});