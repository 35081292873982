define('twapi/models/agreement', ['exports', 'ember-restless'], function (exports, _emberRestless) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberRestless.default.Model.extend({
    agreedAt: _emberRestless.default.attr('momentDateTime'),
    acceptanceMethod: _emberRestless.default.attr('string'),
    document: _emberRestless.default.belongsTo('document')
  }).reopenClass({
    resourceName: 'agreement'
  });
});