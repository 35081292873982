define('twapi/models/supporting-document-verification', ['exports', 'ember-restless', 'ember-decorators/object', 'twapi/utils/slug'], function (exports, _emberRestless, _object, _slug) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.SUPPORTING_DOCUMENT_VERIFICATION_TYPES = exports.SUPPORTING_DOCUMENT_VERIFICATION_SUBMITTED_STATUSES = exports.SUPPORTING_DOCUMENT_VERIFICATION_STATUSES = undefined;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _dec2, _dec3, _dec4, _dec5, _desc, _value, _obj;

  var Model = _emberRestless.default.Model,
      attr = _emberRestless.default.attr,
      belongsTo = _emberRestless.default.belongsTo;
  var _Ember = Ember,
      readOnly = _Ember.computed.readOnly;
  var SUPPORTING_DOCUMENT_VERIFICATION_STATUSES = exports.SUPPORTING_DOCUMENT_VERIFICATION_STATUSES = {
    COMPLETE: 'Complete',
    FAILED: 'Failed',
    PENDING: 'Pending',
    UPLOADED: 'Uploaded',
    UPLOAD_FAILED: 'Upload Failed'
  };

  var SUPPORTING_DOCUMENT_VERIFICATION_SUBMITTED_STATUSES = exports.SUPPORTING_DOCUMENT_VERIFICATION_SUBMITTED_STATUSES = [SUPPORTING_DOCUMENT_VERIFICATION_STATUSES.COMPLETE, SUPPORTING_DOCUMENT_VERIFICATION_STATUSES.PENDING, SUPPORTING_DOCUMENT_VERIFICATION_STATUSES.UPLOADED];

  var SUPPORTING_DOCUMENT_VERIFICATION_TYPES = exports.SUPPORTING_DOCUMENT_VERIFICATION_TYPES = {
    ADDRESS: 'AddressVerification',
    IDENTITY: 'IdentityVerification'
  };

  var NO_MATCHING_SLUG_ERROR = 'No matching slug for document verification type';

  exports.default = Model.extend((_dec = (0, _object.computed)('type'), _dec2 = (0, _object.computed)('type'), _dec3 = (0, _object.computed)('type'), _dec4 = (0, _object.computed)('status'), _dec5 = (0, _object.computed)('status', 'isDiscarded'), (_obj = {
    uuid: attr('string'),

    extTransactionNumber: attr('string'),

    status: attr('string'),

    type: attr('string'),

    supportingDocumentId: attr('number'),

    verificationJson: belongsTo('supporting-document-verification-json'),

    isDiscarded: readOnly('verificationJson.document.isDiscarded'),

    isAddressVerificationType: function isAddressVerificationType(type) {
      return type === SUPPORTING_DOCUMENT_VERIFICATION_TYPES.ADDRESS;
    },
    isIdVerificationType: function isIdVerificationType(type) {
      return type === SUPPORTING_DOCUMENT_VERIFICATION_TYPES.IDENTITY;
    },
    slug: function slug(type) {
      switch (type) {
        case SUPPORTING_DOCUMENT_VERIFICATION_TYPES.ADDRESS:
          return _slug.SLUGS.ADDRESS_DOCUMENT;
        case SUPPORTING_DOCUMENT_VERIFICATION_TYPES.IDENTITY:
          return _slug.SLUGS.ID_DOCUMENT;
        default:
          throw new Error(NO_MATCHING_SLUG_ERROR);
      }
    },
    isSubmitted: function isSubmitted(status) {
      return SUPPORTING_DOCUMENT_VERIFICATION_SUBMITTED_STATUSES.includes(status);
    },
    isFailed: function isFailed(status, isDiscarded) {
      return isDiscarded || status === SUPPORTING_DOCUMENT_VERIFICATION_STATUSES.FAILED || status === SUPPORTING_DOCUMENT_VERIFICATION_STATUSES.UPLOAD_FAILED;
    }
  }, (_applyDecoratedDescriptor(_obj, 'isAddressVerificationType', [_dec], Object.getOwnPropertyDescriptor(_obj, 'isAddressVerificationType'), _obj), _applyDecoratedDescriptor(_obj, 'isIdVerificationType', [_dec2], Object.getOwnPropertyDescriptor(_obj, 'isIdVerificationType'), _obj), _applyDecoratedDescriptor(_obj, 'slug', [_dec3], Object.getOwnPropertyDescriptor(_obj, 'slug'), _obj), _applyDecoratedDescriptor(_obj, 'isSubmitted', [_dec4], Object.getOwnPropertyDescriptor(_obj, 'isSubmitted'), _obj), _applyDecoratedDescriptor(_obj, 'isFailed', [_dec5], Object.getOwnPropertyDescriptor(_obj, 'isFailed'), _obj)), _obj))).reopenClass({
    resourceName: 'supporting-document-verification'
  });
});