define("ember-cli-foundation-6-sass/components/zf-drilldown-menu", ["exports", "ember-cli-foundation-6-sass/mixins/zf-widget"], function (_exports, _zfWidget) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_zfWidget.default, {
    /** @member tag type */
    tagName: 'ul',

    /** @member Class names */
    classNames: ['vertical', 'menu'],

    /** @member Attribute bindings */
    attributeBindings: ['data-drilldown'],

    /** @member Makes the data attribute binding appear */
    'data-drilldown': ' ',

    /** @member Foundation type */
    'zfType': 'Drilldown',

    /** @member Foundation specific options */
    'zfOptions': ['backButton', 'wrapper', 'closeOnClick']
  });

  _exports.default = _default;
});