define('twapi/components/twapi-popup', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['twapi-popup']
  }).reopenClass({
    positionalParams: ['content']
  });
});