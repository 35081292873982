define('twapi/models/futures-trading-request', ['exports', 'ember-restless'], function (exports, _emberRestless) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberRestless.default.Model,
      attr = _emberRestless.default.attr;
  var bool = Ember.computed.bool;
  exports.default = Model.extend({
    approvedAt: attr('momentDateTime'),

    createdAt: attr('momentDateTime'),

    isPendingJointAgreement: attr('boolean'),

    isRequestingCustomer: attr('boolean'),

    isApproved: bool('approvedAt')
  }).reopenClass({
    resourceName: 'futures-trading-request'
  });
});