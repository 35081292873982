define('ember-text-mask/utilities', ['exports', 'ember-text-mask/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.convertMaskToPlaceholder = convertMaskToPlaceholder;
  exports.isArray = isArray;
  exports.isString = isString;
  exports.isNumber = isNumber;
  exports.isNil = isNil;
  exports.processCaretTraps = processCaretTraps;


  var emptyArray = [];

  function convertMaskToPlaceholder() {
    var mask = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : emptyArray;
    var placeholderChar = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : _constants.placeholderChar;

    if (!isArray(mask)) {
      throw new Error('Text-mask:convertMaskToPlaceholder; The mask property must be an array.');
    }

    if (mask.indexOf(placeholderChar) !== -1) {
      throw new Error('Placeholder character must not be used as part of the mask. Please specify a character ' + 'that is not present in your mask as your placeholder character.\n\n' + ('The placeholder character that was received is: ' + JSON.stringify(placeholderChar) + '\n\n') + ('The mask that was received is: ' + JSON.stringify(mask)));
    }

    return mask.map(function (char) {
      return char instanceof RegExp ? placeholderChar : char;
    }).join('');
  }

  function isArray(value) {
    return Array.isArray && Array.isArray(value) || value instanceof Array;
  }

  function isString(value) {
    return typeof value === 'string' || value instanceof String;
  }

  function isNumber(value) {
    return typeof value === 'number' && value.length === undefined && !isNaN(value);
  }

  function isNil(value) {
    return typeof value === 'undefined' || value === null;
  }

  var strCaretTrap = '[]';
  function processCaretTraps(mask) {
    var indexes = [];

    var indexOfCaretTrap = void 0;
    while (indexOfCaretTrap = mask.indexOf(strCaretTrap), indexOfCaretTrap !== -1) {
      // eslint-disable-line
      indexes.push(indexOfCaretTrap);

      mask.splice(indexOfCaretTrap, 1);
    }

    return { maskWithoutCaretTraps: mask, indexes: indexes };
  }
});