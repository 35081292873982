define('ember-ambitious-forms/components/amb-form-group-each', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layoutName: 'ember-ambitious-forms@components/amb-form-group-each',
    tagName: '',

    parent: null
  }).reopenClass({
    positionalParams: ['scopes']
  });
});