define('twapi/mixins/tax-number', ['exports', 'ember-restless', 'ember-decorators/object', 'twapi/utils/mask', 'twapi/utils/regex', 'ember-text-mask'], function (exports, _emberRestless, _object, _mask, _regex, _emberTextMask) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.TAX_NUMBER_VALIDATIONS = exports.ITIN_TYPE = exports.EIN_TYPE = exports.SSN_TYPE = undefined;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _desc, _value, _obj;

  var Mixin = Ember.Mixin,
      _Ember$computed = Ember.computed,
      and = _Ember$computed.and,
      bool = _Ember$computed.bool,
      equal = _Ember$computed.equal,
      not = _Ember$computed.not,
      _get = Ember.get,
      isNone = Ember.isNone,
      _set = Ember.set;
  var attr = _emberRestless.default.attr;
  var SSN_TYPE = exports.SSN_TYPE = 'SSN';
  var EIN_TYPE = exports.EIN_TYPE = 'EIN';
  var ITIN_TYPE = exports.ITIN_TYPE = 'ITIN';

  var TAX_NUMBER_TYPES = [SSN_TYPE, EIN_TYPE, ITIN_TYPE];

  exports.default = Mixin.create((_dec = (0, _object.computed)('isEin', 'isItin', 'isSsn'), (_obj = {

    taxNumberType: attr('string', { defaultValue: SSN_TYPE }),

    taxNumber: attr('string'),

    isDomesticTaxNumber: true,

    isEin: equal('taxNumberType', EIN_TYPE),

    isItin: equal('taxNumberType', ITIN_TYPE),

    isSsn: equal('taxNumberType', SSN_TYPE),

    validateEin: and('isEin', 'isDomesticTaxNumber'),

    validateItin: and('isItin', 'isDomesticTaxNumber'),

    validateSsn: and('isSsn', 'isDomesticTaxNumber'),

    taxNumberMask: function taxNumberMask(isEin, isItin, isSsn) {
      if (isEin) {
        return _mask.EIN;
      } else if (isItin) {
        return _mask.ITIN;
      } else if (isSsn) {
        return _mask.SSN;
      } else {
        return null;
      }
    },


    maskedTaxNumber: Ember.computed('taxNumber', 'taxNumberMask', {
      get: function get(key) {
        return (0, _emberTextMask.conformToMask)(_get(this, 'taxNumber'), _get(this, 'taxNumberMask')).conformedValue;
      },
      set: function set(key, value) {
        return _set(this, 'taxNumber', value);
      }
    })

  }, (_applyDecoratedDescriptor(_obj, 'taxNumberMask', [_dec], Object.getOwnPropertyDescriptor(_obj, 'taxNumberMask'), _obj)), _obj)));
  var TAX_NUMBER_VALIDATIONS = exports.TAX_NUMBER_VALIDATIONS = {
    taxNumberType: { inclusion: { in: TAX_NUMBER_TYPES } },

    taxNumber: {
      presence: {
        presence: true,
        disabled: bool('model.validateItin')
      },

      length: {
        max: 9,
        min: 9,
        value: function value(model, attribute) {
          var val = _get(model, attribute);

          if (isNone(val)) {
            return val;
          }

          return val.replace(/\D/g, '');
        },

        allowBlank: true,
        disabled: not('model.isDomesticTaxNumber')
      },

      format: [{ regex: _regex.exact.SSN, disabled: not('model.validateSsn') }, { regex: _regex.exact.EIN, disabled: not('model.validateEin') }, { regex: _regex.exact.ITIN, disabled: not('model.validateItin'), allowBlank: true }]
    }
  };
});