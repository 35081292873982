define('twapi/models/user-security-question', ['exports', 'ember-restless', 'twapi/utils/validation'], function (exports, _emberRestless, _validation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberRestless.default.Model.extend({
    user: _emberRestless.default.belongsTo('user'),
    question: _emberRestless.default.attr('string'),
    answer: _emberRestless.default.attr('string')
  }).reopen(_validation.build({
    question: { presence: true },
    answer: { presence: true }
  })).reopenClass({
    resourceName: 'user-security-question'
  });
});