define('twapi/models/supporting-document-verification-document', ['exports', 'ember-restless', 'ember-decorators/object'], function (exports, _emberRestless, _object) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var _Ember = Ember,
      equal = _Ember.computed.equal;
  var Model = _emberRestless.default.Model,
      attr = _emberRestless.default.attr;


  var STATUSES = {
    DISCARDED: 'DISCARDED'
  };

  exports.default = Model.extend({
    status: attr('string'),

    isDiscarded: equal('status', STATUSES.DISCARDED)
  }).reopenClass({
    resourceName: 'supporting-document-verification-document'
  });
});