define("twapi/utils/math", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.sum = sum;
  exports.product = product;
  exports.mean = mean;
  exports.sign = sign;
  function sum(array) {
    return array.reduce(function (sum, item) {
      return sum + item;
    }, 0);
  }

  function product(array) {
    return array.reduce(function (prod, item) {
      return prod * item;
    }, 0);
  }

  function mean(array) {
    return sum(array) / array.length;
  }

  function sign(number) {
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/sign
    if (!Math.sign) {
      Math.sign = function (x) {
        // If x is NaN, the result is NaN.
        // If x is -0, the result is -0.
        // If x is +0, the result is +0.
        // If x is negative and not -0, the result is -1.
        // If x is positive and not +0, the result is +1.
        x = +x; // convert to a number
        if (x === 0 || isNaN(x)) {
          return Number(x);
        }
        return x > 0 ? 1 : -1;
      };
    }

    return Math.sign(number);
  }
});