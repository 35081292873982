define('twapi/components/twapi-login-form', ['exports', 'lodash', 'twapi/utils/error', 'twapi/utils/validation'], function (exports, _lodash, _error, _validation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      service = Ember.inject.service,
      setProperties = Ember.setProperties,
      set = Ember.set,
      get = Ember.get,
      getProperties = Ember.getProperties;


  var initialConfirmationState = {
    showSendConfirmationLink: false,
    confirmationResendFailed: false,
    confirmationResent: false
  };
  var userUnconfirmedState = Object.assign({}, initialConfirmationState, {
    showSendConfirmationLink: true
  });
  var confirmationResentState = Object.assign({}, initialConfirmationState, {
    confirmationResent: true
  });
  var resendConfirmationFailedState = Object.assign({}, userUnconfirmedState, {
    confirmationResendFailed: true
  });

  exports.default = Component.extend({
    session: service(),
    twapiClient: service(),
    classNames: 'twapi-login-form',
    loginMessage: null,
    loginErrorMessage: null,
    successMessage: 'Login successful.',

    actions: {
      login: function login() {
        var _this = this;

        this.sendAction('onSubmit');
        setProperties(this, initialConfirmationState);

        var params = getProperties(this, 'login', 'password', 'clientDomain', 'rememberMe');
        params = _lodash.default.mapKeys(params, function (value, key) {
          return Ember.String.dasherize(key);
        });
        get(this, 'session').authenticate('authenticator:twucs', params).then(function (success) {
          set(_this, 'loginMessage', get(_this, 'successMessage'));
          _this.sendAction('action', success);
        }).catch(function (e) {
          if (get(e, 'error.code') === 'unconfirmed_user') {
            setProperties(_this, userUnconfirmedState);
          }
          set(_this, 'loginErrorMessage', _error.parse(e));
          _this.sendAction('onError', e);
        });
      },
      resendConfirmation: function resendConfirmation() {
        var _this2 = this;

        var params = {
          email: get(this, 'login')
        };
        get(this, 'twapiClient').post('confirmation', params).then(function (success) {
          setProperties(_this2, confirmationResentState);
        }).catch(function (xhr) {
          setProperties(_this2, resendConfirmationFailedState);
        });
      },
      showForgot: function showForgot() {
        this.sendAction('showForgot');
      }
    }
  }).reopen(_validation.build({
    login: { presence: true },
    password: { presence: true }
  }));
});