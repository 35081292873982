define('twapi/models/order-leg', ['exports', 'ember-restless'], function (exports, _emberRestless) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberRestless.default.Model.extend({
    symbol: _emberRestless.default.attr('string'),
    instrumentType: _emberRestless.default.attr('string'),
    quantity: _emberRestless.default.attr('number'),
    action: _emberRestless.default.attr('string'),
    fills: _emberRestless.default.hasMany('order-fill-leg')
  }).reopenClass({
    resourceName: 'order-leg'
  });
});