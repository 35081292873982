define('twapi/models/advanced-trading-request', ['exports', 'ember-restless', 'twapi/models/portfolio-margin-questionnaire'], function (exports, _emberRestless, _portfolioMarginQuestionnaire) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.TRADING_REQUEST_TYPES = undefined;
  var Model = _emberRestless.default.Model,
      attr = _emberRestless.default.attr,
      belongsTo = _emberRestless.default.belongsTo;
  var get = Ember.get,
      set = Ember.set;
  var TRADING_REQUEST_TYPES = exports.TRADING_REQUEST_TYPES = {
    CRYPTOCURRENCY_TRADING: 'Cryptocurrency Trading',
    EQUITY_OFFERING: 'Equity Offering',
    INTRADAY_FUTURES_MARGIN: 'Intraday Futures Margin',
    IRA_SHORT_CALL: 'IRA Short Call',
    PORTFOLIO_MARGIN: 'Portfolio Margin'
  };

  exports.default = Model.extend({
    accountNumber: attr('string'),

    requestType: attr('string'),

    portfolioMarginQuestionnaire: belongsTo('', { defaultValue: function defaultValue() {
        return _portfolioMarginQuestionnaire.default.create();
      } }),

    serialize: function serialize() {
      var serialized = this._super.apply(this, arguments);

      // NOTE: [KT] force custom serialization defined on portfolio-margin-questionnaire
      set(serialized, 'portfolio-margin-questionnaire', get(this, 'portfolioMarginQuestionnaire').serialize());

      return serialized;
    }
  }).reopenClass({
    resourceName: 'advanced-trading-request'
  });
});