define('twapi/utils/decorator', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.isDecorating = isDecorating;
  exports.asDecorator = asDecorator;

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  function isDecorating(target, key, desc) {
    return arguments.length === 3 && (typeof target === 'undefined' ? 'undefined' : _typeof(target)) === 'object' && typeof key === 'string' && (typeof desc === 'undefined' ? 'undefined' : _typeof(desc)) === 'object' && typeof desc.value === 'function';
  }

  function asDecorator(fn) {
    return function (target, key, desc) {
      if (isDecorating.apply(undefined, arguments)) {
        return _lodash.default.assign({}, desc, {
          value: fn(desc.value)
        });
      } else {
        return asDecorator(_lodash.default.partial.apply(_lodash.default, [fn].concat(Array.prototype.slice.call(arguments))));
      }
    };
  }
});