define('twapi/components/amb-form-gender-select', ['exports', 'ember-decorators/object'], function (exports, _object) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _desc, _value, _obj;

  var A = Ember.A,
      Component = Ember.Component,
      _Ember$computed = Ember.computed,
      alias = _Ember$computed.alias,
      or = _Ember$computed.or,
      get = Ember.get,
      isEmpty = Ember.isEmpty,
      set = Ember.set;


  var FEMALE = 'Female';
  var MALE = 'Male';
  var NON_BINARY = 'Non-binary';
  var TRANSGENDER = 'Transgender';
  var INTERSEX = 'Intersex';
  var PREFER_NOT_TO_SAY = 'I prefer not to say';
  var DELIMITING_VALUE = ', ';

  var GENDER_OPTIONS = [FEMALE, MALE, NON_BINARY, TRANSGENDER, INTERSEX, PREFER_NOT_TO_SAY];

  function toArray(gender) {
    if (isEmpty(gender)) {
      return [];
    }

    return gender.split(/,\s*/);
  }

  function includesGender(genders, option) {
    return genders.includes(option);
  }

  exports.default = Component.extend((_dec = (0, _object.computed)('genders'), _dec2 = (0, _object.computed)('genders'), _dec3 = (0, _object.computed)('genders'), _dec4 = (0, _object.computed)('genders'), _dec5 = (0, _object.computed)('genders'), _dec6 = (0, _object.computed)('genders'), _dec7 = (0, _object.computed)('genders'), (_obj = {
    classNames: ['amb-form-gender-select', 'amb-form-field-agreed'],

    gender: alias('value'),

    genders: null,

    presetCheckboxesDisabled: or('isPreferNotToSay', 'isCustomInput'),

    isCustomInput: false,

    isFemale: function isFemale(genders) {
      return includesGender(genders, FEMALE);
    },
    isMale: function isMale(genders) {
      return includesGender(genders, MALE);
    },
    isNonBinary: function isNonBinary(genders) {
      return includesGender(genders, NON_BINARY);
    },
    isTransgender: function isTransgender(genders) {
      return includesGender(genders, TRANSGENDER);
    },
    isIntersex: function isIntersex(genders) {
      return includesGender(genders, INTERSEX);
    },
    isPreferNotToSay: function isPreferNotToSay(genders) {
      return includesGender(genders, PREFER_NOT_TO_SAY);
    },
    hasCustomInput: function hasCustomInput(genders) {
      var customInput = function customInput(element) {
        return !GENDER_OPTIONS.includes(element);
      };
      return genders.some(customInput);
    },
    init: function init() {
      this._super.apply(this, arguments);

      set(this, 'genders', A());
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var gender = get(this, 'gender');

      if (get(this, '_previousGender') !== gender) {
        var genders = toArray(gender);
        set(this, 'genders', genders);

        var hasCustomInput = get(this, 'hasCustomInput');

        if (hasCustomInput) {
          set(this, 'isCustomInput', hasCustomInput);
        }

        set(this, '_previousGender', gender);
      }
    },


    actions: {
      onInputChange: function onInputChange(event) {
        var inputValue = get(event, 'target.value');
        var gender = get(this, 'gender');
        if (inputValue === gender) {
          return;
        }

        set(this, 'gender', inputValue);
        this.sendAction('action', inputValue);
      },
      selectPreferNotToSay: function selectPreferNotToSay(attribute, checked) {
        if (checked) {
          set(this, 'gender', PREFER_NOT_TO_SAY);
        } else {
          set(this, 'gender', null);
        }

        this.sendAction('action', get(this, 'gender'));
      },
      selectCustomInput: function selectCustomInput(attribute, checked) {
        set(this, 'gender', null);
        set(this, 'isCustomInput', checked);

        this.sendAction('action', null);
      },
      selectGender: function selectGender(attribute, checked) {
        var genders = get(this, 'genders');

        if (checked) {
          genders.addObject(attribute);
        } else {
          genders.removeObject(attribute);
        }

        var value = genders.join(DELIMITING_VALUE);
        set(this, 'gender', value);

        this.sendAction('action', value);
      }
    }
  }, (_applyDecoratedDescriptor(_obj, 'isFemale', [_dec], Object.getOwnPropertyDescriptor(_obj, 'isFemale'), _obj), _applyDecoratedDescriptor(_obj, 'isMale', [_dec2], Object.getOwnPropertyDescriptor(_obj, 'isMale'), _obj), _applyDecoratedDescriptor(_obj, 'isNonBinary', [_dec3], Object.getOwnPropertyDescriptor(_obj, 'isNonBinary'), _obj), _applyDecoratedDescriptor(_obj, 'isTransgender', [_dec4], Object.getOwnPropertyDescriptor(_obj, 'isTransgender'), _obj), _applyDecoratedDescriptor(_obj, 'isIntersex', [_dec5], Object.getOwnPropertyDescriptor(_obj, 'isIntersex'), _obj), _applyDecoratedDescriptor(_obj, 'isPreferNotToSay', [_dec6], Object.getOwnPropertyDescriptor(_obj, 'isPreferNotToSay'), _obj), _applyDecoratedDescriptor(_obj, 'hasCustomInput', [_dec7], Object.getOwnPropertyDescriptor(_obj, 'hasCustomInput'), _obj)), _obj)));
});