define('twapi/models/acat-request', ['exports', 'ember-restless', 'twapi/utils/validation', 'lodash', 'ember-decorators/object'], function (exports, _emberRestless, _validation, _lodash, _object) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.ACCOUNT_TYPES = exports.TRANSFER_TYPES = undefined;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _desc, _value, _obj;

  var equal = Ember.computed.equal,
      get = Ember.get;
  var attr = _emberRestless.default.attr,
      hasMany = _emberRestless.default.hasMany,
      Model = _emberRestless.default.Model;
  var TRANSFER_TYPES = exports.TRANSFER_TYPES = {
    FULL: 'FULL_TRANSFER',
    PARTIAL: 'PARTIAL_TRANSFER_RECEIVER'
  };

  var ACCOUNT_TYPES = exports.ACCOUNT_TYPES = {
    CORPORATE: 'Corporate',
    INDIVIDUAL: 'Individual',
    JOINT: 'Joint',
    ROTH_IRA: 'Roth IRA',
    SEP_IRA: 'SEP IRA',
    TRADITIONAL_IRA: 'Traditional IRA',
    TRUST: 'Trust'
  };

  var AGGREGATOR_SOURCE = 'Plaid';

  exports.default = Model.extend((_dec = (0, _object.computed)('isComplete', 'isRejected'), (_obj = {
    accountNumber: attr('string'),
    aggregatorSource: attr('string', { defaultValue: AGGREGATOR_SOURCE }),
    createdAt: attr('momentDateTime', { readOnly: true }),
    deliveringAccountNumber: attr('string'),
    deliveringAccountTitle: attr('string'),
    deliveringAccountType: attr('string'),
    externalAccountName: attr('string'),
    externalAccountId: attr('number'),
    externalAccountTitle: attr('string'),
    externalInstitutionName: attr('string'),
    isComplete: attr('boolean'),
    isPendingJointAgreement: attr('boolean'),
    isRejected: attr('boolean'),
    isRequestingCustomer: attr('boolean'),
    transferType: attr('string', { defaultValue: TRANSFER_TYPES.FULL }),
    truncatedExternalAccountNumber: attr('string'),

    holdings: hasMany('holding'),

    isFullTransfer: equal('transferType', TRANSFER_TYPES.FULL),

    isPartialTransfer: equal('transferType', TRANSFER_TYPES.PARTIAL),

    isProcessing: function isProcessing(isComplete, isRejected) {
      return !(isComplete || isRejected);
    },
    serialize: function serialize() {
      var serialized = this._super.apply(this, arguments);

      if (get(this, 'isFullTransfer')) {
        return _lodash.default.omit(serialized, 'holdings');
      }

      return serialized;
    }
  }, (_applyDecoratedDescriptor(_obj, 'isProcessing', [_dec], Object.getOwnPropertyDescriptor(_obj, 'isProcessing'), _obj)), _obj))).reopenClass({
    resourceName: 'acat-request'
  }).reopen(_validation.build({
    deliveringAccountNumberConfirmation: {
      confirmation: {
        on: 'deliveringAccountNumber',
        message: 'Account Numbers do not match'
      }
    },
    deliveringAccountNumber: { presence: true },
    deliveringAccountTitle: { presence: true },
    deliveringAccountType: { presence: true, inclusion: { in: Object.values(ACCOUNT_TYPES) } },
    transferType: { inclusion: { in: Object.values(TRANSFER_TYPES) } }
  }));
});