define('twapi/components/forms/twapi-phone-number', ['exports', 'ember-decorators/object', 'libphonenumber-js', 'twapi/components/amb-form-domestic-phone-number', 'twapi/templates/components/forms/twapi-phone-number'], function (exports, _object, _libphonenumberJs, _ambFormDomesticPhoneNumber, _twapiPhoneNumber) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _desc, _value, _obj;

  var Component = Ember.Component,
      isPresent = Ember.isPresent,
      isBlank = Ember.isBlank;
  exports.default = Component.extend((_dec = (0, _object.computed)('readOnly', 'f._.readOnly'), (_obj = {
    layout: _twapiPhoneNumber.default,

    classNames: ['twapi-phone-number'],

    f: null,

    isInternational: false,

    readOnly: null,

    _readOnly: function _readOnly(readOnly, formReadOnly) {
      if (isPresent(readOnly)) {
        return readOnly;
      }
      if (isPresent(formReadOnly)) {
        return formReadOnly;
      }

      return false;
    },
    domesticFormatter: function domesticFormatter(value) {
      return (0, _ambFormDomesticPhoneNumber.format)(value);
    },
    internationalFormatter: function internationalFormatter(value) {
      if (isBlank(value)) {
        return value;
      }

      return new _libphonenumberJs.AsYouType().input(value);
    }
  }, (_applyDecoratedDescriptor(_obj, '_readOnly', [_dec], Object.getOwnPropertyDescriptor(_obj, '_readOnly'), _obj)), _obj)));
});