define('twapi/models/model-portfolio', ['exports', 'ember-restless'], function (exports, _emberRestless) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberRestless.default.Model,
      attr = _emberRestless.default.attr;
  var get = Ember.get;
  exports.default = Model.extend({
    name: attr('string'),

    leaderAccountNumber: attr('string'),

    unitValue: attr('number'),

    startDate: attr('momentDateTime'),

    endDate: attr('momentDateTime'),

    description: attr('string'),

    subscriptionValue: function subscriptionValue(unitCount) {
      return get(this, 'unitValue') * unitCount;
    }
  }).reopenClass({
    resourceName: 'model-portfolio'
  });
});