define('twapi/session-stores/application', ['exports', 'ember-decorators/object/evented', 'twapi/utils/computed', 'ember-simple-auth/session-stores/cookie'], function (exports, _evented, _computed, _cookie) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _desc, _value, _obj;

  var get = Ember.get,
      set = Ember.set;
  exports.default = _cookie.default.extend((_dec = (0, _evented.on)('init'), (_obj = {
    setCookieDomain: function setCookieDomain() {
      // HACK: [KT] temporarily hardcode .tastyworks.com when .tastyworks.com domain detected
      if (window.location.hostname.endsWith('tastyworks.com')) {
        return set(this, 'cookieDomain', 'tastyworks.com');
      }

      set(this, 'cookieDomain', get(this, 'config.cookieDomain'));
    },


    cookieName: 'ember_simple_auth-session',

    config: _computed.default.registry.resolve('config:environment')
  }, (_applyDecoratedDescriptor(_obj, 'setCookieDomain', [_dec], Object.getOwnPropertyDescriptor(_obj, 'setCookieDomain'), _obj)), _obj)));
});