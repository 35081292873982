define('twapi/components/twapi-pagination', ['exports', 'lodash', 'ember-decorators/object', 'twapi/templates/components/twapi-pagination'], function (exports, _lodash, _object, _twapiPagination) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _dec2, _dec3, _desc, _value, _obj;

  exports.default = Ember.Component.extend((_dec = (0, _object.observes)('current'), _dec2 = (0, _object.computed)('current', 'total'), _dec3 = (0, _object.computed)('total', 'current'), (_obj = {
    layout: _twapiPagination.default,
    tagName: 'ul',
    classNames: 'pagination',

    total: null,
    current: 1,

    showPrev: Ember.computed.notEmpty('prev'),
    showNext: Ember.computed.notEmpty('next'),

    showPrevNext: Ember.computed({
      get: function get() {
        return this.get('showPrev') && this.get('showNext');
      },
      set: function set(key, value) {
        this.setProperties({ showPrev: value, showNext: value });
        return value;
      }
    }),

    _currentObserver: function _currentObserver() {
      this.sendAction('action', this.get('current'));
    },


    hasPrev: Ember.computed.gt('current', 1),

    hasNext: function hasNext(current, total) {
      return current < total;
    },
    pages: function pages(total, current) {
      return _lodash.default.times(total, function (index) {
        var number = index + 1;
        return Ember.Object.create({ number: number, isCurrent: number === current });
      });
    },


    actions: {
      select: function select(value) {
        this.set('current', value);
      },
      prev: function prev() {
        if (!this.get('hasPrev')) {
          return;
        }
        this.decrementProperty('current');
      },
      next: function next() {
        if (!this.get('hasNext')) {
          return;
        }
        this.incrementProperty('current');
      }
    }
  }, (_applyDecoratedDescriptor(_obj, '_currentObserver', [_dec], Object.getOwnPropertyDescriptor(_obj, '_currentObserver'), _obj), _applyDecoratedDescriptor(_obj, 'hasNext', [_dec2], Object.getOwnPropertyDescriptor(_obj, 'hasNext'), _obj), _applyDecoratedDescriptor(_obj, 'pages', [_dec3], Object.getOwnPropertyDescriptor(_obj, 'pages'), _obj)), _obj)));
});