define('twapi/utils/regex/exact', ['exports', 'twapi/utils/regex/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = exact;
  function exact(regex) {
    var source = regex.source;
    if (!source.startsWith('^')) {
      source = '^' + source;
    }
    if (!source.endsWith('$')) {
      source += '$';
    }
    return new RegExp(source, regex.flags);
  }

  for (var key in _constants) {
    var regex = _constants[key];
    if (_constants.hasOwnProperty(key) && regex instanceof RegExp) {
      exact[key] = exact(regex);
    }
  }
});