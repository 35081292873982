define('twapi/mixins/reset-scroll-mixin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Mixin = Ember.Mixin;


  var ResetScrollMixin = Mixin.create({
    activate: function activate() {
      this._super.apply(this, arguments);
      window.scrollTo(0, 0);
    }
  });

  exports.default = ResetScrollMixin;
});