define('ember-text-mask-addons/components/number-mask', ['exports', 'ember-text-mask/components/masked-input', 'ember-text-mask-addons'], function (exports, _maskedInput, _emberTextMaskAddons) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function _createNumberMask() {
    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return Ember.computed.apply(undefined, args.concat([function () {
      return this.createNumberMask(this.getProperties.apply(this, args));
    }]));
  }

  exports.default = _maskedInput.default.extend({
    createNumberMask: _emberTextMaskAddons.createNumberMask,
    mask: _createNumberMask('prefix', 'suffix', 'includeThousandsSeparator', 'thousandsSeparatorSymbol', 'allowDecimal', 'decimalSymbol', 'decimalLimit', 'requireDecimal', 'allowNegative', 'allowLeadingZeroes', 'integerLimit')
  });
});