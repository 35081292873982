define('twapi/models/order-fill-leg', ['exports', 'ember-restless'], function (exports, _emberRestless) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberRestless.default.Model.extend({
    quantity: _emberRestless.default.attr('number'),
    fillPrice: _emberRestless.default.attr('number'),
    marketPrice: _emberRestless.default.attr('number'),
    exchange: _emberRestless.default.attr('string'),
    filledAt: _emberRestless.default.attr('momentDateTime')
  }).reopenClass({
    resourceName: 'order-fill-leg'
  });
});