define('twapi/models/session-response', ['exports', 'ember-restless'], function (exports, _emberRestless) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberRestless.default.Model.extend({
    rememberToken: _emberRestless.default.attr('string'),
    sessionToken: _emberRestless.default.attr('string'),
    user: _emberRestless.default.belongsTo('user'),
    clientDomain: _emberRestless.default.attr('string')
  });
});