define('twapi/utils/url', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.paramsToResolve = paramsToResolve;
  exports.resolve = resolve;
  exports.join = join;
  function paramsToResolve(path) {
    var matches = path.match(/:[a-zA-Z]+/g) || [];
    return matches.map(function (match) {
      return match.slice(1);
    });
  }

  function resolve(path, params) {
    var queryParams = {};
    for (var key in params) {
      if (params.hasOwnProperty(key)) {
        var value = params[key];
        var match = ':' + key;
        if (path.includes(match)) {
          path = path.replace(match, value);
        } else {
          queryParams[key] = value;
        }
      }
    }

    return [path, queryParams];
  }

  function join() {
    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return args.filter(function (arg) {
      return arg && arg !== '/';
    }).join('/').replace(/([^:])\/{2,}/g, '$1/');
  }
});