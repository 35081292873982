define('twapi/services/twapi-content-stack', ['exports', 'contentstack', 'twapi/utils/computed', 'ember-decorators/object'], function (exports, _contentstack, _computed, _object) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _desc, _value, _obj;

  var Service = Ember.Service,
      get = Ember.get,
      readOnly = Ember.computed.readOnly;
  exports.default = Service.extend((_dec = (0, _object.computed)('apiKey', 'deliveryToken', 'environment'), (_obj = {
    config: _computed.registry.resolve('config:environment'),

    apiKey: readOnly('config.CONTENT_STACK.apiKey'),

    deliveryToken: readOnly('config.CONTENT_STACK.deliveryToken'),

    environment: readOnly('config.CONTENT_STACK.environment'),

    stack: function stack(apiKey, deliveryToken, environment) {
      return _contentstack.default.Stack({ 'api_key': apiKey, 'delivery_token': deliveryToken, 'environment': environment });
    },
    fetchTextContent: function fetchTextContent(entryUid) {
      return get(this, 'stack').ContentType('account_opening_text_content').Entry(entryUid).fetch().then(function (entry) {
        return entry.get('content');
      });
    }
  }, (_applyDecoratedDescriptor(_obj, 'stack', [_dec], Object.getOwnPropertyDescriptor(_obj, 'stack'), _obj)), _obj)));
});