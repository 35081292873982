define('twapi/components/twapi-document-field', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      oneWay = Ember.computed.oneWay,
      getProperties = Ember.getProperties,
      set = Ember.set;
  exports.default = Component.extend({
    classNames: ['twapi-document-field'],

    document: null,

    fieldKey: null,

    scope: null,

    displayName: oneWay('document.displayName'),

    actions: {
      markViewed: function markViewed() {
        var _getProperties = getProperties(this, 'fieldKey', 'scope'),
            scope = _getProperties.scope,
            fieldKey = _getProperties.fieldKey;

        set(scope, fieldKey, true);
      }
    }
  });
});