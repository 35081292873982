define('twapi/components/twapi-logout-button', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    session: Ember.inject.service(),
    classNames: 'twapi-logout-button',
    tagName: 'button',

    click: function click() {
      var _this = this;

      this.get('session').invalidate().then(function (resp) {
        return _this.sendAction();
      }).catch(function () {
        return _this.sendAction('onSignOutFail');
      });
    }
  });
});