define("ember-cli-foundation-6-sass/initializers/zf-widget", ["exports", "jquery", "foundation-sites"], function (_exports, _jquery, _foundationSites) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize()
  /* application */
  {
    // application.inject('route', 'foo', 'service:foo');
    if (_jquery.default && Ember.typeOf((0, _jquery.default)().foundation) === 'function') {
      (0, _jquery.default)().foundation();
    }
  }

  var _default = {
    name: 'zf-widget',
    initialize: initialize
  };
  _exports.default = _default;
});