define('ember-ambitious-forms/components/amb-form-select', ['exports', 'ember-ambitious-forms/mixins/converted-options'], function (exports, _convertedOptions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_convertedOptions.default, {
    layoutName: 'ember-ambitious-forms@components/amb-form-select',
    service: Ember.inject.service('ember-ambitious-forms'),

    tagName: 'span',
    classNames: 'amb-form-select',
    classNameBindings: ['isOptionSelected:option-selected:prompt-selected'],

    isOptionSelected: Ember.computed.notEmpty('value'),

    prompt: Ember.computed.oneWay('service.config.prompt'),

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      if (this.get('convertedOptions.length') === 1) {
        Ember.run.next(function () {
          _this.selectConvertedOption(_this.get('convertedOptions.firstObject'));
        });
      }
    },


    actions: {
      selectChange: function selectChange(domSelect) {
        var selectedIndex = domSelect.selectedIndex;
        if (this.get('prompt')) {
          selectedIndex--;
        }
        var option = this.get('convertedOptions').objectAt(selectedIndex);
        this.selectConvertedOption(option);
      }
    }
  });
});