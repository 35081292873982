define('twapi/utils/regex/index', ['exports', 'twapi/utils/regex/constants', 'twapi/utils/regex/exact', 'twapi/utils/regex/combinators'], function (exports, _constants, _exact, _combinators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.combinators = exports.exact = undefined;
  Object.keys(_constants).forEach(function (key) {
    if (key === "default" || key === "__esModule") return;
    Object.defineProperty(exports, key, {
      enumerable: true,
      get: function () {
        return _constants[key];
      }
    });
  });
  Object.defineProperty(exports, 'exact', {
    enumerable: true,
    get: function () {
      return _exact.default;
    }
  });
  exports.combinators = _combinators;
});