define('twapi/models/account-beneficiary', ['exports', 'lodash', 'ember-restless', 'twapi/models/address', 'twapi/utils/validation', 'twapi/utils/mask', 'ember-text-mask', 'twapi/mixins/name', 'twapi/mixins/tax-number'], function (exports, _lodash, _emberRestless, _address, _validation, _mask, _emberTextMask, _name, _taxNumber) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.BeneficiaryTypes = undefined;
  var BeneficiaryTypes = exports.BeneficiaryTypes = {
    PRIMARY: 'Primary',
    CONTINGENT: 'Contingent'
  };

  exports.default = _emberRestless.default.Model.extend(_name.default, _taxNumber.default, {

    id: _emberRestless.default.attr('number'),
    account: _emberRestless.default.belongsTo('account'),
    accountNumber: _emberRestless.default.attr('string'),
    address: _emberRestless.default.belongsTo('address', { defaultValue: function defaultValue() {
        return _address.default.create();
      } }),
    birthDate: _emberRestless.default.attr('string'),

    taxNumberMask: _mask.SSN, // TODO: handle other tax numbers when we accept Trusts
    maskedTaxNumber: Ember.computed('taxNumber', 'taxNumberMask', {
      get: function get(key) {
        return (0, _emberTextMask.conformToMask)(this.get('taxNumber'), this.get('taxNumberMask')).conformedValue;
      },
      set: function set(key, value) {
        return this.set('taxNumber', value);
      }
    }),
    beneficiaryType: _emberRestless.default.attr('string'),
    beneficiaryRelationship: _emberRestless.default.attr('string'),
    beneficiaryPercent: _emberRestless.default.attr('number'),

    isEqual: function isEqual(other) {
      if (Ember.isPresent(this.get('id'))) {
        return this.get('id') === other.get('id');
      } else {
        return this.get('taxNumber') === other.get('taxNumber') && this.get('beneficiaryType') === other.get('beneficiaryType');
      }
    },
    serialize: function serialize() {
      var serialized = this._super.apply(this, arguments);

      if (Ember.isPresent(this.get('id'))) {
        return _lodash.default.omit(serialized, 'tax-number', 'tax-number-type');
      }

      return serialized;
    }
  }).reopen(_validation.build(_lodash.default.merge({

    account: { presence: true },
    accountNumber: { presence: true },
    address: { presence: true },
    birthDate: {
      presence: {
        presence: true,
        message: _validation.BIRTH_DATE_VALIDATION_MESSAGE
      }
    },
    beneficiaryType: { inclusion: { in: _lodash.default.values(BeneficiaryTypes) } },
    beneficiaryRelationship: { presence: true },
    beneficiaryPercent: {
      number: {
        allowString: true,
        integer: true,
        gt: 0,
        lte: 100
      },
      presence: true
    }
  }, _name.NAME_VALIDATIONS, _taxNumber.TAX_NUMBER_VALIDATIONS))).reopenClass({
    resourceName: 'account-beneficiaries'
  });
});