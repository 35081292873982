define('twapi/models/portfolio-margin-questionnaire', ['exports', 'ember-restless', 'lodash', 'twapi/utils/validation'], function (exports, _emberRestless, _lodash, _validation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var equal = Ember.computed.equal,
      get = Ember.get;
  var attr = _emberRestless.default.attr,
      Model = _emberRestless.default.Model;


  var MARGIN_BALANCE_RANGE_MAX = 20000001;
  var MARGIN_BALANCE_RANGES = [0, 5000001, 10000001, MARGIN_BALANCE_RANGE_MAX];
  var MONTHLY_TRADE_COUNTS = [0, 11, 51, 101];
  var OPTION_TRADE_SIZES = [0, 6, 11, 21];
  var EQUITY_TRADE_SIZES = [0, 251, 501, 1001];
  var OPENING_ACCOUNT_BALANCES = [0, 250001, 500001, 1000001];

  exports.default = Model.extend({
    isVolatilityArbitrage: attr('boolean'),
    isRiskArbitrage: attr('boolean'),
    isLongShortBias: attr('boolean'),
    isMergerArbitrage: attr('boolean'),

    hasVerticalSpreads: attr('boolean'),
    hasCalendarSpreads: attr('boolean'),
    hasMultiLegSpreads: attr('boolean'),
    hasMultiProductSpreads: attr('boolean'),
    hasCoveredSpreads: attr('boolean'),
    hasNakedSpreads: attr('boolean'),

    isTradingComplexStrategies: attr('boolean'),
    hasOvernightPositions: attr('boolean'),
    isFlatEndOfDay: attr('boolean'),
    isHighConcentrationIndividualEquity: attr('boolean'),
    isDiversifyingSinglePosition: attr('boolean'),
    hasPreviousPortfolioMargin: attr('boolean'),
    hasTypicalMarginBalance: attr('boolean'),
    marginBalanceRange: attr('number'),
    specificMarginBalance: attr('number'),

    monthlyTrades: attr('number'),
    optionTradeSize: attr('number'),
    equityTradeSize: attr('number'),

    openingAccountBalance: attr('number'),

    hasSpecificMarginBalance: equal('marginBalanceRange', MARGIN_BALANCE_RANGE_MAX),

    filterBalanceProperties: function filterBalanceProperties(serialized) {
      var balanceProperties = {};

      if (get(this, 'hasTypicalMarginBalance')) {
        _lodash.default.merge(balanceProperties, _lodash.default.pick(serialized, 'margin-balance-range'));

        if (get(this, 'hasSpecificMarginBalance')) {
          _lodash.default.merge(balanceProperties, _lodash.default.pick(serialized, 'specific-margin-balance'));
        }
      }

      return _lodash.default.merge(_lodash.default.omit(serialized, 'margin-balance-range', 'specific-margin-balance'), balanceProperties);
    },
    serialize: function serialize() {
      var serialized = this._super.apply(this, arguments);

      serialized = this.filterBalanceProperties(serialized);

      return serialized;
    }
  }).reopen(_validation.build({
    isVolatilityArbitrage: { presence: true },
    isRiskArbitrage: { presence: true },
    isLongShortBias: { presence: true },
    isMergerArbitrage: { presence: true },

    hasVerticalSpreads: { presence: true },
    hasCalendarSpreads: { presence: true },
    hasMultiLegSpreads: { presence: true },
    hasMultiProductSpreads: { presence: true },
    hasCoveredSpreads: { presence: true },
    hasNakedSpreads: { presence: true },
    hasTypicalMarginBalance: { presence: true },

    isTradingComplexStrategies: { presence: true },
    hasOvernightPositions: { presence: true },
    isFlatEndOfDay: { presence: true },
    isHighConcentrationIndividualEquity: { presence: true },
    isDiversifyingSinglePosition: { presence: true },
    hasPreviousPortfolioMargin: { presence: true },
    marginBalanceRange: {
      inclusion: { in: MARGIN_BALANCE_RANGES }
    },
    specificMarginBalance: {
      presence: true,
      number: {
        allowString: true,
        integer: true,
        gte: MARGIN_BALANCE_RANGE_MAX,
        message: 'must be >$20M if specifying a margin balance'
      }
    },
    monthlyTrades: {
      inclusion: { in: MONTHLY_TRADE_COUNTS }
    },
    optionTradeSize: {
      inclusion: { in: OPTION_TRADE_SIZES }
    },
    equityTradeSize: {
      inclusion: { in: EQUITY_TRADE_SIZES }
    },
    openingAccountBalance: {
      inclusion: { in: OPENING_ACCOUNT_BALANCES }
    }
  })).reopenClass({
    resourceName: 'portfolio-margin-questionnaire'
  });
});