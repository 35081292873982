define('twapi/utils/mixin', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.createFlagsMixin = createFlagsMixin;
  function createFlagsMixin(flags) {
    var properties = {};

    _lodash.default.forEach(flags, function (flagValues, flagsProperty) {
      properties[flagsProperty] = Ember.computed(function () {
        return Ember.A();
      });

      _lodash.default.forEach(flagValues, function (flagValue) {
        var property = 'has' + Ember.String.classify(flagValue);

        properties[property] = Ember.computed(flagsProperty + '.[]', {
          get: function get() {
            return this.get(flagsProperty).contains(flagValue);
          },
          set: function set(_key, value) {
            if (value) {
              this.get(flagsProperty).removeObject(flagValue);
            } else {
              this.get(flagsProperty).addObject(flagValue);
            }

            return Boolean(value);
          }
        });
      });
    });

    return Ember.Mixin.create(properties);
  }
});