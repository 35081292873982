define('twapi/models/-server-static', ['exports', 'ember-restless', 'twapi/utils/restless', 'twapi/utils/object'], function (exports, _emberRestless, _restless, _object) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberRestless.default.ReadOnlyModel.extend({}).reopenClass({
    find: function find(params) {
      if (typeof params === 'string' || typeof params === 'number') {
        return this.findByKey(params);
      } else if (params) {
        return this.findQuery(params);
      } else {
        return this.findAll();
      }
    },
    fetch: function fetch(params) {
      return _restless.default.asPromise(this.find(params));
    },
    findAll: function findAll() {
      var _this = this;

      if (!this._cached || Ember.get(this, '_cached.isError')) {
        this._cached = this._super();
        if (typeof this.afterFindAll === 'function') {
          this._cached.on('didLoad', function () {
            return _this.afterFindAll(_this._cached);
          });
        }
      }

      return this._cached;
    },
    findQuery: function findQuery(params) {
      var ret = _emberRestless.default.RecordArray.create();
      this.fetch().then(function (all) {
        var matching = all.filter(function (instance) {
          return _object.default.hasProperties(instance, params);
        });
        ret.pushObjects(matching);
        ret.onLoaded();
      }).catch(function (error) {
        ret.onError(error);
      });
      return ret;
    },
    findByKey: function findByKey(key) {
      var primaryKey = Ember.get(this, 'primaryKey');
      var all = this.findAll();
      if (all.get('isLoaded')) {
        return all.findBy(primaryKey, key);
      }

      var instance = this.create();
      this.fetch().then(function (all) {
        var found = all.findBy(primaryKey, key);
        _restless.default.copyFields(found, instance);
        instance.onLoaded();
      }).catch(function (error) {
        instance.onError(error);
      });
      return instance;
    }
  });
});