define('ember-text-mask-addons/index', ['exports', 'ember-text-mask-addons/createAutoCorrectedDatePipe', 'ember-text-mask-addons/createNumberMask', 'ember-text-mask-addons/emailMask'], function (exports, _createAutoCorrectedDatePipe, _createNumberMask, _emailMask) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.emailMask = exports.createNumberMask = exports.createAutoCorrectedDatePipe = undefined;
  exports.default = { createAutoCorrectedDatePipe: _createAutoCorrectedDatePipe.default, createNumberMask: _createNumberMask.default, emailMask: _emailMask.default };
  exports.createAutoCorrectedDatePipe = _createAutoCorrectedDatePipe.default;
  exports.createNumberMask = _createNumberMask.default;
  exports.emailMask = _emailMask.default;
});