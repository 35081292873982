define('twapi/adapters/twapi', ['exports', 'ember-restless', 'ember-decorators/object', 'twapi/utils/computed'], function (exports, _emberRestless, _object, _computed) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _desc, _value, _obj;

  exports.default = _emberRestless.default.RESTAdapter.extend((_dec = (0, _object.computed)('config.twapi.baseURL'), (_obj = {
    config: _computed.default.registry.resolve('config:environment'),
    headers: Ember.computed(function () {
      return {};
    }),

    init: function init() {
      this._super.apply(this, arguments);

      this.registerTransform('bigNumber', Ember.getOwner(this).lookup('transform:big-number'));
      this.registerTransform('momentDateTime', Ember.getOwner(this).lookup('transform:moment-date-time'));
      this.registerTransform('percent', Ember.getOwner(this).lookup('transform:percent'));

      // RESTLess doesn't like this as a computed property
      this.set('serializer', Ember.getOwner(this).lookup('serializer:twapi'));
    },
    rootPath: function rootPath(baseURL) {
      if (!baseURL) {
        return this._super();
      }

      return baseURL.replace(/\/+$/, '');
    }
  }, (_applyDecoratedDescriptor(_obj, 'rootPath', [_dec], Object.getOwnPropertyDescriptor(_obj, 'rootPath'), _obj)), _obj)));
});