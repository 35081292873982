define('twapi/utils/date-format', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var DATE_FORMAT = exports.DATE_FORMAT = 'YYYY-MM-DD';

  var SHORT_MONTH_DAY_YEAR = exports.SHORT_MONTH_DAY_YEAR = 'M/DD/YY';

  var SHORT_MONTH_DAY = exports.SHORT_MONTH_DAY = 'M/DD';

  var ABBREV_MONTH_DAY = exports.ABBREV_MONTH_DAY = 'MMM D';

  var ABBREV_MONTH_DAY_WITH_ORDINAL = exports.ABBREV_MONTH_DAY_WITH_ORDINAL = 'MMM Do';

  var ABBREV_MONTH_DAY_YEAR = exports.ABBREV_MONTH_DAY_YEAR = 'MMM D YYYY';

  var ABBREV_MONTH_YEAR_DATE = exports.ABBREV_MONTH_YEAR_DATE = 'MMM YY';

  var TIME_24_HOUR = exports.TIME_24_HOUR = 'HH:mm:ss';

  var TIME_12_HOUR = exports.TIME_12_HOUR = 'hh:mma';

  var TIME_COMPACT = exports.TIME_COMPACT = 'h:mma';

  var TIME_WITH_SECONDS = exports.TIME_WITH_SECONDS = 'h:mm:ssa';

  var ISO_DATETIME = exports.ISO_DATETIME = 'YYYY-MM-DDTHH:mm:ssZ';

  var RFC_3339 = exports.RFC_3339 = 'YYYY-MM-DDTHH:mm:ssZ';

  var ORDER_STATUS_DATE_TIME = exports.ORDER_STATUS_DATE_TIME = 'MMM D, YYYY  h:mma';

  var DATE_TIME_COMPACT = exports.DATE_TIME_COMPACT = 'M/DD h:mma';

  var MONTH_DAY = exports.MONTH_DAY = 'MMMM D';
});