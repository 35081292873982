define('twapi/components/amb-form-domestic-phone-number', ['exports', 'lodash', 'libphonenumber-js', 'twapi/templates/components/amb-form-domestic-phone-number', 'twapi/utils/input'], function (exports, _lodash, _libphonenumberJs, _ambFormDomesticPhoneNumber, _input) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.format = format;
  var Component = Ember.Component,
      get = Ember.get,
      set = Ember.set,
      isNone = Ember.isNone,
      next = Ember.run.next;


  var INTERNATIONAL_FORMAT = 'E.164';
  var NATIONAL_FORMAT = 'National';
  var US_COUNTRY_CODE = 'US';

  function sanitize(input) {
    return _lodash.default.trim(input);
  }

  function internationalize(phoneNumber) {
    return (0, _libphonenumberJs.formatNumber)(get(phoneNumber, 'phone'), get(phoneNumber, 'country'), INTERNATIONAL_FORMAT);
  }

  function parse(rawValue) {
    return (0, _libphonenumberJs.parseNumber)(rawValue, US_COUNTRY_CODE);
  }

  function format(rawValue) {
    if (isNone(rawValue)) {
      return '';
    }

    var phoneNumber = parse(rawValue);

    if (isNone(get(phoneNumber, 'phone'))) {
      return rawValue;
    } else if (get(phoneNumber, 'country') === US_COUNTRY_CODE) {
      return (0, _libphonenumberJs.formatNumber)(phoneNumber, NATIONAL_FORMAT);
    } else {
      return new _libphonenumberJs.AsYouType(US_COUNTRY_CODE).input(rawValue);
    }
  }

  exports.default = Component.extend({
    layout: _ambFormDomesticPhoneNumber.default,

    value: null,

    maskedValue: null,

    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this._super.apply(this, arguments);

      var rawValue = sanitize(get(this, 'value'));

      set(this, 'maskedValue', format(rawValue));

      next(function () {
        _this._updateValue();
      });
    },


    actions: {
      onUpdate: function onUpdate() {
        var _this2 = this;

        var previousValue = get(this, 'maskedValue');

        this._updateValue();

        next(function () {
          // NOTE: [KT] no need to focus the input if the value has not changed, e.g. arrow keying
          if (previousValue !== get(_this2, 'maskedValue')) {
            // NOTE: [KT] parentheses for domestic formatting throws off phone number input/modification
            _this2._focusInput();
          }
        });
      }
    },

    _focusInput: function _focusInput() {
      var lastDigitIndex = _lodash.default.findLastIndex(get(this, 'maskedValue'), function (input) {
        return (/\d/.test(input)
        );
      });
      if (lastDigitIndex === -1) {
        return;
      }
      var querySelector = get(this, 'element').querySelector('.amb-form-input');

      _input.focusInputIndex(querySelector, lastDigitIndex + 1);
    },
    _updateValue: function _updateValue() {
      var rawValue = sanitize(get(this, 'maskedValue'));
      var phoneNumber = parse(rawValue);

      if (isNone(get(phoneNumber, 'phone'))) {
        set(this, 'value', rawValue);
      } else {
        set(this, 'value', internationalize(phoneNumber));
      }

      set(this, 'maskedValue', new _libphonenumberJs.AsYouType(US_COUNTRY_CODE).input(rawValue));

      this.sendAction && this.sendAction('action', get(this, 'value'));
    }
  });
});