define('twapi/helpers/tt', ['exports', 'ember-i18n/helper', 'lodash'], function (exports, _helper, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _helper.default.extend({
    compute: function compute(params, hash) {
      var key = _lodash.default.map(params, Ember.String.dasherize).join('.');
      var i18n = this.get('i18n');
      if (!i18n.exists(key) && hash.default) {
        return hash.default;
      } else {
        return this._super([key], hash);
      }
    }
  });
});