define('twapi/transforms/moment-date-time', ['exports', 'ember-restless', 'moment'], function (exports, _emberRestless, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberRestless.default.Transform.extend({
    deserialize: _moment.default,
    serialize: function serialize(obj) {
      if (obj instanceof _moment.default) {
        return obj.format();
      } else {
        return (0, _moment.default)(obj).format();
      }
    }
  });
});