define('twapi/utils/polling', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({
    interval: 5000,
    event: _lodash.default.noop,
    activePoll: null,
    isPolling: false,

    scheduleEvent: function scheduleEvent(event, interval) {
      var _this = this;

      return Ember.run.later(function () {
        event.call();
        _this.set('activePoll', _this.scheduleEvent(event, interval));
      }, interval);
    },
    startPolling: function startPolling() {
      if (Ember.isPresent(this.get('activePoll'))) {
        this.stopPolling();
      }
      this.set('activePoll', this.scheduleEvent(this.get('event'), this.get('interval')));
      this.set('isPolling', true);
    },
    stopPolling: function stopPolling() {
      Ember.run.cancel(this.get('activePoll'));
      this.set('isPolling', false);
    }
  });
});