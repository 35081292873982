define('twapi/utils/deser', ['exports', 'ember-restless', '@tastyworks/tastyworks-api', 'pluralize'], function (exports, _emberRestless, _tastyworksApi, _pluralize) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.toJsObject = undefined;
  exports.getResourceName = getResourceName;
  exports.buildTranslators = buildTranslators;
  exports.buildToJsObjectTranslator = buildToJsObjectTranslator;
  exports.buildModel = buildModel;
  exports.translateItemsResponse = translateItemsResponse;
  exports.translateSingleResponse = translateSingleResponse;
  exports.markError = markError;
  exports.deserialize = deserialize;
  exports.updateFromSingleResponse = updateFromSingleResponse;
  exports.updateFromJsInstance = updateFromJsInstance;
  var get = Ember.get,
      isNone = Ember.isNone,
      isPresent = Ember.isPresent,
      dasherize = Ember.String.dasherize;
  var attr = _emberRestless.default.attr,
      belongsTo = _emberRestless.default.belongsTo,
      hasMany = _emberRestless.default.hasMany,
      RecordArray = _emberRestless.default.RecordArray;
  function getResourceName(field, fieldNames) {
    var objectFieldName = get(field, 'objectFieldName');
    if (isPresent(fieldNames)) {
      var resourceName = get(fieldNames, objectFieldName);
      if (isPresent(resourceName)) {
        return resourceName;
      }
    }
    var singularField = (0, _pluralize.singular)(objectFieldName);
    return dasherize(singularField);
  }

  function buildTranslators(deser) {
    var proxyClass = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

    return {
      createListFromTwResponse: function createListFromTwResponse(response) {
        return translateItemsResponse(response, deser, this);
      },

      createFromTwResponse: function createFromTwResponse(response) {
        var twapiRest = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

        var instance = translateSingleResponse(deser, this, response);
        if (isNone(proxyClass)) {
          return instance;
        }

        return proxyClass.create({ content: instance, twapiRest: twapiRest });
      }
    };
  }

  function buildToJsObjectTranslator(modelClass, deser) {
    return {
      toJsObject: function toJsObject() {
        return _toJsObject(this, modelClass, deser);
      }
    };
  }

  function buildModel(deser, fieldNames) {
    var modelAttributes = {};

    deser.fields.forEach(function (field) {
      if (field instanceof _tastyworksApi.StringField) {
        modelAttributes[field.objectFieldName] = attr('string');
      } else if (field instanceof _tastyworksApi.BooleanField) {
        modelAttributes[field.objectFieldName] = attr('boolean');
      } else if (field instanceof _tastyworksApi.FloatField || field instanceof _tastyworksApi.IntField) {
        modelAttributes[field.objectFieldName] = attr('number');
      } else if (field instanceof _tastyworksApi.DateField || field instanceof _tastyworksApi.DateTimeField) {
        modelAttributes[field.objectFieldName] = attr('momentDateTime');
        // NOTE: [HR] Field name can be overridden by passing in { fieldName: 'resource-name', fieldName2: 'resource-name2', ...}
      } else if (field instanceof _tastyworksApi.NestedField) {
        modelAttributes[field.objectFieldName] = belongsTo(getResourceName(field), fieldNames);
      } else if (field instanceof _tastyworksApi.ArrayField) {
        modelAttributes[field.objectFieldName] = hasMany(getResourceName(field), fieldNames);
      }
    });

    return modelAttributes;
  }

  function translateItemsResponse(response, deser, modelClass) {
    var array = RecordArray.create();

    if (get(response, 'isValid')) {
      get(response, 'items').forEach(function (item) {
        var itemInstance = deserialize(item, deser, modelClass);
        array.pushObject(itemInstance);
      });

      var pagination = get(response, 'pagination');

      if (isPresent(pagination)) {
        var meta = Ember.Object.create();

        meta.set('pagination', _.mapKeys(pagination, function (value, key) {
          return Ember.String.camelize(key);
        }));
        array.set('meta', meta);
      }

      array.onLoaded();
    } else {
      markError(response, array);
    }

    return array;
  }

  function translateSingleResponse(deser, modelClass, response) {
    if (!get(response, 'isValid')) {
      return markError(response, modelClass.create());
    }

    var rawJson = get(response, 'data');
    if (!isPresent(rawJson)) {
      return null;
    }

    var instance = deserialize(get(response, 'data'), deser, modelClass);
    instance.onLoaded();
    return instance;
  }

  function markError(response, instance) {
    instance.onError(get(response, 'errorContainer'));
    return instance;
  }

  function deserialize(rawJson, deser, modelClass) {
    var jsonBuilder = new _tastyworksApi.JsonBuilder();
    deser.serialize(rawJson, jsonBuilder);
    return modelClass.create().deserialize(get(jsonBuilder, 'json'));
  }

  function updateFromSingleResponse(target, response) {
    if (!get(response, 'isValid')) {
      return markError(response, target);
    }
    var data = get(response, 'data');
    updateFromJsInstance(target, data);

    return target;
  }

  function updateFromJsInstance(target, jsInstance) {
    target.deserialize(jsInstance);
    return target;
  }

  function _toJsObject(emberInstance, modelClass, deser) {
    var target = new modelClass();
    var jsonHelper = new _tastyworksApi.JsonHelper(emberInstance.serialize());
    deser.update(target, jsonHelper);
    return target;
  }
  exports.toJsObject = _toJsObject;
});