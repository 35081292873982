define('twapi/mixins/document-verification-upload', ['exports', 'twapi/utils/validation'], function (exports, _validation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Mixin = Ember.Mixin,
      set = Ember.set;
  exports.default = Mixin.create({
    idUploaded: false,

    addressUploaded: false,

    actions: {
      idUploading: function idUploading() {
        set(this, 'idUploaded', false);
      },
      addressUploading: function addressUploading() {
        set(this, 'addressUploaded', false);
      },
      idUploadedSuccess: function idUploadedSuccess() {
        set(this, 'idUploaded', true);
      },
      addressUploadSuccess: function addressUploadSuccess() {
        set(this, 'addressUploaded', true);
      }
    }
  }).reopen(_validation.build({
    idUploaded: {
      inclusion: {
        in: [true]
      }
    },
    addressUploaded: {
      inclusion: {
        in: [true]
      }
    }
  }));
});