define('twapi/models/customer', ['exports', 'lodash', 'ember-restless', 'ember-decorators/object', 'ember-decorators/object/evented', 'twapi/models/referral', 'twapi/models/address', 'twapi/models/customer-suitability', 'twapi/models/futures-agreement-record', 'twapi/mixins/adult-birth-date', 'twapi/mixins/name', 'twapi/mixins/tax-number', 'twapi/utils/date-format', 'twapi/utils/country', 'twapi/utils/validation', 'twapi/utils/sanitize', 'twapi/utils/computed', 'twapi/utils/restless', 'moment'], function (exports, _lodash, _emberRestless, _object, _evented, _referral, _address, _customerSuitability, _futuresAgreementRecord, _adultBirthDate, _name, _taxNumber, _dateFormat, _country, _validation, _sanitize, _computed, _restless, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.DOMESTIC_PHONE_NUMBER_MESSAGE = exports.INTERNATIONAL_PHONE_NUMBER_MESSAGE = undefined;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _desc, _value, _obj;

  var _Ember$computed = Ember.computed,
      and = _Ember$computed.and,
      gte = _Ember$computed.gte,
      not = _Ember$computed.not,
      get = Ember.get,
      isEqual = Ember.isEqual,
      isPresent = Ember.isPresent,
      set = Ember.set,
      setProperties = Ember.setProperties;
  var Model = _emberRestless.default.Model,
      attr = _emberRestless.default.attr,
      belongsTo = _emberRestless.default.belongsTo,
      hasMany = _emberRestless.default.hasMany;


  var NORMAL_IRA_YEARS = 59.5;

  var US_CITIZEN_TYPE = 'Citizen';
  var PERMANENT_RESIDENT_TYPE = 'Permanent Resident';
  var NON_RESIDENT_ALIEN_TYPE = 'Nonresident Alien';
  var NONE_TYPE = 'None';

  var VISA_TYPES = ['E1', 'E2', 'E3', 'F1', 'H1B', 'L1', 'O1', 'TN1'];

  var INTERNATIONAL_PHONE_NUMBER_MESSAGE = exports.INTERNATIONAL_PHONE_NUMBER_MESSAGE = 'Invalid phone number. Please include the country code';
  var DOMESTIC_PHONE_NUMBER_MESSAGE = exports.DOMESTIC_PHONE_NUMBER_MESSAGE = 'This field must be a valid phone number';

  var model = Model.extend(_adultBirthDate.default, _name.default, _taxNumber.default, (_dec = (0, _object.computed)('birthDate'), _dec2 = (0, _object.computed)('taxNumber'), _dec3 = (0, _evented.on)('didLoad'), _dec4 = (0, _evented.on)('didUpdate'), _dec5 = (0, _object.computed)('address', 'mailingAddress'), _dec6 = (0, _evented.on)('init'), _dec7 = (0, _object.observes)('address'), _dec8 = (0, _evented.on)('init'), _dec9 = (0, _object.observes)('mailingAddress'), _dec10 = (0, _evented.on)('init'), _dec11 = (0, _object.observes)('customerSuitability'), _dec12 = (0, _object.computed)('config.environment', 'isUsCitizen'), _dec13 = (0, _object.computed)('address.isForeign', 'citizenshipCountry'), _dec14 = (0, _object.computed)('address.isDomestic', 'isUsCitizen'), _dec15 = (0, _object.computed)('isAlien', 'isPermanentResident'), _dec16 = (0, _object.computed)('address.isForeign', 'mailingAddress.country'), (_obj = {
    config: _computed.default.registry.resolve('config:environment'),

    id: attr('string'),

    address: belongsTo('address', { defaultValue: function defaultValue() {
        return _address.default.create();
      } }),
    mailingAddress: belongsTo('address', { defaultValue: function defaultValue() {
        return _address.default.create();
      } }),
    isAddressSame: attr('boolean', { defaultValue: true }),

    isUsCitizen: attr('boolean'),
    citizenshipCountry: attr('string'),
    birthCountry: attr('string'),
    isPermanentResident: attr('boolean'),
    gender: attr('string'),

    customerSuitability: belongsTo('customerSuitability', { defaultValue: function defaultValue() {
        return _customerSuitability.default.create();
      } }),
    futuresAgreementRecord: belongsTo('futuresAgreementRecord', { defaultValue: function defaultValue() {
        return _futuresAgreementRecord.default.create();
      } }),
    referral: belongsTo('referral', { defaultValue: function defaultValue() {
        return _referral.default.create();
      } }),

    mobilePhoneNumber: attr('string'),
    workPhoneNumber: attr('string'),
    homePhoneNumber: attr('string'),

    foreignTaxNumber: attr('string'),
    visaType: attr('string'),
    visaExpirationDate: attr('string'),

    age: function age(birthDate) {
      return (0, _moment.default)().diff((0, _moment.default)(birthDate), 'years', true);
    },

    isIraDisbursementAge: gte('age', NORMAL_IRA_YEARS),

    hasIndustryAffiliation: attr('boolean'),
    industryAffiliationFirm: attr('string'),
    hasPoliticalAffiliation: attr('boolean'),
    politicalOrganization: attr('string'),
    isInvestmentAdviser: attr('boolean'),
    hasInstitutionalAssets: attr('boolean'),
    familyMemberNames: attr('string'),
    hasListedAffiliation: attr('boolean'),
    listedAffiliationSymbol: attr('string'),
    isProfessional: attr('boolean'),

    subjectToTaxWithholding: attr('boolean', { defaultValue: false }),
    agreedToMargining: attr('boolean'),

    agreedToTerms: attr('boolean'),

    usaCitizenshipType: attr('string'),
    email: attr('string'),

    hasPendingOrApprovedApplication: attr('boolean'),

    permittedAccountTypes: hasMany('account-type', { readOnly: true }),

    hasDelayedQuotes: attr('boolean'),

    sanitizedTaxNumber: function sanitizedTaxNumber(taxNumber) {
      return _sanitize.taxNumber(taxNumber);
    },
    _didLoad: function _didLoad() {
      set(this, 'isAddressSame', get(this, 'addressesMatch'));

      var citizenshipCountry = get(this, 'citizenshipCountry');

      // NOTE: [KT] do not set citizenship questions on load to backfill existing customers
      if (isPresent(citizenshipCountry)) {
        set(this, 'isUsCitizen', isEqual(citizenshipCountry, _country.USA_COUNTRY_CODE));

        if (isEqual(get(this, 'usaCitizenshipType'), PERMANENT_RESIDENT_TYPE)) {
          set(this, 'isPermanentResident', true);
        } else if (isEqual(get(this, 'usaCitizenshipType'), NON_RESIDENT_ALIEN_TYPE)) {
          set(this, 'isPermanentResident', false);
        }
      }
    },
    _didUpdate: function _didUpdate() {
      // TODO: [KT] this guard prevents the record from changing on load but shouldn't be needed
      if (isPresent(get(this, 'isUsCitizen'))) {
        this.updateCitizenshipType();
        this.updateTaxNumberType();
      }
    },
    updateCitizenshipType: function updateCitizenshipType() {
      if (get(this, 'isUsCitizen')) {
        setProperties(this, {
          usaCitizenshipType: US_CITIZEN_TYPE,
          citizenshipCountry: _country.USA_COUNTRY_CODE
        });
      } else if (get(this, 'isPermanentResident')) {
        set(this, 'usaCitizenshipType', PERMANENT_RESIDENT_TYPE);
      } else if (get(this, 'address.isDomestic')) {
        set(this, 'usaCitizenshipType', NON_RESIDENT_ALIEN_TYPE);
      } else {
        set(this, 'usaCitizenshipType', NONE_TYPE);
      }
    },


    updateTaxNumberType: function updateTaxNumberType() {
      if (get(this, 'isUsCitizen') || get(this, 'isAlien')) {
        set(this, 'taxNumberType', _taxNumber.SSN_TYPE);
      } else {
        set(this, 'taxNumberType', _taxNumber.ITIN_TYPE);
      }
    },

    addressesMatch: function addressesMatch(address, mailingAddress) {
      return isEqual(address, mailingAddress);
    },
    _addressObserver: function _addressObserver() {
      var _this = this;

      var address = this.get('address');
      if (address) {
        address.on('didUpdate', function () {
          return _this.trigger('didUpdate');
        });
      }
    },
    _mailingAddressObserver: function _mailingAddressObserver() {
      var _this2 = this;

      var address = this.get('mailingAddress');
      if (address) {
        address.on('didUpdate', function () {
          return _this2.trigger('didUpdate');
        });
      }
    },
    _customerSuitabilityObserver: function _customerSuitabilityObserver() {
      var _this3 = this;

      var suitability = this.get('customerSuitability');
      if (suitability) {
        suitability.on('didUpdate', function () {
          return _this3.trigger('didUpdate');
        });
      }
    },


    isExpatriate: and('isUsCitizen', 'address.isForeign'),

    validateSsn: function validateSsn(environment, isUsCitizen) {
      if (!isUsCitizen) {
        return false;
      }

      // NOTE: disable SSN validations in test to allow creation of Equifax test users
      return environment === 'production' && this._super.apply(this, arguments);
    },
    isForeign: function isForeign(isAddressForeign, citizenshipCountry) {
      return isAddressForeign && !isEqual(_country.USA_COUNTRY_CODE, citizenshipCountry);
    },
    isAlien: function isAlien(isDomestic, isUsCitizen) {
      return isDomestic && isUsCitizen === false;
    },
    isResidentAlien: function isResidentAlien(isAlien, isPermanentResident) {
      return isAlien && !isPermanentResident;
    },
    isForeignMailingCountryMismatch: function isForeignMailingCountryMismatch(addressIsForeign, mailingCountry) {
      return addressIsForeign && isEqual(mailingCountry, _country.USA_COUNTRY_CODE);
    },
    serialize: function serialize() {
      var serialized = this._super.apply(this, arguments);
      var taxNumber = get(serialized, 'tax-number');
      if (isPresent(taxNumber)) {
        set(serialized, 'tax-number', taxNumber.replace(/\D/g, ''));
      }
      this.sanitizeInternationalPhoneNumber(serialized, 'mobile-phone-number');
      this.sanitizeInternationalPhoneNumber(serialized, 'work-phone-number');
      this.sanitizeInternationalPhoneNumber(serialized, 'home-phone-number');

      if (get(this, 'hasPendingOrApprovedApplication')) {
        return _lodash.default.omit(serialized, 'prefix-name', 'first-name', 'middle-name', 'last-name', 'suffix-name', 'birth-date', 'tax-number', 'foreign-tax-number', 'tax-number-type');
      } else {
        return serialized;
      }
    },
    sanitizeInternationalPhoneNumber: function sanitizeInternationalPhoneNumber(serialized, attribute) {
      var number = get(serialized, attribute);
      if (get(this, 'isForeign') && isPresent(number)) {
        set(serialized, attribute, '+' + number.replace(/\D/g, ''));
      }
    },
    deserialize: function deserialize() {
      var taxNumber = get(this, 'taxNumber');
      var foreignTaxNumber = get(this, 'foreignTaxNumber');
      var deserialized = this._super.apply(this, arguments);

      if (!get(this, 'hasPendingOrApprovedApplication')) {
        set(deserialized, 'taxNumber', taxNumber);
        set(deserialized, 'foreignTaxNumber', foreignTaxNumber);
        _restless.default.saveFields(deserialized, ['taxNumber', 'foreignTaxNumber']);
      }

      return deserialized;
    }
  }, (_applyDecoratedDescriptor(_obj, 'age', [_dec], Object.getOwnPropertyDescriptor(_obj, 'age'), _obj), _applyDecoratedDescriptor(_obj, 'sanitizedTaxNumber', [_dec2], Object.getOwnPropertyDescriptor(_obj, 'sanitizedTaxNumber'), _obj), _applyDecoratedDescriptor(_obj, '_didLoad', [_dec3], Object.getOwnPropertyDescriptor(_obj, '_didLoad'), _obj), _applyDecoratedDescriptor(_obj, '_didUpdate', [_dec4], Object.getOwnPropertyDescriptor(_obj, '_didUpdate'), _obj), _applyDecoratedDescriptor(_obj, 'addressesMatch', [_dec5], Object.getOwnPropertyDescriptor(_obj, 'addressesMatch'), _obj), _applyDecoratedDescriptor(_obj, '_addressObserver', [_dec6, _dec7], Object.getOwnPropertyDescriptor(_obj, '_addressObserver'), _obj), _applyDecoratedDescriptor(_obj, '_mailingAddressObserver', [_dec8, _dec9], Object.getOwnPropertyDescriptor(_obj, '_mailingAddressObserver'), _obj), _applyDecoratedDescriptor(_obj, '_customerSuitabilityObserver', [_dec10, _dec11], Object.getOwnPropertyDescriptor(_obj, '_customerSuitabilityObserver'), _obj), _applyDecoratedDescriptor(_obj, 'validateSsn', [_dec12], Object.getOwnPropertyDescriptor(_obj, 'validateSsn'), _obj), _applyDecoratedDescriptor(_obj, 'isForeign', [_dec13], Object.getOwnPropertyDescriptor(_obj, 'isForeign'), _obj), _applyDecoratedDescriptor(_obj, 'isAlien', [_dec14], Object.getOwnPropertyDescriptor(_obj, 'isAlien'), _obj), _applyDecoratedDescriptor(_obj, 'isResidentAlien', [_dec15], Object.getOwnPropertyDescriptor(_obj, 'isResidentAlien'), _obj), _applyDecoratedDescriptor(_obj, 'isForeignMailingCountryMismatch', [_dec16], Object.getOwnPropertyDescriptor(_obj, 'isForeignMailingCountryMismatch'), _obj)), _obj))).reopen(_validation.build(_lodash.default.merge({
    isUsCitizen: { presence: true },
    citizenshipCountry: { inclusion: { in: _country.SELECTABLE_COUNTRIES_OPTIONS } },
    birthCountry: { inclusion: { in: _country.SELECTABLE_COUNTRIES_OPTIONS } },
    isPermanentResident: { presence: true },

    visaType: { inclusion: { in: VISA_TYPES } },
    visaExpirationDate: { presence: true, date: { after: 'now', format: _dateFormat.DATE_FORMAT } },
    email: {
      presence: true,
      format: { type: 'email' }
    },
    foreignTaxNumber: { presence: true },

    mobilePhoneNumber: {
      'domestic-phone-number': {
        disabled: not('model.address.isDomestic'),
        message: DOMESTIC_PHONE_NUMBER_MESSAGE
      },
      'international-phone-number': {
        disabled: not('model.address.isForeign'),
        message: INTERNATIONAL_PHONE_NUMBER_MESSAGE
      }
    },
    workPhoneNumber: {
      'domestic-phone-number': {
        allowBlank: true,
        disabled: not('model.address.isDomestic'),
        message: DOMESTIC_PHONE_NUMBER_MESSAGE
      },
      'international-phone-number': {
        allowBlank: true,
        disabled: not('model.address.isForeign'),
        message: INTERNATIONAL_PHONE_NUMBER_MESSAGE
      }
    },
    homePhoneNumber: {
      'domestic-phone-number': {
        allowBlank: true,
        disabled: not('model.address.isDomestic'),
        message: DOMESTIC_PHONE_NUMBER_MESSAGE
      },
      'international-phone-number': {
        allowBlank: true,
        disabled: not('model.address.isForeign'),
        message: INTERNATIONAL_PHONE_NUMBER_MESSAGE
      }
    },

    isAddressSame: { presence: true },

    hasIndustryAffiliation: { presence: true },
    industryAffiliationFirm: { presence: { if: 'hasIndustryAffiliation' } },

    hasPoliticalAffiliation: { presence: true },
    politicalOrganization: { presence: { if: 'hasPoliticalAffiliation' } },
    familyMemberNames: { presence: { if: 'hasPoliticalAffiliation' } },

    isInvestmentAdviser: { presence: true },
    hasInstitutionalAssets: { presence: true },

    hasListedAffiliation: { presence: true },
    listedAffiliationSymbol: { presence: { if: 'hasListedAffiliation' } },
    gender: { presence: true }
  }, _adultBirthDate.ADULT_BIRTH_DATE_VALIDATIONS, _taxNumber.TAX_NUMBER_VALIDATIONS, _name.NAME_VALIDATIONS))).reopenClass({
    resourceName: 'customer'
  });

  exports.default = model;
});