define('twapi/models/margin-interest-rates', ['exports', 'ember-restless'], function (exports, _emberRestless) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberRestless.default.Model.extend({
    accountNumber: _emberRestless.default.attr('string'),
    debitInterestRate: _emberRestless.default.attr('number'),
    creditInterestRate: _emberRestless.default.attr('number')
  }).reopenClass({
    resourceName: 'margin-interest-rates'
  });
});