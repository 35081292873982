define('twapi/models/required-identity-document', ['exports', 'ember-restless'], function (exports, _emberRestless) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberRestless.default.Model,
      attr = _emberRestless.default.attr;
  exports.default = Model.extend({
    slug: attr('string')
  }).reopenClass({
    resourceName: 'required-identity-document'
  });
});