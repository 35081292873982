define('twapi/components/twapi-toasts', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Component.extend({
    twapiToaster: Ember.inject.service(),

    classNames: 'twapi-toasts',

    type: null,
    types: Ember.computed('type', function () {
      var type = this.get('type');
      if (type) {
        return this.get('type').split(/[,\s]+/);
      }
    }),

    messages: Ember.computed('twapiToaster', 'types', function () {
      var types = this.get('types');
      if (types) {
        var _get;

        return (_get = this.get('twapiToaster')).messagesFor.apply(_get, _toConsumableArray(types));
      } else {
        return this.get('twapiToaster.messages');
      }
    }),

    actions: {
      remove: function remove(msg) {
        this.get('twapiToaster').clear(msg);
      }
    }
  }).reopenClass({
    positionalParams: ['type']
  });
});