define('twapi/models/address-suggestion', ['exports', 'ember-decorators/object'], function (exports, _object) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.AddressSuggestion = undefined;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _dec2, _desc, _value, _obj;

  var _Ember$computed = Ember.computed,
      gt = _Ember$computed.gt,
      not = _Ember$computed.not,
      isPresent = Ember.isPresent;
  var AddressSuggestion = exports.AddressSuggestion = Ember.Object.extend((_dec = (0, _object.computed)('hasCompleteAddress', 'streetTwo'), _dec2 = (0, _object.computed)('numberOfEntries', 'streetOne', 'streetTwo', 'city', 'stateRegion', 'postalCode', 'hasSecondaryAddresses'), (_obj = {
    numberOfEntries: null,

    streetOne: null,

    streetTwo: null,

    city: null,

    stateRegion: null,

    postalCode: null,

    hasSecondaryAddresses: gt('numberOfEntries', 1),

    hasCompleteAddress: not('hasSecondaryAddresses'),

    hasCompleteSecondaryAddress: function hasCompleteSecondaryAddress(hasCompleteAddress, streetTwo) {
      return hasCompleteAddress && isPresent('streetTwo');
    },
    suggestedAddress: function suggestedAddress(numberOfEntries, streetOne, streetTwo, city, stateRegion, postalCode, hasSecondaryAddresses) {
      if (hasSecondaryAddresses) {
        return streetOne + ', ' + streetTwo + ', (' + numberOfEntries + ' more entries), ' + city + ', ' + stateRegion + ', ' + postalCode;
      } else if (isPresent(streetTwo)) {
        return streetOne + ', ' + streetTwo + ', ' + city + ', ' + stateRegion + ', ' + postalCode;
      }

      return streetOne + ', ' + city + ', ' + stateRegion + ', ' + postalCode;
    }
  }, (_applyDecoratedDescriptor(_obj, 'hasCompleteSecondaryAddress', [_dec], Object.getOwnPropertyDescriptor(_obj, 'hasCompleteSecondaryAddress'), _obj), _applyDecoratedDescriptor(_obj, 'suggestedAddress', [_dec2], Object.getOwnPropertyDescriptor(_obj, 'suggestedAddress'), _obj)), _obj)));
});