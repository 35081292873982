define('twapi/models/preregistration-prospect', ['exports', 'ember-restless', 'twapi/utils/country'], function (exports, _emberRestless, _country) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberRestless.default.Model,
      attr = _emberRestless.default.attr;
  var _Ember$computed = Ember.computed,
      alias = _Ember$computed.alias,
      equal = _Ember$computed.equal,
      not = _Ember$computed.not;
  exports.default = Model.extend({
    email: attr('string'),

    firstName: attr('string'),

    lastName: attr('string'),

    mailingCity: attr('string'),

    mailingCountryIso: attr('string'),

    mailingPostal: attr('string'),

    mailingState: attr('string'),

    mailingStreet: attr('string'),

    mobilePhone: attr('string'),

    phone: attr('string'),

    isDomestic: equal('mailingCountryIso', _country.USA_COUNTRY_CODE),

    isForeign: not('isDomestic'),

    mailingCountry: alias('mailingCountryIso'),

    mailingPostalCode: alias('mailingPostal'),

    mailingStreetOne: alias('mailingStreet'),

    mailingStateRegion: alias('mailingState')
  }).reopenClass({
    resourceName: 'preregistration-prospect'
  });
});