define('twapi/models/referral-order', ['exports', 'ember-restless', 'ember-decorators/object'], function (exports, _emberRestless, _object) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _dec2, _dec3, _desc, _value, _obj;

  exports.default = _emberRestless.default.Model.extend((_dec = (0, _object.computed)('referralOrderProducts.[]'), _dec2 = (0, _object.computed)('referralOrderProducts.[]'), _dec3 = (0, _object.computed)('cashItems.[]'), (_obj = {
    id: _emberRestless.default.attr('string'),
    totalRequiredCredits: _emberRestless.default.attr('number'),
    totalCashValue: _emberRestless.default.attr('number'),
    redeemedCashValue: _emberRestless.default.attr('number'),
    accountNumber: _emberRestless.default.attr('string'),
    referralOrderProducts: _emberRestless.default.hasMany('referral-order-product'),
    referralProducts: _emberRestless.default.hasMany('referral-product'),
    createdAt: _emberRestless.default.attr('momentDateTime'),

    prizes: function prizes(referralOrderProducts) {
      return referralOrderProducts.filter(function (rop) {
        return rop.get('isCash') === false;
      });
    },
    cashItems: function cashItems(referralOrderProducts) {
      return referralOrderProducts.filter(function (rop) {
        return rop.get('isCash') === true;
      });
    },
    redeemedCreditsFromCash: function redeemedCreditsFromCash(cashItems) {
      return cashItems.reduce(function (prevValue, cashItem) {
        return prevValue + cashItem.get('requiredCredits');
      }, 0);
    },
    deserialize: function deserialize(resp) {
      var deserialized = this._super(resp);
      var referralOrderProducts = deserialized.get('referralOrderProducts');
      var referralProducts = deserialized.get('referralProducts');

      referralOrderProducts.forEach(function (rop) {
        var referralProduct = referralProducts.findBy('id', '' + rop.get('referralProductId'));
        rop.set('referralProduct', referralProduct);
      });
      return deserialized;
    }
  }, (_applyDecoratedDescriptor(_obj, 'prizes', [_dec], Object.getOwnPropertyDescriptor(_obj, 'prizes'), _obj), _applyDecoratedDescriptor(_obj, 'cashItems', [_dec2], Object.getOwnPropertyDescriptor(_obj, 'cashItems'), _obj), _applyDecoratedDescriptor(_obj, 'redeemedCreditsFromCash', [_dec3], Object.getOwnPropertyDescriptor(_obj, 'redeemedCreditsFromCash'), _obj)), _obj))).reopenClass({
    resourceName: 'referral-order'
  });
});