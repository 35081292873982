define('twapi/models/open-api-access-entitlement', ['exports', 'ember-restless', '@tastyworks/tastyworks-api', 'twapi/utils/deser'], function (exports, _emberRestless, _tastyworksApi, _deser) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  var _Ember = Ember,
      equal = _Ember.computed.equal;
  var Model = _emberRestless.default.Model;
  exports.default = Model.extend(_defineProperty({
    isEnabled: equal('status', _tastyworksApi.OpenApiAccessEntitlementStatus.Enabled),
    isRequested: equal('status', _tastyworksApi.OpenApiAccessEntitlementStatus.Disabled),
    isRejected: equal('status', _tastyworksApi.OpenApiAccessEntitlementStatus.Rejected)
  }, 'isRequested', equal('status', _tastyworksApi.OpenApiAccessEntitlementStatus.Requested)), _deser.buildModel(_tastyworksApi.OPEN_API_ACCESS_ENTITLEMENT_DESER)).reopenClass({
    resourceName: 'open-api-access-entitlement'
  }).reopenClass(_deser.buildTranslators(_tastyworksApi.OPEN_API_ACCESS_ENTITLEMENT_DESER)).reopen(_deser.buildToJsObjectTranslator(_tastyworksApi.OpenApiAccessEntitlement, _tastyworksApi.OPEN_API_ACCESS_ENTITLEMENT_DESER));
});