define('twapi/models/external-transaction', ['exports', 'ember-restless'], function (exports, _emberRestless) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.MAX_AMOUNT_ALLOWED = exports.IRA_DISTRIBUTION_REASONS = exports.IRA_CONTRIBUTION_REASONS = exports.TRANSACTION_FREQUENCIES = exports.DISBURSEMENT_TYPES = exports.ONE_TIME_TRANSACTION_FREQUENCIES = exports.DELIVERY_METHODS = exports.TRANSFER_METHODS = exports.TRANSFER_STATES = undefined;
  var equal = Ember.computed.equal;
  var Model = _emberRestless.default.Model,
      attr = _emberRestless.default.attr,
      belongsTo = _emberRestless.default.belongsTo;
  var TRANSFER_STATES = exports.TRANSFER_STATES = {
    PRE_SEND: 'PRE_SEND',
    SEND_FAILED: 'SEND_FAILED',
    APPROVED: 'APPROVED',
    CANCELED: 'CANCELED',
    CANCEL_REQUESTED: 'CANCEL_REQUESTED',
    COMPLETE: 'COMPLETE',
    COMPLETE_RESTRICTED: 'COMPLETE_RESTRICTED',
    FAILED_AT_BANK: 'FAILED_AT_BANK',
    FROZEN: 'FROZEN',
    FUNDS_POSTED: 'FUNDS_POSTED',
    PENDING: 'PENDING',
    PENDING_BROKER_APPROVAL: 'PENDING_BROKER_APPROVAL',
    PENDING_PRINTING: 'PENDING_PRINTING',
    REJECTED: 'REJECTED',
    RETURNED: 'RETURNED',
    SENT_TO_BANK: 'SENT_TO_BANK',
    STOP_PAYMENT: 'STOP_PAYMENT',
    VOID: 'VOID'
  };

  var TRANSFER_METHODS = exports.TRANSFER_METHODS = {
    ACH: 'ACH',
    WIRE: 'WIRE',
    CHECK: 'CHECK'
  };

  var DELIVERY_METHODS = exports.DELIVERY_METHODS = {
    STANDARD: 'Standard',
    OVERNIGHT: 'Overnight'
  };

  var ONE_TIME_TRANSACTION_FREQUENCIES = exports.ONE_TIME_TRANSACTION_FREQUENCIES = {
    ONE_TIME: 'ONE_TIME'
  };

  exports.default = Model.extend({
    id: attr('number'),
    achRelationshipId: attr('number'),
    accountNumber: attr('string'),
    direction: attr('string'),
    amount: attr('number'),
    state: attr('string'),
    extTransferId: attr('string'),
    reason: attr('string'),
    transferMethod: attr('string'),
    disbursementType: attr('string'),
    createdAt: attr('momentDateTime', { readOnly: true }),
    isCancelable: attr('boolean'),

    achRelationship: belongsTo('ach-relationship'),
    iraDetail: belongsTo('ira-detail'),

    isACH: equal('transferMethod', TRANSFER_METHODS.ACH),
    isCheck: equal('transferMethod', TRANSFER_METHODS.CHECK),
    isWire: equal('transferMethod', TRANSFER_METHODS.WIRE),
    isWithdrawal: equal('direction', 'OUTGOING'),
    isDeposit: equal('direction', 'INCOMING'),
    isFullBalance: equal('disbursementType', 'FULL_BALANCE'),
    isPresend: equal('state', TRANSFER_STATES.PRE_SEND),
    isSendFailed: equal('state', TRANSFER_STATES.SEND_FAILED),
    isPending: equal('state', TRANSFER_STATES.PENDING),
    isFundsPosted: equal('state', TRANSFER_STATES.FUNDS_POSTED),
    isCanceled: equal('state', TRANSFER_STATES.CANCELED),
    isComplete: equal('state', TRANSFER_STATES.COMPLETE)
  });
  var DISBURSEMENT_TYPES = exports.DISBURSEMENT_TYPES = {
    FULL_BALANCE: 'FULL_BALANCE',
    PARTIAL_BALANCE: 'PARTIAL_BALANCE'
  };

  var TRANSACTION_FREQUENCIES = exports.TRANSACTION_FREQUENCIES = {
    ONE_TIME: 'ONE_TIME'
  };

  var IRA_CONTRIBUTION_REASONS = exports.IRA_CONTRIBUTION_REASONS = {
    REGULAR: 'REGULAR',
    EMPLOYEE: 'EMPLOYEE',
    EMPLOYER: 'EMPLOYER',
    RECHARACTERIZATION: 'RECHARACTERIZATION',
    ROLLOVER_60_DAY: 'ROLLOVER_60_DAY',
    ROLLOVER_DIRECT: 'ROLLOVER_DIRECT',
    TRANSFER: 'TRANSFER',
    CONVERSION: 'CONVERSION'
  };

  var IRA_DISTRIBUTION_REASONS = exports.IRA_DISTRIBUTION_REASONS = {
    NORMAL: 'NORMAL',
    PREMATURE: 'PREMATURE',
    DISABILITY: 'DISABILITY',
    SOSEPP: 'SOSEPP',
    DEATH: 'DEATH',
    EXCESS_CONTRIBUTION_REMOVAL_BEFORE_TAX_DEADLINE: 'EXCESS_CONTRIBUTION_REMOVAL_BEFORE_TAX_DEADLINE',
    EXCESS_CONTRIBUTION_REMOVAL_AFTER_TAX_DEADLINE: 'EXCESS_CONTRIBUTION_REMOVAL_AFTER_TAX_DEADLINE',
    ROLLOVER_TO_QUALIFIED_PLAN: 'ROLLOVER_TO_QUALIFIED_PLAN',
    ROLLOVER_TO_IRA: 'ROLLOVER_TO_IRA',
    TRANSFER: 'TRANSFER',
    RECHARACTERIZATION_PRIOR_YEAR: 'RECHARACTERIZATION_PRIOR_YEAR',
    RECHARACTERIZATION_CURRENT_YEAR: 'RECHARACTERIZATION_CURRENT_YEAR',
    CONVERSION: 'CONVERSION',
    MANAGEMENT_FEE: 'MANAGEMENT_FEE',
    PREMATURE_SIMPLE_IRA_LESS_THAN_2_YEARS: 'PREMATURE_SIMPLE_IRA_LESS_THAN_2_YEARS',
    NORMAL_ROTH_IRA_GREATER_THAN_5_YEARS: 'NORMAL_ROTH_IRA_GREATER_THAN_5_YEARS'
  };

  var MAX_AMOUNT_ALLOWED = exports.MAX_AMOUNT_ALLOWED = 250000;
});