define('twapi/models/order', ['exports', 'ember-restless', 'twapi/utils/cost'], function (exports, _emberRestless, _cost) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberRestless.default.Model.extend({
    accountNumber: _emberRestless.default.attr('string'),
    price: _emberRestless.default.attr('number'),
    priceEffect: _emberRestless.default.attr('string'),
    status: _emberRestless.default.attr('string'),
    size: _emberRestless.default.attr('number'),
    underlyingSymbol: _emberRestless.default.attr('string'),
    orderType: _emberRestless.default.attr('string'),
    timeInForce: _emberRestless.default.attr('string'),
    destinationVenue: _emberRestless.default.attr('string'),
    legs: _emberRestless.default.hasMany('order-leg'),

    cost: _cost.default.computed('price', 'priceEffect')
  }).reopenClass({
    resourceName: 'order'
  });
});