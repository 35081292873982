define('twapi/models/promo-code', ['exports', 'ember-restless'], function (exports, _emberRestless) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.PROMO_TYPES = undefined;
  var PROMO_TYPES = exports.PROMO_TYPES = {
    BOB: 'bob'
  };

  exports.default = _emberRestless.default.Model.extend({
    externalId: _emberRestless.default.attr('string'),
    code: _emberRestless.default.attr('string'),
    promoType: _emberRestless.default.attr('string')
  });
});