define('twapi/models/autotrade-eligibility', ['exports', 'ember-restless'], function (exports, _emberRestless) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.STATUSES = undefined;
  var equal = Ember.computed.equal;
  var Model = _emberRestless.default.Model,
      attr = _emberRestless.default.attr;
  var STATUSES = exports.STATUSES = {
    ELIGIBLE: 'Eligible',
    INELIGIBLE: 'Ineligible'
  };

  exports.default = Model.extend({
    status: attr('string'),

    reason: attr('string'),

    isEligible: equal('status', STATUSES.ELIGIBLE)
  }).reopenClass({
    resourceName: 'autotrade-eligibility'
  });
});