define('ember-inputmask/components/ssn-input', ['exports', 'ember', 'ember-inputmask/components/input-mask'], function (exports, _ember, _emberInputmaskComponentsInputMask) {

  /**
   * `{{ssn-input}}` component
   *  Displays an input that masks SSN format
   */

  exports['default'] = _emberInputmaskComponentsInputMask['default'].extend({
    mask: '999-99-9999',

    updateMask: function updateMask() {
      this.set('mask', '999-99-9999');
      this._super();
    },

    _maskShouldChange: _ember['default'].observer('mask', function () {
      _ember['default'].run.once(this, 'updateMask');
    })
  });
});