define('twapi/models/document-verification-payload-metainfo', ['exports', 'ember-restless'], function (exports, _emberRestless) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var ReadOnlyModel = _emberRestless.default.ReadOnlyModel,
      attr = _emberRestless.default.attr;
  exports.default = ReadOnlyModel.extend({
    code: attr('number')
  }).reopenClass({
    resourceName: 'document-verification-payload-metainfo'
  });
});