define('twapi/serializers/twapi', ['exports', 'ember-restless', 'lodash', 'twapi/utils/restless'], function (exports, _emberRestless, _lodash, _restless) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberRestless.default.JSONSerializer.extend({
    keyForAttributeName: Ember.String.dasherize,
    keyForResourceName: function keyForResourceName() {
      return 'data';
    },
    _arrayDataForType: function _arrayDataForType(_type, data) {
      if (Ember.isArray(data)) {
        return data;
      }

      var dataForKey = Ember.get(data, 'data.items');
      return dataForKey || [];
    },
    serialize: function serialize(resource) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      return this._super(resource, _lodash.default.defaults(options, {
        nonEmbedded: true,
        includeRelationships: true
      }));
    },
    serializeMany: function serializeMany(recordArray, _type) {
      return recordArray.map(function (item) {
        return _emberRestless.default.Model.detectInstance(item) ? item.serialize() : item;
      });
    },
    deserialize: function deserialize(resource, body) {
      var ret = this._super(resource, body);
      _restless.default.saveFields(resource);
      return ret;
    },
    extractMeta: function extractMeta(json) {
      var meta = Ember.Object.create();

      if (json && json.pagination) {
        meta.set('pagination', _lodash.default.mapKeys(json.pagination, function (value, key) {
          return Ember.String.camelize(key);
        }));
      }

      return meta;
    },
    parseError: function parseError(data) {
      var body = this._super(data) || {};

      if (Ember.get(body, 'error.code') !== 'validation_error') {
        return Ember.Object.create(body);
      }

      var parsedErrors = Ember.Object.create(body);

      parsedErrors.set('error.fields', Ember.get(body, 'error.errors').reduce(function (result, _ref) {
        var domain = _ref.domain,
            reason = _ref.reason;

        result[Ember.String.camelize(domain)] = [reason];

        return result;
      }, {}));
      return parsedErrors;
    }
  });
});