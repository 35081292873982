define('twapi/models/plaid-link-token', ['exports', 'ember-restless'], function (exports, _emberRestless) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  var ReadOnlyModel = _emberRestless.default.ReadOnlyModel,
      attr = _emberRestless.default.attr;
  exports.default = ReadOnlyModel.extend({
    expiration: attr('momentDateTime'),
    linkToken: attr('string'),
    requestId: attr('string'),

    isExpired: function isExpired() {
      var now = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : moment();

      return get(this, 'expiration') < now;
    }
  }).reopenClass({
    resourceName: 'plaid-link-token'
  });
});