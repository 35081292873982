define('twapi/utils/number', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.isZero = isZero;
  exports.isEqual = isEqual;
  exports.isNumber = isNumber;
  exports.round = round;
  var EPS = exports.EPS = 1e-9;

  var TAU = exports.TAU = 2 * Math.PI;

  function isZero(num) {
    var eps = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : EPS;

    return -eps < num && num < eps;
  }

  function isEqual(num1, num2) {
    var eps = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : EPS;

    return isZero(num1 - num2, eps);
  }

  function isNumber(num) {
    return Ember.isPresent(num) && !isNaN(num);
  }

  function round(num) {
    var precision = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;

    return Number(num).toFixed(precision);
  }
});