define('twapi/services/twapi-cookies', ['exports', 'twapi/utils/computed', 'lodash'], function (exports, _computed, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service,
      service = Ember.inject.service,
      get = Ember.get,
      isBlank = Ember.isBlank,
      isPresent = Ember.isPresent;


  var CONGRATULATIONS_FLAG = '_tastyworks_show_congratulations';
  var TWO_FACTOR_DISMISS_COUNT = '_tastyworks_two_factor_dismiss_count';
  var SHOW_NEW_MANAGEMENT_MESSAGE = '_tastyworks_show_new_management_message';
  var ACCOUNT_OPENING_2_SESSION = 'ao2_auth-session';

  exports.default = Service.extend({
    cookies: service(),

    session: service(),

    config: _computed.default.registry.resolve('config:environment'),

    showNewManagementMessage: function showNewManagementMessage() {
      var cookieService = get(this, 'cookies');

      return cookieService.read(SHOW_NEW_MANAGEMENT_MESSAGE) === 'true';
    },
    setShowNewManagementMessage: function setShowNewManagementMessage(value) {
      var cookieService = get(this, 'cookies');

      cookieService.write(SHOW_NEW_MANAGEMENT_MESSAGE, value, this._options());
    },
    initializeShowNewManagementMessage: function initializeShowNewManagementMessage() {
      var showNewManagementMessage = this._getShowNewManagementMessage();
      if (isPresent(showNewManagementMessage)) {
        return;
      }

      this.setShowNewManagementMessage(true);
    },
    hasCongratulations: function hasCongratulations() {
      var cookieService = get(this, 'cookies');

      return cookieService.read(CONGRATULATIONS_FLAG);
    },
    setCongratulations: function setCongratulations(value) {
      var cookieService = get(this, 'cookies');

      cookieService.write(CONGRATULATIONS_FLAG, value, this._options());
    },
    getTwoFactorDismissCount: function getTwoFactorDismissCount() {
      var value = get(this, 'cookies').read(TWO_FACTOR_DISMISS_COUNT);
      if (isBlank(value)) {
        return 0;
      }
      var count = parseInt(value);
      if (isNaN(count)) {
        return 0;
      }

      return count;
    },
    incrementTwoFactorDismissCount: function incrementTwoFactorDismissCount() {
      var cookieService = get(this, 'cookies');
      var count = this.getTwoFactorDismissCount() + 1;

      return cookieService.write(TWO_FACTOR_DISMISS_COUNT, count, this._options());
    },
    _getShowNewManagementMessage: function _getShowNewManagementMessage() {
      var cookieService = get(this, 'cookies');

      return cookieService.read(SHOW_NEW_MANAGEMENT_MESSAGE);
    },
    setAccountOpening2Session: function setAccountOpening2Session() {
      var sessionToken = get(this, 'session.response.sessionToken');
      var cookieService = get(this, 'cookies');
      var sessionData = JSON.stringify({
        authenticated: {
          data: {
            "session-token": sessionToken
          }
        }
      });

      cookieService.write(ACCOUNT_OPENING_2_SESSION, sessionData, this._options());
    },
    _options: function _options() {
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      var defaultDomain = get(this, 'config.cookieDomain');

      return _lodash.default.merge({ domain: defaultDomain, path: '/' }, options);
    }
  });
});