define('ember-ambitious-forms/components/amb-form-read-only', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layoutName: 'ember-ambitious-forms@components/amb-form-read-only',
    classNames: 'amb-form-read-only',

    value: null,
    formattedValue: null,

    displayValue: Ember.computed('formattedValue', 'value', function () {
      var formattedValue = this.get('formattedValue');
      if (formattedValue != null) {
        return formattedValue;
      }

      var value = this.get('value');
      if (value != null) {
        return value.toString();
      }

      return Ember.String.htmlSafe('&nbsp;');
    })
  });
});