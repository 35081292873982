define('twapi/models/user-mobile-confirmation', ['exports', 'ember-restless'], function (exports, _emberRestless) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberRestless.default.Model,
      attr = _emberRestless.default.attr;
  var equal = Ember.computed.equal;


  var STATE = {
    UNCONFIRMED: 'unconfirmed',
    CONFIRMED: 'confirmed',
    UNKNOWN: 'unknown',
    RECONFIRM: 'reconfirm'
  };

  exports.default = Model.extend({

    mobileConfirmationState: attr('string'),

    mobileNumber: attr('string'),

    isConfirmed: equal('mobileConfirmationState', STATE.CONFIRMED),

    isUnconfirmed: equal('mobileConfirmationState', STATE.UNCONFIRMED),

    isUnknown: equal('mobileConfirmationState', STATE.UNKNOWN),

    isReconfirm: equal('mobileConfirmationState', STATE.RECONFIRM)
  });
});