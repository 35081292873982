define('twapi/mixins/object-state-poller', ['exports', 'ember-decorators/object', 'twapi/utils/polling'], function (exports, _object, _polling) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _desc, _value, _obj;

  var get = Ember.get;
  exports.default = Ember.Mixin.create((_dec = (0, _object.observes)('shouldPoll'), (_obj = {
    isPolling: Ember.computed.readOnly('pollManager.isPolling'),

    init: function init() {
      this._super.apply(this, arguments);
      if (get(this, 'shouldPoll')) {
        get(this, 'pollManager').startPolling();
      }
    },


    shouldPoll: null,
    fetchSelf: function fetchSelf() {},

    serialize: function serialize() {
      return get(this, 'content').serialize();
    },
    pollManager: function pollManager() {
      return _polling.default.create({ event: this.fetchSelf.bind(this) });
    },
    pollingStateObserver: function pollingStateObserver() {
      if (get(this, 'shouldPoll')) {
        get(this, 'pollManager').startPolling();
      } else {
        get(this, 'pollManager').stopPolling();
      }
    }
  }, (_applyDecoratedDescriptor(_obj, 'pollManager', [_object.computed], Object.getOwnPropertyDescriptor(_obj, 'pollManager'), _obj), _applyDecoratedDescriptor(_obj, 'pollingStateObserver', [_dec], Object.getOwnPropertyDescriptor(_obj, 'pollingStateObserver'), _obj)), _obj)));
});