define('twapi/models/tax-event', ['exports', 'ember-restless'], function (exports, _emberRestless) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var ReadOnlyModel = _emberRestless.default.ReadOnlyModel,
      attr = _emberRestless.default.attr;
  exports.default = ReadOnlyModel.extend({
    date: attr('momentDateTime'),

    deliveryMethod: attr('string'),

    document: attr('string'),

    documentDescription: attr('string'),

    eventDescription: attr('string')
  });
});