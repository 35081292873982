define('twapi/models/user-two-factor-method', ['exports', 'ember-restless', 'ember-decorators/object'], function (exports, _emberRestless, _object) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.TWO_FACTOR_TYPES = undefined;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _desc, _value, _obj;

  var Model = _emberRestless.default.Model,
      attr = _emberRestless.default.attr;
  var _Ember$computed = Ember.computed,
      and = _Ember$computed.and,
      equal = _Ember$computed.equal,
      or = _Ember$computed.or;
  var TWO_FACTOR_TYPES = exports.TWO_FACTOR_TYPES = {
    AUTHENTICATOR: 'authenticator',
    SMS: 'sms'
  };

  exports.default = Model.extend((_dec = (0, _object.computed)('isNew', 'isActive'), (_obj = {

    isActive: attr('boolean'),

    type: attr('string'),

    isSms: equal('type', TWO_FACTOR_TYPES.SMS),

    isSmsEnabled: and('isSms', 'isActive'),

    isAuthenticator: equal('type', TWO_FACTOR_TYPES.AUTHENTICATOR),

    isAuthenticatorEnabled: and('isAuthenticator', 'isActive'),

    isEnabled: or('isSmsEnabled', 'isAuthenticatorEnabled'),

    isDeactivated: function isDeactivated(isNew, isActive) {
      if (isNew) {
        return false;
      }

      return !isActive;
    }
  }, (_applyDecoratedDescriptor(_obj, 'isDeactivated', [_dec], Object.getOwnPropertyDescriptor(_obj, 'isDeactivated'), _obj)), _obj)));
});