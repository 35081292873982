define('twapi/utils/object', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.hasProperties = hasProperties;
  exports.deleteProperties = deleteProperties;
  exports.copyProperties = copyProperties;
  exports.filterKeys = filterKeys;
  exports.withNonserializedProperties = withNonserializedProperties;
  function hasProperties(instance, params) {
    if (Ember.isArray(params)) {
      return _lodash.default.every(params, function (key) {
        return Ember.get(instance, key) != null;
      });
    } else {
      return _lodash.default.every(params, function (value, key) {
        return Ember.get(instance, key) === value;
      });
    }
  }

  function deleteProperties(instance, keys) {
    if (instance.setProperties) {
      var props = _lodash.default.zipObject(keys);
      instance.setProperties(props);
    } else {
      for (var i = 0; i < keys.length; i++) {
        delete instance[keys[i]];
      }
    }
  }

  function copyProperties(src, dst, keys) {
    var props = Ember.getProperties(src, keys);
    return Ember.setProperties(dst, props);
  }

  function filterKeys(object, iteratee) {
    var func = void 0;
    if (typeof iteratee === 'function') {
      func = iteratee;
    } else {
      func = function func(value) {
        return _lodash.default.isEqual(value, iteratee);
      };
    }

    var filtered = [];
    for (var key in object) {
      if (object.hasOwnProperty(key) && func(object[key], key)) {
        filtered.push(key);
      }
    }
    return filtered;
  }

  function withNonserializedProperties(obj, props) {
    _lodash.default.forEach(props, function (value, key) {
      Object.defineProperty(obj, key, { writable: true, value: value });
    });
    return obj;
  }
});