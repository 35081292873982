define('twapi/components/twapi-content-stack-text', ['exports', 'twapi/templates/components/twapi-content-stack-text'], function (exports, _twapiContentStackText) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      service = Ember.inject.service,
      set = Ember.set;
  exports.default = Ember.Component.extend({
    layout: _twapiContentStackText.default,

    twapiContentStack: service(),

    entryUid: null,

    textContent: null,

    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);
      var entryUid = get(this, 'entryUid');

      get(this, 'twapiContentStack').fetchTextContent(entryUid).then(function (content) {
        return set(_this, 'textContent', content);
      });
    }
  });
});