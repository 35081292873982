define('ember-ambitious-forms/components/amb-form-radio', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layoutName: 'ember-ambitious-forms@components/amb-form-radio',
    tagName: 'label',
    classNames: 'amb-form-radio',
    classNameBindings: ['checked:checked:unchecked', 'disabled:disabled'],

    actions: {
      select: function select() {
        var value = this.get('value');
        this.sendAction('action', value);
      }
    }
  });
});