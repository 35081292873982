define('twapi/models/customer-account-agreeement', ['exports', 'ember-restless'], function (exports, _emberRestless) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberRestless.default.Model,
      attr = _emberRestless.default.attr,
      belongsTo = _emberRestless.default.belongsTo;
  exports.default = Model.extend({

    accountNumber: attr('string'),

    version: attr('string'),

    document: belongsTo('document')

  }).reopenClass({
    resourceName: 'customer-account-agreement'
  });
});