define('twapi/models/valid-distribution', ['exports', 'ember-restless', '@tastyworks/tastyworks-api', 'twapi/utils/deser'], function (exports, _emberRestless, _tastyworksApi, _deser) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberRestless.default.Model;
  exports.default = Model.extend(_deser.buildModel(_tastyworksApi.VALID_DISTRIBUTION_DESER)).reopenClass({
    resourceName: 'valid-distribution'
  });
});