define('twapi/models/account-position', ['exports', 'ember-decorators/object', 'twapi/utils/cost', 'ember-restless', 'twapi/mixins/instrument-type', 'twapi/mixins/quantity'], function (exports, _object, _cost, _emberRestless, _instrumentType, _quantity) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _dec2, _dec3, _desc, _value, _obj;

  exports.default = _emberRestless.default.Model.extend(_instrumentType.default, _quantity.default, (_dec = (0, _object.computed)('markPrice', 'multiplier', 'quantity', 'isShort'), _dec2 = (0, _object.computed)('isLong', 'isShort', 'isZero'), _dec3 = (0, _object.computed)('markCost'), (_obj = {
    accountNumber: _emberRestless.default.attr('string'),
    averageOpenPrice: _emberRestless.default.attr('bigNumber'),
    closePrice: _emberRestless.default.attr('bigNumber'),
    quantity: _emberRestless.default.attr('bigNumber'),
    quantityDirection: _emberRestless.default.attr('string'),
    instrumentType: _emberRestless.default.attr('string'),
    symbol: _emberRestless.default.attr('string'),
    mark: _emberRestless.default.attr('bigNumber'),
    markPrice: _emberRestless.default.attr('bigNumber'),
    multiplier: _emberRestless.default.attr('number'),
    costEffect: _emberRestless.default.attr('string'),
    underlyingSymbol: _emberRestless.default.attr('string'),
    averageOpenCost: _cost.default.computed('averageOpenPrice', 'positionCostEffect'),

    closeCost: _cost.default.computed('closePrice', 'positionCostEffect'),

    markCost: function markCost(markPrice, multiplier, quantity, isShort) {
      var markRaw = quantity.multipliedBy(multiplier).multipliedBy(markPrice);

      if (isShort) {
        return _cost.default.debit(markRaw);
      }

      return _cost.default.credit(markRaw);
    },
    positionCostEffect: function positionCostEffect(isLong, isShort, isZero) {
      if (isZero) {
        return _cost.EFFECTS.NONE;
      } else if (isLong) {
        return _cost.EFFECTS.DEBIT;
      } else if (isShort) {
        return _cost.EFFECTS.CREDIT;
      } else {
        throw new Error('Unknown effect');
      }
    },
    currentValueCost: function currentValueCost(markCost) {
      return markCost.opposite();
    }
  }, (_applyDecoratedDescriptor(_obj, 'markCost', [_dec], Object.getOwnPropertyDescriptor(_obj, 'markCost'), _obj), _applyDecoratedDescriptor(_obj, 'positionCostEffect', [_dec2], Object.getOwnPropertyDescriptor(_obj, 'positionCostEffect'), _obj), _applyDecoratedDescriptor(_obj, 'currentValueCost', [_dec3], Object.getOwnPropertyDescriptor(_obj, 'currentValueCost'), _obj)), _obj))).reopenClass({
    resourceName: 'account-position'
  });
});