define('twapi/utils/slug', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var SLUGS = exports.SLUGS = {
    ADDRESS_DOCUMENT: 'address_document',
    BROKER_FUTURES_CUSTOMER_AGREEMENT: 'broker_futures_customer_agreement',
    BROKER_FUTURES_INTRADAY_MARGIN_AGREEMENT: 'broker_futures_intraday_margin_agreement',
    BROKER_SHORT_UNCOVERED_CALLS_IRA_AGREEMENT: 'broker_short_uncovered_calls_ira_agreement',
    BROKER_TRUST_BENEFICIARY_DISCLOSURE: 'broker_trust_beneficiary_disclosure',
    CLEARING_CORPORATE_ACCOUNT_AGREEMENT: 'clearing_corporate_account_agreement',
    CLEARING_CORPORATE_CASH_ACCOUNT_AGREEMENT: 'clearing_corporate_cash_account_agreement',
    CLEARING_FUTURES_EXCHANGE_TRADED_OPTIONS_CUSTOMER_AGREEMENT: 'clearing_futures_exchange_traded_options_customer_agreement',
    CLEARING_LLC_AGREEMENT: 'clearing_llc_agreement',
    CLEARING_PARTNERSHIP_AGREEMENT: 'clearing_partnership_agreement',
    CLEARING_TRUST_AGREEMENT: 'clearing_trust_agreement',
    ID_DOCUMENT: 'id_document',
    IRS_1042_S_TAX: 'irs_1042_s_tax',
    IRS_1099_TAX: 'irs_1099_tax',
    IRS_1099B_CRYPTOCURRENCIES_TAX: 'irs_1099b_cryptocurrencies_tax',
    IRS_1099B_TAX: 'irs_1099b_tax',
    IRS_1099R_TAX: 'irs_1099r_tax',
    IRS_5498_TAX: 'irs_5498_tax',
    SIGNATURE_IMAGE: 'signature_image',
    SSN_DOCUMENT: 'ssn_document',
    VISA_DOCUMENT: 'visa_document'
  };
});