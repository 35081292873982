define('twapi/utils/computed/registry', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.lookup = lookup;
  exports.resolve = resolve;
  function lookup(name) {
    return Ember.computed(function () {
      return Ember.getOwner(this).lookup(name);
    });
  }

  function resolve(name) {
    return Ember.computed(function () {
      return Ember.getOwner(this).resolveRegistration(name);
    });
  }
});