define('twapi/utils/permitted-account-type', ['exports', 'twapi/utils/sanitize'], function (exports, _sanitize) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.isPermitted = isPermitted;
  exports.getReason = getReason;
  var get = Ember.get,
      isEmpty = Ember.isEmpty,
      isPresent = Ember.isPresent,
      isNone = Ember.isNone,
      underscore = Ember.String.underscore;


  function isPermittedAccountType(permittedAccountType, accountTypePath) {
    var options = { sensitivity: 'base' };

    return get(permittedAccountType, 'name').localeCompare(underscore(_sanitize.accountTypeName(accountTypePath[0])), undefined, options) === 0;
  }

  function isPermitted(permittedAccountTypes) {
    for (var _len = arguments.length, accountTypePath = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      accountTypePath[_key - 1] = arguments[_key];
    }

    if (isNone(permittedAccountTypes)) {
      return false;
    }

    var accountType = permittedAccountTypes.find(function (permittedAccountType) {
      return isPermittedAccountType(permittedAccountType, accountTypePath);
    });

    if (isNone(accountType)) {
      return false;
    } else if (accountTypePath.length === 1) {
      return get(accountType, 'isPermitted') || isPresent(get(accountType, 'subTypes'));
    } else {
      accountTypePath.shift();

      return isPermitted.apply(undefined, [get(accountType, 'subTypes')].concat(accountTypePath));
    }
  }

  function getReason(permittedAccountTypes) {
    for (var _len2 = arguments.length, accountTypePath = Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
      accountTypePath[_key2 - 1] = arguments[_key2];
    }

    if (isEmpty(permittedAccountTypes)) {
      return null;
    }

    var accountType = permittedAccountTypes.find(function (permittedAccountType) {
      return isPermittedAccountType(permittedAccountType, accountTypePath);
    });

    if (isNone(accountType)) {
      return null;
    } else if (accountTypePath.length === 1) {
      return get(accountType, 'reason');
    } else {
      accountTypePath.shift();

      return getReason.apply(undefined, [get(accountType, 'subTypes')].concat(accountTypePath));
    }
  }
});