define('twapi/validators/dependent-compare', ['exports', 'ember-cp-validations/validators/base', 'lodash'], function (exports, _base, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      isNone = Ember.isNone,
      isEmpty = Ember.isEmpty;


  var keys = Object.keys;

  var comparisons = {
    lt: function lt(value, dep) {
      return value < dep;
    },
    gt: function gt(value, dep) {
      return value > dep;
    },
    lte: function lte(value, dep) {
      return value <= dep;
    },
    gte: function gte(value, dep) {
      return value >= dep;
    },
    eq: function eq(value, dep) {
      return value === dep;
    }
  };

  var emberCpValidatorTypes = {
    lt: 'lessThan',
    gt: 'greaterThan',
    lte: 'lessThanOrEqualTo',
    gte: 'greaterThanOrEqualTo',
    eq: 'equalTo'
  };

  function hasValidComparisonOptions(options) {
    var optionsKeys = keys(options);
    return keys(comparisons).filter(function (comparisonKey) {
      return optionsKeys.includes(comparisonKey);
    }).every(function (comparisonKey) {
      return _lodash.default.isString(options[comparisonKey]);
    });
  }

  function findFailedComparisons(value, options, model) {
    var allComparisons = keys(comparisons);
    var activeComparisons = allComparisons.filter(function (comparison) {
      return !isEmpty(options[comparison]);
    });
    var failedComparisons = activeComparisons.filter(function (comparisonKey) {
      var compareFn = comparisons[comparisonKey];
      return !compareFn(value, get(model, options[comparisonKey]));
    });
    return failedComparisons;
  }

  var DependentCompare = _base.default.extend({
    validate: function validate(value, options, model) {
      if (isNone(options) || isNone(model) || isEmpty(keys(options))) {
        return true;
      }

      if (options.allowBlank && isEmpty(value)) {
        return true;
      }

      if (!hasValidComparisonOptions(options)) {
        return true;
      }

      var firstFailedComparison = findFailedComparisons(value, options, model)[0];
      if (firstFailedComparison) {
        var emberCpValidatorType = emberCpValidatorTypes[firstFailedComparison];
        options[firstFailedComparison] = options.targetDisplayName || options[firstFailedComparison];
        // see ember-cp-validations/addon/validators/messages.js
        return this.createErrorMessage(emberCpValidatorType, value, options);
      }

      return true;
    }
  });

  exports.default = DependentCompare;
});