define('twapi/models/internal-cash-transfer', ['exports', 'ember-restless', '@tastyworks/tastyworks-api', 'twapi/proxies/internal-cash-transfer', 'twapi/utils/deser', 'twapi/mixins/ira-detail-sanitize'], function (exports, _emberRestless, _tastyworksApi, _internalCashTransfer, _deser, _iraDetailSanitize) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.INTERNAL_CASH_TRANSFER_FREQUENCIES = exports.MAX_TRANSFER_AMOUNT = undefined;
  var equal = Ember.computed.equal;
  var Model = _emberRestless.default.Model;
  var MAX_TRANSFER_AMOUNT = exports.MAX_TRANSFER_AMOUNT = 250000;

  var INTERNAL_CASH_TRANSFER_FREQUENCIES = exports.INTERNAL_CASH_TRANSFER_FREQUENCIES = {
    ONE_TIME: 'One Time'
  };

  exports.default = Model.extend(_iraDetailSanitize.default, {
    isRejected: equal('status', _tastyworksApi.INTERNAL_CASH_TRANSFER_STATUSES.CLEARING_REJECTED),

    isPending: equal('status', _tastyworksApi.INTERNAL_CASH_TRANSFER_STATUSES.PENDING_SUBMISSION),

    isSubmitted: equal('status', _tastyworksApi.INTERNAL_CASH_TRANSFER_STATUSES.SUBMITTED_TO_CLEARING)
  }, _deser.buildModel(_tastyworksApi.INTERNAL_CASH_TRANSFER_DESER)).reopenClass({
    resourceName: 'internal-cash-transfer'
  }).reopenClass(_deser.buildTranslators(_tastyworksApi.INTERNAL_CASH_TRANSFER_DESER, _internalCashTransfer.default)).reopen(_deser.buildToJsObjectTranslator(_tastyworksApi.InternalCashTransfer, _tastyworksApi.INTERNAL_CASH_TRANSFER_DESER));
});