define('twapi/services/twapi-signature', ['exports', 'raven', 'twapi/utils/computed', 'twapi/utils/slug'], function (exports, _raven, _computed, _slug) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service,
      alias = Ember.computed.alias,
      get = Ember.get,
      service = Ember.inject.service,
      set = Ember.set;
  exports.default = Service.extend({
    session: service(),

    signature: null,

    config: _computed.default.registry.resolve('config:environment'),

    sessionToken: alias('session.response.sessionToken'),

    onStrokeEnd: function onStrokeEnd(signature) {
      set(this, 'signature', signature);
    },
    uploadSignature: function uploadSignature() {
      var _this = this;

      return new Promise(function (resolve, reject) {
        var xhr = new XMLHttpRequest();
        var url = 'customers/me/documents/async';
        var slug = _slug.SLUGS.SIGNATURE_IMAGE;

        xhr.open('POST', '' + get(_this, 'config.twapi.baseURL') + url + '/' + slug, true);
        xhr.setRequestHeader('Authorization', get(_this, 'sessionToken'));
        xhr.onload = function () {
          if (get(this, 'status') === 200 || get(this, 'status') === 201) {
            resolve();
          } else {
            _raven.default.captureMessage('Error uploading signature for application');
            reject(Ember.Object.create({
              error: {
                message: 'Error uploading signature'
              }
            }));
          }
        };
        xhr.onerror = function (progressEvent) {
          var message = 'Unable to upload signature';
          _raven.default.captureException(message, {
            extra: { progressEvent: progressEvent }
          });
          reject(Ember.Object.create({ error: { message: message } }));
        };

        var formData = new FormData();
        formData.append('file', get(_this, 'signature'));

        xhr.send(formData);
      });
    }
  });
});