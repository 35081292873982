define('twapi/mixins/selectable', ['exports', 'ember-decorators/object'], function (exports, _object) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.SelectableArray = undefined;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _dec2, _desc, _value, _obj, _init;

  var SelectableMixin = Ember.Mixin.create((_dec = (0, _object.computed)('content.[]'), _dec2 = (0, _object.computed)('selected', 'content.[]'), (_obj = {
    selectableDefault: null,

    selected: {
      get: function get(content) {
        if (!content) {
          return this.set('_selected', null);
        }

        var current = this.get('_selected');
        if (content.contains(current)) {
          return current;
        }

        var path = this.get('selectableDefault');
        return this.set('_selected', path ? content.get(path) : null);
      },
      set: function set(value, content) {
        return this.set('_selected', value);
      }
    },

    deselected: function deselected(selected, content) {
      if (!content) {
        return;
      }

      if (!selected) {
        return content;
      }

      return content.filter(function (item) {
        return item !== selected;
      });
    }
  }, (_applyDecoratedDescriptor(_obj, 'selected', [_dec], (_init = Object.getOwnPropertyDescriptor(_obj, 'selected'), _init = _init ? _init.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function initializer() {
      return _init;
    }
  }), _obj), _applyDecoratedDescriptor(_obj, 'deselected', [_dec2], Object.getOwnPropertyDescriptor(_obj, 'deselected'), _obj)), _obj)));

  exports.default = SelectableMixin;
  var SelectableArray = exports.SelectableArray = Ember.ArrayProxy.extend(SelectableMixin);
});