define('twapi/models/account-trading-status', ['exports', 'ember-restless'], function (exports, _emberRestless) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberRestless.default.Model,
      attr = _emberRestless.default.attr;
  exports.default = Model.extend({

    accountNumber: attr('string'),

    dayTradeCount: attr('number'),

    equitiesMarginCalculationType: attr('string'),

    hasIntradayEquitiesMargin: attr('boolean'),

    isClosed: attr('boolean'),

    isClosingOnly: attr('boolean'),

    isCryptocurrencyEnabled: attr('boolean'),

    isEquityOfferingClosingOnly: attr('boolean'),

    isEquityOfferingEnabled: attr('boolean'),

    isFrozen: attr('boolean'),

    isFuturesEnabled: attr('boolean'),

    isInDayTradeMinimumEquityCall: attr('boolean'),

    isInMarginCall: attr('boolean'),

    isPatternDayTrader: attr('boolean'),

    optionsLevel: attr('string')

  }).reopenClass({
    resourceName: 'account-trading-status'
  });
});