define('twapi/utils/restless', ['exports', 'ember-restless', 'lodash'], function (exports, _emberRestless, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.asPromise = asPromise;
  exports.loadPromise = loadPromise;
  exports.loadManyPromise = loadManyPromise;
  exports.getFields = getFields;
  exports.saveFields = saveFields;
  exports.restoreFields = restoreFields;
  exports.copyFields = copyFields;
  exports.copyManyFields = copyManyFields;
  exports.changedFields = changedFields;
  exports.hasChanges = hasChanges;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var get = Ember.get,
      getProperties = Ember.getProperties,
      isArray = Ember.isArray,
      isEqual = Ember.isEqual,
      isPresent = Ember.isPresent,
      RSVP = Ember.RSVP,
      set = Ember.set,
      setProperties = Ember.setProperties,
      typeOf = Ember.typeOf;
  function asPromise(restlessModel) {
    if (get(restlessModel, 'isLoaded')) {
      return RSVP.resolve(restlessModel);
    } else if (get(restlessModel, 'isError')) {
      return RSVP.reject(get(restlessModel, 'errors'));
    } else {
      return new RSVP.Promise(function (resolve, reject) {
        function didLoad() {
          restlessModel.off('becameError', becameError);
          return resolve(restlessModel);
        }

        function becameError(error) {
          restlessModel.off('didLoad', didLoad);
          return reject(error);
        }

        restlessModel.one('didLoad', didLoad);
        restlessModel.one('becameError', becameError);
      });
    }
  }

  function loadPromise(klass, promise) {
    var instance = klass.create({ isNew: false });

    promise.then(function (data) {
      instance.deserialize(data);
      instance.onLoaded();
    }).catch(function (error) {
      instance.onError(error);
    });

    return instance;
  }

  function loadManyPromise(klass, promise) {
    var array = _emberRestless.default.RecordArray.createWithContent();

    promise.then(function (data) {
      array.deserializeMany(klass, data);
      array.onLoaded();
    }).catch(function (error) {
      array.onError(error);
    });

    return array;
  }

  function getKeys(resource, fields) {
    var fieldDefinitions = get(resource, 'constructor.fields');
    var keys = fields.length > 0 ? fields : Object.keys(fieldDefinitions);

    return keys;
  }

  function getFields(resource) {
    var fields = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];

    var keys = getKeys(resource, fields);

    return getProperties(resource, keys);
  }

  function saveFields(resource) {
    var fields = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];

    return recursiveSaveFields(resource, fields);
  }

  function previouslySavedFields(resource) {
    return isPresent(get(resource, '_savedFields')) ? get(resource, '_savedFields') : {};
  }

  function recursiveSaveFields(resource, fields) {
    var data = getFields(resource, fields);

    Object.keys(data).forEach(function (property) {
      var value = data[property];

      if (_emberRestless.default.Model.detectInstance(value)) {
        recursiveSaveFields(value);
      }
    });
    var previous = previouslySavedFields(resource);
    return set(resource, '_savedFields', _lodash.default.merge(previous, data));
  }

  function restoreFields(resource) {
    var fields = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];

    if (isArray(resource)) {
      resource.forEach(function (model) {
        restoreFields(model, fields);
      });
      return;
    }

    var state = get(resource, '_savedFields');
    if (!state) {
      console.warn('_savedFields not found – skipping restore...');
    } else {
      if (fields.length > 0) {
        var props = fields.reduce(function (props, field) {
          if (field.includes('.')) {
            var _field$split = field.split(/\.(.+)/),
                _field$split2 = _slicedToArray(_field$split, 2),
                resourceName = _field$split2[0],
                property = _field$split2[1];

            restoreFields(get(state, resourceName), [property]);

            return props;
          } else {
            set(props, field, get(state, field));
          }
          return props;
        }, {});

        recursiveRestoreFields(resource, props);
      } else {
        recursiveRestoreFields(resource, state);
      }
    }
  }

  function recursiveRestoreFields(resource, properties) {
    Object.keys(properties).forEach(function (property) {
      var value = properties[property];
      if (_emberRestless.default.Model.detectInstance(value) && get(value, '_savedFields')) {
        recursiveRestoreFields(value, get(value, '_savedFields'));
      } else {
        set(resource, property, value);
      }
    });
  }

  function copyFields(src, dst) {
    return setProperties(dst, getFields(src));
  }

  function copyManyFields(srcs, dsts) {
    var identity = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'id';

    var fn = typeOf(identity) === 'function' ? identity : function (obj) {
      return get(obj, identity);
    };
    var lookupTable = _lodash.default.indexBy(dsts, fn);
    srcs.forEach(function (src) {
      var key = [fn(src)];
      var dst = lookupTable[key];
      copyFields(src, dst);
    });
  }

  function changedFields(resource) {
    var fields = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];

    return recursiveChangedFields(resource, fields);
  }

  function recursiveChangedFields(resource, fields) {
    var previous = previouslySavedFields(resource);
    var changes = {};
    if (_lodash.default.isEmpty(previous)) {
      return changes;
    }

    var data = getFields(resource, fields);

    Object.keys(data).forEach(function (property) {
      var value = get(data, property);

      if (_emberRestless.default.Model.detectInstance(value)) {
        var nestedChanges = recursiveChangedFields(value);

        if (!_lodash.default.isEmpty(nestedChanges)) {
          set(changes, property, nestedChanges);
        }
      } else if (!isEqual(value, get(previous, property))) {
        set(changes, property, value);
      }
    });

    return changes;
  }

  function hasChanges(resource) {
    var fields = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];

    return recursiveHasChanges(resource, fields);
  }

  function recursiveHasChanges(resource, fields) {
    var previous = previouslySavedFields(resource);
    var data = getFields(resource, fields);

    return Object.keys(data).some(function (property) {
      var value = get(data, property);

      if (_emberRestless.default.Model.detectInstance(value)) {
        return recursiveHasChanges(value);
      } else {
        return !isEqual(value, get(previous, property));
      }
    });
  }
});