define('twapi/locales/en/translations', ['exports', 'twapi/locales/en/amb-form'], function (exports, _ambForm) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    'amb-form': _ambForm.default,

    'login': {
      'password-changed': 'You have been logged out due to a password change. Please log in again with the new credentials.'
    },

    'options-levels': {
      'covered-and-cash-secured': 'Limited',
      'defined-risk-spreads': 'Basic',
      'no-restrictions': 'The Works'
    },

    'residency-country': {
      'aus': {
        'amb-form.customer.foreign-tax-number': 'Passport or other Government-Issued ID Number'
      },
      'mex': {
        'amb-form.customer.foreign-tax-number': 'Unique Population Registry Code (CURP)'
      },
      'mys': {
        'amb-form.customer.foreign-tax-number': 'National Registration ID Card (NRIC) Number'
      },
      'sgp': {
        'amb-form.customer.foreign-tax-number': 'National Registration ID Card (NRIC) Number'
      }
    },

    'twapi': {
      'fully-paid-securities-lending': {
        'description': 'When demand for shorting shares in a specific stock or ETF elevates, locating long shares to short may become scarce.\n                      tastytrade offers Fully Paid Securities Lending for you to earn incremental income on your portfolio\'s long shares\n                      to facilitate a short stock sale. In return for lending shares from your portfolio, you will accrue lending income\n                      each day your shares are lent out.'
      }
    },

    'twapi-address-document-verification': {
      'success-message': 'Your address document has been uploaded'
    },

    'twapi-footer': {
      'cryptocurrency': 'Cryptocurrency transaction and custody services are powered by Zero Hash LLC and Zero Hash Liquidity Services LLC.\n                       Cryptocurrency assets are held and custodied by Zero Hash LLC, not tastytrade. Services may not be available in all states.\n                       Cryptocurrency assets are not subject to Federal Deposit Insurance Corporation (FDIC) or Securities Investor Protection Corporation (SIPC) coverage.\n                       Cryptocurrency trading is not suitable for all investors due to the number of risks involved.\n                       The value of any cryptocurrency, including digital assets pegged to fiat currency, commodities, or any other asset, may go to zero.',
      'renamed': 'tastytrade, Inc. was formerly known as tastyworks, Inc.'
    },

    'twapi-id-document-verification': {
      'success-message': 'Your ID document has been uploaded',
      'time-out-warning': 'Documents must be uploaded within 15 minutes',
      'timed-out': 'Your session has timed out. Click below to upload your documents again.'
    },

    'twapi-two-factor-modal': {
      'one-time-password-error': 'Oops! That security code does not appear to be correct.'
    },

    'twapi-trusted-contact-form': {
      'is-us-resident': 'Does the trusted contact currently reside in the United States?'
    }
  };
});