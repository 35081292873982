define('twapi/services/twapi-plan-access', ['exports', 'twapi/utils/restless', 'ember-decorators/object', 'lodash', 'twapi/models/access-plan', 'twapi/models/options-level'], function (exports, _restless, _object, _lodash, _accessPlan, _optionsLevel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _desc, _value, _obj;

  function asDirective(value) {
    return {
      value: value,
      property: 'has' + value.classify()
    };
  }

  var Service = Ember.Service,
      get = Ember.get,
      service = Ember.inject.service,
      set = Ember.set;
  exports.default = Service.extend((_dec = (0, _object.computed)('optionsLevel.optionsLevel', 'accountType', 'optionsLevel'), (_obj = {

    twapiEndpoints: service(),

    accountType: null,

    optionsLevel: _optionsLevel.default.create(),

    permissionGroups: [_lodash.default.map(_accessPlan.EQUITY_PERMISSIONS, asDirective), _lodash.default.map(_accessPlan.DERIVATIVE_PERMISSIONS, asDirective)],

    accessPlanOptions: function accessPlanOptions(availableOptionsLevel, accountType) {
      return _accessPlan.default.all(accountType).map(function (plan) {
        return Ember.Object.create({
          plan: plan,
          text: get(plan, 'name'),
          value: get(plan, 'optionsLevel'),
          disabled: _optionsLevel.default.create({ optionsLevel: get(plan, 'optionsLevel') }).isDisabled(availableOptionsLevel)
        });
      });
    },
    getAvailableOptionsLevel: function getAvailableOptionsLevel(account) {
      var accountNumber = get(account, 'accountNumber');

      return _restless.default.asPromise(get(this, 'twapiEndpoints').getAvailableOptionsLevel({ accountNumber: accountNumber })).then(this._setOptionsLevel.bind(this));
    },
    _setOptionsLevel: function _setOptionsLevel(optionsLevel) {
      return set(this, 'optionsLevel', _optionsLevel.default.create({ optionsLevel: optionsLevel }));
    }
  }, (_applyDecoratedDescriptor(_obj, 'accessPlanOptions', [_dec], Object.getOwnPropertyDescriptor(_obj, 'accessPlanOptions'), _obj)), _obj)));
});