define('ember-cookies/services/cookies', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var keys = Object.keys;

  var assign = Object.assign || Ember.assign || Ember.merge;
  var DEFAULTS = { raw: false };

  exports.default = Ember.Service.extend({
    _isFastBoot: Ember.computed.reads('_fastBoot.isFastBoot'),

    _fastBoot: Ember.computed(function () {
      var owner = Ember.getOwner(this);

      return owner.lookup('service:fastboot');
    }),

    _document: Ember.computed(function () {
      return document;
    }),

    _documentCookies: Ember.computed(function () {
      var all = this.get('_document.cookie').split(';');
      var filtered = this._filterDocumentCookies(Ember.A(all));

      return filtered.reduce(function (acc, cookie) {
        if (!Ember.isEmpty(cookie)) {
          var _cookie = _slicedToArray(cookie, 2),
              key = _cookie[0],
              value = _cookie[1];

          acc[key.trim()] = (value || '').trim();
        }
        return acc;
      }, {});
    }).volatile(),

    _fastBootCookies: Ember.computed(function () {
      var fastBootCookiesCache = this.get('_fastBootCookiesCache');

      if (!fastBootCookiesCache) {
        var fastBootCookies = this.get('_fastBoot.request.cookies');
        fastBootCookiesCache = Ember.A(keys(fastBootCookies)).reduce(function (acc, name) {
          var value = fastBootCookies[name];
          acc[name] = { value: value };
          return acc;
        }, {});
        this.set('_fastBootCookiesCache', fastBootCookiesCache);
      }

      return this._filterCachedFastBootCookies(fastBootCookiesCache);
    }).volatile(),

    read: function read(name) {
      var _this = this;

      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      options = assign({}, DEFAULTS, options || {});
      (false && !(Ember.isEmpty(options.domain) && Ember.isEmpty(options.expires) && Ember.isEmpty(options.maxAge) && Ember.isEmpty(options.path)) && Ember.assert('Domain, Expires, Max-Age, and Path options cannot be set when reading cookies', Ember.isEmpty(options.domain) && Ember.isEmpty(options.expires) && Ember.isEmpty(options.maxAge) && Ember.isEmpty(options.path)));


      var all = void 0;
      if (this.get('_isFastBoot')) {
        all = this.get('_fastBootCookies');
      } else {
        all = this.get('_documentCookies');
      }

      if (name) {
        return this._decodeValue(all[name], options.raw);
      } else {
        Ember.A(keys(all)).forEach(function (name) {
          return all[name] = _this._decodeValue(all[name], options.raw);
        });
        return all;
      }
    },
    write: function write(name, value) {
      var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

      options = assign({}, DEFAULTS, options || {});
      (false && !(!options.httpOnly) && Ember.assert('Cookies cannot be set to be HTTP-only as those cookies would not be accessible by the Ember.js application itself when running in the browser!', !options.httpOnly));
      (false && !(!options.signed) && Ember.assert("Cookies cannot be set as signed as signed cookies would not be modifyable in the browser as it has no knowledge of the express server's signing key!", !options.signed));
      (false && !(Ember.isEmpty(options.expires) || Ember.isEmpty(options.maxAge)) && Ember.assert('Cookies cannot be set with both maxAge and an explicit expiration time!', Ember.isEmpty(options.expires) || Ember.isEmpty(options.maxAge)));

      value = this._encodeValue(value, options.raw);

      if (this.get('_isFastBoot')) {
        this._writeFastBootCookie(name, value, options);
      } else {
        this._writeDocumentCookie(name, value, options);
      }
    },
    clear: function clear(name) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      (false && !(Ember.isEmpty(options.expires) && Ember.isEmpty(options.maxAge) && Ember.isEmpty(options.raw)) && Ember.assert('Expires, Max-Age, and raw options cannot be set when clearing cookies', Ember.isEmpty(options.expires) && Ember.isEmpty(options.maxAge) && Ember.isEmpty(options.raw)));


      options.expires = new Date('1970-01-01');
      this.write(name, null, options);
    },
    exists: function exists(name) {
      var all = void 0;
      if (this.get('_isFastBoot')) {
        all = this.get('_fastBootCookies');
      } else {
        all = this.get('_documentCookies');
      }

      return all.hasOwnProperty(name);
    },
    _writeDocumentCookie: function _writeDocumentCookie(name, value) {
      var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

      var serializedCookie = this._serializeCookie(name, value, options);
      this.set('_document.cookie', serializedCookie);
    },
    _writeFastBootCookie: function _writeFastBootCookie(name, value) {
      var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

      var responseHeaders = this.get('_fastBoot.response.headers');
      var serializedCookie = this._serializeCookie.apply(this, arguments);

      if (!Ember.isEmpty(options.maxAge)) {
        options.maxAge *= 1000;
      }

      this._cacheFastBootCookie.apply(this, arguments);

      responseHeaders.append('set-cookie', serializedCookie);
    },
    _cacheFastBootCookie: function _cacheFastBootCookie(name, value) {
      var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

      var fastBootCache = this.getWithDefault('_fastBootCookiesCache', {});
      var cachedOptions = Ember.merge({}, options);

      if (cachedOptions.maxAge) {
        var expires = new Date();
        expires.setSeconds(expires.getSeconds() + options.maxAge);
        cachedOptions.expires = expires;
        delete cachedOptions.maxAge;
      }

      fastBootCache[name] = { value: value, options: cachedOptions };
      this.set('_fastBootCookiesCache', fastBootCache);
    },
    _filterCachedFastBootCookies: function _filterCachedFastBootCookies(fastBootCookiesCache) {
      var _get = this.get('_fastBoot.request'),
          requestPath = _get.path,
          protocol = _get.protocol;

      // cannot use deconstruct here


      var host = this.get('_fastBoot.request.host');

      return Ember.A(keys(fastBootCookiesCache)).reduce(function (acc, name) {
        var _fastBootCookiesCache = fastBootCookiesCache[name],
            value = _fastBootCookiesCache.value,
            options = _fastBootCookiesCache.options;

        options = options || {};

        var _options = options,
            optionsPath = _options.path,
            domain = _options.domain,
            expires = _options.expires,
            secure = _options.secure;


        if (optionsPath && requestPath.indexOf(optionsPath) !== 0) {
          return acc;
        }

        if (domain && host.indexOf(domain) + domain.length !== host.length) {
          return acc;
        }

        if (expires && expires < new Date()) {
          return acc;
        }

        if (secure && !(protocol || '').match(/^https/)) {
          return acc;
        }

        acc[name] = value;
        return acc;
      }, {});
    },
    _encodeValue: function _encodeValue(value, raw) {
      if (Ember.isNone(value)) {
        return '';
      } else if (raw) {
        return value;
      } else {
        return encodeURIComponent(value);
      }
    },
    _decodeValue: function _decodeValue(value, raw) {
      if (Ember.isNone(value) || raw) {
        return value;
      } else {
        return decodeURIComponent(value);
      }
    },
    _filterDocumentCookies: function _filterDocumentCookies(unfilteredCookies) {
      return unfilteredCookies.map(function (c) {
        return c.split('=');
      }).filter(function (c) {
        return c.length === 2 && Ember.isPresent(c[0]);
      });
    },
    _serializeCookie: function _serializeCookie(name, value) {
      var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

      var cookie = name + '=' + value;

      if (!Ember.isEmpty(options.domain)) {
        cookie = cookie + '; domain=' + options.domain;
      }
      if (Ember.typeOf(options.expires) === 'date') {
        cookie = cookie + '; expires=' + options.expires.toUTCString();
      }
      if (!Ember.isEmpty(options.maxAge)) {
        cookie = cookie + '; max-age=' + options.maxAge;
      }
      if (options.secure) {
        cookie = cookie + '; secure';
      }
      if (!Ember.isEmpty(options.path)) {
        cookie = cookie + '; path=' + options.path;
      }

      return cookie;
    }
  });
});