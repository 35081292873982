define("ember-cli-foundation-6-sass/components/zf-callout", ["exports", "ember-cli-foundation-6-sass/templates/components/zf-callout"], function (_exports, _zfCallout) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // Note: this doesn't use the zfWidget mixin since this isn't part of the
  // Foundation javascript.
  var _default = Ember.Component.extend({
    layout: _zfCallout.default,
    type: '',
    content: null,
    classNameBindings: ['alertType', 'active', 'exiting', 'flashType'],
    classNames: ['callout'],
    // handle bindings for ember-cli-flash integration
    flashType: Ember.computed('flash.type', function () {
      return this.get('flash.type');
    }),
    // handle bindings for regular integration
    alertType: Ember.computed('type', function () {
      return this.get('type');
    })
  });

  _exports.default = _default;
});