define('twapi/models/acat-account', ['exports', 'ember-restless'], function (exports, _emberRestless) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberRestless.default.Model,
      attr = _emberRestless.default.attr,
      belongsTo = _emberRestless.default.belongsTo,
      hasMany = _emberRestless.default.hasMany;
  exports.default = Model.extend({

    isSynced: attr('boolean'),

    mask: attr('string'),

    name: attr('string'),

    externalInstitution: belongsTo('acatInstitution'),

    holdings: hasMany('holding')

  }).reopenClass({
    resourceName: 'acat-account'
  });
});