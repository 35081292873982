define('twapi/models/account-opening-application', ['exports', 'ember-restless', 'lodash', 'twapi/models/account-type', 'twapi/models/account-beneficiary', 'twapi/models/account', 'twapi/models/access-plan', 'twapi/utils/validation', 'ember-decorators/object/evented'], function (exports, _emberRestless, _lodash, _accountType, _accountBeneficiary, _account, _accessPlan, _validation, _evented) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.ENTITY_INITIAL_DEPOSIT_TYPES = exports.FOREIGN_INITIAL_DEPOSIT_TYPES = exports.ACCOUNT_ACTIVITY_TYPES = exports.BeneficiaryDesignations = exports.STATUSES = exports.COMPLETE = exports.CANCELED = exports.ACTION_REQUIRED = exports.SUPPORTING_DOCUMENTS_REQUIRED = exports.NEEDS_REVIEW = exports.REJECTED = exports.SEND_FAILED = exports.APPROVED = exports.SUBMITTED = exports.IN_PROGRESS = undefined;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _desc, _value, _obj;

  var _Ember$computed = Ember.computed,
      equal = _Ember$computed.equal,
      filterBy = _Ember$computed.filterBy,
      none = _Ember$computed.none,
      not = _Ember$computed.not,
      or = _Ember$computed.or,
      readOnly = _Ember$computed.readOnly,
      get = Ember.get,
      getProperties = Ember.getProperties,
      set = Ember.set,
      setProperties = Ember.setProperties;
  var Model = _emberRestless.default.Model,
      attr = _emberRestless.default.attr,
      belongsTo = _emberRestless.default.belongsTo,
      hasMany = _emberRestless.default.hasMany;
  var IN_PROGRESS = exports.IN_PROGRESS = 'In Progress';
  var SUBMITTED = exports.SUBMITTED = 'Submitted';
  var APPROVED = exports.APPROVED = 'Approved';
  var SEND_FAILED = exports.SEND_FAILED = 'Send Failed';
  var REJECTED = exports.REJECTED = 'Rejected';
  var NEEDS_REVIEW = exports.NEEDS_REVIEW = 'Needs Review';
  var SUPPORTING_DOCUMENTS_REQUIRED = exports.SUPPORTING_DOCUMENTS_REQUIRED = 'Supporting Documents Required';
  var ACTION_REQUIRED = exports.ACTION_REQUIRED = 'Action Required';
  var CANCELED = exports.CANCELED = 'Canceled';
  var COMPLETE = exports.COMPLETE = 'Complete';
  var READY_FOR_SKETCH_APPROVAL = 'Ready For Sketch Approval';

  var STATUSES = exports.STATUSES = [SUBMITTED, APPROVED, REJECTED, NEEDS_REVIEW, SUPPORTING_DOCUMENTS_REQUIRED, ACTION_REQUIRED, CANCELED, COMPLETE];

  var BeneficiaryDesignations = exports.BeneficiaryDesignations = {
    BENEFICIARY: 'Beneficiary',
    TRUST: 'Trust',
    ESTATE: 'Estate'
  };

  var PERSONAL_REFERRAL_TYPE = 'Personal Referral';
  var REFERENCE_SOURCE_TYPES = ['tastylive', 'Internet', 'Live Events', PERSONAL_REFERRAL_TYPE];

  var ACCOUNT_ACTIVITY_TYPES = exports.ACCOUNT_ACTIVITY_TYPES = ['Active Trading', 'Long Term Investing', 'Short Term Investing'];

  var EXPECTED_WITHDRAWAL_TYPES = ['FREQUENT', 'OCCASIONAL', 'RARE'];
  var INITIAL_DEPOSIT_TYPES = {
    ACCOUNT_TRANSFER: 'Account Transfer',
    WIRE: 'Wire',
    ACH: 'ACH',
    CHECK: 'Check'
  };
  var FOREIGN_INITIAL_DEPOSIT_TYPES = exports.FOREIGN_INITIAL_DEPOSIT_TYPES = [INITIAL_DEPOSIT_TYPES.ACCOUNT_TRANSFER, INITIAL_DEPOSIT_TYPES.WIRE];

  var ENTITY_INITIAL_DEPOSIT_TYPES = exports.ENTITY_INITIAL_DEPOSIT_TYPES = Object.values(INITIAL_DEPOSIT_TYPES);

  var LIQUIDITY_NEEDS = ['VERY_IMPORTANT', 'SOMEWHAT_IMPORTANT', 'NOT_IMPORTANT'];
  var INITIAL_FUNDING_SOURCES = ['Corporate Income', 'Investment Capital', 'Personal', 'Inherited Funds'];

  exports.default = Model.extend(_accountType.HasAccountTypeName, (_dec = (0, _evented.on)('didUpdate'), (_obj = {
    customer: belongsTo('customer'),

    status: attr('string', { readOnly: true }),
    submittedAt: attr('momentDateTime', { readOnly: true }),
    approvedAt: attr('momentDateTime', { readOnly: true }),
    rejectedAt: attr('momentDateTime', { readOnly: true }),
    externalReason: attr('string', { readOnly: true }),
    reason: attr('string', { readOnly: true }),

    accountTypeName: attr('string'),
    optionsLevel: attr('string'),
    marginOrCash: attr('string', { defaultValue: 'Margin' }),
    isForeign: attr('boolean'),
    needsCustomerSupportingDocuments: attr('boolean'),

    letterOfExplanation: attr('string'),
    decedentName: attr('string'),
    referenceSource: attr('string'),
    initialContact: attr('string'),
    isReferredToBroker: attr('bool'),
    referredName: attr('string'),
    referredRelationship: attr('string'),

    accountActivityType: attr('string'),
    primaryBanking: attr('string'),
    hasFullyPaidSecurities: attr('boolean'),
    initialDepositType: attr('string'),
    initialFundingSource: attr('string'),
    valueInitialDeposit: attr('number'),
    expectedWithdrawals: attr('string'),
    liquidityNeeds: attr('string'),
    riskTolerance: attr('string'),
    investmentObjective: attr('string'),
    wantFutures: attr('boolean'),
    investmentTimeHorizon: attr('string'),

    isTrusteeCompensated: attr('boolean'),

    accountNumber: attr('string', { readOnly: true }),

    entityId: attr('number'),

    accessPlan: belongsTo('access-plan', { defaultValue: function defaultValue() {
        return _accessPlan.TheWorksPlan.create();
      } }),

    agreements: hasMany('agreement'),

    accountBeneficiaries: hasMany('account-beneficiary'),

    applicants: hasMany('applicant'),

    _didUpdate: function _didUpdate() {
      if (get(this, 'isPersonallyReferred')) {
        setProperties(this, {
          initialContact: null,
          isReferredToBroker: true
        });
      } else {
        setProperties(this, {
          initialContact: get(this, 'referenceSource'),
          isReferredToBroker: false
        });
      }
    },


    isAnyJoint: readOnly('accountType.isAnyJoint'),

    isTrust: readOnly('accountType.isTrust'),

    isEntity: readOnly('accountType.isEntity'),

    isRetirement: readOnly('accountType.isAnyIra'),

    isAnyBeneficiaryIra: readOnly('accountType.isAnyBeneficiaryIra'),

    isPersonallyReferred: equal('referenceSource', PERSONAL_REFERRAL_TYPE),

    primaryBeneficiaries: filterBy('accountBeneficiaries', 'beneficiaryType', _accountBeneficiary.BeneficiaryTypes.PRIMARY),

    contingentBeneficiaries: filterBy('accountBeneficiaries', 'beneficiaryType', _accountBeneficiary.BeneficiaryTypes.CONTINGENT),

    beneficiaryDesignation: BeneficiaryDesignations.BENEFICIARY,

    hasEstateBeneficiaryDesignation: equal('beneficiaryDesignation', BeneficiaryDesignations.ESTATE),

    hasTrustBeneficiaryDesignation: equal('beneficiaryDesignation', BeneficiaryDesignations.TRUST),

    isUnsubmitted: none('status'),
    isInProgress: equal('status', IN_PROGRESS),
    isSubmitted: equal('status', SUBMITTED),
    isApproved: equal('status', APPROVED),
    isSendFailed: equal('status', SEND_FAILED),
    isRejected: equal('status', REJECTED),
    isNeedsReview: equal('status', NEEDS_REVIEW),
    isSupportingDocumentsRequired: equal('status', SUPPORTING_DOCUMENTS_REQUIRED),
    isActionRequired: equal('status', ACTION_REQUIRED),
    isCanceled: equal('status', CANCELED),
    isComplete: equal('status', COMPLETE),
    isReadyForSketchApproval: equal('status', READY_FOR_SKETCH_APPROVAL),
    isMargin: equal('marginOrCash', _accountType.MARGIN_TYPES.MARGIN),

    isTerminal: or('isApproved', 'isCancelled', 'isComplete', 'isRejected', 'isReadyForSketchApproval'),

    didLoad: function didLoad() {
      this._super.apply(this, arguments);

      var _getProperties = getProperties(this, 'optionsLevel', 'accountType'),
          optionsLevel = _getProperties.optionsLevel,
          accountType = _getProperties.accountType;

      set(this, 'accessPlan', _accessPlan.default.fromOptionsLevel(optionsLevel, accountType));
    },
    serialize: function serialize() {
      var serialized = this._super.apply(this, arguments);

      if (get(this, 'isTrust')) {
        serialized = _lodash.default.omit(serialized, 'beneficial-owners');
      }

      if (!get(this, 'isAnyJoint')) {
        serialized = _lodash.default.omit(serialized, 'applicants');
      }

      return serialized;
    }
  }, (_applyDecoratedDescriptor(_obj, '_didUpdate', [_dec], Object.getOwnPropertyDescriptor(_obj, '_didUpdate'), _obj)), _obj))).reopen(_validation.build({
    letterOfExplanation: { presence: { presence: true } },
    referenceSource: { inclusion: { in: REFERENCE_SOURCE_TYPES } },
    referredName: { presence: true },
    referredRelationship: { presence: true },
    decedentName: { presence: true },
    primaryBanking: { presence: true },
    valueInitialDeposit: { presence: true },
    liquidityNeeds: { inclusion: { in: LIQUIDITY_NEEDS } },
    accountActivityType: { inclusion: { in: Object.values(ACCOUNT_ACTIVITY_TYPES) } },
    expectedWithdrawals: { inclusion: { in: EXPECTED_WITHDRAWAL_TYPES } },
    initialDepositType: { inclusion: { in: Object.values(INITIAL_DEPOSIT_TYPES) } },
    initialFundingSource: { inclusion: { in: INITIAL_FUNDING_SOURCES } },
    investmentTimeHorizon: {
      presence: {
        presence: true,
        disabled: not('model.isEntity')
      },
      inclusion: { in: _account.INVESTMENT_TIME_HORIZONS }
    },
    isTrusteeCompensated: {
      presence: {
        presence: true,
        disabled: not('model.isTrust')
      }
    },
    riskTolerance: {
      presence: {
        presence: true,
        disabled: not('model.isEntity')
      },
      inclusion: { in: _account.RISK_TOLERANCES }
    },
    marginOrCash: {
      inclusion: { in: ['MARGIN', 'CASH'] }
    },
    accountTypeName: {
      inclusion: { in: _accountType.ACCOUNT_TYPE_NAMES }
    },
    investmentObjective: {
      inclusion: { in: Object.values(_account.INVESTMENT_OBJECTIVES) }
    }
  })).reopenClass({
    resourceName: 'account-opening-application'
  });
});