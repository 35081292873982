define('twapi/components/twapi-spinner', ['exports', 'twapi/templates/components/twapi-spinner'], function (exports, _twapiSpinner) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _twapiSpinner.default,
    classNames: ['twapi-spinner']
  });
});