define('twapi/models/document-verification-payload', ['exports', 'ember-restless'], function (exports, _emberRestless) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberRestless.default.Model,
      attr = _emberRestless.default.attr,
      belongsTo = _emberRestless.default.belongsTo;
  exports.default = Model.extend({
    value: attr('string'),
    metainfo: belongsTo('document-verification-payload-metainfo')
  }).reopenClass({
    resourceName: 'document-verification-payload'
  });
});