define('twapi/models/equity-offering', ['exports', 'ember-decorators/object', 'ember-restless', 'moment', '@tastyworks/tastyworks-api', 'twapi/utils/deser'], function (exports, _object, _emberRestless, _moment, _tastyworksApi, _deser) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _dec2, _dec3, _dec4, _desc, _value, _obj;

  var Model = _emberRestless.default.Model;
  var get = Ember.get,
      _Ember$computed = Ember.computed,
      and = _Ember$computed.and,
      not = _Ember$computed.not,
      notEmpty = _Ember$computed.notEmpty,
      or = _Ember$computed.or,
      isNone = Ember.isNone,
      isPresent = Ember.isPresent;
  exports.default = Model.extend(_deser.buildModel(_tastyworksApi.EQUITY_OFFERING_DESER)).reopenClass({
    resourceName: 'equity-offering'
  }).reopen((_dec = (0, _object.computed)('cancelledAt', 'activatedAt'), _dec2 = (0, _object.computed)('isUpdateOnly', 'isOrderWindowClosed'), _dec3 = (0, _object.computed)('orderWindowClosesAt'), _dec4 = (0, _object.computed)('minTicketSize', 'unitIncrement'), (_obj = {
    isInactive: not('active'),

    isInactiveOrCancelled: or('isInactive', 'isCancelled'),

    isOrderingDisabled: or('isInactiveOrCancelled', 'isOrdersClosed'),

    isCancelled: function isCancelled(cancelledAt, activatedAt) {
      if (isNone(cancelledAt)) {
        return false;
      }

      if (isNone(activatedAt)) {
        return true;
      }

      return cancelledAt.isAfter(activatedAt);
    },
    isOrdersClosed: function isOrdersClosed(isUpdateOnly, isOrderWindowClosed) {
      return isUpdateOnly || isOrderWindowClosed;
    },
    isOrderWindowClosed: function isOrderWindowClosed(orderWindowClosesAt) {
      return isPresent(orderWindowClosesAt) && (0, _moment.default)().isAfter(orderWindowClosesAt);
    },
    maxShares: function maxShares(value) {
      return Math.floor(value / get(this, 'minPrice'));
    },
    minShares: function minShares(value) {
      return Math.floor(value / get(this, 'maxPrice'));
    },
    minValue: function minValue(minTicketSize, unitIncrement) {
      return isNone(minTicketSize) || isNaN(minTicketSize) ? unitIncrement : minTicketSize;
    }
  }, (_applyDecoratedDescriptor(_obj, 'isCancelled', [_dec], Object.getOwnPropertyDescriptor(_obj, 'isCancelled'), _obj), _applyDecoratedDescriptor(_obj, 'isOrdersClosed', [_dec2], Object.getOwnPropertyDescriptor(_obj, 'isOrdersClosed'), _obj), _applyDecoratedDescriptor(_obj, 'isOrderWindowClosed', [_dec3], Object.getOwnPropertyDescriptor(_obj, 'isOrderWindowClosed'), _obj), _applyDecoratedDescriptor(_obj, 'minValue', [_dec4], Object.getOwnPropertyDescriptor(_obj, 'minValue'), _obj)), _obj)));
});