define('twapi/models/referral-user', ['exports', 'ember-restless', 'twapi/models/referral'], function (exports, _emberRestless, _referral) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberRestless.default.Model.extend({
    credits: _emberRestless.default.attr('number'),
    isQualified: _emberRestless.default.attr('boolean'),
    referral: _emberRestless.default.belongsTo('referral', { defaultValue: function defaultValue() {
        return _referral.default.create();
      } }),
    referralUsed: _emberRestless.default.belongsTo('referral', { defaultValue: function defaultValue() {
        return _referral.default.create();
      } })
  }).reopenClass({
    resourceName: 'referral-user'
  });
});