define('twapi/utils/validation', ['exports', 'lodash', 'ember-cp-validations'], function (exports, _lodash, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.BIRTH_DATE_VALIDATION_MESSAGE = undefined;
  exports._setOwner = _setOwner;
  exports.build = build;
  exports.validator = validator;
  exports.equal = equal;
  var computed = Ember.computed,
      _Ember$computed = Ember.computed,
      bool = _Ember$computed.bool,
      not = _Ember$computed.not;


  // TODO: fix this magical owner
  function _setOwner(owner) {
    _setOwner.owner = owner;
  }

  function build(facadeOptions) {
    if (!_setOwner.owner) {
      console.warn('Owner not set. Skipping validations...');
      return {};
    }

    var libraryOptions = _lodash.default.mapValues(facadeOptions, function (attrOptions, _attr) {
      return _lodash.default.flatten(_lodash.default.map(attrOptions, function (validatorOptions, validatorName) {
        if (Ember.isArray(validatorOptions)) {
          return validatorOptions.map(function (o) {
            return validator(validatorName, o);
          });
        } else {
          return validator(validatorName, validatorOptions);
        }
      }));
    });
    var mixin = (0, _emberCpValidations.buildValidations)(libraryOptions);
    return mixin.reopen(_setOwner.owner.ownerInjection());
  }

  function validator(name, options) {
    if (options.if) {
      var ifProperty = options.if;
      delete options.if;
      _lodash.default.merge(options, {
        presence: true,
        disabled: not('model.' + ifProperty)
      });
    }
    if (options.unless) {
      var unlessProperty = options.unless;
      delete options.unless;
      _lodash.default.merge(options, {
        presence: true,
        disabled: bool('model.' + unlessProperty)
      });
    }
    return (0, _emberCpValidations.validator)(name, options);
  }

  function equal(propName, value) {
    return computed.equal('model.' + propName, value);
  }

  var BIRTH_DATE_VALIDATION_MESSAGE = exports.BIRTH_DATE_VALIDATION_MESSAGE = 'You must select a valid birth date';
});