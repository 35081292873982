define('twapi/components/twapi-gender-checkbox', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      get = Ember.get;
  exports.default = Component.extend({
    tagName: 'label',
    classNames: 'amb-form-checkbox',
    classNameBindings: ['checked:checked:unchecked', 'disabled'],
    disabled: false,

    change: function change() {
      var value = get(this, 'value');

      var checked = !get(this, 'checked');

      get(this, 'onChange')(value, checked);
    }
  });
});